import React from "react";
import { Link, useParams } from "react-router-dom";
import { Summary } from "../../common/summary.component";
import { useLiensOutcomes } from "../liens-outcomes.helper";

export function LiensWithdrawal({ outcomes, urls }) {
  const params = useParams();
  const { withdrawalAvailable, cantCalculateOutcomes } =
    useLiensOutcomes(outcomes);
  return (
    <div>
      <div>
        <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
          <div className="cps-card__body">
            <div className="cps-row cps-margin-top-8 cps-padding-left-8">
              <div className="cps-body">
                Recommendation:{" "}
                <span>
                  {withdrawalAvailable && (
                    <span className="cps-color-primary">
                      Lien Withdrawal available
                    </span>
                  )}
                  {!withdrawalAvailable && !cantCalculateOutcomes && (
                    <span className="cps-warning">
                      Lien Withdrawal unlikely
                    </span>
                  )}
                </span>
                {cantCalculateOutcomes && (
                  <span className="cps-light-gray">
                    Recommendation unavailable
                  </span>
                )}
              </div>
              <div>
                {withdrawalAvailable && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, your client may qualify for Lien Withdrawal. Check
                    out the resources below for additional help.
                  </div>
                )}
                {!withdrawalAvailable && !cantCalculateOutcomes && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, Lien Withdrawal for your client is unlikely.
                    Sometimes it doesn't hurt to try though - check out the
                    resources below to learn more.
                  </div>
                )}
                {cantCalculateOutcomes && (
                  <div>
                    We cannot provide a recommendation at this time. Please
                    complete the{" "}
                    <Link to={urls.assistant}>Liens Assistant</Link> if you
                    would like custom recommendations for your client.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Summary>
          <p>
            A lien withdrawal removes a lien from public record. Liens are filed
            at the county recorder/clerk's office and are considered a public
            record. The IRS does not specifically notify credit reporting
            agencies of the filing of a NFTL. With that said, credit reporting
            agencies do take note of NFTLs.
          </p>

          <p>
            You may ask for a withdrawal of the filing of the NFTL if you
            believe the IRS filed the NFTL prematurely or did not follow
            procedures, or you have entered into an installment agreement and
            the installment agreement does not provide for the filing of the
            NFTL. A withdrawal does not remove the lien from your IRS records.
          </p>

          <p>
            This process could take 30 to 90 days, and sometimes longer. Be
            aware that if the IRS thinks the taxpayer will file a return in the
            future with a balance due, the IRS may challenge your taxpayer's
            request for a lien withdrawal.
          </p>

          <p>
            If the IRS approves the lien withdrawal, keep in mind that the
            taxpayer must file all future returns by the filing date and must
            pay the entire balance by the deadline. In addition, any under
            withholding must be corrected, which can be done by the taxpayer by
            submitting an updated W4 to his/her employer. Additionally, the
            taxpayer must make all required estimated tax payments by their
            respective due dates.
          </p>

          <p>
            You may request a lien withdrawal when any of the below conditions
            in bold are met:
          </p>

          <ul>
            <li>
              <strong>Tax has been paid in full</strong> - The IRS will not
              automatically withdraw a lien when the tax is paid in full; you
              must request withdrawal.
              <p>
                <i>Example:</i> The taxpayer makes his or her last installment
                payment and now has paid the IRS in full. Thus, you request a
                certificate of withdrawal from the IRS.
              </p>
            </li>
            <li>
              <strong>
                Withdrawal of the lien will allow the IRS to be paid sooner (IRC
                § 6323(j)(1)(C))
              </strong>{" "}
              - The IRS may withdraw a lien when it would help the taxpayer gain
              employment or credit to produce income or obtain a loan - allowing
              the taxpayer to make IRS payments, increase payments or fully pay
              the taxes.
              <p>
                This argument is based on the idea that it is in the Best
                Interest of the Government to withdraw the lien. You will need
                to demonstrate that it is harming the taxpayer in such a way to
                have the lien filed that it will actually prevent the government
                from receiving payment on the tax liability. You may also
                consider if there are specific circumstances or hardships that
                make this argument stronger.
              </p>
              <p>
                <i>Example:</i> The taxpayer is unemployed and the IRS files a
                lien. The taxpayer is offered a job, provided that he or she is
                bondable, but the bonding company refuses to issue a bond
                because the taxpayer's credit score has been reduced by the lien
                and is unacceptably low. The taxpayer agrees to pay the tax
                through payroll deductions if the IRS will withdraw the lien.
                The IRS agrees to do so, but can file a lien again if the
                taxpayer defaults on the agreement.
              </p>
            </li>
            <li>
              <strong>
                Obtain a direct debit installment agreement (IRC §
                6323(j)(1)(B))
              </strong>{" "}
              - The IRS may withdraw the lien if the taxpayer obtains a direct
              debit installment agreement (DDIA). This includes if the taxpayer
              is currently in an installment agreement and converts to a DDIA. A
              DDIA is applicable for an assessed balance of $25,000 or less and
              when the taxpayer can pay the remaining balance in full within 60
              months or before the statute expires.
              <p>
                If you have a client with an existing regular Installment
                Agreement (IA), consider converting it to a direct debit IA and
                then help your client obtain a lien withdrawal. You may also
                want to review any current direct debit IA and verify whether
                your client has met the requirements to have the lien released.
              </p>
              <p>These requirements must be met:</p>
              <ul>
                <li>
                  The taxpayer is compliant with all current filing and paying
                  requirements.
                </li>
                <li>
                  The taxpayer makes at least three consecutive electronic
                  payments.
                </li>
                <li>
                  The taxpayer hasn't already had a lien withdrawn on any of the
                  tax years included in the agreement.
                </li>
              </ul>
              <p>
                <i>Example:</i> The taxpayer set up an installment agreement for
                a balance of $30,000. The taxpayer has mailed payments of $500
                per month for the past 15 months, reducing the balance to
                $22,500. The taxpayer can now make direct debits from his or her
                bank account and request lien withdrawal after three consecutive
                electronic payments are made.
              </p>
            </li>
            <li>
              <strong>
                Lien was filed too soon or not according to IRS procedures (IRC
                § 6323(j)(1)(A))
              </strong>{" "}
              - Though rare, the IRS may withdraw the lien if it was filed too
              soon or not according to IRS procedures. Note that requesting
              withdrawal is usually quicker than appealing the lien through a
              Collection Due Process hearing.
              <p>
                <i>Example:</i> The IRS grants the taxpayer a 30-day extension
                of time to pay a liability in full. With this extension, the IRS
                agrees that it will not file a lien during this 30-day period.
                Ten days later, the IRS files a lien anyway and you are aware
                that the lien was filed without manager approval (which is
                explicitly not allowed). The taxpayer requests a lien withdrawal
                because the lien filing was not in accordance with IRS
                administrative procedures.
              </p>
            </li>
            <li>
              <strong>
                Taxpayer Advocate Service recommended (IRC § 6323(j)(1)(D))
              </strong>{" "}
              - The IRS may withdraw the lien if the Taxpayer Advocate Service
              recommended lien withdrawal. If a taxpayer is experiencing a
              severe hardship due to the lien, don't hesitate involve TAS. A
              hardship is one of the qualifying reasons that TAS can step in to
              assist a taxpayer.
              <p>
                <i>Example:</i> The taxpayer contacts the Taxpayer Advocate
                Service, stating that he or she can't purchase inventory to
                generate income because of the lien. The advocate considers all
                of the facts and determines that lien withdrawal is in the best
                interest of the government and the taxpayer. Lien withdrawal
                would allow the taxpayer to be able to purchase inventory and
                then make payments on the outstanding tax liability.
              </p>
            </li>
          </ul>

          <p>
            If the taxpayer needs additional copies of Form 10916(c),{" "}
            <i>Certificate of Withdrawal of Notice of Federal Tax Lien</i>, send
            a written request to the Technical Services Group manager using the
            Advisory Group address found in Publication 4235. The request must
            provide:
          </p>

          <ul>
            <li>Name, current address and identification number</li>
            <li>Copy of the notice of withdrawal (if available)</li>
            <li>
              Names and addresses of any credit reporting agencies, financial
              institutions or creditors to be notified of the withdrawal
            </li>
          </ul>

          <p>The following steps outline the lien withdrawal process:</p>

          <ul>
            <li>The IRS files a lien at the taxpayer's local courthouse.</li>
            <li>
              The taxpayer meets the criteria to obtain a lien withdrawal.
            </li>
            <li>
              Prepare the lien withdrawal package (Form 12277,{" "}
              <i>
                Application for Withdrawal of Filed Form 668(Y), Notice of
                Federal Tax Lien
              </i>
              , including all required documentation).
            </li>
            <li>Submit the application to the IRS Advisory Group.</li>
            <li>Wait 30 to 90 days (or longer) for an IRS determination.</li>
            <li>
              If the IRS approves the withdrawal request, the IRS will mail a
              copy of the lien withdrawal certificate to you; to the courthouse
              where the lien was recorded; and to each of the creditors,
              reporting agencies and/or financial institutions on the list
              provided.
            </li>
            <li>
              Contact the courthouse to confirm the lien withdrawal was received
              and recorded.
            </li>
            <li>
              If the IRS does not approve the request, follow up immediately
              with the Advisory Group manager and, if necessary, consider filing
              an appeal.
            </li>
          </ul>
        </Summary>

        <div className="cps-fixed-masonry">
          <div className="cps-row">
            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Templates</strong>
                </div>
                <a
                  className="cps-slat"
                  href="https://cdn.canopytax.com/static/liens/withdrawal/AttachmentToForm12277.docx"
                >
                  <div className="cps-slat__badge cps-padding-left-24">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                      className="cp-outcomes__file-icon"
                    />
                  </div>
                  <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                    Attachment to Form 12277
                  </div>
                </a>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Contact info</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>Mailing Form 12277</strong>
                    <br />
                    Mail completed Form 12277 to the IRS office assigned your
                    account.
                  </p>

                  <p>
                    <strong>
                      Mailing Form 12277 (if the account is not assigned or you
                      are uncertain where it is assigned)
                    </strong>
                    <br />
                    IRS, Attn: Advisory Group Manager
                    <br />
                    Refer to{" "}
                    <a
                      href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                      target="_blank"
                    >
                      Publication 4235
                    </a>{" "}
                    Collection Advisory Group Addresses for the correct address
                    to send Form 12277. The correct address is the office
                    assigned to the location where the property is located.
                  </p>

                  <p>
                    <strong>Other</strong>
                    <br />
                    If you want to know how much you owe in order to pay the
                    lien(s) in full, call 1-800-913-6050, visit the nearest
                    Taxpayer Assistance Center, or contact the revenue officer
                    assigned to your case. You can also obtain a copy of lost
                    liens or check the status of liens or requests.
                  </p>
                </div>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Links and articles</strong>
                </div>

                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/Businesses/Small-Businesses-&-Self-Employed/Understanding-a-Federal-Tax-Lien"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Understanding a Federal Tax Lien
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 4235 - Collection Advisory Group contact
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p1468.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Guidelines for processing Notice of Federal Tax Lien
                        documents
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
              </div>
            </div>

            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>IRS forms</strong>
                </div>

                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/QGDZ/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 12277
                    </div>
                    Application for Withdrawal of Filed Form 668(Y), Notice of
                    Federal Tax Lien
                  </div>
                </Link>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Best practices</strong>
                </div>

                <div className="cps-card__body">
                  <ul>
                    <li>
                      <strong>Five business days rule</strong> - When a lien is
                      created for a taxpayer, the IRS is required to follow up
                      with a Letter 3172 within <u>five business days</u>. If
                      the IRS sends it after that, file a request for a hearing
                      and have it withdrawn. The IRS may follow up again later
                      with another lien, but it may allow the taxpayer enough
                      time to use the asset to pay down debt or taxes.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Public records vs IRS records</strong> - A lien
                      withdrawal removes the Federal Tax Lien from public
                      records but does not remove it from IRS records.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Future non-compliance</strong> - Remind taxpayers
                      that any future non-compliance (either no/late filing of
                      returns or no/late paying of balance(s) due) can result in
                      the refiling of the lien assuming the statute for
                      collection has not expired.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Verify withdrawal</strong> - It is the taxpayer's
                      responsibility to verify that the lien withdrawal has been
                      recorded in his/her county recorder/clerk's office. This
                      may be a good add-on service to provide to your clients.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Credit reports</strong> - Taxpayers should review
                      their credit report and make contact with the credit
                      reporting agencies, providing copies of the lien
                      withdrawal if necessary, to ensure their credit report
                      reflect this appropriately.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Appeal</strong> - If your request for a lien
                      withdrawal is denied and you still feel you have
                      reasonable cause for the withdrawal, consider appealing
                      the decision.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Simultaneous requests</strong> - The
                      Commissioner's 2011 Fresh Start Initiative allows
                      withdrawal of your Notice of Federal Tax Lien after the
                      lien's release, but has the following requirements: 1)
                      Your tax liability has been satisfied and your lien has
                      been released, 2) You are in compliance for the past three
                      years in filing (all individual returns, business returns,
                      and information returns), and 3) You are current on your
                      estimated tax payments and federal tax deposits, as
                      applicable.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Credit reporting agencies</strong> - After filing
                      Form 12277, the IRS will provide Form 10916-A,
                      <i>
                        Withdrawal of Filed Notice of Federal Tax Lien after
                        Release
                      </i>
                      . You can then send copies of that form to all three
                      credit bureaus to have the lien removed from your credit
                      report. This may be a good add-on service to provide to
                      your clients.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
