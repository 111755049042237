import React from "react";
import { TopnavSecondary } from "primary-navbar!sofe";
import ActionIcon from "./action-icon";

export default class SecondaryNavigationBar extends React.PureComponent {
  render() {
    return (
      <TopnavSecondary>
        <div className="cps-topnav-secondary__content">
          <ul className="cps-topnav-secondary__content__menu +title">
            <li>Resolution Cases</li>
          </ul>
          <ul className="cps-topnav-secondary__content__right-menu">
            {this.props.iconConfig
              .filter((item) =>
                item.hideExpression && item.hideExpression() ? undefined : item
              )
              .map((icon) =>
                icon.component ? (
                  React.createElement(icon.component, {
                    ...icon.props,
                    key: JSON.stringify(icon),
                  })
                ) : (
                  <ActionIcon key={JSON.stringify(icon)} config={icon} />
                )
              )}
          </ul>
        </div>
      </TopnavSecondary>
    );
  }
}
