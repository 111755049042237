import React, { useEffect, useMemo, useState } from "react";
import styles from "./client-request-drawer.styles.css";
import { CpButton, CpDropdown, CpSelectSingle } from "canopy-styleguide!sofe";
import { find, isEmpty } from "lodash";

const reminderIntervals = [
  { id: "weekday", name: "Every Weekday" },
  { id: "every-mwf", name: "Every Monday, Wednesday, Friday" },
  { id: "every-tth", name: "Every Tuesday, Thursday" },
  { id: "every-m", name: "Every Monday" },
];

const expirationOptions = [
  { id: "15", name: "15 days" },
  { id: "30", name: "30 days" },
  { id: "45", name: "45 days" },
  { id: "60", name: "60 days" },
];

export function ClientRequestReminder({ request, onChange }) {
  const [reminderInterval, setReminderInterval] = useState(null);
  const [reminderExpiration, setReminderExpiration] = useState(null);

  useEffect(() => {
    if (isEmpty(request)) return;
    const selectedInterval = find(
      reminderIntervals,
      ({ id }) => request.reminders?.reminder_interval === id
    );
    setReminderInterval(selectedInterval);

    const selectedExpiration = find(
      expirationOptions,
      ({ id }) => request.reminders?.expiration_interval_days === id
    );
    setReminderExpiration(selectedExpiration);
  }, [request]);

  function renderReminderText() {
    switch (request.reminders?.reminder_interval) {
      case "weekday":
        return "Weekdays";
      case "every-mwf":
        return "Mon, Wed, Fri";
      case "every-tth":
        return "Tue, Thu";
      case "every-m":
        return "Mondays";
      default:
        return "Add a reminder";
    }
  }

  function handleSave() {
    const newRequest = {
      reminders: {
        reminder_interval: reminderInterval?.id,
        expiration_interval_days: reminderExpiration?.id,
        days_expires_in: Number(reminderExpiration?.id),
      },
    };
    onChange(newRequest);
  }

  function clearReminder() {
    setReminderInterval(null);
    setReminderExpiration(null);
    const newRequest = {
      reminders: {
        reminder_interval: null,
        expiration_interval_days: null,
        days_expires_in: null,
      },
    };
    onChange(newRequest);
  }

  return (
    <CpDropdown
      allowContentClicks
      contentWidth={326}
      renderTrigger={({ toggle }) => (
        <div className={styles.reminderTrigger}>
          <button onClick={toggle} className={styles.dialogTrigger}>
            {request.reminders?.reminder_interval ? (
              <span className={styles.triggerPlaceholder}>
                {renderReminderText()}
              </span>
            ) : (
              <span className={styles.triggerPlaceholderEmpty}>
                Add a reminder
              </span>
            )}
          </button>
          {request.reminders?.reminder_interval && (
            <CpButton onClick={clearReminder}>Remove</CpButton>
          )}
        </div>
      )}
      renderContent={({ close }) => (
        <div className={styles.remindersFormContainer}>
          <div className={styles.dialogTitle}>Client Reminder</div>
          <div className="cps-form-group">
            <label htmlFor="reminder-interval">Every</label>
            <CpSelectSingle
              id="reminder-interval"
              triggerIsBlock
              contentWidth="sm"
              data={reminderIntervals}
              onChange={setReminderInterval}
              value={reminderInterval}
              placeholder="Select weekdays, Mondays, Wednesdays and Fridays"
            />
          </div>
          <div className="cps-form-group">
            <label htmlFor="reminder-expiration">For</label>
            <CpSelectSingle
              id="reminder-expiration"
              triggerIsBlock
              contentWidth="sm"
              data={expirationOptions}
              onChange={setReminderExpiration}
              value={reminderExpiration}
              placeholder="Select 15 days, 30 days, 45 days or 60 days"
            />
          </div>
          <div className="cp-mb-24 cp-mb-8">Or until request is completed</div>
          <div className={styles.reminderActions}>
            <div>
              <CpButton
                disabled={!reminderInterval || !reminderExpiration}
                onClick={() => {
                  handleSave();
                  close();
                }}
                className="cp-mr-8"
              >
                Save
              </CpButton>
              <CpButton btnType="tertiary" onClick={close}>
                Cancel
              </CpButton>
            </div>
            {request.reminders?.reminder_interval && (
              <div>{request.reminders?.days_expires_in} Days Left</div>
            )}
          </div>
        </div>
      )}
    />
  );
}
