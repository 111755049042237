import React from "react";
import { Link, useParams } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { useLeviesOutcomes } from "../levies-outcomes.helper";

export function LeviesAppeals({ outcomes, urls }) {
  const params = useParams();
  const { appealsAvailable, cantCalculateOutcomes } =
    useLeviesOutcomes(outcomes);
  return (
    <div>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {appealsAvailable && (
                  <span className="cps-color-primary">Appeals available</span>
                )}
                {!appealsAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">Appeals unlikely</span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {appealsAvailable && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, your client may qualify for Appeals. Check out the
                  resources below for additional help.
                </div>
              )}
              {!appealsAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, Appeals for your client is unlikely. Sometimes it
                  doesn't hurt to try though - check out the resources below to
                  learn more.
                </div>
              )}
              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the <Link to={urls.assistant}>Levies Assistant</Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          There are several ways to dispute an IRS collection action. In
          addition to the options listed below, keep in mind that you can always
          contact the Taxpayer Advocate Service (TAS) if you think your client's
          case is not being handled fairly or if the taxpayer is experiencing a
          financial hardship and you are not receiving timely assistance to
          resolve the hardship caused by IRS action.
        </p>

        <p>
          TAS can be contacted via telephone at 1-877-777-4778 as well as by
          submitting Form 911,{" "}
          <i>Request for Taxpayer Advocate Service Assistance</i>. TAS contact
          information can also be found in Publication 1546,
          <i>Taxpayer Advocate Service - Your Voice at the IRS</i>.
          Additionally, there is also an{" "}
          <a
            href="https://www.irs.gov/Advocate/Local-Taxpayer-Advocate"
            target="_blank"
          >
            interactive map
          </a>{" "}
          to help you find the contact information of the closest TAS office.
          Remember that the state to choose is based on the state the taxpayer
          is located in, not where the representative is located.
        </p>

        <ul>
          <li>
            <p>
              <strong>Collection Appeals Program (CAP)</strong> - Only certain
              Collection actions can be appealed through the CAP program. In
              addition, you can neither challenge the existence or amount of the
              tax liability, nor can you proceed to court if you don't agree
              with Appeals decision in the CAP case. Collection actions that can
              be appealed through CAP include the following:
            </p>

            <ol>
              <li>Proposed or actual filing of a Notice of Federal Tax Lien</li>
              <li>
                Notice of Levy (can be appealed either before or after issuance)
              </li>
              <li>Seizure of Property</li>
              <li>
                Rejection, Modification, or Termination of Installment Agreement
              </li>
              <li>Wrongful Levy</li>
            </ol>

            <p>
              <i>How to obtain relief via a CAP hearing</i>
            </p>

            <ul>
              <li>
                Any disagreement between the Taxpayer/representative and a
                collection agent/revenue officer must first be discussed with
                said collection agent/revenue officer's manager. Upon your
                request for a conference with the manager, you should be
                contacted within 24 hours. This managerial review process can
                also assist you if you just started working with a client but
                the IRS is already on its way to seize assets. This request will
                stop the seizure until you have the ability to have a conference
                with the manager.
                <br />
              </li>
              <li>
                Once contacted by the collection agent/revenue officer's
                manager, you must explain why you disagree with said action(s)
                or decision made on the case. The manager will make a decision
                on the case at this time. If you disagree with the manager's
                decision, you can proceed with the CAP process. To do so,
                request that your case be forwarded to Appeals and submit Form
                9423,
                <i>Collection Appeal Request</i>, to the manager. You must let
                the manager know within two business days of the conference that
                you intend to appeal the decision.
                <br />
              </li>
              <li>
                Postmark Form 9423, <i>Collection Appeal Request</i>, within
                three business days of the conference with the collection
                manager. It is important to inform the manager of your intent to
                file an appeal before the postmark deadline date to avoid
                resumption of collection activity.
                <br />
              </li>
              <li>
                If you are not contacted by the manager within two days of
                requesting the conference, complete and submit Form 9423,{" "}
                <i>Collection Appeal Request</i>, to the Collection office where
                the assigned collection agent/revenue officer is located. Note
                the date of your initial request for a conference and indicate
                that you were not contacted by the manager. In this situation,
                the Form 9423 must be postmarked within three business days of
                your initial request for a conference to avoid resumption of
                collection action.
                <br />
              </li>
              <li>
                Upon receipt of Form 9423, <i>Collection Appeal Request</i>, you
                will be contacted by a member of Advisory to discuss your
                disagreement and come to a resolution.
                <br />
              </li>
            </ul>
          </li>
          <li>
            <strong>Collection Due Process hearing</strong> - The Collection Due
            Process (CDP) hearing is more formal than the Collection Appeals
            Program (CAP) hearing, and its findings are subject to review by the
            courts. The IRS is required to notify you the first time a Notice of
            Federal Tax Lien is filed for each tax and period. The IRS must
            notify you within five business days after the lien filing. This
            notice may be mailed, given to you, or left at your home or office.
            You then have 30 days, after that five-day period, to request a
            hearing with Appeals. The lien notice you receive will indicate the
            date this 30-day period expires.
            <p>
              If your request for a CDP hearing is not timely, you may request
              an equivalent hearing. To receive an equivalent hearing, your
              request must be postmarked on or before the end of the one-year
              period after the date of the levy notice or on or before the end
              of the one-year period plus 5 business days after the filing date
              of the Notice of Federal Tax Lien.
            </p>
            <p>
              Note that when you request a CDP hearing, the 10-year statute for
              the IRS to collect taxes will be suspended until the date the
              determination becomes final.
            </p>
            <p>
              <i>How to obtain a CDP hearing</i>
            </p>
            <ul>
              <li>
                Complete Form 12153,{" "}
                <i>
                  Request for a Collection Due Process or Equivalent Hearing
                </i>
                , or a written request with the same information and send it to
                the IRS address shown on the latest notice. Once the IRS
                receives the request, it will typically cease collection action.
                <br />
              </li>
              <li>
                Appeals will contact you to schedule a conference. Appeals will
                verify whether the notice was correctly issued, explore
                collection alternatives or challenges to the liability, and
                determine whether the IRS' action was appropriate based on your
                client's situation. Appeals will then issue a determination
                letter.
                <br />
              </li>
              <li>
                If your client disagrees, he or she can petition the U.S. Tax
                Court within 30 days of the determination letter.
                <br />
              </li>
            </ul>
          </li>
          <li>
            <strong>Equivalent hearing</strong> - If the request for a
            Collection Due Process (CDP) hearing is not done timely, you can
            instead request an equivalent hearing. The request must be
            postmarked no later than one year plus five business days after the
            filing date of the notice of federal tax lien or levy.
            <p>
              Filing a request for an equivalent hearing does not prohibit a
              levy or suspend the 10-year collection statute. An equivalent
              hearing is similar to a Collection Appeals Program (CAP) hearing.
              It is informal, and your client cannot appeal the decision to the
              U.S. Tax Court. It does, however, offer you an opportunity to
              discuss the case with an Appeals officer.
            </p>
            <p>
              <i>How to obtain relief via an equivalent hearing</i>
            </p>
            <ul>
              <li>
                To request an equivalent hearing, complete Form 12153,{" "}
                <i>
                  Request for a Collection Due Process or Equivalent Hearing
                </i>
                , and check the "equivalent hearing" box on line 7 the form. You
                must provide alternatives and reasons for disagreeing with the
                collection action.
                <br />
              </li>
              <li>Appeals will contact you to schedule a conference.</li>
            </ul>
          </li>
        </ul>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>

              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/AttachmentToForm9423.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Attachment to Form 9423
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/AttachmentToForm12153.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Attachment to Form 12153
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Contact info</strong>
              </div>

              <div className="cps-card__body">
                <ul>
                  <li>
                    <strong>
                      <i>Form 9423</i>
                    </strong>
                    <br />
                    Never forward your request for an Appeals conference
                    directly to Appeals. It must be submitted to the office
                    which took the action on your installment agreement.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>
                      <i>Form 12153</i>
                    </strong>
                    <br />
                    Call the phone number on the notice or 1-800-829-1040 if you
                    are not sure about the correct address or if you want to fax
                    your request. File your request by mail at the address on
                    your lien notice or levy notice. You may also fax your
                    request. Do not send your CDP or equivalent hearing request
                    directly to the IRS Office of Appeals.
                  </li>
                </ul>
              </div>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/Individuals/Appeals-Resolving-Tax-Disputes"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">Appeals</span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p5.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 5 - Your appeal rights
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p594.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 594 - The IRS collection process
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p1660.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 1660 - Collection appeal rights
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p1546.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 1546 - Taxpayer Advocate Service is here to
                      help
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
            </div>
          </div>

          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/V912/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 9423
                  </div>
                  Collection Appeal Request
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/CPK7D/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 12153
                  </div>
                  Request for a Collection Due Process or Equivalent Hearing
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/CF9RS/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 911
                  </div>
                  Request for a Taxpayer Advocate Service Assistance
                </div>
              </Link>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>Suspend collections</strong> - As long as your request
                  for a CDP hearing is timely, the 10-year period the IRS has to
                  collect your taxes will be suspended until the Appeals’
                  determination becomes final or you withdraw your request for a
                  hearing in writing.
                </p>
                <p>
                  <strong>Equivalent hearing</strong> - If your request for a
                  CDP hearing was not timely but you requested an equivalent
                  hearing, the law does not prohibit levy and the collection
                  statute is not suspended.
                </p>
                <p>
                  <strong>CDP Appeal</strong> - During a CDP Appeal, you cannot
                  raise an issue that was raised and considered at a prior
                  administrative or judicial hearing if you or the taxpayer
                  participated meaningfully in the prior hearing or proceeding.
                </p>
                <p>
                  <strong>Court-ordered restitution</strong> - You may not
                  challenge the existence or amount of an assessment made based
                  on court ordered restitution.
                </p>
                <p>
                  <strong>General timeframe for Appeals responses</strong> -
                  Response times from Appeals will vary, depending on the type
                  of case and the time needed to review the file. Normally, an
                  Appeals employee will contact the taxpayer/representative
                  within 90 days of a request being filed.
                </p>
                <p>
                  If more than 90 days have passed since filing, contact the
                  office where the original appeal request was sent. That
                  office/contact can advise when the case was actually forwarded
                  to Appeals. Allow 90 days from when the case file was sent to
                  Appeals for a reply.
                </p>
                <p>
                  If there seems to be undue delay, you have two options: (1)
                  have the recipient office contact Appeals for an approximate
                  date when Appeals might be in contact or (2) call the Appeals
                  Account Resolution Specialist (AARS) at 559-233-1267 (this
                  option is generally quicker). The AARS can advise if the case
                  has been assigned to an Appeals employee and how to contact
                  that employee directly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
