import React from "react";
import { Summary } from "../../common/summary.component";
import { Link, useParams } from "react-router-dom";
import { useTrustFundOutcomes } from "../trust-fund-outcomes.helper";

export function TrustFundTfrpDefense({ urls, outcomes }) {
  const params = useParams();
  const { TFRPDefenseAvailable, cantCalculateOutcomes } =
    useTrustFundOutcomes(outcomes);
  return (
    <div>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {TFRPDefenseAvailable && (
                  <span className="cps-color-primary">
                    Individual-level resolution available
                  </span>
                )}
                {!TFRPDefenseAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">
                    Individual-level resolution unlikely
                  </span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {TFRPDefenseAvailable && (
                <div>
                  Thank you for using the Trust Fund Recovery Penalty Assistant!
                  Based on your answers, your client may qualify for
                  individual-level resolution. Check out the resources below for
                  additional help.
                </div>
              )}
              {!TFRPDefenseAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Trust Fund Recovery Penalty Assistant!
                  Based on your answers, individual-level resolution for your
                  client is unlikely. Sometimes it doesn't hurt to try though -
                  check out the resources below to learn more.
                </div>
              )}
              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the{" "}
                  <Link to={urls.assistant}>
                    Trust Fund Recovery Penalty Assistant
                  </Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          Trust fund taxes are collected or withheld by third parties on the
          government's behalf and held in trust until remitted to the
          government. They should not be used for any other purpose. Common
          examples include income taxes, employment taxes, and certain excise
          taxes. Problems can arise when a business falls on hard times and dips
          into the trust fund taxes to pay other operating expenses.
        </p>

        <p>
          For the purposes of this summary, we will outline specific employment
          tax information, but know that Trust Fund Recovery Penalties can be
          assessed on other types of taxes as well.
        </p>

        <p>
          Under the provisions of Section 6672 of the Internal Revenue Code, the
          IRS is authorized to assess a penalty to responsible parties that fail
          to timely collect and pay withholding taxes that were taken from
          employee paychecks. The Trust Fund Recovery Penalty (TFRP) is equal to
          the unpaid portion of income taxes, Social Security taxes, and
          Medicare taxes withheld from employee paychecks.
        </p>

        <p>
          The TFRP allows the government to in essence &quot;pierce the
          corporate veil&quot; and assert a liability against an individual for
          the withheld taxes that were not remitted as required to the
          government. The employer is liable for the entire payroll tax,
          including the Trust Fund (withheld portion) as well as the employer's
          contribution and any interest and penalties. However, the TFRP is
          limited only to the portion of Trust Fund taxes that were required to
          be withheld in trust and paid in to the IRS. Interest does not accrue
          on this liability until it is assessed against the individual. There
          is also no additional penalty added to the trust fund liability.
        </p>

        <p>
          Lastly, if an individual is assessed a TFRP, it will only be collected
          once. This is because it is used only as a collection device. The
          liability is joint and several, so no matter how many parties have
          been assessed, the IRS can collect from any of the parties in varying
          amounts until the tax is satisfied.
        </p>

        <p>
          If your client is at risk of being assessed the TFRP, consider the
          following steps to prevent a TFRP investigation, look to other
          potentially responsible parties, or defend against it being assessed.
        </p>

        <p>
          <strong>Prevention:</strong>
        </p>

        <p>
          If the business is able to pay the entire amount of taxes owed in full
          now, or at least the trust fund portion of the taxes owed, it may
          prevent the launch of a TFRP investigation. You will need to ensure
          the payment is properly designated towards Trust Fund taxes,
          especially if it is a partial payment only.
        </p>

        <p>
          If the business cannot pay the entire amount of taxes owed in full
          now, you can prevent a TFRP investigation by setting up an Express
          Installment Agreement, assuming the business qualifies. To qualify the
          business must meet the following requirements:
        </p>

        <ul>
          <li>Owe less than $25,000</li>
          <li>Pay the debt in full within 24 months</li>
          <li>
            Be fully compliant with all filings and current payments (at least
            for the current quarter)
          </li>
        </ul>

        <p>
          Additionally, you may be able to set up this IA with ACS and not need
          to have it assigned to a Revenue Officer.
        </p>

        <p>
          It is also advisable to review the Statute of Limitations (SOL). If
          the SOL has run out, the IRS cannot assess the TFRP on an individual.
          Remember the SOL for this purpose is 3 years from the due date of the
          return (or later if filed later). Furthermore, for all payroll taxes,
          this due date is considered April 15 of the following year, regardless
          of which quarter may be affected.
        </p>

        <p>
          <strong>Responsible party &amp; willfulness</strong>
        </p>

        <p>
          In order for your client to be assessed a TFRP, the IRS must first
          show that your client was BOTH{" "}
          <u>
            <i>responsible and willful</i>
          </u>
          . These tests are applied quarter by quarter, and both tests must be
          met in order for your client to be assessed.
        </p>

        <p>
          <u>Determining responsibility</u> - This determination depends on the
          facts and circumstances. Responsible persons can include officers,
          corporate directors or shareholders of a corporation, partners of a
          partnership, or employees. Even other persons or entities outside the
          delinquent organization, such as bankers, bookkeepers, or accountants,
          can be considered responsible parties.
        </p>

        <p>
          The TFRP is not assessed on individuals who are under the control of
          others and who are not in a position to make independent decisions.
          Additionally, the TFRP is not normally imposed on unpaid volunteers or
          directors of tax exempt organizations who don't participate in the
          financial operations of the organization and lack knowledge of the
          failure to remit the payroll taxes.
        </p>

        <p>
          To be considered responsible, a person must normally have the power to
          pay or direct which bills are paid (or not paid).
        </p>

        <p>
          <u>Willfulness</u> - In addition to being responsible, your client
          must ALSO be considered willful in order to be assessed a TFRP.
          "Willfulness" has been defined as "intentional, deliberate, voluntary,
          reckless, knowing, as opposed to accidental." No evil intent or bad
          motive is required.
        </p>

        <p>
          The IRS must show that a person was aware or should have been aware of
          the outstanding tax liability, but intentionally disregarded the law
          or was indifferent. For example, a person who was aware of the
          outstanding payroll tax but gave preference to other creditors and
          bills acted willfully. Even failing to correct or investigate
          mismanagement after being notified withholding taxes were not paid
          satisfies this willful element. Willfulness cannot be avoided simply
          by delegating authority to others. Failure to personally attend to the
          payroll tax requirement can be considered of itself, willful.
        </p>

        <p>
          <strong>4180 interview:</strong>
        </p>

        <p>
          To determine if your client is potentially responsible for the TFRP,
          the Revenue Officer will request an interview with your client. This
          interview is documented on Form 4180. As the client's representative,
          you may refuse to have your client attend this interview and may
          choose instead to furnish the IRS with a previously filled out Form
          4180 (with attachments that help explain the answers in detail). This
          is generally recommended since this form is designed with very short
          'yes/no' answers that can provide a 'false positive' indicating
          potential responsibility that may not exist.
        </p>

        <p>
          Another option is to have your client attend the interview in person
          with you. If you choose to do this, it is advisable to review the 4180
          questions with your client in detail ahead of time and to instruct
          them to answer only the questions on the form.
        </p>

        <p>
          No matter the approach, there are many cautions that surround this
          form. For example, there is criminal exposure risk to clients. Clients
          can be criminally prosecuted for failing to pay employment taxes, and
          the 4180 form can be used as an admission of guilt by your client. On
          the other hand, simply refusing to provide this form could cause a
          Revenue Officer to recommend a criminal investigation. This is even
          more worrisome given that the IRS announced in public statements at
          the beginning of 2016 that it was intending to increase criminal
          prosecutions for trust fund violations. At a minimum, you should make
          your clients aware of the risk.
        </p>

        <p>
          <strong>Collectibility</strong>
        </p>

        <p>
          Consider collectability as a defense. If your client is unable to pay
          due to a financial hardship, the IRS can choose not to apply the TFRP
          against an individual. It is not required that an individual supply
          personal financial information to the IRS. However, if an individual
          would not have the ability to pay were a penalty to be assessed,
          consider supplying the IRS with a voluntary 433-A form and requesting
          that the IRS not assert the TFRP based on your client's financial
          condition. However, keep in mind that the IRS may still assess the
          TFRP, especially in cases where a business is a repeat offender or
          continues to pyramid the tax.
        </p>

        <p>
          <strong>Other responsible parties</strong>
        </p>

        <p>
          Your clients have the right to request the identities of other
          responsible parties. Clients who have been assessed the TFRP also have
          the right to personally sue any of the other responsible parties for
          recovery of any amounts they may end up paying.
        </p>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Contact info</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>Tax professional priority line</strong>
                  <br />
                  1-866-860-4259
                </p>
                <p>
                  <strong>Business collections line</strong>
                  <br />
                  1-800-829-3903
                </p>
                <p>
                  <strong>General business information line</strong>
                  <br />
                  1-800-829-0115
                </p>
                <p>
                  To reach your local taxpayer advocate office{" "}
                  <a href="http://www.taxpayeradvocate.irs.gov" target="_blank">
                    www.taxpayeradvocate.irs.gov
                  </a>{" "}
                  or (877) 777-4778
                </p>
              </div>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/6Z96/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 4180
                  </div>
                  Report of interview with individual relative to trust fund
                  recovery penalty or personal liability for excise taxes
                </div>
              </Link>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/F1YXV/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 433-A
                  </div>
                  Collection information statement for businesses
                </div>
              </Link>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/GXNXQ/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 433-D
                  </div>
                  Installment agreement
                </div>
              </Link>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/Voluntary_Business_Payment_Designation_Letter.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Voluntary business payment designation
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/In_Business_Trust_Fund_Express_IA_Cover_Letter.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - In-business trust fund express IA cover
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/In_Business_Trust_Fund Express_IA_Call_Script.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  In-business trust fund express IA call script
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/Letter_to_Request_TFRP_Responsible_Person_Info.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Request TFRP responsible person info
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/TFRP_Appeal_Request_Letter.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - TFRP Appeals request
                </div>
              </a>
            </div>
          </div>

          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>Uncollectibility</strong> - If the IRS would not be
                  able to collect the TFRP if assessed, the IRM instructs the
                  agent not to assess it (IRM 5.7.5.3.1), but many still do it
                  anyway. Raise this as a defense! You can tell the agent you
                  provided them with a 433-A even though it was not required,
                  and reference the IRM where it illustrates that the agent does
                  not need to assess the TFRP if your client is unable to pay.
                </p>

                <p>
                  <strong>Check signing authority</strong> - During the
                  investigation process the IRS will subpoena the bank for
                  records and check signature cards. The IRS generally considers
                  check signers to be in control of company finances (in other
                  words decision makers). However, sometimes a check signer does
                  not have control over finances. For example, the duties of an
                  accounting clerk are likely only ministerial in nature and
                  lack control. You will need to show that the evidence of check
                  signing authority is only a 'false positive'&mdash;it is
                  delegated authority as opposed to real control, and your
                  client lacks decision-making ability. You will want to request
                  all these bank records yourself to review the information and
                  provide proof if you need to appeal the decision.
                </p>

                <p>
                  <strong>Letter 1153 and appeal rights</strong> - If the
                  Revenue Officer believes your client had responsibility and
                  was willful, he or she will send Letter 1153, Proposed
                  Assessment of Trust Fund Recovery Penalty. It is important to
                  keep in mind that Letter 1153 is only a proposal of potential
                  liability, not a final determination. If you disagree, this
                  letter provides a 60 day right to file an administrative
                  appeal. While the appeal is pending, the liability is only
                  proposed, so nothing will be on the IRS's books until the
                  investigation is considered final. This is also a good time to
                  reduce the liability amount by designating any extra payments
                  towards the Trust Fund liability portion of the tax.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
