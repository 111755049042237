import { trustFundConstants } from "./trust-fund.constants";
import { useMemo } from "react";

export const useTrustFundOutcomes = (outcomes) => {
  return useMemo(
    () => ({
      cantCalculateOutcomes:
        outcomes === trustFundConstants.OUTCOME_NOT_YET_CALCULABLE ||
        !outcomes?.length,
      reliefUnlikely: !!(
        outcomes && outcomes.indexOf(trustFundConstants.RELIEF_UNLIKELY) >= 0
      ),
      appealAvailable: !!(
        outcomes && outcomes.indexOf(trustFundConstants.APPEAL) >= 0
      ),
      employmentTaxAvailable: !!(
        outcomes && outcomes.indexOf(trustFundConstants.EMPLOYMENT_TAX) >= 0
      ),
      employmentTaxWarningAvailable: !!(
        outcomes &&
        outcomes.indexOf(trustFundConstants.EMPLOYMENT_TAX_WARNING) >= 0
      ),
      TFRPDefenseAvailable: !!(
        outcomes && outcomes.indexOf(trustFundConstants.TFRP_DEFENSE) >= 0
      ),
    }),
    [outcomes]
  );
};
