// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dashboard-resolution-cases-table-steps-steps-styles__summaryValue--D9sZR {\n  width: 32px;\n  line-height: 32px;\n  cursor: pointer;\n  justify-content: center;\n  display: flex;\n  border-radius: 0.5rem;\n}\n\n.src-dashboard-resolution-cases-table-steps-steps-styles__summaryValue--D9sZR:hover {\n  background-color: #e9e9e9;\n}\n\n.src-dashboard-resolution-cases-table-steps-steps-styles__dropdown--1KI-I {\n  float: right;\n  position: absolute;\n}\n\n.src-dashboard-resolution-cases-table-steps-steps-styles__absolute--Cfo_0 {\n  position: absolute;\n}\n\n.src-dashboard-resolution-cases-table-steps-steps-styles__centeredLoader--2Blus {\n  min-height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"summaryValue": "src-dashboard-resolution-cases-table-steps-steps-styles__summaryValue--D9sZR",
	"dropdown": "src-dashboard-resolution-cases-table-steps-steps-styles__dropdown--1KI-I",
	"absolute": "src-dashboard-resolution-cases-table-steps-steps-styles__absolute--Cfo_0",
	"centeredLoader": "src-dashboard-resolution-cases-table-steps-steps-styles__centeredLoader--2Blus"
};
module.exports = exports;
