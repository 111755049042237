import React, { createContext, useContext } from "react";

const CommentsContext = createContext();

export function useComments() {
  return useContext(CommentsContext);
}

export function CommentsProvider({ children, ...props }) {
  return (
    <CommentsContext.Provider value={props}>
      {children}
    </CommentsContext.Provider>
  );
}
