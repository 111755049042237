import React from "react";
import { CpModal, CpButton, modalService } from "canopy-styleguide!sofe";

export function renderInfoModal(title, content, modalOptions = { width: 500 }) {
  return modalService.render(InfoModal, { title, content }, modalOptions);
}

function InfoModal({ onSubmit, title, content }) {
  return (
    <>
      <CpModal.Header title={title} />
      <CpModal.Body>{content}</CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="secondary"
          className="cp-mr-8"
          onClick={() => onSubmit()}
        >
          Done
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
