// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dashboard-secondary-navigation-bar-action-icon-action-icon-styles__fontSize24--1e_50 {\n  font-size: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"fontSize24": "src-dashboard-secondary-navigation-bar-action-icon-action-icon-styles__fontSize24--1e_50"
};
module.exports = exports;
