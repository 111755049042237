import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CpTooltip } from "canopy-styleguide!sofe";

import { CheckboxClickableField } from "../../common/checkbox-clickable-item.component";

const TOOLTIP_ACCURACY =
  "IRC 6662. You may wish to refer to IRM 20.1.1.3.3.4.3 for additional information if you think this may apply.";
const TOOLTIP_ADVICE =
  "The IRS is required under IRC 6404(f) and Treas. Reg. 301.6404-3 to abate any portion of any penalty attributed to erroneous written advice furnished by an officer or employee of the IRS acting in their official capacity. They have extended this relief to include oral advice when appropriate. However, if they do not meet these criteria, they may still qualify if they show ordinary business care and prudence in relying on the written advice.";
const TOOLTIP_EXTENSION =
  "Form 1127, Application for Extension of Time for Payment of Tax Due to Undue Hardship";
const TOOLTIP_INCONVENIENCE = "Treas. Reg. 1.6161-1(b)";

export function PenaltyAbatementCircumstance({
  updateAnswersInMemory,
  answer = {},
}) {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      illness: false,
      disaster: false,
      incapable: false,
      mistake: false,
      erroneous_advice: false,
      blame_irs: false,
      ignorant: false,
      hardship: false,
      reasonable: false,
      not_applicable: false,
      ...answer,
    },
    mode: "onChange",
  });

  const onSubmit = (data) => updateAnswersInMemory(data);

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div>
      <div className="cps-padding-bottom-32">
        <div className="cps-subheader-sm">
          <strong>
            In the list below, select any situations that apply to your client
            (you may select more than one):
          </strong>
        </div>
      </div>
      <CheckboxClickableField
        control={control}
        fieldName="illness"
        label="Your client experienced a death, serious illness, or
                    unavoidable absence"
      >
        <div>Consider the following:</div>
        <ul>
          <li>This is for the taxpayer or their immediate family only.</li>
          <li>
            The Issue must be addressed in a reasonable time after the death or
            illness.
          </li>
          <li>
            They should show that this caused other obligations to also be
            impaired – not just taxes.
          </li>
          <li>You need written proof of the illness or death.</li>
          <li>
            Some potential examples of this could be terminal illness, surgery,
            substance or physical abuse, depression, disability, and emotional
            or psychological distress.
          </li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="disaster"
        label="Your client experienced a fire, casualty, or natural disaster"
      >
        <div>Consider the following:</div>
        <ul>
          <li>What steps were taken to comply?</li>
          <li>Did the taxpayer comply as soon as it was possible to?</li>
          <li>
            Major disasters include hurricane, tornado, earthquake, flood, riot,
            casualty, or other 'emergency' or disturbance.
          </li>
          <li>
            Consider how this may create other reasonable causes – for example a
            fire could cause them to be unable to access his or her records (See
            unable to obtain records next) or if it is a declared natural
            disaster – the IRS issues special instructions which may make
            penalties eligible for abatement through specific rules or process.
          </li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="mistake"
        label="Your client was unable to obtain records"
      >
        <div>Consider the following:</div>
        <ul>
          <li>Why were the records unavailable?</li>
          <li>Why were the records needed to comply?</li>
          <li>
            When and how did the taxpayer become aware that they didn't have the
            necessary records?
          </li>
          <li>What other means were explored to secure the information?</li>
          <li>Why didn't the taxpayer estimate the information?</li>
          <li>
            Did the taxpayer comply once the missing information was obtained?
          </li>
          <li>
            Consider how the taxpayer's specific facts show they exercised
            ordinary business care and prudence but due to circumstances beyond
            their control, they were unable to comply.
          </li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="incapable"
        label="Your client was subject to a mistake that was made"
      >
        <div>Consider the following:</div>
        <ul>
          <li>When and how did the taxpayer become aware of the mistake?</li>
          <li>How did the taxpayer attempt to correct the mistake?</li>
          <li>
            What was the relationship between the taxpayer and the person they
            relied on (if the mistake was not the taxpayer's own and they
            delegated their duty)?
          </li>
          <li>
            Did the taxpayer then take timely steps to correct the mistake after
            it was discovered?
          </li>
          <li>
            Generally a mistake does not keep with the idea of 'ordinary
            business care and prudence'. It will be important to rely on the
            facts stated above to show compliance even though a mistake could
            have caused a taxpayer to fail in meeting their obligations if due
            to circumstances beyond their control they were unable to comply.
          </li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="erroneous_advice"
        label="Your client was provided or relied on erroneous advice"
      >
        <div>Consider the following:</div>
        <ul>
          <li>
            This relates to tax issues that are considered too technical or
            complicated for a layperson. Did they hire a tax preparer or
            consultant, and what is their level of understanding?
          </li>
          <li>
            Even if a taxpayer hires someone to prepare their taxes, they are
            still responsible for reporting all income to the person they hired.
            However, there is a specific relief from certain penalties due to
            erroneous advice from a tax advisor for the{" "}
            <CpTooltip text={TOOLTIP_ACCURACY}>
              <a>accuracy-related penalty</a>
            </CpTooltip>
            .
          </li>
          <li>
            Was the failure to comply due to a change in the tax law that the
            taxpayer could not be reasonably expected to know? (you may also
            want to explore Ignorance of the Law as another potential reasonable
            cause).
          </li>
          <li>
            Was the taxpayer unable to comply because they did not have access
            to their own records?
          </li>
          <li>
            You may also wish to consider their educational level for their
            potential level of understanding.
          </li>
          <li>
            Generally a taxpayer is responsible for meeting their own tax
            obligations and that responsibility cannot be delegated, but there
            can be circumstances beyond their control preventing them from being
            able to comply.
          </li>
          <li>
            Additionally, there is specific relief for reliance on written or
            oral advice from the IRS. (See written/oral Advice from IRS next.)
          </li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="blame_irs"
        label={
          <div>
            Your client relied on{" "}
            <CpTooltip text={TOOLTIP_ADVICE}>
              <a>written or oral advice</a>
            </CpTooltip>{" "}
            from the IRS
          </div>
        }
      >
        <div>Consider the following:</div>
        <ul>
          <li>Was it reasonable for them to rely on that advice?</li>
          <li>
            Was there a clear connection between the taxpayer's situation, the
            advice provided, and the penalty assessed?
          </li>
          <li>What was the taxpayer's prior history of tax compliance?</li>
          <li>What type of supporting documentation is available?</li>
          <li>
            If the advice is oral – this can be very difficult to prove but it
            depends on the circumstances and supporting documentation which
            could include:
            <ul>
              <li>A notation of the taxpayer's question to the IRS.</li>
              <li>Documentation regarding the advice provided by the IRS.</li>
              <li>
                Information regarding the office and method by which the advice
                was obtained.
              </li>
              <li>The date the advice was provided, and</li>
              <li>The name of the employee who provided the information.</li>
              <li>
                While it isn't specifically required, you may wish to include
                the badge (ID) number of the IRS employee also.
              </li>
            </ul>
          </li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="ignorant"
        label="Your client was ignorant of the law"
      >
        <div>Consider the following:</div>
        <ul>
          <li>What is the taxpayer's educational level?</li>
          <li>Has the taxpayer ever been previously subject to the tax?</li>
          <li>Has the taxpayer been penalized before?</li>
          <li>
            Were there any recent changes to the tax forms or the law that the
            taxpayer could not reasonably expected to know?
          </li>
          <li>
            What is the level of complexity of the tax or compliance issue in
            question?
          </li>
          <li>
            Did the taxpayer show a reasonable good faith effort to comply with
            the law?
          </li>
          <li>What steps did they take to comply?</li>
          <li>
            In some instances taxpayers may not be aware of specific obligations
            to file or pay taxes, but they are still required to make reasonable
            efforts to determine their tax obligations. They must prove a good
            faith effort to comply with the law and that under their
            circumstances they could not have been reasonably expected to know
            the requirement.
          </li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="hardship"
        label="Your client experienced an undue financial hardship"
      >
        <div>Consider the following:</div>
        <ul>
          <li>
            An undue hardship may support the granting of an{" "}
            <CpTooltip text={TOOLTIP_EXTENSION}>
              <a>extension of time</a>
            </CpTooltip>{" "}
            for paying a tax or deficiency, but it must be{" "}
            <CpTooltip text={TOOLTIP_INCONVENIENCE}>
              <a>more than an inconvenience</a>
            </CpTooltip>{" "}
            to the taxpayer.
          </li>
          <li>
            The taxpayer must show that they would sustain substantial financial
            loss if required to pay the tax on the due date.
          </li>
          <li>
            Since undue hardship generally does not affect a taxpayer's ability
            to file, it is not generally considered reasonable cause for a
            Failure to File (FTF) penalty, but will be at least considered if
            the facts and circumstances contribute.
          </li>
          <li>
            Financial records are required such as bank statements, bills,
            payments, and assets and liabilities.
          </li>
          <li>
            Did the taxpayer make any "discretionary expenditures" prior to
            paying the tax on the due date; for example vacations, new cars,
            kids' tuition costs, a new home, unnecessary remodeling, charity,
            IRA or 401k contributions, etc.?
          </li>
          <li>
            The taxpayer may claim that they had enough funds on hand but as a
            result of unanticipated events, was unable to pay the tax.
          </li>
          <li>
            Was there a bankruptcy? If so, consider the inability to pay a
            factor if the insolvency occurred before the due date of the tax
            payment.
          </li>
          <li>When did the taxpayer realize they couldn't pay?</li>
          <li>Why was the taxpayer unable to pay?</li>
          <li>
            What other means did the taxpayer explore to secure the necessary
            funds?
          </li>
          <li>Did the taxpayer pay when the funds did become available?</li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="reasonable"
        label="Your client exercised reasonable cause and ordinary business care"
      >
        <div>Consider the following:</div>
        <ul>
          <li>
            Did the taxpayer "exercise ordinary business care and prudence" but
            was still unable to comply with the law?
          </li>
          <li>
            The events and explanations must correspond with the dates of the
            penalty.
          </li>
          <li>
            Was the taxpayer able to handle other affairs in their life during
            this time, or were taxes the only thing that the taxpayer put off?
          </li>
          <li>
            When circumstances changed, what attempt did the taxpayer make to
            become compliant? Remember - if too much time has passed between the
            event and compliance, it weakens the argument.
          </li>
          <li>
            What is the taxpayer's overall compliance history? Are there
            problems in preceding years as well? If there are continual
            problems, it shows lack of business care and prudence.
          </li>
          <li>The taxpayer must have supporting documentation.</li>
        </ul>
      </CheckboxClickableField>

      <CheckboxClickableField
        control={control}
        fieldName="not_applicable"
        label="None of the above"
      />
    </div>
  );
}
