import React, { useState } from "react";
import { CpRadio } from "canopy-styleguide!sofe";

import { trustFundConstants } from "../trust-fund.constants";
import { k, useCss } from "kremling";

export function TrustFundClientType({ submitAnswer }) {
  const [clientType, setClientType] = useState("");
  const scope = useCss(css);
  function updateAnswer(nextClientType) {
    setClientType(nextClientType);
    submitAnswer(trustFundConstants.CLIENT_TYPE, nextClientType);
  }

  return (
    <div {...scope}>
      <div className="cps-subheader-sm cp-text-center">
        <strong>Who is your client?</strong>
      </div>

      <div className="client-type-question">
        <CpRadio onChange={updateAnswer} value={clientType} name="clientType">
          <label className="client-type-question__item">
            <CpRadio.Item id="business-separate-entity" /> A Business under a
            separate entity such as an LLC, C-Corporation, S-Corp or similar
            structure
          </label>
          <label className="client-type-question__item">
            <CpRadio.Item id="business" /> A Business such as a Sole
            Proprietorship without a separate legal entity
          </label>
          <label className="client-type-question__item">
            <CpRadio.Item id="individual" /> An Individual facing potential TFRP
            issues
          </label>
        </CpRadio>
      </div>
    </div>
  );
}

const css = k`
  .client-type-question {
    margin: 0 auto;
    width: 50rem;
    padding-top: 3.2rem;
  }
  
  .client-type-question__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: .8rem;
    font-size: 1.4rem;
  }
  
  .client-type-question__item > label {
    margin-top: .2rem;
  }
`;
