import React, { useEffect, useState } from "react";
import { trim } from "lodash";
import { CpButton, CpModal, CpInput } from "canopy-styleguide!sofe";

export function CreateEditServiceModal({
  service,
  show,
  onClose,
  onSubmit,
  saving,
}) {
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");

  useEffect(() => {
    setServiceName(service?.name || "");
    setServiceDescription(service?.description || "");
  }, [service]);

  const reset = () => {
    setServiceName("");
    setServiceDescription("");
  };

  return (
    <CpModal show={show} onClose={onClose} onAfterClose={reset}>
      <CpModal.Header
        title={service ? "Edit service details" : "Create a Custom Service"}
      />
      <CpModal.Body>
        <CpInput
          id="service-name"
          onChange={setServiceName}
          value={serviceName}
          label="Service Name"
          required={true}
          className="cp-mb-16"
          maxLength={60}
        />
        <CpInput
          id="service-description"
          onChange={setServiceDescription}
          value={serviceDescription}
          label="Service Description"
          required={true}
          maxLength={512}
        />
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          onClick={() => onSubmit(serviceName, serviceDescription)}
          btnType="primary"
          disabled={!(trim(serviceName) && trim(serviceDescription))}
          showLoader={saving}
        >
          {service ? "Save changes" : "Create service"}
        </CpButton>
        <CpButton className="cp-ml-8" btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
