import { innocentSpouseConstants } from "./innocent-spouse.constants";
import { useMemo } from "react";

export function useInnocentSpouseOutcome(outcomes) {
  return useMemo(
    () => ({
      cantCalculateOutcomes:
        outcomes === innocentSpouseConstants.OUTCOME_NOT_YET_CALCULABLE ||
        !outcomes?.length,
      reliefUnlikely: !!(
        outcomes &&
        outcomes.indexOf(innocentSpouseConstants.RELIEF_UNLIKELY) >= 0
      ),
      communityPropertyAvailable: !!(
        outcomes &&
        outcomes.indexOf(innocentSpouseConstants.COMMUNITY_PROPERTY) >= 0
      ),
      equitableReliefAvailable: !!(
        outcomes &&
        outcomes.indexOf(innocentSpouseConstants.EQUITABLE_RELIEF) >= 0
      ),
      innocentSpouseAvailable: !!(
        outcomes &&
        outcomes.indexOf(innocentSpouseConstants.INNOCENT_SPOUSE) >= 0
      ),
      separationOfLiabilityAvailable: !!(
        outcomes &&
        outcomes.indexOf(innocentSpouseConstants.SEPARATION_OF_LIABILITY) >= 0
      ),
    }),
    [JSON.stringify(outcomes)]
  );
}
