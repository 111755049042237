import React, { useEffect, useState } from "react";
import {
  CpCard,
  CpButton,
  CpEmptyState,
  CpInput,
  CpTooltip,
  CpModal,
  CpLoader,
} from "canopy-styleguide!sofe";
import styles from "./section-workpapers.styles.css";
import { isEmpty, trim } from "lodash";
import {
  addWorkpaper,
  getClientData,
  getProgramSectionService,
  updateProgramSection,
  getEnvelope,
  updateEnvelope,
} from "src/common/resolution-cases.resource";
import { catchAsyncStacktrace, catchSyncStacktrace } from "auto-trace";
import { forceBustCache } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";
import { ChangeStatus } from "src/common/change-status.component";
import { taxResServiceSlugs } from "src/common/resolution-cases.helpers";
import { successToast } from "toast-service!sofe";
import { createBlankWorkpaperStub } from "../../sections.utils";
import { DateTime } from "luxon";
import { CollaboratorPicker } from "src/common/collaborator-picker.component";
import {
  Comments,
  CommentsList,
  CommentsInput,
} from "src/common/comments/comments.component";
import { DatePicker } from "src/common/date-picker.component";
import {
  getAttachments,
  deleteAttachment,
} from "src/common/attachments.resource";
import { getFileIconPath } from "./attachments.utils";
import { applicationProps } from "src/resolution-cases-ui";
import { useAttachmentDropzone } from "src/common/use-attachment-dropzone.hook";

const containers = {};
export function SectionWorkpapers({
  program,
  section,
  resolutionCase,
  clientId,
}) {
  const [showEditStepModal, setShowEditStepModal] = useState(false);
  const [activeTeamMembers, setActiveTeamMembers] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [workpaper, setWorkpaper] = useState(null);
  const [programSection, setProgramSection] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  useAttachmentDropzone(
    {
      clickableId: "#upload-a-workpaper-new",
      dropzoneId: "#workpaperFilesNew",
      onUpload,
      pivotId: workpaper?.id,
      pivotType: "workpapers",
      targetId: clientId,
      targetPath: "clients",
    },
    [workpaper?.id]
  );
  const requiredPermission = taxResServiceSlugs.find(
    (slug) => slug === program.slug
  )
    ? "tax_resolution_services"
    : "custom_services";

  useEffect(() => {
    getActiveTeamMembers();
  }, [clientId]);

  useEffect(() => {
    if (!section?.id) return;
    (async function () {
      const _programSection = await getProgramSectionService(
        clientId,
        resolutionCase.id,
        section.id
      );
      if (!_programSection.relationships.children) {
        let newWorkpaper = createBlankWorkpaperStub(section.id);
        const _workpaper = await addWorkpaper(
          clientId,
          resolutionCase.id,
          section.id || section.slug,
          newWorkpaper
        );
        _programSection.relationships.children.push(workpaper);
        setProgramSection(_programSection);
        setWorkpaper(_workpaper);
        setDueDate(_workpaper.due_at);
      } else {
        getEnvelope(
          clientId,
          resolutionCase.id,
          _programSection.id,
          _programSection.relationships.children[0].references
        ).then((envelope) => {
          setProgramSection(_programSection);
          setWorkpaper(envelope);
          setDueDate(envelope.due_at);
        });
      }
    })();
  }, [section?.id]);

  useEffect(() => {
    if (!workpaper) return;
    setLoadingAttachments(true);
    getAttachments(clientId, "workpapers", workpaper.id).subscribe(
      (response) => {
        setAttachments(response);
        setLoadingAttachments(false);
      },
      catchAsyncStacktrace()
    );
  }, [workpaper?.id]);

  function getActiveTeamMembers() {
    getClientData(clientId).subscribe(({ users }) => {
      const _activeTeamMembers = users.filter(
        ({ is_activated, is_deleted, role }) => {
          return is_activated && !is_deleted && role === "TeamMember";
        }
      );
      setActiveTeamMembers(_activeTeamMembers);
    }, catchAsyncStacktrace());
  }

  async function onEditStepSubmit(name, description) {
    updateProgram({ name, description });
  }

  function updateStatus(status, notifications) {
    updateProgram({ status }, notifications).then(() => {
      successToast("Status changed successfully.");
    }, catchAsyncStacktrace());
  }

  async function updateProgram(newValues, notifications) {
    try {
      await updateProgramSection(
        clientId,
        resolutionCase.id,
        section.slug,
        {
          ...section,
          ...newValues,
        },
        notifications
      );
      forceBustCache(
        `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${
          resolutionCase.id
        }`
      );
    } catch (err) {
      catchSyncStacktrace(err);
    }
  }

  function removeAssignee() {
    updateEnvelope(clientId, resolutionCase.id, section.id, workpaper.id, {
      description: workpaper.description,
      due_at: DateTime.fromJSDate(workpaper.due_at).toMillis(),
      id: workpaper.id,
      relationships: {
        ...workpaper.relationships,
        assigned_to: { type: "user", id: null },
      },
      status: workpaper.status,
      title: workpaper.title,
      type: workpaper.type,
    }).subscribe(() => {
      const assigned_to = {
        id: null,
        type: "user",
      };
      setWorkpaper((state) => ({
        ...state,
        relationships: { ...state.relationships, assigned_to },
      }));
    }, catchAsyncStacktrace());
  }

  function openInviteNewTeamMember() {
    SystemJS.import("invite-team-member!sofe")
      .then(({ inviteTeamMember }) =>
        inviteTeamMember({
          isFreeTrial: window.tenant.is_trial,
          closeCallback: getActiveTeamMembers,
          clientId: Number(clientId),
        })
      )
      .catch((err) => {
        setTimeout(() => {
          throw err;
        });
      });
  }

  function handleDateChange(date) {
    updateEnvelope(
      clientId,
      resolutionCase.id,
      programSection.id,
      workpaper.id,
      {
        description: workpaper.description,
        due_at: DateTime.fromJSDate(date).toMillis(),
        id: workpaper.id,
        relationships: workpaper.relationships,
        status: workpaper.status,
        title: workpaper.title,
        type: workpaper.type,
      }
    ).subscribe(() => {
      setWorkpaper((state) => ({
        ...state,
        due_at: DateTime.fromJSDate(date).toISO(),
      }));
      setDueDate(DateTime.fromJSDate(date).toISO());
    }, catchAsyncStacktrace());
  }

  function selectAssignee(assignee) {
    updateEnvelope(clientId, resolutionCase.id, section.id, workpaper.id, {
      description: workpaper.description,
      due_at: DateTime.fromISO(workpaper.due_at).toMillis(),
      id: workpaper.id,
      relationships: {
        ...workpaper.relationships,
        assigned_to: { type: "user", id: assignee.id },
      },
      status: workpaper.status,
      title: workpaper.title,
      type: workpaper.type,
    }).subscribe(() => {
      const assigned_to = {
        id: assignee.id,
        name: assignee.name,
        type: "user",
      };
      setWorkpaper((state) => ({
        ...state,
        relationships: { ...state.relationships, assigned_to },
      }));
    }, catchAsyncStacktrace());
  }

  function onUpload(attachment) {
    setAttachments((state) => [...state, attachment]);
  }

  function downloadAttachment(attachment) {
    SystemJS.import("docs-ui!sofe").then((m) => {
      m.isFileSafeToOpen(attachment.file_id).then(
        ({ trust, virusStatuses, file }) => {
          if (trust) {
            window.location.href = file.download_link;
          } else {
            containers[attachment.id] = document.getElementById(
              `file-${attachment.id}`
            );
            const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
              virusFound: virusStatuses.infectedDocs.length > 0,
              domElement: containers[attachment.id],
              handleCloseModal() {
                parcel.unmount();
              },
            });
          }
        }
      );
    });
  }

  function handleDeleteAttachment(attachment) {
    deleteAttachment(clientId, attachment.id).subscribe(() => {
      setAttachments((state) => state.filter(({ id }) => id !== attachment.id));
    });
  }

  return (
    <>
      <CpCard>
        <div className={styles.workpaperHeader}>
          <div>
            <div className="cp-subheader-sm">{section?.name}</div>
            <div className="cp-body-sm">{section?.description}</div>
          </div>
          <div className={styles.workpaperActions}>
            <CpTooltip text="Edit">
              <CpButton
                icon="crud-pencil"
                aria-label="Edit"
                onClick={() => setShowEditStepModal(true)}
              />
            </CpTooltip>
            <CollaboratorPicker
              assignee={workpaper?.relationships.assigned_to}
              onSelect={selectAssignee}
              onRemove={removeAssignee}
              openInviteNewTeamMember={openInviteNewTeamMember}
              activeTeamMembers={activeTeamMembers}
            />
            <DatePicker onChange={handleDateChange} value={dueDate} />
            <ChangeStatus
              status={section?.status}
              onChange={updateStatus}
              activeTeamMembers={activeTeamMembers}
              requiredPermission={requiredPermission}
            />
          </div>
        </div>
        <div className={styles.workpaperSubHeader}>
          <div>Workpapers</div>
          <div>
            <CpButton btnType="flat" id="upload-a-workpaper-new">
              + Upload a workpaper
            </CpButton>
          </div>
        </div>
        <div>
          <div id="workpaperFilesNew">
            {loadingAttachments ? (
              <CpLoader />
            ) : isEmpty(attachments) ? (
              <div className={styles.workpaperEmptyContainer}>
                <CpEmptyState
                  img="es_note"
                  text="No workpapers added"
                  subText="Please drop workpapers here"
                />
              </div>
            ) : (
              attachments.map((attachment) => (
                <div
                  className={styles.workpaperFileContainer}
                  key={attachment.file_id}
                >
                  <div className={styles.workpaperFileInfo}>
                    <div className="cp-mr-36">
                      <img
                        width="20"
                        src={getFileIconPath(attachment.filename)}
                        aria-hidden
                      />
                    </div>
                    <div className={styles.workpaperFileTitle}>
                      {attachment.filename}
                    </div>
                    <div className={styles.workpaperFileUploader}>
                      by {attachment.relationships.uploaded_by.name}
                    </div>
                  </div>
                  <div className={styles.workpaperFileActions}>
                    <span id={`file-${attachment.id}`} />
                    <CpButton
                      icon="af-open-down"
                      aria-label="Download"
                      onClick={() => downloadAttachment(attachment)}
                    />
                    <CpButton
                      icon="crud-trash-large"
                      aria-label="Delete"
                      onClick={() => handleDeleteAttachment(attachment)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <Comments
          clientId={clientId}
          pivotId={workpaper?.id}
          pivotType="workpapers"
        >
          <CommentsInput clientId={clientId} />
          <CommentsList sortOrder="desc" />
        </Comments>
      </CpCard>
      <EditStepModal
        show={showEditStepModal}
        onClose={() => setShowEditStepModal(false)}
        onSubmit={onEditStepSubmit}
        section={section}
      />
    </>
  );
}

function EditStepModal({ show, onClose, onSubmit, section }) {
  const [name, setName] = useState(section?.name || "");
  const [description, setDescription] = useState(section?.description || "");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setName(section?.name || "");
    setDescription(section?.description || "");
  }, [section]);

  async function handleSave() {
    setSaving(true);
    try {
      await onSubmit(name, description);
      onClose();
      setSaving(false);
    } catch (err) {
      setSaving(false);
    }
  }

  return (
    <CpModal show={show} onClose={onClose} width={540}>
      <CpModal.Header title="Edit step details" />
      <CpModal.Body>
        <div className="cp-mb-8">
          <CpInput
            id="service-name"
            onChange={setName}
            value={name}
            label="Step title"
            required
          />
        </div>
        <div>
          <CpInput
            id="service-name"
            onChange={setDescription}
            value={description}
            label="Step description"
            required
          />
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          showLoader={saving}
          onClick={handleSave}
          btnType="primary"
          disabled={!trim(name) || !trim(description)}
        >
          Save changes
        </CpButton>
        <CpButton className="cp-ml-8" btnType="flat" onClick={onClose}>
          Close
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
