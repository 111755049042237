import { leviesConstants } from "./levies.constants";
import { useMemo } from "react";

export const useLeviesOutcomes = (outcomes) => {
  return useMemo(
    () => ({
      cantCalculateOutcomes:
        outcomes === leviesConstants.OUTCOME_NOT_YET_CALCULABLE ||
        !outcomes?.length,
      reliefUnlikely: !!(
        outcomes && outcomes.indexOf(leviesConstants.RELIEF_UNLIKELY) >= 0
      ),
      appealsAvailable: !!(
        outcomes && outcomes.indexOf(leviesConstants.APPEALS) >= 0
      ),
      returningPropertyAvailable: !!(
        outcomes && outcomes.indexOf(leviesConstants.RETURNING_PROPERTY) >= 0
      ),
      preventingLevyAvailable: !!(
        outcomes && outcomes.indexOf(leviesConstants.PREVENTING_LEVY) >= 0
      ),
      releasingLevyAvailable: !!(
        outcomes && outcomes.indexOf(leviesConstants.RELEASING_LEVY) >= 0
      ),
    }),
    [JSON.stringify(outcomes)]
  );
};
