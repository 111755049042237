import React from "react";
import { CpButton } from "canopy-styleguide!sofe";

import { useLiensOutcomes } from "./liens-outcomes.helper";
import { tryRestart } from "../assistant-utils";
import { useHistory } from "react-router-dom";

export function LiensOutro({ outcomes, urls, restartAssistant }) {
  const history = useHistory();
  const {
    reliefUnlikely,
    dischargeAvailable,
    releaseAvailable,
    subordinationAvailable,
    withdrawalAvailable,
    appealAvailable,
  } = useLiensOutcomes(outcomes);
  return (
    <div>
      <div className="cp-assistant-outro__recommendation">
        <div className="cps-subheader-sm">Recommendation:</div>

        {!reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm cps-color-primary">
              {dischargeAvailable && <div>Lien Discharge available</div>}
              {releaseAvailable && <div>Lien Release available</div>}
              {subordinationAvailable && (
                <div>Lien Subordination available</div>
              )}
              {withdrawalAvailable && <div>Lien Withdrawal available</div>}
              {appealAvailable && <div>Appeals available</div>}
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Liens Assistant! Based on your answers,
              your client may qualify for the outcome(s) listed above.
            </div>
          </div>
        )}

        {reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm cps-warning">Relief unlikely</div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Liens Assistant! Based on your answers,
              relief for your client is unlikely. Sometimes it doesn't hurt to
              try though - check out our outcomes to learn more.
            </div>
          </div>
        )}
      </div>

      <div className="end-of-assistant-actions">
        <CpButton onClick={() => history.push(urls.resolution)}>
          Go to outcomes
        </CpButton>
        <CpButton btnType="flat" onClick={() => tryRestart(restartAssistant)}>
          Restart assistant
        </CpButton>
      </div>
    </div>
  );
}
