import { useMemo } from "react";
import { modalService } from "canopy-styleguide!sofe";

import {
  sectionConst,
  serviceConst,
  outcomeConst,
} from "./assistant.constants";
import { penaltyAbatementQuestionEnhancer } from "./penalty-abatement/penalty-abatement-question-enhancer";
import { innocentSpouseQuestionEnhancer } from "./innocent-spouse/innocent-spouse-question-enhancer";
import { RestartModal } from "./common/restart-modal.component";
import { liensQuestionEnhancer } from "./liens/liens-question-enhancer";
import { leviesQuestionEnhancer } from "./levies/levies-question-enhancer";
import { trustFundQuestionEnhancer } from "./trust-fund/trust-fund-question-enhancer";

export const programToSectionMap = {
  [serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG]:
    sectionConst.PENALTY_ABATEMENT_SECTION_SLUG,
  [serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG]:
    sectionConst.INNOCENT_SPOUSE_SECTION_SLUG,
  [serviceConst.LIENS_SERVICE_SLUG]: sectionConst.LIENS_SECTION_SLUG,
  [serviceConst.LEVIES_SERVICE_SLUG]: sectionConst.LEVIES_SECTION_SLUG,
  [serviceConst.TRUST_FUND_SERVICE_SLUG]: sectionConst.TRUST_FUND_SECTION_SLUG,
};

export const programToOutcomeMap = {
  [serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG]:
    outcomeConst.PENALTY_ABATEMENT_OUTCOMES_SLUG,
  [serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG]:
    outcomeConst.INNOCENT_SPOUSE_OUTCOMES_SLUG,
  [serviceConst.LIENS_SERVICE_SLUG]: outcomeConst.LIENS_OUTCOMES_SLUG,
  [serviceConst.LEVIES_SERVICE_SLUG]: outcomeConst.LEVIES_OUTCOMES_SLUG,
  [serviceConst.TRUST_FUND_SERVICE_SLUG]: outcomeConst.TRUST_FUND_OUTCOMES_SLUG,
};

export function useUrlBuilder(clientId, resolutionCaseId, programSlug) {
  return useMemo(() => {
    const sectionSlugAssistant = programToSectionMap[programSlug];
    const sectionSlugResolution = programToOutcomeMap[programSlug];
    return {
      assistant: `/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/program/${programSlug}/section/${sectionSlugAssistant}`,
      resolution: `/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/program/${programSlug}/section/${sectionSlugResolution}`,
    };
  }, [clientId, resolutionCaseId, programSlug]);
}

export function questionEnhancer(programSlug, questionSlug) {
  const enhancers = {
    [serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG]:
      penaltyAbatementQuestionEnhancer,
    [serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG]: innocentSpouseQuestionEnhancer,
    [serviceConst.LIENS_SERVICE_SLUG]: liensQuestionEnhancer,
    [serviceConst.LEVIES_SERVICE_SLUG]: leviesQuestionEnhancer,
    [serviceConst.TRUST_FUND_SERVICE_SLUG]: trustFundQuestionEnhancer,
  };
  return enhancers[programSlug](questionSlug);
}

export function totalNumQuestions(programSlug) {
  const totals = {
    [serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG]: 12,
    [serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG]: 18,
    [serviceConst.LIENS_SERVICE_SLUG]: 22,
    [serviceConst.LEVIES_SERVICE_SLUG]: 18,
    [serviceConst.TRUST_FUND_SERVICE_SLUG]: 17,
  };
  return totals[programSlug];
}

export async function tryRestart(restartAssistant) {
  if (await modalService.render(RestartModal)) {
    restartAssistant();
  }
}
