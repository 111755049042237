export const penaltyAbatementConstants = {
  // Outcomes
  RELIEF_UNLIKELY: "RELIEF_UNLIKELY",
  REASONABLE_CAUSE: "REASONABLE_CAUSE",
  FIRST_TIME_ABATEMENT: "FIRST_TIME_ABATEMENT",

  NON_ASSESSMENT: "NON_ASSESSMENT",
  OUTCOME_NOT_YET_CALCULABLE: null,

  // Question slugs
  INTRO: "intro", // 0
  AUDIT: "audit", // 1
  PENALTY: "penalty", // 1.a.i
  LATE: "late", // 2
  NOTIFICATION: "notification", // 3
  LIABILITY: "liability", // 6
  PAYMENTS: "payments", // 7
  PENALTY_REASON: "penalty_reason", // 8
  PREVIOUS_PENALTY: "previous_penalty", // 9
  COMPLIANCE: "compliance", // 10
  INSTALLMENT: "installment", // 11
  PENALTY_TYPE: "penalty_type", // 12
  CIRCUMSTANCE: "circumstance", // 13
};
