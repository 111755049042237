// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dashboard-resolution-cases-table-resolution-cases-table-styles__table--e5O3z {\n  width: 100%;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-styles__headerRow--3ZRld {\n  font-weight: 600;\n  color: var(--cps-color-secondary-text);\n  min-height: 50px;\n  position: fixed;\n  width: calc(100% - 80px);\n  z-index: 1;\n  font-size: 13px;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-styles__rowWrapper--3Dq_L {\n  height: 4rem;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-styles__loaderWrapper--1c7St {\n  display: flex;\n  justify-content: center;\n  height: 5rem;\n}\n", ""]);
// Exports
exports.locals = {
	"table": "src-dashboard-resolution-cases-table-resolution-cases-table-styles__table--e5O3z",
	"headerRow": "src-dashboard-resolution-cases-table-resolution-cases-table-styles__headerRow--3ZRld",
	"rowWrapper": "src-dashboard-resolution-cases-table-resolution-cases-table-styles__rowWrapper--3Dq_L",
	"loaderWrapper": "src-dashboard-resolution-cases-table-resolution-cases-table-styles__loaderWrapper--1c7St"
};
module.exports = exports;
