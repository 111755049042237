import { penaltyAbatementConstants } from "./penalty-abatement.constants";

const questions = {
  [penaltyAbatementConstants.AUDIT]: {
    questionText:
      "Is your client currently under audit or examination (or was one recently completed)?",
    type: "boolean",
  },
  [penaltyAbatementConstants.PENALTY]: {
    questionText: "Has your client been assessed a penalty?",
    type: "boolean",
  },
  [penaltyAbatementConstants.LATE]: {
    questionText:
      "Is your client filing a late return or making a payment past the due date?",
    type: "boolean",
  },
  [penaltyAbatementConstants.NOTIFICATION]: {
    questionText:
      "Did your client have a penalty assessed and has she/he received notification?",
    type: "boolean",
  },
  [penaltyAbatementConstants.LIABILITY]: {
    questionText: "Is there an outstanding tax liability owing at this time?",
    type: "boolean",
  },
  [penaltyAbatementConstants.PAYMENTS]: {
    questionText:
      "Were there payments made against balances due within the last two years?",
    type: "boolean",
  },
  [penaltyAbatementConstants.PENALTY_REASON]: {
    questionText:
      "Is the penalty for Failure to File (FTF), Failure to Pay (FTP), or Failure to Deposit (FTD)?",
    type: "boolean",
  },
  [penaltyAbatementConstants.PREVIOUS_PENALTY]: {
    questionText:
      "Has your client incurred any Failure to File (FTF) or Failure to Pay (FTP) penalties anytime during the three years preceding a year for which you would like to request First Time Abatement?",
    type: "boolean",
  },
  [penaltyAbatementConstants.COMPLIANCE]: {
    questionText:
      "Has your client filed all currently required returns, or has she/he filed the appropriate extensions (i.e., is your client in compliance for their filing requirements)?",
    type: "boolean",
  },
  [penaltyAbatementConstants.INSTALLMENT]: {
    questionText:
      'If your client has an installment agreement in place, is she/he current with the installment payments? (If your client does not have an installment agreement in place, simply select "Yes" below.)',
    type: "boolean",
  },
  [penaltyAbatementConstants.PENALTY_TYPE]: {
    questionText: "Is your client’s penalty listed in the table below?",
    type: "boolean",
  },
  [penaltyAbatementConstants.CIRCUMSTANCE]: {
    questionText:
      "In the list below, select any situations that apply to your client (you may select more than one):",
    type: "checkboxes",
  },
};

export const penaltyAbatementQuestionEnhancer = (questionSlug) => {
  return questions[questionSlug];
};
