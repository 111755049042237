import React from "react";
import { get } from "lodash";
import { fromEvent } from "rxjs";
import AsyncDecorator from "async-decorator/rx6";
import { fetchClients } from "./filter-dropdown.resource.js";
import { CpNameChip, CpButton, CpInput, CpIcon } from "canopy-styleguide!sofe";
import styles from "./filter-dropdown.styles.css";

@AsyncDecorator
export default class FilterDropdown extends React.PureComponent {
  state = {
    showMenu: false,
    clients: [],
    selected: "",
    search: "",
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fetchClients().subscribe((clients) => {
        this.setState({
          clients,
        });
      }),
      fromEvent(this.menuEl, "click").subscribe(this.handleMenuClick)
    );
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }));
  };

  closeMenu = (evt) => {
    if (evt.myProp !== JSON.stringify(this.props.config)) {
      this.setState({ showMenu: false });
    }
  };

  handleMenuClick = (evt) => {
    evt.myProp = JSON.stringify(this.props.config);
  };

  handleClientSelectOrRemove = (item) => {
    const itemValue = get(item, "name", "");
    this.setState({
      selected: itemValue,
      search: "",
      showMenu: false,
    });
    this.props.selectItem(item);
  };

  search = (value) => {
    this.props.cancelWhenUnmounted(
      fetchClients(value).subscribe((clients) => {
        this.setState({
          clients,
        });
      })
    );
  };

  handleInputChange = (value) => {
    this.setState({
      search: value,
    });
    this.search(value);
  };

  render() {
    return (
      <div className="cps-btn-icon">
        <div className="cp-flex">
          {this.state.selected && (
            <div
              className={`cp-mr-8 ${styles.blue__pill} ${styles.pill}`}
              data-testid="selected-section"
            >
              <div
                className={`${styles.pill__text} ${styles.blue__pill__text} cp-ph-8`}
              >
                <span>{this.state.selected}</span>
              </div>
              <a
                className={`cps-link ${styles.pill__action}`}
                onClick={this.handleClientSelectOrRemove}
                aria-label={`Remove ${this.state.selected}`}
              >
                <CpIcon
                  name="close-circle-open"
                  fill="var(--cp-color-pill-text)"
                />
              </a>
            </div>
          )}
          <ul className="cps-list-unstyled">
            <li
              className={`cps-dropdown ${
                this.state.showMenu ? "cps-open" : ""
              }`}
            >
              <CpButton
                icon="misc-funnel"
                onClick={this.toggleMenu}
                aria-label="Search clients"
              />
              <ul
                ref={(el) => (this.menuEl = el)}
                className={`cps-dropdown-menu cps-pull-right ${styles.wideDropdown}`}
                role="menu"
              >
                <div className="cp-mh-8 cp-mt-8">
                  <CpInput
                    value={this.state.search}
                    onChange={this.handleInputChange}
                    placeholder="Search Clients"
                    isSearch
                  />
                </div>
                <div className="cp-select-list">
                  {this.state.clients.map((item) => {
                    return (
                      <button
                        key={item.id}
                        className="filter-client"
                        onClick={() => this.handleClientSelectOrRemove(item)}
                        aria-label={`Select ${item.name}`}
                      >
                        <CpNameChip className="cp-mr-12" name={item.name} />
                        <span className={`${styles.name}`}>{item.name || item.display_name}</span>
                      </button>
                    );
                  })}
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
