import { innocentSpouseConstants } from "./innocent-spouse.constants";

// Questions
let questions = {
  [innocentSpouseConstants.JOINT_RETURN]: {
    questionText:
      "Did the client file a joint return for the year she/he wants relief?",
    type: "boolean",
  },
  [innocentSpouseConstants.MULTI_SELECT]: {
    questionText:
      "Does the joint return have an understatement of tax or an underpayment of tax?",
    type: "radio",
  },
  [innocentSpouseConstants.ERRONEOUS_ITEMS]: {
    questionText: `Is the understatement of tax due to erroneous items of the client’s spouse (or former spouse)?`,
    type: "boolean",
  },
  [innocentSpouseConstants.KNOW_UNDERSTATEMENT]: {
    questionText:
      "At the time the client signed the joint return, did the client know or have reason to know that there was an understatement of tax?",
    type: "boolean",
  },
  [innocentSpouseConstants.SEPARATED]: {
    questionText:
      "Is the client legally separated from, or no longer married to, the spouse with whom she/he filed a joint return?",
    type: "boolean",
  },
  [innocentSpouseConstants.PAID_TAX]: {
    questionText: "Did the client pay the tax amount owed?",
    type: "boolean",
  },
  [innocentSpouseConstants.UNFAIR_7]: {
    questionText:
      "Is the client able to show it would be unfair to hold her/him liable for any understated or underpaid tax?",
    type: "boolean",
  },
  [innocentSpouseConstants.TRANSFER_TO_SPOUSE]: {
    questionText:
      "Did the client and spouse (or former spouse) transfer property to one another as part of a fraudulent scheme?",
    type: "boolean",
  },
  [innocentSpouseConstants.TRANSFER_TO_CLIENT]: {
    questionText:
      "Did the client’s spouse (or former spouse) transfer property to the client for the main purpose of avoiding tax or the payment of tax?",
    type: "boolean",
  },
  [innocentSpouseConstants.FRAUD_INTENT]: {
    questionText:
      "Did the client file or fail to file her/his return with the intent to commit fraud?",
    type: "boolean",
  },
  [innocentSpouseConstants.SPOUSE_ITEM]: {
    questionText:
      "Is the income tax liability from which the client seeks relief due to an item of the spouse (or former spouse) with whom the client filed the joint return?",
    type: "boolean",
  },
  [innocentSpouseConstants.SAME_HOUSEHOLD]: {
    questionText:
      "Was the client a member of the same household as the spouse with whom the client filed the joint return at any time during the 12-month period ending on the date the form 8857 was filed?",
    type: "boolean",
  },
  [innocentSpouseConstants.UNFAIR_13]: {
    questionText:
      "Can the client show, based on the facts and circumstances, that it would be unfair to hold her/him liable for the understatement of tax?",
    type: "boolean",
  },
  [innocentSpouseConstants.COMMUNITY_PROPERTY_STATE]: {
    questionText: "Did the client live in a community property state?",
    type: "boolean",
  },
  [innocentSpouseConstants.INCLUDE_IN_GROSS_INCOME]: {
    questionText:
      "Did the client include the item of community income in gross income?",
    type: "boolean",
  },
  [innocentSpouseConstants.COMMUNITY_INCOME_RELATED]: {
    questionText:
      "Was the item of community income related to any of the following?",
    type: "boolean",
  },
  [innocentSpouseConstants.KNOW_COMMUNITY_INCOME]: {
    questionText:
      "Did your client know of, or had reason to know of, that community income?",
    type: "boolean",
  },
  [innocentSpouseConstants.UNFAIR_18]: {
    questionText: `Under all facts and circumstances, would it not be fair to include the item of community income in your client’s gross income?`,
    type: "boolean",
  },
};

export const innocentSpouseQuestionEnhancer = (questionSlug) => {
  return questions[questionSlug];
};
