import { get, cloneDeep, filter, map, isNull } from "lodash";

export {
  filterOutProgramsWithoutRelevantSections,
  addClientRequestsToResolutionCase,
};

function filterOutProgramsWithoutRelevantSections(resolutionCase, status) {
  const resolutionCaseClone = cloneDeep(resolutionCase);
  const programs = get(resolutionCaseClone, "program_data.programs", []);
  const programsWithValidSections = map(programs, (program) => {
    return {
      ...program,
      sections: program.sections.filter(
        (section) =>
          section.status === status &&
          section.slug !== "adminforms" &&
          section.slug !== "irsForms"
      ),
    };
  }).filter((program) => {
    return program.visible && program.sections.length >= 1;
  });

  resolutionCaseClone.program_data.programs = programsWithValidSections;

  return resolutionCaseClone;
}

function addClientRequestsToResolutionCase(
  resolutionCase,
  clientRequests = [],
  status
) {
  if (clientRequests.length === 0) {
    return resolutionCase;
  } else {
    const filteredClientRequests = clientRequests.filter((request) => {
      return request.status === status;
    });
    resolutionCase.program_data.programs.push({
      uid: "manualClientMap",
      name: "Client Requests",
      slug: "client_requests",
      sections: filteredClientRequests,
      visible: true,
    });
    return resolutionCase;
  }
}
