export const trustFundConstants = {
  // Outcomes
  APPEAL: "APPEAL",
  EMPLOYMENT_TAX: "EMPLOYMENT_TAX",
  EMPLOYMENT_TAX_WARNING: "EMPLOYMENT_TAX_WARNING",
  TFRP_DEFENSE: "TFRP_DEFENSE",

  RELIEF_UNLIKELY: "RELIEF_UNLIKELY",
  OUTCOME_NOT_YET_CALCULABLE: null,

  // Question slugs
  INTRO: "intro", // 0
  UNPAID_EMPL_TAX: "unpaid_empl_tax", // 1
  CLIENT_TYPE: "client_type", // 2
  CLIENT_PRINCIPAL: "client_principal", // 2a
  CAN_PREVENT: "can_prevent", // 3
  PAY_BALANCE_24_MO: "pay_balance_24_mo", // 4
  BALANCE_LESS: "balance_less", // 5
  FULLY_COMPLIANT: "fully_compliant", // 6
  RECEIVED_1058: "received_1058", // 7
  RECEIPT_OF_NOTICE: "receipt_of_notice", // 8
  FINANCIALLY_INCAPABLE: "financially_incapabale", // 9
  RECEIVED_1153: "received_1153", // 10
  DAYS_LAPSED: "days_lapsed", // 11
  DISPUTE_ASSESSED_PENALTY: "dispute_assessed_penalty", // 12
  RESPONSIBLE_PERSON: "responsible_person", // 13
  COMPLETED_4180: "completed_4180", // 14
  OTHER_PERSON: "other_person", // 15
  SIGNER_ON_ACCOUNT: "signer_on_account", // 16
  RECEIVED_3586: "received_3586", // 17
};
