import React from "react";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function LeviesIssuedWrongfully() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Was the levy issued <a onClick={issuedWrongfullyModal}>wrongfully</a>?
      </strong>
    </div>
  );
}

function issuedWrongfullyModal() {
  renderInfoModal(
    "Wrongful Levy",
    <>
      A levy that improperly attaches property belonging to a third party in
      which the taxpayer has no rights.
    </>
  );
}
