// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__columnHeader--2xPwN {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  cursor: pointer;\n  /*padding: 16px;*/\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__allContent--21K72 {\n  padding: 0 12px 0 12px;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__columnHeader--2xPwN:hover {\n  background-color: #f2f2f2;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__highlightRow--19SuY:hover {\n  background-color: #fafafa;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__row--pR-1l {\n  height: calc(100% - 1px);\n  display: flex;\n  align-items: center;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__content--1e3VC {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__right--3pHTA {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__center--FIzw2 {\n  display: flex;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"columnHeader": "src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__columnHeader--2xPwN",
	"allContent": "src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__allContent--21K72",
	"highlightRow": "src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__highlightRow--19SuY",
	"row": "src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__row--pR-1l",
	"content": "src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__content--1e3VC",
	"right": "src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__right--3pHTA",
	"center": "src-dashboard-resolution-cases-table-resolution-cases-table-row-resolution-cases-table-row-styles__center--FIzw2"
};
module.exports = exports;
