export const leviesConstants = {
  // Outcomes
  APPEALS: "APPEALS",
  RETURNING_PROPERTY: "RETURNING_PROPERTY",
  PREVENTING_LEVY: "PREVENTING_LEVY",
  RELEASING_LEVY: "RELEASING_LEVY",

  RELIEF_UNLIKELY: "RELIEF_UNLIKELY",
  OUTCOME_NOT_YET_CALCULABLE: null,

  // Question slugs
  INTRO: "intro", // 0
  ISSUED_NOTICE: "issued_notice", // 1
  ALREADY_LEVIED: "already_levied", // 2
  LEVY_RELEASED: "levy_released", // 3
  PROVE_FINANCIAL_HARDSHIP: "prove_financial_hardship", // 4
  INCREASE_ABILITY_TO_PAY: "increase_ability_to_pay", // 5
  ISSUED_PREMATURELY: "issued_prematurely", // 6
  AGAINST_EXEMPT_PROPERTY: "against_exempt_property", // 7
  VALUE_GREATER_THAN_OWED: "value_greater_than_owed", // 8
  OIC_OR_INSTALLMENT: "oic_or_installment", // 9
  RELEASE_FACILITATE: "release_facilitate", // 10
  SATISFIED_LIABILITY: "satisfied_liability", // 11
  FILED_FOR_BANKRUPTCY: "filed_for_bankruptcy", // 12
  COLLECTION_PERIOD_EXPIRED: "collection_period_expired", // 13
  IDENTITY_THEFT: "identity_theft", // 14
  ISSUED_ERRONEOUSLY: "issued_erroneously", // 15
  ISSUED_WRONGFULLY: "issued_wrongfully", // 16
  ABILITY_TO_PAY: "ability_to_pay", // 17
  ABLE_TO_SATISFY: "able_to_satisfy", // 18
  CIRCUMSTANCE: "circumstance",
};
