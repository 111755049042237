import React, { useEffect, useMemo, useState } from "react";
import { CpDrawer, CpIcon, CpTextarea, CpButton, CpDropdown, CpSelectSingle, CpPill } from "canopy-styleguide!sofe";
import { useParams } from "react-router-dom";
import {
  getClientRequest,
  updateRequest,
} from "src/common/client-requests.resource";
import styles from "./client-request-drawer.styles.css";
import { DatePicker } from "src/common/date-picker.component";
import { ChangeStatus } from "../../common/change-status.component";
import { DateTime } from "luxon";
import { RichTextEditor } from "src/common/rich-text-editor.component";
import {
  Comments,
  CommentsInput,
  CommentsList,
} from "src/common/comments/comments.component";
import { ClientRequestReminder } from "./client-request-reminder.component";
import { catchAsyncStacktrace } from "auto-trace";
import { debounce, isEmpty, trim } from "lodash";
import {
  getAttachments,
  saveAttachments,
  deleteAttachment,
} from "../../common/attachments.resource";
import { getUrlFromState } from "./client-requests.utils";
import { AttachmentCard } from "../../common/attachment-card.component";
import { useCrmHierarchy } from "../../common/use-crm-hierarchy.helpers";

export function ClientRequestDrawer({
  onClose,
  onOpen,
  activeTeamMembers,
  onDelete,
  sendSingleRequest,
  handleRequestUpdate,
}) {
  const { requestId, clientId, resolutionCaseId } = useParams();
  const [show, setShow] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [request, setRequest] = useState({});
  const [title, setTitle] = useState("");
  const shouldUseNewHierarchy = useCrmHierarchy();

  useEffect(() => {
    const obs = getClientRequest(
      clientId,
      resolutionCaseId,
      requestId
    ).subscribe((clientRequest) => {
      setRequest(clientRequest);
      setTitle(clientRequest.title || "");
    });
    return () => obs.unsubscribe();
  }, [requestId]);

  useEffect(() => {
    const obs = getAttachments(
      clientId,
      "client_requests",
      requestId
    ).subscribe(setAttachments);
    return () => obs.unsubscribe();
  }, [requestId]);

  function handleClose() {
    setShow(false);
    onClose();
  }

  useEffect(() => {
    onOpen(requestId);
  }, [requestId]);

  function handleSendNow() {
    if(shouldUseNewHierarchy) {
      SystemJS.import('clients-ui!sofe').then(({showInviteClientModal}) => {
        showInviteClientModal({
          clientId,
          infoText: 'The client request will be sent to the client portal of the selected contacts below. If a selected client does not have a client portal login they will be sent an invite to create one.',
          onInviteSuccess: (contactIds) => {
            sendSingleRequest({...request, contactIds})
          },
        })
      })
    } else {
      sendSingleRequest(request);
    }
  }

  function updateStatus(status) {
    const newRequest = { status };
    handleUpdate(newRequest);
  }

  function updateTitle(title) {
    const newRequest = { title };
    handleUpdate(newRequest);
  }

  function updateDueDate(dueDate) {
    const newRequest = { due_at: DateTime.fromJSDate(dueDate).toMillis() };
    handleUpdate(newRequest);
  }

  function updateDescription(text) {
    const newRequest = { description: text };
    handleUpdate(newRequest);
  }
  const debouncedUpdateDescription = debounce(updateDescription, 500);

  function handleUpdate(newRequest) {
    const updatedRequest = { ...request, ...newRequest };
    setRequest(updatedRequest);
    updateRequest(
      clientId,
      resolutionCaseId,
      requestId,
      updatedRequest
    ).subscribe(() => {
      handleRequestUpdate(updatedRequest);
    }, catchAsyncStacktrace());
  }

  const notification = {
    teammember_url: getUrlFromState(
      "engagement.layout.client-requests.instance",
      {
        clientRequestId: requestId,
        clientId,
        resolutionCaseId: request?.relationships?.for.id,
      }
    ),
    client_url: getUrlFromState("client.general.requests.instance", {
      clientRequestId: requestId,
      clientId,
    }),
  };

  const clientRequestAttachments = useMemo(
    () =>
      attachments.filter(
        (attachment) => !attachment.relationships.ancestor.type
      ),
    [attachments]
  );

  return (
    <CpDrawer
      show={show}
      top={118}
      styles={{ height: "100%" }}
      className={styles.clientRequestDrawer}
    >
      <button className={styles.closeBtn} onClick={handleClose}>
        <CpIcon name="caret-small-right" />
      </button>
      <div className={styles.drawerContentContainer}>
        <div className={styles.topContent}>
          <div className={styles.titleContainer}>
            {request.first_sent_at && (
              <div>
                <ChangeStatus
                  status={request.status}
                  onChange={updateStatus}
                  activeTeamMembers={activeTeamMembers}
                  requiredPermission="can_update_client_request"
                />
              </div>
            )}
            <CpTextarea
              onChange={(text) => {
                handleRequestUpdate({ ...request, title: text });
                setTitle(text);
              }}
              style={{ overflowY: "auto" }}
              maxHeight={190}
              resize="auto"
              value={title}
              className={styles.titleTextarea}
              placeholder={"Untitled"}
              onBlur={(e) => updateTitle(e.target.value)}
            />
            <CpButton
              onClick={() => {
                setShow(false);
                onDelete(request);
              }}
              className={styles.deleteBtn}
              icon="crud-trash-large"
            />
          </div>
          <div className={styles.dueDateContainer}>
            <div className={styles.triggerLabel}>Due Date: </div>
            <div>
              <DatePicker
                onChange={updateDueDate}
                position={"bottom"}
                value={request.due_at}
                renderTrigger={({ toggle }) => (
                  <button onClick={toggle} className={styles.dialogTrigger}>
                    {request.due_at ? (
                      <span className={styles.triggerPlaceholder}>
                        {DateTime.fromMillis(request.due_at).toFormat(
                          "MMM dd, yyyy"
                        )}
                      </span>
                    ) : (
                      <span className={styles.triggerPlaceholderEmpty}>
                        Add a due date
                      </span>
                    )}
                  </button>
                )}
              />
            </div>
          </div>
          <div className={styles.remindersContainer}>
            <div className={styles.triggerLabel}>Reminders: </div>
            <div>
              <ClientRequestReminder
                request={request}
                onChange={handleUpdate}
              />
            </div>
          </div>
          <div className={styles.richTextContainer}>
            <RichTextEditor
              clientId={clientId}
              onChange={debouncedUpdateDescription}
              files={[]} //This hides the loader that the RTE displays
              clientRequest={{
                id: request.id || "",
                description: request.description || "",
              }}
              disabled={!!request.first_sent_at}
              handleFiles={(files) => {
                const promises = [];
                files.forEach((file) => {
                  promises.push(
                    saveAttachments(
                      "clients",
                      clientId,
                      "client_requests",
                      requestId,
                      { file }
                    )
                  );
                });

                Promise.all(promises).then((res) => {
                  const newAttachments = res.map((r) => r[0]);
                  setAttachments((prevState) => [
                    ...prevState,
                    ...newAttachments,
                  ]);
                });
              }}
            />
          </div>

          {!isEmpty(clientRequestAttachments) && (
            <div className={styles.clientRequestAttachmentsContainer}>
              {clientRequestAttachments.map((a) => (
                <AttachmentCard
                  key={a.id}
                  attachment={a}
                  isDownloadable
                  onDelete={(attachmentId) => {
                    deleteAttachment(clientId, attachmentId).subscribe(() => {
                      setAttachments((prevState) =>
                        prevState.filter((a) => a.id !== attachmentId)
                      );
                    });
                  }}
                />
              ))}
            </div>
          )}
          <div className={styles.actionsContainer}>
            {request.first_sent_at && (
              <div>
                Sent by {request.first_sent_by} on{" "}
                {DateTime.fromMillis(request.first_sent_at).toFormat(
                  "M/d/yyyy"
                )}
              </div>
            )}
          </div>
          {shouldUseNewHierarchy && <div className={styles.contactList}>
            <span className='cp-mr-12'><b>Sent to:</b></span>
            <CpSelectSingle
              data={request.contact_ids || []}
              disableSelection
              contentWidth={320}
              renderTrigger={({toggle}) => (
                <div onClick={toggle}>
                  <CpPill text={`${request?.contact_ids?.length} contacts`}/>
                </div>
              )}
            />
            <p className="cp-m-0">The request was sent to the client portal of these listed contacts</p>
          </div>}
        </div>
        {!request.first_sent_at && (
          <div className={styles.bottomContent}>
            <div className={styles.actionsContainer}>
              <CpButton
                className="cp-mr-8"
                onClick={handleSendNow}
                disabled={!trim(title)}
              >
                Send now
              </CpButton>
              <CpButton
                onClick={handleClose}
                disabled={!trim(title)}
                btnType="secondary"
              >
                Save draft
              </CpButton>
            </div>
          </div>
        )}
        {request.first_sent_at && (
          <div className={styles.bottomContent}>
            <Comments
              clientId={clientId}
              pivotType={"client_requests"}
              pivotId={Number(requestId)}
              filesUpload={true}
              attachments={attachments}
              notification={notification}
              onAttachmentSend={(attachment) =>
                setAttachments((prevAttachments) => [
                  ...prevAttachments,
                  attachment,
                ])
              }
            >
              <CommentsList
                availableActions={["edit"]}
                className={styles.commentsListWrapper}
              />
              <CommentsInput clientId={clientId} />
            </Comments>
          </div>
        )}
      </div>
    </CpDrawer>
  );
}
