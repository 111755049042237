import { penaltyAbatementConstants } from "./penalty-abatement.constants";
import { useMemo } from "react";

export function usePenaltyAbatementOutcomes(outcomes) {
  return useMemo(() => {
    const firstTimeAbatementAvailable = !!(
      outcomes &&
      outcomes.indexOf(penaltyAbatementConstants.FIRST_TIME_ABATEMENT) >= 0
    );
    const reasonableCauseAvailable = !!(
      outcomes &&
      outcomes.indexOf(penaltyAbatementConstants.REASONABLE_CAUSE) >= 0
    );
    return {
      onlyFTA: firstTimeAbatementAvailable && !reasonableCauseAvailable,
      onlyReasonableCause:
        !firstTimeAbatementAvailable && reasonableCauseAvailable,
      bothFTAAndReasonableCause:
        firstTimeAbatementAvailable && reasonableCauseAvailable,
      cantCalculateOutcomes:
        outcomes === penaltyAbatementConstants.OUTCOME_NOT_YET_CALCULABLE ||
        !outcomes?.length,
      reliefUnlikely: !!(
        outcomes &&
        outcomes.indexOf(penaltyAbatementConstants.RELIEF_UNLIKELY) >= 0
      ),
      firstTimeAbatementAvailable,
      reasonableCauseAvailable,
      nonAssessment: !!(
        outcomes &&
        outcomes.indexOf(penaltyAbatementConstants.NON_ASSESSMENT) >= 0
      ),
    };
  }, [JSON.stringify(outcomes)]);
}
