import React, { useEffect, useState } from "react";
import { Route, useParams } from "react-router-dom";
import LetterRoutes from "./letters/letter-routes.component";
import { ServicesList } from "./services/services-list.component";
import { ResolutionCaseSummary } from "./summary/resolution-case-summary.component";
import { ClientRequests } from "./client-requests/client-requests.component";
import { Archive } from "./archive/archive.component";
import { getResolutionCase } from "../common/resolution-cases.resource";
import { CpLoader } from "canopy-styleguide!sofe";
import { NavContent } from "primary-navbar!sofe";
import { catchAsyncStacktrace } from "auto-trace";
import { SectionsSwitch } from "./sections/sections-switch.component";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { Details } from "./details/details.component";

export function ResolutionCaseRoutes() {
  const { resolutionCaseId, clientId } = useParams();
  const [resolutionCase, setResolutionCase] = useState(null);
  const [user] = useWithUserAndTenant();

  useEffect(() => {
    const subscription = getResolutionCase(
      clientId,
      resolutionCaseId
    ).subscribe(setResolutionCase, catchAsyncStacktrace());
    return () => subscription.unsubscribe();
  }, []);

  if (!resolutionCase || !user)
    return (
      //NavContent gets wrapped around these individually
      //because not all the routes in resolution cases use NavContent
      <NavContent
        hasTopnavSecondary={false}
        clientMenuPossible={true}
        className="wide-menu"
      >
        <CpLoader />
      </NavContent>
    );

  if (window.location.href.includes("engagements")) {
    // Remove this when we add engagements routes
    window.location.replace(
      window.location.href.replace("engagements", "resolution-cases")
    );
  }

  return (
    <>
      <Route
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/summary"
        render={(props) => (
          <ResolutionCaseSummary resolutionCase={resolutionCase} {...props} />
        )}
      />
      <Route
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/services"
        render={(props) => (
          <ServicesList resolutionCase={resolutionCase} {...props} />
        )}
      />
      <Route
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/program/:programSlug/section/:sectionSlug"
        render={(props) => (
          <SectionsSwitch
            clientUser={user?.role === "Client"}
            resolutionCase={resolutionCase}
            {...props}
          />
        )}
      />
      <Route
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/lock"
        render={(props) => (
          <Archive
            clientId={clientId}
            resolutionCase={resolutionCase}
            {...props}
          />
        )}
      />
      <Route
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/client_requests"
        render={(props) => (
          <ClientRequests resolutionCase={resolutionCase} {...props} />
        )}
      />
      <Route
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/details"
        render={(props) => (
          <Details resolutionCase={resolutionCase} {...props} />
        )}
      />
      <LetterRoutes />
    </>
  );
}
