import React, { useEffect, lazy, Suspense, useRef } from "react";
import { CpLoader } from "canopy-styleguide!sofe";
import styles from "./rich-text-editor.styles.css";
// import { getRelatedFiles } from 'src/resources/files.resource.js'
const CpRichTextEditor = lazy(() =>
  import("rich-text-ui!sofe")
    .then((module) => module.getRichTextEditor())
    .then((rte) => rte)
);

export function RichTextEditor({
  disabled,
  clientRequest,
  files,
  handleFiles,
  removeFile,
  onChange,
  clientId,
}) {
  const editorRef = useRef();

  useEffect(() => {
    if (!files) {
      // editing a CR out of database and consumer of CRModal didn't provide files
      // const obs = getRelatedFiles('request_tool', [clientRequest.id])
      //   .subscribe(res => props.handleInputChange('relationships.files', res[clientRequest.id]))
      // return () => obs.unsubscribe()
    }
  }, [clientRequest.id, files]);

  useEffect(() => {
    if (!clientRequest.id || !editorRef.current) return;
    editorRef.current.setHTML(clientRequest.description);
  }, [clientRequest.id]);

  function handleChange() {
    const html = editorRef.current.getHTML();
    onChange(html);
  }

  const filesConfig = {
    files,
    handleFiles,
    removeFile,
    clientId,
    onlyUploadToClient: true,
  };

  return (
    <div style={{ height: "100%" }}>
      <Suspense fallback={<CpLoader dotSize={12} />}>
        <CpRichTextEditor
          editorRef={editorRef}
          className={styles.richTextEditor}
          onInput={handleChange}
          disabled={disabled}
          filesConfig={filesConfig}
          buttons={["bold", "italic", "list-unordered", "list-ordered", "file"]}
          initialHTML={clientRequest.description}
          placeholder="Add a Description"
        />
      </Suspense>
    </div>
  );
}
