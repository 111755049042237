import React from "react";
import styles from "./date-picker.styles.css";
import { DateTime } from "luxon";
import {
  CpButton,
  CpTooltip,
  CprCalendar,
  CpDropdown,
} from "canopy-styleguide!sofe";

export function DatePicker({ onChange, value, renderTrigger, position }) {
  function trigger({ toggle }) {
    return (
      <CpTooltip text="Due date">
        {value ? (
          <CpButton
            btnType="tertiary"
            className={styles.datePickerButton}
            onClick={toggle}
            aria-label="Due date"
          >
            <img
              className={styles.datePickerImage}
              src="https://cdn.canopytax.com/static/workflow-ui/due-date-chip.svg"
            />
            <div className={styles.datePickerMonthAbbreviation}>
              {DateTime.fromISO(value).toFormat("MMM")}
            </div>
            <div className={styles.datePickerDayNumber}>
              {DateTime.fromISO(value).toFormat("d")}
            </div>
          </CpButton>
        ) : (
          <CpButton
            icon="misc-alarm-clock"
            aria-label="Due date"
            onClick={toggle}
          />
        )}
      </CpTooltip>
    );
  }

  return (
    <CpDropdown
      allowContentClicks
      position={position || "bottom-start"}
      contentWidth={264}
      renderTrigger={renderTrigger || trigger}
      renderContent={({ close }) => (
        <CprCalendar
          date={value}
          removeDateOption
          removeDateText="Remove due date"
          events={{
            datechange: (e) => {
              onChange(e.detail);
              close();
            },
          }}
        />
      )}
    />
  );
}
