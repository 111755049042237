import React from "react";
import { Link, useParams } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { useLiensOutcomes } from "../liens-outcomes.helper";

export function LiensSubordination({ outcomes, urls }) {
  const params = useParams();
  const { subordinationAvailable, cantCalculateOutcomes } =
    useLiensOutcomes(outcomes);
  return (
    <div>
      <div>
        <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
          <div className="cps-card__body">
            <div className="cps-row cps-margin-top-8 cps-padding-left-8">
              <div className="cps-body">
                Recommendation:{" "}
                <span>
                  {subordinationAvailable && (
                    <span className="cps-color-primary">
                      Lien Subordination available
                    </span>
                  )}
                  {!subordinationAvailable && !cantCalculateOutcomes && (
                    <span className="cps-warning">
                      Lien Subordination unlikely
                    </span>
                  )}
                </span>
                {cantCalculateOutcomes && (
                  <span className="cps-light-gray">
                    Recommendation unavailable
                  </span>
                )}
              </div>
              <div>
                {subordinationAvailable && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, your client may qualify for Lien Subordination.
                    Check out the resources below for additional help.
                  </div>
                )}
                {!subordinationAvailable && !cantCalculateOutcomes && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, Lien Subordination is unlikely. Sometimes it
                    doesn't hurt to try though - check out the resources below
                    to learn more.
                  </div>
                )}
                {cantCalculateOutcomes && (
                  <div>
                    We cannot provide a recommendation at this time. Please
                    complete the{" "}
                    <Link to={urls.assistant}>Liens Assistant</Link> if you
                    would like custom recommendations for your client.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Summary>
          <p>
            When you request lien subordination, you are asking the IRS to make
            a Federal Tax Lien secondary to a non-IRS lien. For example, you may
            ask for a subordination of the Federal Tax Lien to get a refinancing
            mortgage on your house or other real property you own. You would ask
            to make the Federal Tax Lien secondary to the mortgage, even though
            the mortgage came after the tax lien filing. The IRS Office of
            Appeals would consider lien subordination, in this example, if you
            used the mortgage proceeds to pay your taxes.
          </p>

          <p>
            The IRS will only consider lien subordination if it is in the best
            interest of the government. The taxpayer's compliance history may
            affect the IRS's decision to subordinate, and extensive
            documentation may be required. For example, the IRS may want
            documents that demonstrate how this transaction would increase the
            taxpayer's ability to pay.
          </p>

          <p>
            The IRS normally takes 30 days to process a loan subordination
            request, but it may take up to 90 days depending on the volume of
            applications being processed. Subordination requests are worked on a
            first come, first served basis.
          </p>

          <p>Some examples of lien subordination include:</p>

          <ul>
            <li>
              <strong>Cash-out refinance (IRC § 6325(d)(1))</strong> - The IRS
              may subordinate a lien to allow a cash-out refinance when proceeds
              from a loan or home equity line of credit will go toward taxes
              owed.
              <p>
                <i>Example:</i> Your client owns a home with a fair market value
                of $200,000 and has an outstanding mortgage of $100,000. Your
                client can refinance up to 80% of the home's value ($160,000)
                with a new mortgage bank. After $5,000 in closing costs, your
                client receives a $55,000 payout to put toward the tax
                liability. Thus, the IRS agrees to subordinate the federal tax
                lien in favor of the new mortgagor.
              </p>
            </li>
            <li>
              <strong>Rate/term reduction refinance (IRC § 6325(d)(2))</strong>{" "}
              - The IRS may subordinate a lien to allow a rate/term reduction
              refinance when lower interest rates or better terms will allow a
              higher monthly payment of the tax liability.
              <p>
                <i>Example:</i> Your client pays $1,600 per month for a home
                mortgage, $150 of which goes toward private mortgage insurance
                (PMI). Your client refinances with a new mortgage bank to lower
                the interest rate and remove the PMI. Your client has a new
                monthly payment of $1,200 and the IRS subordinates the lien in
                favor of the new mortgagor. Now, your client can pay an
                additional $400 per month toward the tax liability.
              </p>
            </li>
            <li>
              <strong>Debt consolidation refinance (IRC § 6325(d)(2))</strong> -
              The IRS will subordinate a lien to allow debt consolidation
              refinance when a lower consolidated monthly payment leaves
              additional funds to apply to the tax liability.
              <p>
                <i>Example:</i> Your client refinances to consolidate a home
                mortgage and two car loans. Before the consolidation, the total
                of the three monthly payments was $1,800. With debt
                consolidation, your client's total monthly payment will be
                $1,150. Thus, the IRS subordinates the federal tax lien in favor
                of the new mortgager. This leaves an additional $650 per month
                to apply to the tax liability.
              </p>
            </li>
          </ul>

          <p>The following steps outline the lien subordination process:</p>

          <ul>
            <li>
              You determine that a lien subordination should be requested based
              on the facts of the Taxpayers' specific situation.
            </li>
            <li>
              Prepare the lien subordination package including Form 14134,{" "}
              <i>
                Application for Certificate of Subordination of a Federal Tax
                Lien
              </i>
              , and all required documentation utilizing Publication 784,
              <i>
                Instructions on How to Apply for a Certificate of Subordination
                of Federal Tax Lien
              </i>
              .
            </li>
            <li>
              Submit the lien subordination request to the IRS Advisory Group.
            </li>
            <li>Wait 30 to 90 days for an IRS determination.</li>
            <li>
              If the request is approved for a cash-out refinance, the IRS will
              issue a conditional commitment letter. For rate/term reduction
              refinances and consolidations (transactions from which the IRS
              will not receive direct proceeds), the IRS will provide a
              projected date for issuance of a certificate of lien
              subordination.
            </li>
            <li>
              If the IRS does not approve the request, follow up immediately
              with the Advisory Group manager and, if necessary, consider filing
              an appeal.
            </li>
          </ul>
        </Summary>

        <div className="cps-fixed-masonry">
          <div className="cps-row">
            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Templates</strong>
                </div>
                <a
                  className="cps-slat"
                  href="https://cdn.canopytax.com/static/liens/subordination/AttachmentToForm14134.docx"
                >
                  <div className="cps-slat__badge cps-padding-left-24">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                      className="cp-outcomes__file-icon"
                    />
                  </div>
                  <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                    Attachment to Form 14134
                  </div>
                </a>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Contact info</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>Mailing Form 14134</strong>
                    <br />
                    IRS, Attn: Advisory Group Manager
                    <br />
                    Refer to{" "}
                    <a
                      href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                      target="_blank"
                    >
                      Publication 4235
                    </a>{" "}
                    Collection Advisory Group Addresses for the correct address
                    to send Form 14134. The correct address is the office
                    assigned to the location where the property is located.
                  </p>

                  <p>
                    <strong>Other</strong>
                    <br />
                    If you want to know how much you owe in order to pay the
                    lien(s) in full, call 1-800-913-6050, visit the nearest
                    Taxpayer Assistance Center, or contact the revenue officer
                    assigned to your case. You can also obtain a copy of lost
                    liens or check the status of liens or requests.
                  </p>
                </div>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Links and articles</strong>
                </div>

                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/Businesses/Small-Businesses-&-Self-Employed/Understanding-a-Federal-Tax-Lien"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Understanding a Federal Tax Lien
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p784.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 784 - Certificate of Subordination
                        instructions
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 4235 - Collection Advisory Group contact
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p1468.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Guidelines for processing Notice of Federal Tax Lien
                        documents
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
              </div>
            </div>

            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>IRS forms</strong>
                </div>

                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/KLRX/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 14134
                    </div>
                    Application for Certificate of Subordination of Federal Tax
                    Lien
                  </div>
                </Link>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Best practices</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>Submitting your request</strong> - You must submit
                    your request for subordination at least 45 days before your
                    settlement or closing date to ensure ample time for the IRS
                    to consider your request, make a determination, and issue
                    required notices/documents.
                  </p>
                  <p>
                    <strong>Form 14134</strong> - If you are submitting Form
                    14134 as a representative of a Taxpayer, Box 4 must be
                    checked and a copy of the in-force Form 2848 must be
                    attached. It is imperative that you submit as much
                    supporting documentation as you can with form 14134, (i.e.,
                    proposed closing statement, HUD statement, explanation for
                    your subordination request, appraisal (if available),
                    lienholder information (contact and amount owed), etc.)
                  </p>
                  <p>
                    <strong>Payment method</strong> - If payment due to the IRS
                    is made via personal check, the certificate of subordination
                    will not be issued until the bank honors the check.
                    Certified or cashier's checks are the preferred method of
                    payment.
                  </p>
                  <p>
                    <strong>Denials</strong> - If your request for subordination
                    is denied, you will receive Form 9423,{" "}
                    <i>Collection Appeal Request</i> and Publication 1660,{" "}
                    <i>Collection Appeal Rights</i>, with an explanation of why
                    your application was denied.
                  </p>
                  <p>
                    <strong>Stand-alone actions</strong> - Lien subordination
                    and lien discharge are stand-alone actions, not to be
                    combined with one another, or with a release or withdrawal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
