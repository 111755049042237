/* Joel Denning (12/28/15):
 *
 * Jdee and our resolution experts have drawn up a state machine for the questions and outcomes of penalty abatements.
 * This is our implementation of the state machine. Each state is an object with a function that, when called with a value, returns
 * the next state. For sanity's sake, the names of the states are the answers that led to that state (Y/N), followed by
 * a somewhat human readable string that represents the question. If you don't like this, then feel free to hate me.
 * However, I felt it made sense at the time :D
 *
 * Additionally, when you reach an outcome state, the outcomes are returned as part of the state, as an array of string
 * constants. If no outcome state is reached because not enough answers have been provided, then nextState will be null
 * and outcomes will be null.
 */

import { penaltyAbatementConstants } from "./penalty-abatement.constants";

const states = {};
const NA = "not_applicable";

export function penaltyAbatementStateMachine(answers = {}, questionToStopAt) {
  const initialState = {
    reachedOutcome: false,
    outcomes: penaltyAbatementConstants.OUTCOME_NOT_YET_CALCULABLE,
    nextState: states.INITIAL_STATE,
    nextQuestionSlug: penaltyAbatementConstants.INTRO,
    currentQuestionSlug: null,
    previousQuestionSlug: null,
    nextQuestionIsOutcome: false,
  };
  answers = JSON.parse(JSON.stringify(answers));
  if (answers[penaltyAbatementConstants.CIRCUMSTANCE]) {
    answers[penaltyAbatementConstants.CIRCUMSTANCE] = !!Object.keys(
      answers[penaltyAbatementConstants.CIRCUMSTANCE]
    ).find((key) =>
      key !== NA ? answers[penaltyAbatementConstants.CIRCUMSTANCE][key] : false
    );
  }
  return traverse(answers, questionToStopAt, initialState);
}

function traverse(answers, questionToStopAt, currentState) {
  const newState = currentState.nextState(
    answers[currentState.nextQuestionSlug],
    currentState.currentQuestionSlug
  );
  if (currentState.nextQuestionSlug === questionToStopAt) {
    return createResultForNonOutcomeState(currentState, newState);
  } else {
    if (newState.reachedOutcome) {
      newState.previousQuestionSlug = newState.currentQuestionSlug;
      newState.currentQuestionSlug = null;
      return newState;
    } else if (!newState.nextState) {
      return newState;
    } else if (!newState.nextState) {
      return createResultForNonOutcomeState(newState);
    } else {
      return traverse(answers, questionToStopAt, newState);
    }
  }
}

function createResultForNonOutcomeState(state, newState) {
  return {
    reachedOutcome: false,
    outcomes: null,
    nextState: null,
    nextQuestionSlug: newState ? newState.nextQuestionSlug : null,
    nextQuestionIsOutcome: !!newState.reachedOutcome,
    currentQuestionSlug: state.nextQuestionSlug,
    previousQuestionSlug: state.currentQuestionSlug,
  };
}

states.INITIAL_STATE = createBinaryState(
  penaltyAbatementConstants.INTRO,
  "Y_AUDIT",
  "Y_AUDIT"
);

states.Y_AUDIT = createBinaryState(
  penaltyAbatementConstants.AUDIT,
  "YY_LATE",
  "YN_PENALTY"
);

states.YY_LATE = createBinaryState(
  penaltyAbatementConstants.LATE,
  "YYY_CIRCUMSTANCE",
  "YYN_NOTIFICATION"
);

states.YYN_NOTIFICATION = createBinaryState(
  penaltyAbatementConstants.NOTIFICATION,
  "YYNY_LIABILITY",
  [penaltyAbatementConstants.RELIEF_UNLIKELY]
);

states.YYY_CIRCUMSTANCE = states.YNN_CIRCUMSTANCE = createBinaryState(
  penaltyAbatementConstants.CIRCUMSTANCE,
  [
    penaltyAbatementConstants.REASONABLE_CAUSE,
    penaltyAbatementConstants.NON_ASSESSMENT,
  ],
  [penaltyAbatementConstants.RELIEF_UNLIKELY]
);

states.YN_PENALTY = createBinaryState(
  penaltyAbatementConstants.PENALTY,
  "YNY_LIABILITY",
  "YNN_CIRCUMSTANCE"
);

states.YNY_LIABILITY = states.YYNY_LIABILITY = createBinaryState(
  penaltyAbatementConstants.LIABILITY,
  "YNYY_PENALTY_REASON",
  "YNYN_PAYMENTS"
);

states.YNYN_PAYMENTS = createBinaryState(
  penaltyAbatementConstants.PAYMENTS,
  "YNYNY_PENALTY_REASON",
  "YNYNN_PENALTY_TYPE"
);

states.YNYY_PENALTY_REASON =
  states.YNYY_PENALTY_REASON =
  states.YNYNY_PENALTY_REASON =
    createBinaryState(
      penaltyAbatementConstants.PENALTY_REASON,
      "YNYYY_PREVIOUS_PENALTY",
      "YNYYN_PENALTY_TYPE"
    );

states.YNYYY_PREVIOUS_PENALTY = createBinaryState(
  penaltyAbatementConstants.PREVIOUS_PENALTY,
  "YNYYYY_PENALTY_TYPE",
  "YNYYYN_COMPLIANCE"
);

states.YNYYYY_PENALTY_TYPE =
  states.YNYYYNN_PENALTY_TYPE =
  states.YNYYYNYN_PENALTY_TYPE =
  states.YNYNN_PENALTY_TYPE =
  states.YNYYN_PENALTY_TYPE =
    createBinaryState(
      penaltyAbatementConstants.PENALTY_TYPE,
      "YNYYYYY_CIRCUMSTANCE",
      [penaltyAbatementConstants.RELIEF_UNLIKELY]
    );

states.YNYYYN_COMPLIANCE = createBinaryState(
  penaltyAbatementConstants.COMPLIANCE,
  "YNYYYNY_INSTALLMENT",
  "YNYYYNN_PENALTY_TYPE"
);

states.YNYYYNY_INSTALLMENT = createBinaryState(
  penaltyAbatementConstants.INSTALLMENT,
  "YNYYYNYY_PENALTY_TYPE",
  "YNYYYNYN_PENALTY_TYPE"
);

states.YNYYYNYY_PENALTY_TYPE = createBinaryState(
  penaltyAbatementConstants.PENALTY_TYPE,
  "YNYYYNYYY_CIRCUMSTANCE",
  [penaltyAbatementConstants.RELIEF_UNLIKELY]
);

states.YNYYYNYYY_CIRCUMSTANCE = createBinaryState(
  penaltyAbatementConstants.CIRCUMSTANCE,
  [
    penaltyAbatementConstants.FIRST_TIME_ABATEMENT,
    penaltyAbatementConstants.REASONABLE_CAUSE,
  ],
  [penaltyAbatementConstants.FIRST_TIME_ABATEMENT]
);

states.YNYYYYY_CIRCUMSTANCE = createBinaryState(
  penaltyAbatementConstants.CIRCUMSTANCE,
  [penaltyAbatementConstants.REASONABLE_CAUSE],
  [penaltyAbatementConstants.RELIEF_UNLIKELY]
);

function createBinaryState(questionSlug, stateIfYes, stateIfNo) {
  const result = function (value, previousQuestionSlug) {
    let nextPlaceToGo;
    if (value === true) nextPlaceToGo = stateIfYes;
    else if (value === false) nextPlaceToGo = stateIfNo;
    else
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (typeof nextPlaceToGo === "string") {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(
        `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`
      );
    }
  };

  result.questionSlug = questionSlug;
  return result;
}
