import React from "react";
import styles from "./resolution-cases-table-row.styles.css";

export default class ResolutionCasesTableRow extends React.PureComponent {
  handleCheckboxChange = (evt) => {
    const value = evt.target.checked;
    if (value) {
      this.props.selectResolutionCase(this.props.resolutionCase);
    } else {
      this.props.deselectResolutionCase(this.props.resolutionCase);
    }
  };

  render() {
    const { resolutionCase } = this.props;
    return (
      <div
        className={`${styles.row} ${
          !this.props.isHeader && styles.highlightRow
        }`}
      >
        <div
          className={`${styles.content} ${styles.allContent} cps-margin-left-12 cps-padding-bottom-4`}
          style={{ flex: 1 }}
        >
          <form>
            <label className="cps-checkbox">
              <input
                data-testid={
                  resolutionCase
                    ? `select-resolution-case-${resolutionCase.id}`
                    : "select-all-checkbox"
                }
                type="checkbox"
                checked={!!this.props.selected}
                onChange={this.handleCheckboxChange}
              />
              <span />
            </label>
            <span />
          </form>
        </div>
        {this.props.columns.map((column) => {
          return !!this.props.isHeader ? (
            <div
              key={column.title}
              className={`${styles.columnHeader} ${styles.allContent} ${
                column.columnAlign && styles[column.columnAlign]
              }`}
              style={{ flex: column.width }}
              onClick={() => this.props.onColumnClick(column.title)}
            >
              <span>{column.title}</span>
              {this.props.sortOptions.columnTitle === column.title && (
                <span>
                  <i
                    className={`cps-icon cps-icon-sm-caret-${
                      this.props.sortOptions.ascending ? "up" : "down"
                    }`}
                  />
                </span>
              )}
            </div>
          ) : (
            <div
              style={{ flex: column.width }}
              className={`${styles.content} ${styles.allContent} ${
                column.columnAlign && styles[column.columnAlign]
              }`}
              key={column.title}
            >
              {column.getDOM
                ? column.getDOM(resolutionCase)
                : column.getValue(resolutionCase)}
            </div>
          );
        })}
      </div>
    );
  }
}
