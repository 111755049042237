import Steps from "./steps";
import React from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";

const archivedBlank =
  "https://cdn.canopytax.com/static/workflow-ui/archived-blank.png";
const deletedBlank =
  "https://cdn.canopytax.com/static/workflow-ui/deleted-blank.png";
const activeBlank =
  "https://cdn.canopytax.com/static/workflow-ui/empty-cases.png";

export function getEmptyStateConfig(showing) {
  switch (showing) {
    case "deleted":
      return {
        image: "es_delete",
        header: "The trash is empty",
        description:
          "There is nothing in the trash, bit if there were it would be here.",
      };
    case "archived":
      return {
        image: "es_archive",
        header: "The archive is empty.",
        description:
          "There is nothing in the archive, but if there were it would be here.",
      };
    default:
      return {
        image: "es_resolution_cases_list",
        header: "No resolution cases created",
        description:
          "A resolution case allows you to bundle and invoice your work.",
      };
  }
}

export function getColumns(component) {
  return [
    {
      title: "Resolution Case Name",
      width: 7,
      getValue: (obj) => obj.name,
      getDOM: (obj) =>
        component.props.showing === "deleted" ? (
          <div>{obj.name}</div>
        ) : (
          <Link
            to={`/taxes/client/${get(obj, "client_id")}/resolution-cases/${
              obj.id
            }/summary`}
          >
            {obj.name}
          </Link>
        ),
    },
    {
      title: "Client",
      width: 4,
      getValue: (obj) => get(obj, "client_id"),
      getDOM: (obj) => (
        <Link to={`/client/${get(obj, "client_id")}`}>
          {get(obj, "client_name")}
        </Link>
      ),
    },
    {
      title: "In Progress",
      width: 2,
      columnAlign: "center",
      getValue: (obj) => get(obj, "in_progress"),
      getDOM: (obj) => (
        <Steps
          resolutionCase={obj}
          accessor={"in_progress"}
          status={"in_progress"}
          showingAccessor={component.state.showingAccessor}
          showing={component.state.showing}
          updateDetailDropdown={component.updateDetailDropdown}
        />
      ),
    },
    {
      title: "In Review",
      width: 2,
      columnAlign: "center",
      getValue: (obj) => get(obj, "in_review"),
      getDOM: (obj) => (
        <Steps
          resolutionCase={obj}
          accessor={"in_review"}
          status={"needs_review"}
          showingAccessor={component.state.showingAccessor}
          showing={component.state.showing}
          updateDetailDropdown={component.updateDetailDropdown}
        />
      ),
    },
    {
      title: "Completed",
      width: 2,
      columnAlign: "center",
      getValue: (obj) => get(obj, "completed"),
      getDOM: (obj) => (
        <Steps
          resolutionCase={obj}
          accessor={`completed`}
          status={"complete"}
          showingAccessor={component.state.showingAccessor}
          showing={component.state.showing}
          updateDetailDropdown={component.updateDetailDropdown}
        />
      ),
    },
    {
      title: "Last Editor",
      width: 4,
      getValue: (obj) => obj.user_name,
    },
    {
      title: "Days Open",
      width: 2,
      columnAlign: "center",
      getValue: (obj) => obj.days_open,
    },
    {
      title: "Due Date",
      width: 2,
      getValue: (obj) =>
        obj.due_at && moment(obj.due_at).format("MMM DD, YYYY"),
    },
  ];
}
