import { leviesConstants } from "./levies.constants";

let questions = {
  [leviesConstants.ISSUED_NOTICE]: {
    questionText: "Has the IRS already issued a Notice of Intent to Levy?",
    type: "boolean",
  },
  [leviesConstants.ALREADY_LEVIED]: {
    questionText: "Has the IRS already levied property of your client?",
    type: "boolean",
  },
  [leviesConstants.LEVY_RELEASED]: {
    questionText:
      "Has the levy been released and you now want the levied property returned?",
    type: "boolean",
  },
  [leviesConstants.PROVE_FINANCIAL_HARDSHIP]: {
    questionText: "Can you prove financial hardship?",
    type: "boolean",
  },
  [leviesConstants.INCREASE_ABILITY_TO_PAY]: {
    questionText: `Will releasing the levy increase your client's ability to pay?`,
    type: "boolean",
  },
  [leviesConstants.ISSUED_PREMATURELY]: {
    questionText: "Was the levy issued prematurely?",
    type: "boolean",
  },
  [leviesConstants.AGAINST_EXEMPT_PROPERTY]: {
    questionText: "Was the levy issued against property exempt from seizure?",
    type: "boolean",
  },
  [leviesConstants.VALUE_GREATER_THAN_OWED]: {
    questionText: `Is the value of the property greater than the amount owed and releasing the levy won't impede IRS collections?`,
    type: "boolean",
  },
  [leviesConstants.OIC_OR_INSTALLMENT]: {
    questionText:
      "Is an offer in compromise or installment agreement currently pending or in effect?",
    type: "boolean",
  },
  [leviesConstants.RELEASE_FACILITATE]: {
    questionText: "Will releasing the levy faciliate collection by the IRS?",
    type: "boolean",
  },
  [leviesConstants.SATISFIED_LIABILITY]: {
    questionText: "Has the taxpayer already satisfied the liability in full?",
    type: "boolean",
  },
  [leviesConstants.FILED_FOR_BANKRUPTCY]: {
    questionText: "Has the taxpayer filed for bankruptcy?",
    type: "boolean",
  },
  [leviesConstants.COLLECTION_PERIOD_EXPIRED]: {
    questionText: "Has the statutory collection period already expired?",
    type: "boolean",
  },
  [leviesConstants.IDENTITY_THEFT]: {
    questionText: "Was the taxpayer a victim of identity theft?",
    type: "boolean",
  },
  [leviesConstants.ISSUED_ERRONEOUSLY]: {
    questionText: "Was the levy issued erroneously in some other way?",
    type: "boolean",
  },
  [leviesConstants.ISSUED_WRONGFULLY]: {
    questionText: "Was the levy issued wrongfully?",
    type: "boolean",
  },
  [leviesConstants.ABILITY_TO_PAY]: {
    questionText:
      "Does your client have the ability to pay the entire remaining tax liability now?",
    type: "boolean",
  },
  [leviesConstants.ABLE_TO_SATISFY]: {
    questionText:
      "Is your client able to satisfy his or her entire tax liability via an Installment Agreement or Offer in Compromise?",
    type: "boolean",
  },
};
export const leviesQuestionEnhancer = (questionSlug) => {
  return questions[questionSlug];
};
