// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-dashboard-resolution-cases-create-styles__resolutionCaseDetailsContainer--2U4Hv {\n  width: 76rem;\n  margin: 0 auto;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__datePickerWrapper--2tcqf {\n  display: flex;\n  align-items: center;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__calendarIcon--1SlYu {\n  position: absolute;\n  left: 0.8rem;\n  z-index: 100;\n  color: var(--cp-color-app-icon);\n}\n\n.src-client-dashboard-resolution-cases-create-styles__serviceListItem--MyeAN {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 1rem;\n  border-bottom: solid 1px var(--cp-color-app-border);\n}\n\n.src-client-dashboard-resolution-cases-create-styles__serviceItemTitle--20m3C {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__serviceItemAction--33rLT {\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__textArea--2JhVk {\n  min-height: 5.6rem;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__textArea--2JhVk textarea {\n  max-height: 5.6rem;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__dateInput--3FfzI {\n  flex: 1;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__infoIcon--1HHKs {\n  flex-shrink: 0;\n}\n\n.src-client-dashboard-resolution-cases-create-styles__flexEllipsis--1T_9b,\n.src-client-dashboard-resolution-cases-create-styles__flexEllipsis--1T_9b > * {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n", ""]);
// Exports
exports.locals = {
	"resolutionCaseDetailsContainer": "src-client-dashboard-resolution-cases-create-styles__resolutionCaseDetailsContainer--2U4Hv",
	"datePickerWrapper": "src-client-dashboard-resolution-cases-create-styles__datePickerWrapper--2tcqf",
	"calendarIcon": "src-client-dashboard-resolution-cases-create-styles__calendarIcon--1SlYu",
	"serviceListItem": "src-client-dashboard-resolution-cases-create-styles__serviceListItem--MyeAN",
	"serviceItemTitle": "src-client-dashboard-resolution-cases-create-styles__serviceItemTitle--20m3C",
	"serviceItemAction": "src-client-dashboard-resolution-cases-create-styles__serviceItemAction--33rLT",
	"textArea": "src-client-dashboard-resolution-cases-create-styles__textArea--2JhVk",
	"dateInput": "src-client-dashboard-resolution-cases-create-styles__dateInput--3FfzI",
	"infoIcon": "src-client-dashboard-resolution-cases-create-styles__infoIcon--1HHKs",
	"flexEllipsis": "src-client-dashboard-resolution-cases-create-styles__flexEllipsis--1T_9b"
};
module.exports = exports;
