import React, { useEffect, useMemo, useState } from "react";
import { NavContent } from "primary-navbar!sofe";
import styles from "./client-requests.styles.css";
import {
  CpCard,
  CpButton,
  CpEmptyState,
  CpIcon,
  CpTooltip,
  CpModal,
} from "canopy-styleguide!sofe";
import { showInviteClientModal } from "clients-ui!sofe";

import {
  sendClientRequests,
  getClientRequests,
  createClientRequest,
  deleteClientRequest,
} from "src/common/client-requests.resource";
import {
  useParams,
  Link,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { catchAsyncStacktrace } from "auto-trace";
import { groupRequests, getRelativeTime } from "./client-requests.utils";
import { isEmpty, map, filter, orderBy, cloneDeep, groupBy } from "lodash";
import { DateTime } from "luxon";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { ClientRequestDrawer } from "./client-request-drawer.component";
import { getClientData } from "src/common/resolution-cases.resource";
import { useCrmHierarchy } from "../../common/use-crm-hierarchy.helpers";

const contentDrawerMargin = "36rem";

export function ClientRequests({ resolutionCase }) {
  const { clientId } = useParams();
  const [clientRequests, setClientRequests] = useState({});
  const [activeClientIds, setActiveClientIds] = useState([]);
  const [activeTeamMembers, setActiveTeamMembers] = useState([]);
  const [unsentTitledRequests, setUnsentTitledRequests] = useState([]);
  const [unsentUntitledRequests, setUnsentUntitledRequests] = useState([]);
  const [selectedDrawerId, setSelectedDrawerId] = useState("");
  const [showSendRequestModal, setShowSendRequestModal] = useState(false);
  const [showConfirmSendRequestsModal, setShowConfirmSendRequestsModal] =
    useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const { unsent, needs_review, in_progress, complete } = useMemo(
    () => groupRequests(clientRequests),
    [clientRequests]
  );
  const [user] = useWithUserAndTenant();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const shouldUseNewHierarchy = useCrmHierarchy();

  useEffect(() => {
    const sub = getClientRequests(clientId, resolutionCase.id).subscribe(
      setClientRequests,
      catchAsyncStacktrace()
    );
    return () => sub.unsubscribe();
  }, [clientId, resolutionCase.id]);

  useEffect(() => {
    const sub = getClientData(clientId).subscribe(({ users }) => {
      const _activeClientIds = map(
        filter(
          users,
          (user) =>
            user.is_activated && !user.is_deleted && user.role === "Client"
        ),
        "id"
      );

      const _activeTeamMembers = filter(users, {
        is_activated: true,
        is_deleted: false,
        role: "TeamMember",
      });

      setActiveClientIds(_activeClientIds);
      setActiveTeamMembers(_activeTeamMembers);
    }, catchAsyncStacktrace());

    return () => sub.unsubscribe();
  }, [clientId]);

  function handleDrawerClose() {
    setSelectedDrawerId("");
    setTimeout(() => {
      history.push(url);
    }, 400);
  }

  function handleDelete(request) {
    handleDrawerClose();
    deleteClientRequest(clientId, resolutionCase.id, request.id).subscribe(
      () => {
        setClientRequests((prevClientRequests) => {
          return filter(prevClientRequests, ({ id }) => id !== request.id);
        });
      }
    );
  }

  function addNewRequest() {
    createClientRequest(clientId, resolutionCase.id).subscribe((newRequest) => {
      setClientRequests((prevClientRequests) => [
        ...prevClientRequests,
        newRequest,
      ]);
      history.push(`${url}/${newRequest.id}`);
    });
  }

  function sendSingleRequest(request) {
    setSendingRequest(true);
    setShowSendRequestModal(true);
    sendClientRequests(clientId, [request]).subscribe(() => {
      setSendingRequest(false);
      const _clientRequests = cloneDeep(clientRequests);
      const clientRequest = _clientRequests.find(({ id }) => id === request.id);
      const updatedClientRequest = {
        ...clientRequest,
        first_sent_at: new Date().getTime(),
        aggregate_updated_at: new Date().getTime(),
      };
      _clientRequests.splice(
        _clientRequests.indexOf(clientRequest),
        1,
        updatedClientRequest
      );
      setClientRequests(_clientRequests);
    });
  }

  function checkClientPortal(cb) {
    if (isEmpty(activeClientIds)) {
      if (shouldUseNewHierarchy) {
        SystemJS.import("clients-ui!sofe").then(({ showInviteClientModal }) => {
          showInviteClientModal({
            clientId: resolutionCase.relationships.for.id,
            infoText: `Your client hasn't joined you on Canopy yet. If you want to send this request, you'll need to invite them to Canopy's client portal.`,
            onInviteSuccess: (contactIds) => {
              setActiveClientIds(contactIds);
              cb();
            },
          });
        });
      } else {
        showInviteClientModal(
          resolutionCase.relationships.for.id,
          (newUserIds) => {
            setActiveClientIds(newUserIds);
            cb();
          },
          `Your client hasn't joined you on Canopy yet. If you want to send this request, you'll need to invite them to Canopy's client portal.`
        );
      }
    } else {
      cb();
    }
  }

  function startSendSingle(request) {
    handleDrawerClose();
    checkClientPortal(() => {
      sendSingleRequest(request);
    });
  }

  function startSendBulk() {
    checkClientPortal(() => {
      checkBulkSend();
    });
  }

  function checkBulkSend() {
    // Check if there are any unsent requests missing titles
    let unsentRequests = groupBy(unsent, ({ title }) => isEmpty(title));
    const _unsentUntitledRequests = unsentRequests[true];
    const _unsentTitledRequests = unsentRequests[false];
    setUnsentTitledRequests(_unsentTitledRequests);
    setUnsentUntitledRequests(_unsentUntitledRequests);

    if (_unsentUntitledRequests && _unsentUntitledRequests.length) {
      setShowConfirmSendRequestsModal(true);
    } else {
      sendBulkRequests(unsent);
    }
  }

  function sendBulkRequests(requests) {
    setShowSendRequestModal(true);
    sendClientRequests(clientId, requests).subscribe(() => {
      const newRequests = clientRequests.map((cr) => {
        const matchedRequest = requests.find((request) => request.id === cr.id);
        if (matchedRequest) {
          return { ...cr, first_sent_at: new Date().getTime() };
        }
        return cr;
      });

      setClientRequests(newRequests);
      setUnsentTitledRequests([]);
      setUnsentUntitledRequests([]);
    }, catchAsyncStacktrace());
  }

  function handleRequestUpdate(newRequest) {
    setClientRequests((prevClientRequests) => {
      return map(prevClientRequests, (clientRequest) => {
        if (clientRequest.id === newRequest.id) {
          return newRequest;
        }
        return clientRequest;
      });
    });
  }

  return (
    <NavContent
      hasTopnavSecondary={false}
      clientMenuPossible={true}
      className="wide-menu"
    >
      <div>
        <div
          style={{
            marginRight: selectedDrawerId ? contentDrawerMargin : 0,
          }}
          className={styles.clientRequestsContentWrapper}
        >
          {unsent && (
            <CpCard className={styles.clientRequestCardItem}>
              <CpCard.Header>
                <div className="cp-subheader">Draft Requests</div>
                <div>
                  <CpButton btnType="tertiary" onClick={startSendBulk}>
                    Send all drafts
                  </CpButton>
                  <CpButton btnType="flat" onClick={addNewRequest}>
                    New request
                  </CpButton>
                </div>
              </CpCard.Header>
              <div className={styles.columnsSubheader}>
                <div className={styles.listColumns}>
                  <div>Request Name</div>
                  <div>Due Date</div>
                </div>
              </div>
              <div>
                {orderBy(unsent, "updated_at", "desc").map(
                  ({ due_at, id, title }) => (
                    <Link
                      to={`${url}/${id}`}
                      className={styles.requestListItem}
                      key={id}
                    >
                      <div
                        className={`${styles.flexEllipsis} ${styles.listItemTitle}`}
                      >
                        {title || (
                          <span className="cp-color-app-disabled-text">
                            Untitled
                          </span>
                        )}
                      </div>
                      <div>
                        {due_at ? (
                          DateTime.fromMillis(due_at).toFormat("d/m/yyyy")
                        ) : (
                          <span className="cp-color-app-disabled-text">—</span>
                        )}
                      </div>
                    </Link>
                  )
                )}
              </div>
            </CpCard>
          )}
          <CpCard className={styles.clientRequestCardItem}>
            <CpCard.Header>
              <div className="cp-subheader">In Progress</div>
              {isEmpty(unsent) && (
                <CpButton onClick={addNewRequest} btnType="flat">
                  New request
                </CpButton>
              )}
            </CpCard.Header>
            {isEmpty(in_progress) ? (
              <CpEmptyState
                className="cp-p-32"
                img="es_resolution_cases_list"
                text="No requests in progress"
                subText="There are no requests in progress at this time."
              />
            ) : (
              <div>
                <div className={styles.columnsSubheader}>
                  <div
                    className={`${styles.listColumnsInProgress} ${styles.listColumns}`}
                  >
                    <div>Request Name</div>
                    <div>
                      <div>Sent</div>
                      <div className={styles.listColumnDueDate}>Due Date</div>
                    </div>
                  </div>
                </div>
                {orderBy(in_progress, "updated_at", "desc").map((request) => (
                  <RequestListItem
                    key={request.id}
                    request={request}
                    user={user}
                  />
                ))}
              </div>
            )}
          </CpCard>
          <CpCard className={styles.clientRequestCardItem}>
            {isEmpty(needs_review) ? (
              <CpEmptyState
                className="cp-p-32"
                img="es_tasks_list"
                text="You have no requests for review"
                subText="There are no requests that need to be reviewed for this client."
              />
            ) : (
              <>
                <CpCard.Header>
                  <div className="cp-subheader">Requests for review</div>
                </CpCard.Header>
                <div>
                  {orderBy(needs_review, "updated_at", "desc").map(
                    (request) => (
                      <RequestListItem
                        key={request.id}
                        request={request}
                        user={user}
                      />
                    )
                  )}
                </div>
              </>
            )}
          </CpCard>
          <CpCard className={styles.clientRequestCardItem}>
            {isEmpty(complete) ? (
              <CpEmptyState
                className="cp-p-32"
                img="es_tasks_complete"
                text="You have no completed requests"
                subText="There are no completed requests assigned to this client."
              />
            ) : (
              <>
                <CpCard.Header>
                  <div className="cp-subheader">Completed Requests</div>
                </CpCard.Header>
                <div>
                  {orderBy(complete, "updated_at", "desc").map((request) => (
                    <RequestListItem
                      key={request.id}
                      request={request}
                      user={user}
                    />
                  ))}
                </div>
              </>
            )}
          </CpCard>
        </div>
      </div>
      <Route
        exact
        path={`${path}/:requestId`}
        render={(props) => (
          <ClientRequestDrawer
            {...props}
            onOpen={setSelectedDrawerId}
            onClose={handleDrawerClose}
            activeTeamMembers={activeTeamMembers}
            onDelete={handleDelete}
            sendSingleRequest={startSendSingle}
            handleRequestUpdate={handleRequestUpdate}
          />
        )}
      />
      <SendRequestModal
        show={showSendRequestModal}
        onClose={() => setShowSendRequestModal(false)}
        sending={sendingRequest}
      />
      <ConfirmSendRequestsModal
        show={showConfirmSendRequestsModal}
        onClose={() => setShowConfirmSendRequestsModal(false)}
        unsentUntitledRequests={unsentUntitledRequests}
        unsentTitledRequests={unsentTitledRequests}
        onSend={sendBulkRequests}
      />
    </NavContent>
  );
}

function RequestListItem({ request, user }) {
  const { url } = useRouteMatch();
  const { due_at, title, first_sent_at, id, total_comments, unread_comments } =
    request;
  const hasComments = total_comments > 0;
  const hasUnread = unread_comments > 0;

  return (
    <Link
      to={`${url}/${id}`}
      className={`
        ${styles.listColumnsInProgress} ${styles.requestListItem} ${
        hasUnread ? "cp-wt-semibold" : ""
      }
      `}
    >
      <div className={`${styles.flexEllipsis} ${styles.listItemTitle}`}>
        {title}
      </div>
      <div>
        {hasComments && (
          <CpIcon
            className={`
              ${styles.commentIcon} ${
              unread_comments ? styles.unreadComments : ""
            }
            `}
            name="communication-chat-bubble"
          />
        )}
        <div>
          <RelativeTime date={first_sent_at} timeZone={user?.time_zone} />
        </div>
        <div className={styles.listColumnDueDate}>
          {due_at ? (
            DateTime.fromMillis(due_at).toFormat("D")
          ) : (
            <span className="cp-color-app-disabled-text">—</span>
          )}
        </div>
      </div>
    </Link>
  );
}

function SendRequestModal({ show, onClose, sending }) {
  return (
    <CpModal show={show} onClose={onClose} width={540}>
      <div className={styles.sendingModalHeroImage}>
        <img
          src="https://cdn.canopytax.com/static/workflow-ui/sending-illustration.svg"
          alt=""
        />
      </div>
      {sending ? (
        <div className="cps-text-center">
          <div className={styles.sendingModalHeader}>
            Sending this request to your client!
          </div>
          <div className={styles.sendingModalSubheader}>
            This should just take a second.
          </div>
        </div>
      ) : (
        <div className="cps-text-center cps-animate-fade">
          <div className={styles.sendingModalHeader}>
            Your requests have been delivered!
          </div>
          <div className={`${styles.sendingModalSubheader} cp-mt-24`}>
            <CpButton onClick={onClose} btnType="primary">
              Done
            </CpButton>
          </div>
        </div>
      )}
    </CpModal>
  );
}

function ConfirmSendRequestsModal({
  show,
  onClose,
  onSend,
  unsentTitledRequests = [],
  unsentUntitledRequests = [],
}) {
  return (
    <CpModal show={show} onClose={onClose} width={540}>
      <CpModal.Header title="Before We Send Your Requests" />
      <div className="cps-card__body">
        <p>
          It looks like
          <strong> you haven't added titles</strong> to{" "}
          {unsentUntitledRequests.length} of your unsent client requests. You'll
          need to add titles to these requests before we can send them to your
          client.
        </p>
        {unsentTitledRequests.length > 0 && (
          <p>
            You can choose to do that below or
            <strong> you can send only the requests with titles.</strong>
          </p>
        )}
      </div>
      <div className="cps-modal__dialog__actions">
        {unsentTitledRequests.length > 0 && (
          <CpButton
            className="cp-mr-8"
            btnType="primary"
            onClick={() => {
              onClose();
              onSend(unsentTitledRequests);
            }}
          >
            Send titled requests
          </CpButton>
        )}
        <CpButton btnType="secondary" onClick={onClose}>
          Add titles now
        </CpButton>
      </div>
    </CpModal>
  );
}

function RelativeTime({ date, timeZone }) {
  const jsDate = new Date(date);
  const relativeTime = useMemo(
    () => getRelativeTime(date, timeZone) || "",
    [date, timeZone]
  );
  const formattedDate = useMemo(
    () =>
      date ? DateTime.fromJSDate(jsDate).toFormat("MMM d, yyyy, h:mma") : "",
    [jsDate]
  );
  return (
    <CpTooltip text={formattedDate}>
      <span>{date ? relativeTime : "Invalid date"}</span>
    </CpTooltip>
  );
}
