import React, { useState } from "react";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";
import {
  OutcomeHeader,
  OutcomeHeaderItem,
} from "../common/outcome-header.component";
import { liensConstants } from "./liens.constants";
import { useLiensOutcomes } from "./liens-outcomes.helper";
import { LiensAppeals } from "./outcomes/liens-appeals.component";
import { LiensDischarge } from "./outcomes/liens-discharge.component";
import { LiensRelease } from "./outcomes/liens-release.component";
import { LiensSubordination } from "./outcomes/liens-subordination.component";
import { LiensWithdrawal } from "./outcomes/liens-withdrawal.component";

export function LiensResolution({ urls, outcomes }) {
  const {
    appealAvailable,
    subordinationAvailable,
    dischargeAvailable,
    withdrawalAvailable,
    releaseAvailable,
  } = useLiensOutcomes(outcomes);
  const [resolutionType, setResolutionType] = useState(
    (() => {
      if (appealAvailable) {
        return liensConstants.APPEAL;
      } else if (dischargeAvailable) {
        return liensConstants.DISCHARGE;
      } else if (releaseAvailable) {
        return liensConstants.RELEASE;
      } else if (subordinationAvailable) {
        return liensConstants.SUBORDINATION;
      } else if (withdrawalAvailable) {
        return liensConstants.WITHDRAWAL;
      }
      return liensConstants.APPEAL;
    })()
  );
  return (
    <>
      <OutcomeHeader value={resolutionType} onChange={setResolutionType}>
        <OutcomeHeaderItem id={liensConstants.APPEAL} active={appealAvailable}>
          Appeals
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={liensConstants.DISCHARGE}
          active={dischargeAvailable}
        >
          Lien discharge
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={liensConstants.RELEASE}
          active={releaseAvailable}
        >
          Lien release
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={liensConstants.SUBORDINATION}
          active={subordinationAvailable}
        >
          Lien subordination
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={liensConstants.WITHDRAWAL}
          active={withdrawalAvailable}
        >
          Lien withdrawal
        </OutcomeHeaderItem>
      </OutcomeHeader>

      <CpSwitch expression={resolutionType}>
        <CpCase value={liensConstants.APPEAL}>
          <LiensAppeals outcomes={outcomes} urls={urls} />
        </CpCase>
        <CpCase value={liensConstants.DISCHARGE}>
          <LiensDischarge outcomes={outcomes} urls={urls} />
        </CpCase>
        <CpCase value={liensConstants.RELEASE}>
          <LiensRelease outcomes={outcomes} urls={urls} />
        </CpCase>
        <CpCase value={liensConstants.SUBORDINATION}>
          <LiensSubordination outcomes={outcomes} urls={urls} />
        </CpCase>
        <CpCase value={liensConstants.WITHDRAWAL}>
          <LiensWithdrawal outcomes={outcomes} urls={urls} />
        </CpCase>
      </CpSwitch>
    </>
  );
}
