/* Jdee and our resolution experts have drawn up a state machine for the questions and outcomes.
 * This is our implementation of the state machine. Each state is an object with a function that, when called with a value, returns
 * the next state. For sanity's sake, the names of the states are the answers that led to that state (Y/N), followed by
 * a somewhat human readable string that represents the question. If you don't like this, then feel free to hate us.
 * However, I felt it made sense at the time :D
 *
 * Additionally, when you reach an outcome state, the outcomes are returned as part of the state, as an array of string
 * constants. If no outcome state is reached because not enough answers have been provided, then nextState will be null
 * and outcomes will be null.
 */

import { trustFundConstants } from "./trust-fund.constants";

// -----------------------------------------------------
// create all of the recognized states of the finite state automaton
// -----------------------------------------------------
const states = {};

states.INITIAL_STATE = createBinaryState(
  trustFundConstants.INTRO,
  "Y_UNPAID_EMPL_TAX",
  "Y_UNPAID_EMPL_TAX"
);

states.Y_UNPAID_EMPL_TAX = createBinaryState(
  trustFundConstants.UNPAID_EMPL_TAX,
  "YY_CLIENT_TYPE",
  [trustFundConstants.RELIEF_UNLIKELY]
);

states.YY_CLIENT_TYPE = createMultiSelectState(trustFundConstants.CLIENT_TYPE);

states.YYA_CLIENT_PRINCIPAL = createBinaryState(
  trustFundConstants.CLIENT_PRINCIPAL,
  [trustFundConstants.EMPLOYMENT_TAX_WARNING],
  [trustFundConstants.EMPLOYMENT_TAX]
);

states.YYC_CAN_PREVENT = createBinaryState(
  trustFundConstants.CAN_PREVENT,
  [trustFundConstants.EMPLOYMENT_TAX],
  "YYCN_PAY_BALANCE_24_MO"
);

states.YYCN_PAY_BALANCE_24_MO = createBinaryState(
  trustFundConstants.PAY_BALANCE_24_MO,
  "YYCNY_BALANCE_LESS",
  "YYCNN_RECEIVED_1058"
);

states.YYCNY_BALANCE_LESS = createBinaryState(
  trustFundConstants.BALANCE_LESS,
  "YYCNYY_FULLY_COMPLIANT",
  "YYCNYN_RECEIVED_1058"
);

states.YYCNYY_FULLY_COMPLIANT = createBinaryState(
  trustFundConstants.FULLY_COMPLIANT,
  [trustFundConstants.EMPLOYMENT_TAX],
  "YYCNYYN_RECEIVED_1058"
);

states.YYCNN_RECEIVED_1058 =
  states.YYCNYN_RECEIVED_1058 =
  states.YYCNYYN_RECEIVED_1058 =
    createBinaryState(
      trustFundConstants.RECEIVED_1058,
      "YYCNNY_RECEIPT_OF_NOTICE",
      "YYCNNN_FINANCIALLY_INCAPABLE"
    );

states.YYCNNY_RECEIPT_OF_NOTICE = createBinaryState(
  trustFundConstants.RECEIPT_OF_NOTICE,
  [trustFundConstants.APPEAL, trustFundConstants.TFRP_DEFENSE],
  "YYCNNYN_FINANCIALLY_INCAPABLE"
);

states.YYCNNN_FINANCIALLY_INCAPABLE = states.YYCNNYN_FINANCIALLY_INCAPABLE =
  createBinaryState(
    trustFundConstants.FINANCIALLY_INCAPABLE,
    [trustFundConstants.TFRP_DEFENSE],
    "YYCNNNN_RECEIVED_1153"
  );

states.YYCNNNN_RECEIVED_1153 = createBinaryState(
  trustFundConstants.RECEIVED_1153,
  "YYCNNNNY_DAYS_LAPSED",
  "YYCNNNNN_RESPONSIBLE_PERSON"
);

states.YYCNNNNY_DAYS_LAPSED = createBinaryState(
  trustFundConstants.DAYS_LAPSED,
  "YYCNNNNNY_RESPONSIBLE_PERSON",
  "YYCNNNNNN_DISPUTE_ASSESSED_PENALTY"
);

states.YYCNNNNNN_DISPUTE_ASSESSED_PENALTY = createBinaryState(
  trustFundConstants.DISPUTE_ASSESSED_PENALTY,
  [trustFundConstants.APPEAL, trustFundConstants.TFRP_DEFENSE],
  "YYCNNNNNNN_RESPONSIBLE_PERSON"
);

states.YYCNNNNN_RESPONSIBLE_PERSON =
  states.YYCNNNNNY_RESPONSIBLE_PERSON =
  states.YYCNNNNNNN_RESPONSIBLE_PERSON =
    createBinaryState(
      trustFundConstants.RESPONSIBLE_PERSON,
      "YYCNNNNNY_COMPLETED_4180",
      [trustFundConstants.TFRP_DEFENSE]
    );

states.YYCNNNNNY_COMPLETED_4180 = createBinaryState(
  trustFundConstants.COMPLETED_4180,
  "YYCNNNNNYY_OTHER_PERSON",
  [trustFundConstants.TFRP_DEFENSE]
);

states.YYCNNNNNYY_OTHER_PERSON = createBinaryState(
  trustFundConstants.OTHER_PERSON,
  [trustFundConstants.TFRP_DEFENSE],
  "YYCNNNNNYYN_SIGNER_ON_ACCOUNT"
);

states.YYCNNNNNYYN_SIGNER_ON_ACCOUNT = createBinaryState(
  trustFundConstants.SIGNER_ON_ACCOUNT,
  [trustFundConstants.TFRP_DEFENSE],
  "YYCNNNNNYYNN_RECEIVED_3586"
);

states.YYCNNNNNYYNN_RECEIVED_3586 = createBinaryState(
  trustFundConstants.RECEIVED_3586,
  [trustFundConstants.TFRP_DEFENSE],
  [trustFundConstants.RELIEF_UNLIKELY]
);

//----------------------------------------------------
function createMultiSelectState(questionSlug) {
  let result = function (answer, previousQuestionSlug) {
    let nextPlaceToGo;

    if (answer === "business-separate-entity") {
      nextPlaceToGo = "YYA_CLIENT_PRINCIPAL";
    } else if (answer === "business") {
      nextPlaceToGo = [trustFundConstants.EMPLOYMENT_TAX];
    } else if (answer === "individual") {
      nextPlaceToGo = "YYC_CAN_PREVENT";
    }

    if (!nextPlaceToGo) {
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    }

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (typeof nextPlaceToGo === "string") {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }

      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(
        `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`
      );
    }
  };

  result.questionSlug = questionSlug;
  return result;
}

function createBinaryState(questionSlug, stateIfYes, stateIfNo) {
  const result = function (value, previousQuestionSlug) {
    let nextPlaceToGo;

    if (value === true) {
      nextPlaceToGo = stateIfYes;
    } else if (value === false) {
      nextPlaceToGo = stateIfNo;
    } else {
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    }

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (typeof nextPlaceToGo === "string") {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }

      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(
        `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`
      );
    }
  };

  result.questionSlug = questionSlug;
  return result;
}

export function trustFundStateMachine(answers = {}, questionToStopAt) {
  const initialState = {
    reachedOutcome: false,
    outcomes: trustFundConstants.OUTCOME_NOT_YET_CALCULABLE,
    nextState: states.INITIAL_STATE,
    nextQuestionSlug: trustFundConstants.INTRO,
    currentQuestionSlug: null,
    previousQuestionSlug: null,
    nextQuestionIsOutcome: false,
  };
  answers = JSON.parse(JSON.stringify(answers));

  return traverse(answers, questionToStopAt, initialState);
}

function traverse(answers, questionToStopAt, currentState) {
  const newState = currentState.nextState(
    answers[currentState.nextQuestionSlug],
    currentState.currentQuestionSlug
  );
  if (currentState.nextQuestionSlug === questionToStopAt) {
    return createResultForNonOutcomeState(currentState, newState);
  } else {
    if (newState.reachedOutcome) {
      newState.previousQuestionSlug = newState.currentQuestionSlug;
      newState.currentQuestionSlug = null;
      return newState;
    } else if (!newState.nextState) {
      return newState;
    } else if (!newState.nextState) {
      return createResultForNonOutcomeState(newState);
    } else {
      return traverse(answers, questionToStopAt, newState);
    }
  }
}

function createResultForNonOutcomeState(state, newState) {
  return {
    reachedOutcome: false,
    outcomes: null,
    nextState: null,
    nextQuestionSlug: newState ? newState.nextQuestionSlug : null,
    nextQuestionIsOutcome: !!newState.reachedOutcome,
    currentQuestionSlug: state.nextQuestionSlug,
    previousQuestionSlug: state.currentQuestionSlug,
  };
}
