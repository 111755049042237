import React from "react";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function LeviesAgainstExemptProperty() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Was the levy issued against{" "}
        <a onClick={exemptPropertyModal}>property exempt from seizure</a>?
      </strong>
    </div>
  );
}

function exemptPropertyModal() {
  renderInfoModal(
    "Examples of Property Exempt from Seizure",
    <ul>
      <li>Unemployment benefits</li>
      <li>Certain annuity and pension benefits</li>
      <li>Certain disability payments</li>
      <li>Workers compensation</li>
      <li>Certain public assistance payments</li>
      <li>Minimum weekly exempt income</li>
      <li>Income for court-ordered child support payments</li>
      <li>Certain personal property and personal household effects</li>
    </ul>
  );
}
