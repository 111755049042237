import React, { lazy, Suspense, useState, useEffect, useMemo } from "react";
import { asyncStacktrace, catchAsyncStacktrace } from "auto-trace";
import { Link, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { track } from "cp-analytics";
import { NavContent } from "primary-navbar!sofe";
import {
  CpButton,
  CpCard,
  CpEmptyState,
  CpIcon,
  CpLoader,
  useIsMounted,
} from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import {
  getLetters,
  deleteLetter,
  unDeleteLetter,
  getClient,
} from "../../resources/resolution-case-letter.resource";
import styles from "./letters.styles.css";

const NewLetterModal = lazy(() =>
  SystemJS.import("document-editor-ui!sofe")
    .then((deUI) => deUI.getNewLetterFromTemplateModal())
    .then((modal) => modal)
);

const DocumentEditorOverlay = lazy(() =>
  SystemJS.import("document-editor-ui!sofe")
    .then((deUI) => deUI.getDocumentEditorOverlay())
    .then((modal) => modal)
);

export const Letters = ({ location }) => {
  const letterId = location.state?.letterId;
  const isMounted = useIsMounted();
  const [showNewLetterModal, setShowNewLetterModal] = useState(false);
  const [showDocumentEditor, setShowDocumentEditor] = useState(false);
  const [documentOverlayData, setDocumentOverlayData] = useState({});
  const [letters, setLetters] = useState([]);
  const [client, setClient] = useState();
  const [loading, setLoading] = useState(true);
  const [letterToDelete, setLetterToDelete] = useState();
  const { clientId, resolutionCaseId } = useParams();

  const sortedLetters = useMemo(() => {
    return letters.sort((a, b) => a.title.localeCompare(b.title));
  }, [letters]);

  useEffect(() => {
    if (clientId) {
      const clientSubscription = getClient(clientId).subscribe((client) => {
        setClient(client);
      }, catchAsyncStacktrace());

      return () => clientSubscription?.unsubscribe();
    }
  }, [clientId]);

  useEffect(() => {
    if (!letterId || letters.length === 0 || !client) return;

    const letter = letters.find((letter) => letter.id === letterId);

    if (letter) {
      if (letter.document_template_id) {
        openExistingLetter(letter.document_template_id, client, letter.title);
      } else {
        window.location = `#/letters/edit/client/${clientId}/resolution-cases/${resolutionCaseId}/letter/${letterId}`;
      }
    }
  }, [letterId, letters.length, client?.id]);

  useEffect(() => {
    if (showDocumentEditor) return;
    const subscription = getLetters(clientId, resolutionCaseId).subscribe(
      (res) => {
        setLetters(res.letters);
        setLoading(false);
      },
      asyncStacktrace((err) => {
        setLoading(false);
        catchAsyncStacktrace(err);
      })
    );
    return () => subscription.unsubscribe();
  }, [showDocumentEditor]);

  useEffect(() => {
    if (!letterToDelete) return;
    const subscription = deleteLetter(
      clientId,
      resolutionCaseId,
      letterToDelete.id
    ).subscribe(() => {
      setLetters((prevState) =>
        prevState.filter((letter) => letter.id !== letterToDelete.id)
      );
      successToast({
        message: `Letter deleted`,
        actionText: "Undo",
        actionCallback: () => {
          unDeleteLetter(
            clientId,
            resolutionCaseId,
            letterToDelete.id
          ).subscribe();
          if (isMounted.current) {
            setLetters((prevState) => [...prevState, letterToDelete]);
          }
          successToast("Letter restored successfully");
        },
      });
      setLetterToDelete(null);
    }, catchAsyncStacktrace());
    return () => subscription.unsubscribe();
  }, [letterToDelete]);

  const openExistingLetter = (documentId, client, title) => {
    setShowDocumentEditor(true);
    setDocumentOverlayData({
      existing: true,
      documentId,
      client,
      title,
    });
  };

  const title = useMemo(() => {
    const baseTitle =
      documentOverlayData.title ||
      documentOverlayData.template?.title ||
      "New Letter";
    if (!documentOverlayData.existing) {
      return `${baseTitle} - ${DateTime.local().toLocaleString(
        DateTime.DATE_SHORT
      )}`;
    } else {
      return baseTitle;
    }
  }, [documentOverlayData]);

  return (
    <>
      <NavContent
        hasTopnavSecondary={false}
        clientMenuPossible={true}
        className="wide-menu"
      >
        <div className="cp-flex-spread-center cp-pb-16">
          <div className="cps-subheader">Letters</div>
          <CpButton btnType="flat" onClick={() => setShowNewLetterModal(true)}>
            Create a new letter
          </CpButton>
        </div>
        {loading ? (
          <CpLoader />
        ) : !letters.length ? (
          <CpCard style={{ padding: "10rem 0" }}>
            <CpEmptyState
              img="es_letters"
              text="Add a letter"
              subText="You have not created any letters for this client."
            />
          </CpCard>
        ) : (
          <div className={styles.lettersCardsWrapper}>
            {sortedLetters.map((letter) => {
              const {
                id,
                preview_image_url,
                title,
                updated_at,
                relationships,
                document_template_id,
              } = letter;
              return (
                <CpCard
                  key={id}
                  className={`${
                    !document_template_id
                      ? styles.lettersCard
                      : styles.newLettersCard
                  } cp-p-16`}
                >
                  {!document_template_id && (
                    <Link
                      to={`/letters/edit/client/${clientId}/resolution-cases/${resolutionCaseId}/letter/${id}`}
                    >
                      <img
                        className={`${styles.letterThumbnail} cp-p-8`}
                        src={preview_image_url}
                        alt="letter-thumbnail"
                      />
                    </Link>
                  )}
                  <div>
                    <div className="cp-flex-spread">
                      <div
                        className="cps-ellipsis"
                        style={{ maxWidth: "16.5rem" }}
                      >
                        {title}
                      </div>
                      <CpIcon
                        className={styles.lettersDelete}
                        onClick={() => setLetterToDelete(letter)}
                        name="close-large"
                        fill="var(--cp-color-app-icon)"
                      />
                    </div>
                    <div
                      className={`${styles.lettersLastEdited} cps-caption cps-wt-light cp-mv-16`}
                    >
                      {`Letter last edited: ${DateTime.fromMillis(
                        updated_at
                      ).toLocaleString()} by ${relationships.updated_by.name}`}
                    </div>
                    {!document_template_id ? (
                      <Link
                        to={`/letters/edit/client/${clientId}/resolution-cases/${resolutionCaseId}/letter/${id}`}
                        className={`${styles.letterOpenLink} cp-pr-24 cps-wt-bold`}
                      >
                        Open
                      </Link>
                    ) : (
                      <span
                        className={`${styles.letterOpenLink} cp-pr-24 cps-wt-bold`}
                        onClick={() =>
                          openExistingLetter(
                            document_template_id,
                            client,
                            title
                          )
                        }
                      >
                        Open
                      </span>
                    )}
                    {relationships.last_client_request?.id && (
                      <Link
                        to={`/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/client_requests/${relationships.last_client_request?.id}`}
                        className="cp-wt-bold cp-color-app-secondary-text"
                      >
                        See client request
                      </Link>
                    )}
                  </div>
                </CpCard>
              );
            })}
          </div>
        )}
      </NavContent>
      <>
        <Suspense fallback={null}>
          <NewLetterModal
            show={showNewLetterModal}
            onClose={(documentOverlayData) => {
              setShowNewLetterModal(false);
              if (documentOverlayData) {
                track("practice_management", "letters", "create", {
                  source: "resolutionCase",
                  fromTemplate: !!documentOverlayData.template?.title,
                });
                setDocumentOverlayData(documentOverlayData);
                setShowDocumentEditor(true);
              }
            }}
            clientId={clientId}
            canChangeClient={false}
            resolutionCaseId={resolutionCaseId}
            client={client}
          />
        </Suspense>
        <Suspense fallback={null}>
          <DocumentEditorOverlay
            documentId={documentOverlayData.documentId}
            resolutionCaseId={resolutionCaseId}
            client={documentOverlayData.client}
            title={title}
            mode={
              documentOverlayData.existing
                ? "resolution_case_letter_existing"
                : "resolution_case_letter_new"
            }
            show={showDocumentEditor}
            onClose={() => {
              setShowDocumentEditor(false);
            }}
          />
        </Suspense>
      </>
    </>
  );
};
