import React from "react";
import { useCss, k, a } from "kremling";
import { Link, useParams } from "react-router-dom";

import { usePenaltyAbatementOutcomes } from "../penalty-abatement-outcomes.helper";
import { Summary } from "../../common/summary.component";

export function PenaltyAbatementFirstTime({ outcomes, urls }) {
  const scope = useCss(css);
  const params = useParams();

  const { firstTimeAbatementAvailable, cantCalculateOutcomes } =
    usePenaltyAbatementOutcomes(outcomes);

  return (
    <div {...scope}>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {firstTimeAbatementAvailable && (
                  <span className="cps-color-primary">
                    First Time Abatement available
                  </span>
                )}
                {!firstTimeAbatementAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">
                    First Time Abatement unlikely
                  </span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {firstTimeAbatementAvailable && (
                <div>
                  Thank you for using the Penalty Abatement Assistant! Based on
                  your answers, your client may qualify for First Time Abatement
                  relief. Check out the resources below for additional help.
                </div>
              )}
              {!firstTimeAbatementAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Penalty Abatement Assistant! Based on
                  your answers, First Time Abatement relief for your client is
                  unlikely. Sometimes it doesn't hurt to try though - check out
                  the resources below to learn more.
                </div>
              )}
              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the{" "}
                  <Link to={urls.assistant}>Penalty Abatement Assistant</Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          The IRS provides first time abatement (FTA) to qualifying taxpayers
          for failure-to-file, failure-to-pay, or failure-to-deposit penalties.
          This administrative waiver is provided to reward taxpayers with a
          clean compliance history for the three years preceding the penalty
          year.
        </p>
        <p>
          Before submitting a FTA request, be sure to understand how and why the
          taxpayer meets the requirements. Below are three ways a taxpayer can
          request FTA with the IRS:
        </p>
        <ul>
          <li>
            <strong>Written petition</strong> - Send a letter stating why the
            taxpayer qualifies for FTA. See the example letter templates we have
            listed below.
          </li>
          <br />
          <li>
            <strong>Verbally</strong> - Talk to someone over the phone, or meet
            in person, to state your case. Refer to the IRS Phone Discussion
            Guide below for conversation tips and contact information. If the
            request is granted verbally, it is usually approved on the spot, so
            this is a good method to get more immediate results. Additionally,
            since the FTA is considered an administrative waiver, it does not
            carry any dollar threshold that would prevent approval (as long as
            you meet all FTA acceptance criteria).
          </li>
          <br />
          <li>
            <strong>IRS Form 843</strong> - Submit Form 843, entitled "Claim for
            Refund and Request for Abatement." This form is typically submitted
            with a small written petition as well. This form is generally used
            if payments were made against balances due within the last two
            years, and you wish to request a refund of taxes in conjunction with
            requesting FTA.
          </li>
        </ul>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/first-time_abatement/Letter_Failure_To_File_Or_Pay.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - FTA Failure to file or pay
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/first-time_abatement/Letter_Penalty_Denial_Appeal.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - FTA Penalty Denial Appeal
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/first-time_abatement/IRS_Phone_Discussion_Guide.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  IRS phone discussion guide
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Contact info</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>IRS</strong>
                  <br />
                  See address associated with notice
                </p>
                <p>
                  <strong>IRS Tax Practitioner Priority Line</strong>
                  <br />
                  866-860-4259
                </p>
                <p>
                  <strong>Penalty assistance line</strong>
                  <br />
                  855-223-4017 extension 225
                </p>
                <p>
                  <strong>e-Help</strong>
                  <br />
                  866-255-0654
                </p>
              </div>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/irm/part20/irm_20-001-001r-cont01.html"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Internal Revenue Manual: Penalty and Interest
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="http://www.journalofaccountancy.com/issues/2013/jul/20137885.html"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Requesting a first-time abatement penalty waiver
                    </span>
                  </div>
                  Journal of Accountancy
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.aicpa.org/interestareas/tax/resources/irsprocedureadministration/pages/irspenaltyabatement.aspx"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      IRS First time penalty abatement
                    </span>
                  </div>
                  AICPA
                </div>
              </a>
            </div>
          </div>
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href={`/#/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/4LHN/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 843
                  </div>
                  Claim for Refund and Request for Abatement
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>Save FTA for later</strong> - Since FTA is designed to
                  be a one-time option, be sure to consider whether the taxpayer
                  may also qualify for Reasonable Cause penalty relief.
                  Taxpayers who qualify for either forms of relief are not
                  required to choose FTA, and saving FTA for the future may be
                  more beneficial for the taxpayer.
                </p>
                <p>
                  <strong>Activating IRS Collections</strong> - If the taxpayer
                  owes less than $10K and IRS Collections is NOT active, you may
                  not want to request FTA at that time. The taxpayer may not
                  appreciate having collection activity actively restarted.
                </p>
                <p>
                  <strong>Installment agreements</strong> - Consider whether the
                  taxpayer has an installment agreement and whether you are
                  going to set one up for a remaining balance owing. If you are
                  successful in abating the penalty using FTA, this should
                  reduce the overall amount the taxpayer owes. You may consider
                  using this opportunity to re-negotiate an installment
                  agreement after the abatement is complete, since the taxpayer
                  now owes less.
                </p>
                <p>
                  <strong>Obtaining transcripts</strong> - Taxpayers can pull
                  their own transcripts using the IRS's "Get Transcript" app or
                  by contacting the IRS directly. However, the transcripts
                  obtained using these methods go back only 10 years. As a
                  practitioner, you can obtain additional years by using
                  e-services or by calling the IRS at 866-860-4259 or e-Help at
                  866-255-0654. We recommend obtaining transcripts from 1990 to
                  present.
                </p>
                <p>
                  <strong>Calling the IRS</strong> - If you call the IRS, be
                  prepared with your client's reasonable cause argument if FTA
                  is denied. You can request reasonable cause abatement on the
                  same call.
                </p>
                <p>
                  <strong>Failure to pay</strong> - For unpaid failure to pay
                  (FTP) penalties, be sure to ask the IRS representative to
                  abate the accrued but not assessed FTP penalty. The FTP
                  penalty is not posted to your client's IRS account until there
                  is a credit balance to pay the penalty.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const css = k`

`;
