import React from "react";
import { fromEvent } from "rxjs";
import AsyncDecorator from "async-decorator/rx6";
import styles from "./action-icon.styles.css";
import { CprTooltip } from "canopy-styleguide!sofe";
import MenuItem from "./menu-item.component";

@AsyncDecorator
export default class ActionIcon extends React.PureComponent {
  state = {
    showMenu: false,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fromEvent(document, "click").subscribe(this.closeMenu),
      fromEvent(this.iconEl, "click").subscribe(this.handleClick)
    );

    if (this.menuEl) {
      this.props.cancelWhenUnmounted(
        fromEvent(this.menuEl, "click").subscribe((evt) => {
          if (evt.target !== this.menuEl && !this.menuEl.contains(evt.target)) {
            evt.stopPropagation();
          }
        })
      );
    }
  }

  handleClick = (evt) => {
    evt.myProp = JSON.stringify(this.props.config);
    if (this.props.config.menu) {
      this.setState((prev) => ({
        showMenu: !prev.showMenu,
      }));
    } else if (this.props.config.filter) {
      this.setState((prev) => ({
        showMenu: !prev.showMenu,
      }));
    } else {
      this.props.config.click();
    }
  };

  closeMenu = (evt) => {
    if (evt.myProp !== JSON.stringify(this.props.config)) {
      this.setState({ showMenu: false });
    }
  };

  getIconAction = (config) => {
    const anchor = (
      <a
        style={{ marginTop: 0 }}
        className="cps-link"
        ref={(el) => (this.iconEl = el)}
      >
        {config.icon && (
          <span
            className={`cps-icon cps-icon-${config.icon} ${styles.fontSize24} cps-color-secondary-text`}
          />
        )}
        {config.text && <span>{config.text}</span>}
      </a>
    );
    if (!!config.menu) {
      return (
        <div>
          {
            <ul className="cps-list-unstyled">
              <li
                className={`cps-dropdown ${
                  this.state.showMenu ? "cps-open" : ""
                }`}
              >
                {anchor}
                <ul
                  ref={(el) => (this.menuEl = el)}
                  className="cps-dropdown-menu cps-pull-right"
                  role="menu"
                >
                  {this.props.config.menu.map((menuItem) => {
                    return menuItem.hideExpression &&
                      menuItem.hideExpression() ? undefined : (
                      <MenuItem
                        key={JSON.stringify(menuItem)}
                        menuItem={menuItem}
                      />
                    );
                  })}
                </ul>
              </li>
            </ul>
          }
        </div>
      );
    } else {
      return config.hover ? (
        <CprTooltip html={this.props.config.hover}>{anchor}</CprTooltip>
      ) : (
        anchor
      );
    }
  };

  render() {
    return (
      <li>
        <div className="cps-btn-icon">
          {this.getIconAction(this.props.config)}
        </div>
      </li>
    );
  }
}
