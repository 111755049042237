import React, { useState } from "react";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";

import {
  OutcomeHeader,
  OutcomeHeaderItem,
} from "../common/outcome-header.component";
import { leviesConstants } from "./levies.constants";
import { useLeviesOutcomes } from "./levies-outcomes.helper";
import { LeviesAppeals } from "./outcomes/levies-appeals.component";
import { LeviesPreventingLevy } from "./outcomes/levies-preventing-levy.component";
import { LeviesReleasingLevy } from "./outcomes/levies-releasing-levy.component";
import { LeviesReturningProperty } from "./outcomes/levies-returning-property.component";

export function LeviesResolution({ urls, outcomes }) {
  const {
    appealsAvailable,
    preventingLevyAvailable,
    releasingLevyAvailable,
    returningPropertyAvailable,
  } = useLeviesOutcomes(outcomes);
  const [resolutionType, setResolutionType] = useState(
    (() => {
      if (appealsAvailable) {
        return leviesConstants.APPEALS;
      } else if (preventingLevyAvailable) {
        return leviesConstants.PREVENTING_LEVY;
      } else if (releasingLevyAvailable) {
        return leviesConstants.RELEASING_LEVY;
      } else if (returningPropertyAvailable) {
        return leviesConstants.RETURNING_PROPERTY;
      }
      return leviesConstants.APPEALS;
    })()
  );
  return (
    <>
      <OutcomeHeader value={resolutionType} onChange={setResolutionType}>
        <OutcomeHeaderItem
          id={leviesConstants.APPEALS}
          active={appealsAvailable}
        >
          Appeals
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={leviesConstants.PREVENTING_LEVY}
          active={preventingLevyAvailable}
        >
          Preventing levy
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={leviesConstants.RELEASING_LEVY}
          active={releasingLevyAvailable}
        >
          Releasing levy
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={leviesConstants.RETURNING_PROPERTY}
          active={returningPropertyAvailable}
        >
          Returning property
        </OutcomeHeaderItem>
      </OutcomeHeader>

      <CpSwitch expression={resolutionType}>
        <CpCase value={leviesConstants.APPEALS}>
          <LeviesAppeals outcomes={outcomes} urls={urls} />
        </CpCase>
        <CpCase value={leviesConstants.PREVENTING_LEVY}>
          <LeviesPreventingLevy outcomes={outcomes} urls={urls} />
        </CpCase>
        <CpCase value={leviesConstants.RELEASING_LEVY}>
          <LeviesReleasingLevy outcomes={outcomes} urls={urls} />
        </CpCase>
        <CpCase value={leviesConstants.RETURNING_PROPERTY}>
          <LeviesReturningProperty outcomes={outcomes} urls={urls} />
        </CpCase>
      </CpSwitch>
    </>
  );
}
