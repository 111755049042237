import React, { useEffect, useMemo, useState } from "react";
import styles from "./change-status.styles.css";
import {
  CpDropdown,
  CpTooltip,
  CpButton,
  CpIcon,
  CpModal,
  CpCheckbox,
  CpNameChip,
} from "canopy-styleguide!sofe";
import { hasAccess } from "cp-client-auth!sofe";
import { isEmpty } from "lodash";

function RedCircle({ className, style }) {
  return (
    <div
      style={style}
      className={`${styles.circleIcon} ${styles.circleRed} ${className}`}
    />
  );
}

function GreenCircle({ className, style }) {
  return (
    <div
      style={style}
      className={`${styles.circleIcon} ${styles.circleGreen} ${className}`}
    />
  );
}

export function ChangeStatus({
  status,
  onChange,
  activeTeamMembers,
  requiredPermission,
}) {
  const [usersToNotify, setUsersToNotify] = useState([]);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  function handleChangeStatus(updateStatus) {
    if (updateStatus === status) return;
    setNewStatus(updateStatus);
    setUsersToNotify(activeTeamMembers);

    if (activeTeamMembers.length > 0) return setShowNotificationModal(true);
    dontNotifyAnyoneOfStatusChange(updateStatus);
  }

  function dontNotifyAnyoneOfStatusChange(updateStatus) {
    onChange(updateStatus);
  }

  function changeStatusAndNotifyPeople(users) {
    const notifications = {
      users,
      url: window.location.href,
    };

    onChange(newStatus, notifications);
  }

  function renderTrigger({ toggle }) {
    switch (status) {
      case "needs_review":
        return (
          <CpButton
            onClick={toggle}
            btnType="tertiary"
            className={styles.circleButton}
          >
            <RedCircle />
          </CpButton>
        );
      case "complete":
        return (
          <CpTooltip text="Complete">
            <CpButton
              onClick={toggle}
              btnType="tertiary"
              className={styles.circleButton}
            >
              <GreenCircle />
            </CpButton>
          </CpTooltip>
        );
      default:
        return (
          <CpTooltip text="In progress">
            <CpButton
              onClick={toggle}
              icon="shape-circle-dashed"
              aria-label="In progress"
            />
          </CpTooltip>
        );
    }
  }

  return (
    <>
      <CpDropdown
        position="bottom-start"
        renderTrigger={renderTrigger}
        renderContent={({ close }) => (
          <div className="cp-select-list">
            <button
              onClick={() => {
                handleChangeStatus("in_progress");
                close();
              }}
            >
              <CpIcon name="shape-circle-dashed" className="cp-mr-20" /> In
              progress
            </button>
            <button
              onClick={() => {
                handleChangeStatus("needs_review");
                close();
              }}
            >
              <RedCircle style={{ marginLeft: 2 }} className="cp-mr-20" /> Needs
              review
            </button>
            <button
              onClick={() => {
                handleChangeStatus("complete");
                close();
              }}
            >
              <GreenCircle style={{ marginLeft: 2 }} className="cp-mr-20" />{" "}
              Completed
            </button>
          </div>
        )}
      />
      <NotificationsDialog
        show={showNotificationModal}
        onClose={() => setShowNotificationModal(false)}
        onSubmit={changeStatusAndNotifyPeople}
        onNotifyNoOne={() => dontNotifyAnyoneOfStatusChange(newStatus)}
        teammembers={usersToNotify}
        requiredPermission={requiredPermission}
      />
    </>
  );
}

function NotificationsDialog({
  show,
  onClose,
  onSubmit,
  onNotifyNoOne,
  teammembers = [],
  requiredPermission,
}) {
  const filteredByPermission = useMemo(() => {
    return teammembers.filter((user) =>
      hasAccess(user, true)(requiredPermission)
    );
  }, [teammembers]);
  const [selectedUsers, setSelectedUsers] = useState(
    filteredByPermission.map(({ id }) => id)
  );
  useEffect(() => {
    setSelectedUsers(filteredByPermission.map(({ id }) => id));
  }, [filteredByPermission]);

  return (
    <CpModal show={show} onClose={onClose}>
      <CpModal.Header title="Notify Collaborators" />
      <CpModal.Body>
        {filteredByPermission.map(({ id, name }) => (
          <div key={id} className={styles.notificationsModalListItem}>
            <div className={styles.notificationsModalListItemName}>
              <CpNameChip name={name} className="cp-mr-20" />
              {name}
            </div>
            <CpCheckbox
              onChange={() => {
                if (selectedUsers.includes(id)) {
                  setSelectedUsers((state) =>
                    state.filter((userId) => userId !== id)
                  );
                } else {
                  setSelectedUsers((state) => [...state, id]);
                }
              }}
              checked={selectedUsers.includes(id)}
            />
          </div>
        ))}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          onClick={() => {
            onSubmit(selectedUsers);
            onClose();
          }}
          btnType="primary"
          disabled={isEmpty(selectedUsers)}
        >
          Notify people
        </CpButton>
        <CpButton
          className="cp-ml-8"
          btnType="flat"
          onClick={() => {
            onNotifyNoOne();
            onClose();
          }}
        >
          Don't notify anyone
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
