import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export const getLetters = (clientId, resolutionCaseId) => {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters`
  );
};

export const getLetter = (clientId, resolutionCaseId, letterId) => {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}`
  );
};

export const createLetter = (clientId, resolutionCaseId, letter) => {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters`,
    {
      method: "POST",
      body: {
        letters: letter,
      },
    }
  ).pipe(pluck("letters"));
};

export const updateLetter = (clientId, resolutionCaseId, letter) => {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letter.id}`,
    {
      method: "PUT",
      body: {
        letters: letter,
      },
    }
  ).pipe(pluck("letters"));
};

export const deleteLetter = (clientId, resolutionCaseId, letterId) => {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}`,
    {
      method: "DELETE",
    }
  );
};

export const unDeleteLetter = (clientId, resolutionCaseId, letterId) => {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}?deleted=false`,
    {
      method: "PATCH",
    }
  );
};

export const getLetterTemplates = () => {
  return fetchAsObservable(`/api/templates/letters`);
};

export const getLetterTemplate = (templateId) => {
  return fetchAsObservable(`/api/templates/letters/${templateId}`).pipe(
    pluck("letters")
  );
};

export function getClient(clientId) {
  return fetchAsObservable(`/api/clients/${clientId}?include=users`).pipe(
    pluck("clients")
  );
}
