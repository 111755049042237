import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";

export function PenaltyAbatementPayments() {
  const TOOLTIP = "Per IRC 6511";
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Were there{" "}
        <CpTooltip text={TOOLTIP}>
          <a>payments</a>
        </CpTooltip>{" "}
        made against balances due within the last two years?
      </strong>
    </div>
  );
}
