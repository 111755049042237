import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { useHistory } from "react-router-dom";

import { tryRestart } from "../assistant-utils";
import { useTrustFundOutcomes } from "./trust-fund-outcomes.helper";

export function TrustFundOutro({ urls, restartAssistant, outcomes }) {
  const history = useHistory();
  const {
    reliefUnlikely,
    appealAvailable,
    employmentTaxAvailable,
    employmentTaxWarningAvailable,
    TFRPDefenseAvailable,
  } = useTrustFundOutcomes(outcomes);
  return (
    <div>
      <div className="cp-assistant-outro__recommendation">
        <div className="cps-subheader-sm">Recommendation:</div>

        {!reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm cps-color-primary">
              {appealAvailable && (
                <span>
                  Appeals available
                  <br />
                </span>
              )}
              {(employmentTaxAvailable || employmentTaxWarningAvailable) && (
                <span>
                  Business-level resolution available
                  <br />
                </span>
              )}
              {TFRPDefenseAvailable && (
                <span>
                  Individual-level resolution available
                  <br />
                </span>
              )}
            </div>

            {employmentTaxWarningAvailable && (
              <p>
                <strong>Caution</strong> – you may have a potential conflict of
                interest between the business and the owner(s)/officer(s) of the
                business, especially if there are more than one. You may want to
                consider obtaining conflict waivers if appropriate, and know you
                would be prohibited from representing more than one owner in a
                TFRP case.
              </p>
            )}
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Trust Fund Recovery Penalty Assistant!
              Based on your answers, your client may qualify for the outcome(s)
              listed above.
            </div>
          </div>
        )}

        {reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm cps-warning">Relief unlikely</div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Trust Fund Recovery Penalty Assistant!
              Based on your answers, relief for your client is unlikely.
              Sometimes it doesn’t hurt to try though - check out our outcomes
              to learn more.
            </div>
          </div>
        )}
      </div>

      <div className="end-of-assistant-actions">
        <CpButton onClick={() => history.push(urls.resolution)}>
          Go to outcomes
        </CpButton>
        <CpButton btnType="flat" onClick={() => tryRestart(restartAssistant)}>
          Restart assistant
        </CpButton>
      </div>
    </div>
  );
}
