import { cloneDeep, isEmpty, map, filter } from "lodash";
import { hasAccess } from "cp-client-auth!sofe";

export function getRequestsProgress(requests) {
  let progressData = {
    inProgress: [],
    needReview: [],
    totalSections: 0,
    completedSections: 0,
  };

  requests.forEach(function (request) {
    request.type = "request";
    request.name = request.title;
    if (request.first_sent_at && request.status) {
      if (request.status === "in_progress") {
        progressData.inProgress.push(request);
      } else if (request.status === "needs_review") {
        progressData.needReview.push(request);
      } else if (request.status === "complete") {
        progressData.completedSections++;
      }
      progressData.totalSections++;
    }
  });

  return progressData;
}

export function getServiceProgress(programs, loggedInUser = null) {
  let progressData = {
    inProgress: [],
    needReview: [],
    totalSections: 0,
    completedSections: 0,
  };

  programs.forEach((program) => {
    if (program.visible && program.sections) {
      let statusesToExamine = ["in_progress", "needs_review", "complete", null];
      for (let statusToExamine of statusesToExamine) {
        if (
          program.sections.some((section) => section.status === statusToExamine)
        ) {
          program.sections.forEach(function (section) {
            let item = cloneDeep(section);
            item.programSlug = program.slug;
            item.programName = program.name;
            item.type = "step";

            if (typeof item.aggregate_updated_at === "undefined") {
              item.aggregate_updated_at = item.updated_at;
            }

            if (
              item.status === statusToExamine &&
              item.slug !== "adminforms" &&
              item.slug !== "irsforms"
            ) {
              if (statusToExamine === "in_progress") {
                progressData.inProgress.push(item);
              } else if (statusToExamine === "needs_review") {
                progressData.needReview.push(item);
              } else if (statusToExamine === "complete") {
                progressData.completedSections++;
              }

              if (!!statusToExamine) progressData.totalSections++;
            }

            if (!item.updated_at) {
              // Should only be set on a newly created section
              item.updated_at = new Date().getTime();
            }

            if (!item.updated_by && loggedInUser) {
              // Should only be set on a newly created section which should be the currently logged in user
              item.updated_by = loggedInUser.name;
            }
          });
        }
      }
    }
  });
  return progressData;
}

/**
 * Returns a pretty, human-readable string representing an array.
 *
 * @param arr : Array : array to format
 * @param max : int : max number of items to show before "... and X more things". if 0, there will be no limit.
 * @param noun : String : noun describing the type of items in array.
 * @param pluralNoun : String : plural noun of items in array. defaults to noun with 's' appended.
 * @returns String
 *
 * Examples:
 *
 * prettyList(['dog', 'cat'], 0, 'animal') => 'dog & cat'
 * prettyList(['dog', 'cat', 'horse'], 0, 'animal') => 'dog, cat, & horse'
 * prettyList(['dog', 'cat', 'horse', 'pig'], 3, 'animal') => 'dog, cat, horse, & 1 more animal'
 * prettyList(['dog', 'cat', 'horse', 'pig', 'duck'], 3, 'animal') => 'dog, cat, horse, & 2 more animals'
 * prettyList([], 0, 'animal') => 'no animals'
 */
export function prettyList(
  arr,
  max,
  noun,
  pluralNoun = null,
  formatter = null
) {
  if (typeof formatter === "function") arr = map(arr, formatter);
  pluralNoun = pluralNoun || noun + "s";
  if (max === 0) {
    max = arr.length;
  }

  if (isEmpty(arr)) {
    return "no " + pluralNoun;
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2 && max >= 2) {
    return arr.join(" & ");
  } else if (arr.length <= max) {
    return (
      arr.slice(0, arr.length - 1).join(", ") + ", & " + arr[arr.length - 1]
    );
  } else {
    let more = arr.length - max;
    return (
      arr.slice(0, max).join(", ") +
      (max > 1 ? "," : "") +
      " & " +
      String(more) +
      " more " +
      (more == 1 ? noun : pluralNoun)
    );
  }
}

const taxResolutionServices = [
  "Collections",
  "Penalty Abatement",
  "Innocent Spouse",
  "Liens",
  "Levies",
  "Trust Fund Recovery",
];

export function formatResolutionCaseHistory(
  resolutionCase,
  history,
  loggedInUser
) {
  if (!loggedInUser) return [];
  var sectionIdToService = [];
  var sectionsById = {};

  for (var program of resolutionCase.program_data.programs) {
    for (var section of program.sections) {
      sectionIdToService[section.id] = program;
      sectionsById[section.id] = section;
    }
  }

  const userHasAccess = hasAccess(loggedInUser);

  return history.map((action) => {
    //Name
    if (action.performed_by_id === loggedInUser.id)
      action.performed_by_name = "You";

    //Format actions
    switch (action.name) {
      case "created":
        //Pretty Print Created this resolution case
        action.icon = "misc-document-calculator";
        if (
          action.pivot_type === "App\\ResolutionCase" &&
          action.pivot_id === resolutionCase.id
        ) {
          action.name = "created this resolution case";
          action.value = null;
        } else if (action.pivot_type === "App\\MasterSurvey") {
          action.name = "created the";
          action.value.name = "client survey";
          action.link_type = "survey";
          action.no_link = !userHasAccess("client_survey");
        } else {
          action.name = "created the";
        }
        break;
      case "created_letter":
        action.name = "created the letter,";
        action.icon = "communication-letter-back";
        action.link_type = "resolution_case_letter";
        action.no_link = !userHasAccess("letters");
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        break;
      case "updated_letter":
        action.name = "updated the letter,";
        action.icon = "communication-letter-back";
        action.link_type = "resolution_case_letter";
        action.no_link = !userHasAccess("letters");
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        break;
      case "sent_to_files":
        action.name = "sent the letter,";
        action.icon = "communication-paper-airplane";
        action.link_type = "resolution_case_letter";
        action.no_link = !userHasAccess("letters");
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        break;
      case "updated_letter_title":
        action.name = "renamed the letter,";
        action.link_type = "resolution_case_letter";
        action.no_link = !userHasAccess("letters");
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        action.value.old_name = action.value.old_title;
        action.value.new_name = action.value.title;
        action.value.title = "";
        break;
      case "deleted_letter":
        action.name = "deleted the letter,";
        action.pivot_id = action.value.id;
        break;
      case "edit":
        action.name = "edited the";
        action.icon = "crud-pencil";
        break;
      case "sent":
        action.name = "sent the";
        action.icon = "communication-paper-airplane";
        if (action.pivot_type.indexOf("ClientRequest") > -1) {
          action.name += " client request,";
        }
        if (action.pivot_type === `App\\MasterSurvey`) {
          action.value.name = "client survey";
          action.link_type = "survey";
          action.no_link = !userHasAccess("client_survey");
        }
        break;
      case "sent_bulk":
        action.name = "sent";
        action.icon = "communication-paper-airplane";
        if (action.pivot_type.indexOf("ClientRequest") > -1) {
          action.name +=
            " " + String(action.value.number_of_requests) + " client requests";
        }
        action.link_type = "client_requests";
        action.no_link = !userHasAccess("tasks_client_requests");
        break;
      case "added":
        action.icon = "add-large";
        if (action.pivot_type.indexOf("ResolutionCaseSection") > -1) {
          action.name = "added the step";
          if (!!sectionIdToService[action.pivot_id]) {
            action.value.parent_service_name =
              sectionIdToService[action.pivot_id].name;
            action.value.parent_service_deleted =
              !sectionIdToService[action.pivot_id].visible;
          }
        } else {
          action.name = "added";
        }
        break;
      case "service_created":
        action.name = "created the service";
        action.program = { slug: action.value.slug };
        action.icon = "misc-document-calculator";
        break;
      case "workpaper_uploaded": {
        action.name = "uploaded";
        action.icon = "misc-cloud-up-arrow";
        action.value.name = action.value.filename;
        const service = sectionIdToService[action.value.section_id];
        const section = sectionsById[action.value.section_id];
        if (!!service && !!section) {
          action.program = { slug: service.slug };
          action.section = { slug: section.slug };
          action.link_type = "step";
          action.no_link = shouldHideLink(service, userHasAccess);
        }
        break;
      }
      case "due_at":
        action.name = "changed the resolution case due date to";
        action.icon = "misc-alarm-clock";
        if (action.value === 0)
          action.name = "removed the resolution case due date";
        break;
      case "status":
        action.name = "changed the status of";
        action.value.name = action.value.section_name;
        action.icon = "shape-circle-dashed";

        if (!!sectionsById[action.pivot_id] && action.value.section_name)
          action.value.title = action.value.section_name;

        if (action.value.status === "in_progress")
          action.value.status = "In Progress";
        if (action.value.status === "needs_review")
          action.value.status = "Needs Review";
        if (action.value.status === "complete")
          action.value.status = "Complete";

        break;
      case "program_data.visible":
        action.name = action.value.visible
          ? "restored the service"
          : "removed the service";
        action.icon = action.value.visible ? "af-refresh" : "crud-trash-large";
        action.value.name = action.value.service_name;
        action.no_link = taxResolutionServices.includes(
          action.value.service_name
        )
          ? !userHasAccess("tax_resolution_services")
          : !userHasAccess("custom_services");
        if (action.name === "removed the service")
          action.link_type = "services";
        break;
      case "title": {
        action.name = "renamed";
        action.icon = "crud-pencil";
        action.value.old_name = action.value.step_old_name;
        action.value.new_name = action.value.step_name;
        const service = sectionIdToService[action.pivot_id];
        const section = sectionsById[action.pivot_id];
        if (!!service && !!section && !action.value.parent_service_deleted) {
          action.program = { slug: service.slug };
          action.section = { slug: section.slug };
          action.link_type = "step";
          // If it's a custom step, we need to hide the link behind the permission
          action.no_link = shouldHideLink(service, userHasAccess);
        }
        break;
      }
      case "program_data.name":
        action.name = "renamed";
        action.icon = "crud-pencil";
        action.value.old_name = action.value.service_old_name;
        action.value.new_name = action.value.service_name;
        break;
      case "description":
        action.name = "changed the description of";
        action.icon = "crud-pencil";
        action.value.title = action.value.step_name;
        break;
      case "deleted":
        action.name = "deleted";
        action.icon = "crud-trash-large";
        if (action.pivot_type.indexOf("ResolutionCaseSection") > -1) {
          action.name += " the step";
          action.value.title = action.value.section_name;
        } else if (action.pivot_type.indexOf("ClientRequest") > -1) {
          if (!isEmpty(action.value.title)) {
            action.name += " the client request,";
          } else {
            action.name += " an untitled client request";
          }
        }
        break;
      case "submitted":
        if (action.pivot_type === "App\\MasterSurvey") {
          action.name = "submitted the";
          action.icon = "person";
          action.value.name = "client survey";
          action.link_type = "survey";
          action.no_link = !userHasAccess("client_survey");
        }
        break;
    }

    //Sections / Steps - links
    if (
      !!action.value &&
      !!action.value.title &&
      action.pivot_type.indexOf("ResolutionCaseSection") > -1
    ) {
      action.value.name = action.value.title;
      const service = sectionIdToService[action.pivot_id];
      const section = sectionsById[action.pivot_id];
      if (!!service && !!section && !action.value.parent_service_deleted) {
        action.program = { slug: service.slug };
        action.section = { slug: section.slug };
        action.link_type = "step";
        // If it's a custom step, we need to hide the link behind the permission
        action.no_link = shouldHideLink(service, userHasAccess);
      }
    }

    // Surveys
    if (!!action.value && !!action.value.survey_name) {
      action.value.name = action.value.survey_name;
      action.value.surveyId = action.pivot_id;
      action.link_type = "survey";
      action.no_link = !userHasAccess("client_survey");
    }

    // Client Request
    if (!!action.value && action.pivot_type.indexOf("ClientRequest") > -1) {
      // Instance link
      if (!!action.value.title && action.name.indexOf("deleted") === -1) {
        action.link_type = "client_request";
        action.no_link = !userHasAccess("tasks_client_requests");
      }

      // Recipients
      if (
        action.name.indexOf("sent") > -1 &&
        !isEmpty(action.value.recipients)
      ) {
        action.value.requestId = action.pivot_id;
        action.value.recipient = prettyList(
          action.value.recipients,
          3,
          "recipient"
        );
      }
    }

    //The pretty printing of survey names and links are hard coded, this is because the same thing is done on the sidebar section links
    if (
      !!action.value &&
      (!!action.value.survey_form_id || !!action.value.master_survey_id) &&
      !!action.value.name
    ) {
      if (
        action.value.name.indexOf("poa-") > -1 ||
        action.value.name.indexOf("Poa ") > -1
      ) {
        action.value.name = "Power of Attorney Survey";
        if (action.name.indexOf("edit") === -1) {
          //Link to the parent section instead of the survey on every survey action except edit
          action.program = { slug: "planning" };
          action.section = { slug: "poa_survey" };
          action.link_type = "step";
          action.no_link = !userHasAccess("tax_resolution_services");
        }
      }
      if (
        action.value.name.indexOf("collection") === 0 ||
        action.value.name.indexOf("Collection") === 0 ||
        action.value.name.indexOf("business-collection") === 0 ||
        action.value.name.indexOf("Business Collection") === 0
      ) {
        action.value.name = "Client Survey";
        if (action.name.indexOf("edit") === -1) {
          //Link to the parent section instead of the survey on every survey action except edit
          action.program = { slug: "collections" };
          action.section = { slug: "collection_survey" };
          action.link_type = "step";
          action.no_link = !userHasAccess("tax_resolution_services");
        }
      }
    }
    return action;
  });
}

function shouldHideLink(service, userHasAccess) {
  return taxResServiceSlugs.find((slug) => slug === service.slug)
    ? !userHasAccess("tax_resolution_services")
    : !userHasAccess("custom_services");
}

export const taxResServiceSlugs = [
  "collections",
  "innocent_spouse",
  "liens",
  "levies",
  "trust_fund_recovery",
  "penalty_abatement",
];

export function isDeleted(history, id) {
  const filtered = filter(history, ({ pivot_id, name }) => {
    return pivot_id === id && name.indexOf("deleted") > -1;
  });

  return !isEmpty(filtered);
}
