import React from "react";
import { trustFundConstants } from "./trust-fund.constants";
import { TrustFundClientType } from "./questions/trust-fund-client-type.component";

export function TrustFundQuestionSwitch({ question, submitAnswer }) {
  if (question.questionSlug === trustFundConstants.CLIENT_TYPE) {
    return <TrustFundClientType submitAnswer={submitAnswer} />;
  }
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>{question?.questionText}</strong>
    </div>
  );
}
