import React from "react";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";

import { innocentSpouseConstants } from "./innocent-spouse.constants";
import { InnocentSpouseCommunityPropertyState } from "./questions/innocent-spouse-community-property-state.component";
import { InnocentSpouseErroneousItems } from "./questions/innocent-spouse-erroneous-items.component";
import { InnocentSpouseKnowCommunityIncome } from "./questions/innocent-spouse-know-community-income.component";
import { InnocentSpouseCommunityIncomeRelated } from "./questions/innocent-spouse-community-income-related.component";
import { InnocentSpouseMultiSelect } from "./questions/innocent-spouse-multi-select.component";
import { InnocentSpouseUnfair7 } from "./questions/innocent-spouse-unfair-7.component";
import { InnocentSpouseUnfair13 } from "./questions/innocent-spouse-unfair-13.component";
import { InnocentSpouseUnfair18 } from "./questions/innocent-spouse-unfair-18.component";

export const InnocentSpouseQuestionSwitch = ({
  question,
  submitAnswer,
  answers,
}) => {
  return (
    <CpSwitch expression={question?.questionSlug}>
      <CpCase value={innocentSpouseConstants.COMMUNITY_PROPERTY_STATE}>
        <InnocentSpouseCommunityPropertyState />
      </CpCase>
      <CpCase value={innocentSpouseConstants.ERRONEOUS_ITEMS}>
        <InnocentSpouseErroneousItems />
      </CpCase>
      <CpCase value={innocentSpouseConstants.KNOW_COMMUNITY_INCOME}>
        <InnocentSpouseKnowCommunityIncome />
      </CpCase>
      <CpCase value={innocentSpouseConstants.COMMUNITY_INCOME_RELATED}>
        <InnocentSpouseCommunityIncomeRelated />
      </CpCase>
      <CpCase value={innocentSpouseConstants.MULTI_SELECT}>
        <InnocentSpouseMultiSelect
          submitAnswer={submitAnswer}
          answer={answers?.[innocentSpouseConstants.MULTI_SELECT]}
        />
      </CpCase>
      <CpCase value={innocentSpouseConstants.UNFAIR_7}>
        <InnocentSpouseUnfair7 />
      </CpCase>
      <CpCase value={innocentSpouseConstants.UNFAIR_13}>
        <InnocentSpouseUnfair13 />
      </CpCase>
      <CpCase value={innocentSpouseConstants.UNFAIR_18}>
        <InnocentSpouseUnfair18 />
      </CpCase>
      <CpCase default>
        <div className="cps-subheader-sm cp-text-center">
          <strong>{question?.questionText}</strong>
        </div>
      </CpCase>
    </CpSwitch>
  );
};
