import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";

const urlBase = `${canopyUrls.getWorkflowUrl()}/api/clients`;

export function getClientRequest({ clientId, resolutionCaseId }) {
  return fetchAsObservable(
    `${urlBase}/${clientId}/resolution_cases/${resolutionCaseId}/requests?sent=true`
  );
}
