export function getSectionType(
  resolutionCase,
  programSlug,
  sectionSlug,
  clientId,
  clientUser
) {
  let program;
  let section;
  for (let i = 0; i < resolutionCase.program_data.programs.length; i++) {
    if (resolutionCase.program_data.programs[i].slug === programSlug) {
      program = resolutionCase.program_data.programs[i];
      break;
    }
  }

  if (!program) {
    program = { sections: [] };
  }

  for (let i = 0; i < program.sections.length; i++) {
    if (program.sections[i].slug === sectionSlug) {
      section = program.sections[i];
      break;
    }
  }

  if (!section) {
    section = {
      id: sectionSlug,
      slug: sectionSlug,
    };
  }

  switch (section.slug) {
    case "collection_survey":
      setTimeout(() => {
        /* We redirect people to the new client survey when they're trying to get to the old collections survey*/
        window.location.hash = `#/forms/clients/${clientId}/resolution-cases/${resolutionCase.id}/survey`;
      });
      break;
    case "poa_survey":
      setTimeout(() => {
        if (clientUser) {
          //We redirect clients to a landing page that explains why the survey is no longer necessary
          //TODO NAVIGATE TO OLD SURVEY?
          //$state.go('oldSurvey');
        } else {
          //We redirect people to the new Form 2848 when they're trying to get to the old poa survey
          window.location.hash = `#/forms/clients/${clientId}/resolution-cases/${resolutionCase.id}/sourceforms/CRZ1X/sections/0`;
        }
      });
      break;

    case "compromise":
    case "payment":
    case "notcollectible":
    case "analytics":
      setTimeout(() => {
        //We redirect people to the new analytics when they're trying to get to the old analytics
        window.location.hash = `#/forms/clients/${clientId}/resolution-cases/${resolutionCase.id}/collections/analytics/offer`;
      });
      break;

    case "irsforms":
    case "adminforms":
      setTimeout(() => {
        /* We redirect people to the 404 page when they try to go to the old landing page for the
         * Planning Administrative Forms section or the Collections IRS Forms section.
         */
        //TODO navigate to 404 page
        //$state.go('404')
      });
      break;

    case "abatement_assistant":
    case "innocent_spouse_assistant":
    case "liens_assistant":
    case "levies_assistant":
    case "trust_fund_recovery_assistant":
      return "assistant";
    case "abatement_resolution":
    case "innocent_spouse_resolution":
    case "liens_resolution":
    case "levies_resolution":
    case "trust_fund_recovery_resolution":
      return "outcomes";
    default:
      try {
        parseInt(section.slug);
        return "workpaper";
      } catch (ex) {
        throw new Error(`Unknown section slug '${section.slug}'`);
      }
  }
}

export const taxResServiceSlugs = [
  "collections",
  "innocent_spouse",
  "liens",
  "levies",
  "trust_fund_recovery",
  "penalty_abatement",
];

export function createBlankWorkpaperStub(sectionId) {
  return {
    type: "workpapers",
    title: null,
    description: null,
    status: "in_progress",
    due_at: null,
    relationships: {
      assigned_to: {
        type: "user",
        id: null,
      },
      belongs_to: {
        type: "section",
        id: sectionId,
      },
    },
  };
}
