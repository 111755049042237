import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { NavContent } from "primary-navbar!sofe";
import styles from "./services.styles.css";
import { CpCard, CpButton } from "canopy-styleguide!sofe";
import { ServiceLineItem } from "./service-line-item.component";
import { updateResolutionCasePromise } from "../../common/resolution-cases.resource";
import { useParams } from "react-router-dom";
import { catchAsyncStacktrace } from "auto-trace";
import { createBlankService } from "./services.utils";
import { CreateEditServiceModal } from "./create-edit-service-modal.component";

export function ServicesList(props) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editService, setEditService] = useState(null);
  const [resolutionCase, setResolutionCase] = useState(null);
  const { clientId } = useParams();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!props.resolutionCase) return;
    setResolutionCase(props.resolutionCase);
  }, [props.resolutionCase]);

  function openEditServiceModal(service) {
    setEditService(service);
    setShowCreateModal(true);
  }

  function openCreateServiceModal() {
    setShowCreateModal(true);
  }

  async function onSaveService(name, description) {
    setSaving(true);
    const _resolutionCase = cloneDeep(resolutionCase);
    if (editService) {
      const service = _resolutionCase.program_data.programs.find(
        (program) => program.uid === editService.uid
      );
      service.name = name;
      service.description = description;
      await updateResolutionCase(_resolutionCase);
      setSaving(false);
      return closeServiceModal();
    }

    const newService = createBlankService(
      _resolutionCase.program_data.programs,
      name,
      description
    );
    _resolutionCase.program_data.programs.push(newService);
    await updateResolutionCase(_resolutionCase);
    setSaving(false);
    closeServiceModal();
  }

  function closeServiceModal() {
    setShowCreateModal(false);
    setEditService(null);
  }

  function onToggleService(uid, toggleState) {
    const _resolutionCase = cloneDeep(resolutionCase);
    const service = _resolutionCase.program_data.programs.find(
      (program) => program.uid === uid
    );
    service.visible = toggleState;
    updateResolutionCase(_resolutionCase);
  }

  function updateResolutionCase(newResolutionCase) {
    return updateResolutionCasePromise(clientId, newResolutionCase).then(
      () => {},
      catchAsyncStacktrace()
    );
  }

  return (
    <NavContent
      hasTopnavSecondary={false}
      clientMenuPossible={true}
      className="wide-menu"
    >
      <CpCard className={styles.servicesContainer}>
        <CpCard.Header>
          <div className="cps-subheader-sm cps-wt-semibold">
            Manage resolution case services
          </div>
        </CpCard.Header>
        <div>
          {resolutionCase?.program_data.programs.map((program) => (
            <ServiceLineItem
              key={program.uid}
              service={program}
              onEditService={openEditServiceModal}
              onToggleService={onToggleService}
            />
          ))}
        </div>
        <CpCard.Footer>
          <CpButton onClick={openCreateServiceModal} btnType="flat">
            + Create a blank service
          </CpButton>
        </CpCard.Footer>
      </CpCard>
      <CreateEditServiceModal
        show={showCreateModal}
        onClose={closeServiceModal}
        service={editService}
        onSubmit={onSaveService}
        saving={saving}
      />
    </NavContent>
  );
}
