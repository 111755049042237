import React from "react";
import { useCss, k, a } from "kremling";
import { Link, useParams } from "react-router-dom";
import { usePenaltyAbatementOutcomes } from "../penalty-abatement-outcomes.helper";
import { Summary } from "../../common/summary.component";

export function PenaltyAbatementReasonableCause({ outcomes, urls }) {
  const scope = useCss(css);
  const params = useParams();

  const { reasonableCauseAvailable, nonAssessment, cantCalculateOutcomes } =
    usePenaltyAbatementOutcomes(outcomes);
  return (
    <div {...scope}>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {reasonableCauseAvailable && (
                  <span className="cps-color-primary">
                    Reasonable Cause available{" "}
                    {nonAssessment && <span>(non-assessment)</span>}
                  </span>
                )}
                {!reasonableCauseAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">Reasonable Cause unlikely</span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {reasonableCauseAvailable && !nonAssessment && (
                <div>
                  Thank you for using the Penalty Abatement Assistant! Based on
                  your answers, your client may qualify for Reasonable Cause
                  penalty relief. Check out the resources below for additional
                  help.
                </div>
              )}
              {reasonableCauseAvailable && nonAssessment && (
                <div>
                  Thank you for using the Penalty Abatement Assistant! Based on
                  your answers, your client may qualify for non-assessment of
                  penalty due to Reasonable Cause. Check out the resources below
                  for additional help.
                </div>
              )}
              {!reasonableCauseAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Penalty Abatement Assistant! Based on
                  your answers, Reasonable Cause penalty relief for your client
                  is unlikely. Sometimes it doesn't hurt to try though - check
                  out the resources below to learn more.
                </div>
              )}
              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the{" "}
                  <Link to={urls.assistant}>Penalty Abatement Assistant</Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          The IRS provides Reasonable Cause penalty relief if it determines that
          the taxpayer used all ordinary business care and prudence to meet
          her/his Federal tax obligation, but was nevertheless unable to do so.
          IRS representatives use an automated tool called the Reasonable Cause
          Assistant (RCA) to make initial penalty abatement determinations.
        </p>
        <p>
          Before submitting a Reasonable Cause request, be sure to understand
          how and why the taxpayer meets the requirements. Below are three ways
          you can request this penalty abatement relief.
        </p>
        <ul>
          <li>
            <strong>Written petition</strong> - Send a letter stating why the
            taxpayer qualifies for penalty relief due to reasonable cause. If
            applicable, you may also wish to ask for non-assessment of penalties
            in conjunction with filing a return for reasonable cause. In the
            case of non-assessment requests, a written request is required since
            penalties have not been assessed yet. See the example letter
            templates we have listed below.
          </li>
          <br />
          <li>
            <strong>Verbally</strong> - Talk to someone over the phone, or meet
            in person, to state your case. Refer to the IRS Phone Discussion
            Guide for conversation tips and contact information. If the request
            is granted verbally, it is usually approved on the spot, so this is
            a good method to get more immediate results. However, verbal
            approval is usually available for only certain types of penalties
            (e.g., FTP or FTF). There may also be a dollar threshold that could
            prevent verbal approval, even if you met other criteria.
          </li>
          <br />
          <li>
            <strong>IRS Form 843</strong> - Submit Form 843, entitled "Claim for
            Refund and Request for Abatement." This form is typically submitted
            with a small written petition as well. This form is generally used
            if payments were made against balances due within the last two years
            and you wish to request a refund of these taxes in conjunction with
            requesting Reasonable Cause abatement.
          </li>
        </ul>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/reasonable_cause/Letter_2012_Delay_Failure_To_Pay_Penalty_Relief_Request.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - 2012 Delay failure-to-pay penalty relief request
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/reasonable_cause/Letter_Accuracy_Related_Penalty.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Accuracy related penalty
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/reasonable_cause/Letter_Failure_To_File_Or_Pay.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Failure to file or pay
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/reasonable_cause/Letter_IRS_Or_Statute_Estimated_Tax_Penalty_Response.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - IRS or statute estimated tax penalty response
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/reasonable_cause/Letter_Misc_Small_Partnership_Rev_Proc_84-35.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Misc. small partnership Rev. Proc. 84-35
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/penalty_abatement/reasonable_cause/Letter_Penalty_Denial_Appeal.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Penalty denial appeal
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Contact info</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>IRS</strong>
                  <br />
                  See address associated with notice
                </p>
                <p>
                  <strong>IRS Tax Practitioner Priority Line</strong>
                  <br />
                  866-860-4259
                </p>
                <p>
                  <strong>e-Help</strong>
                  <br />
                  866-255-0654
                </p>
              </div>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/irm/part20/irm_20-001-001r-cont01.html"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Internal Revenue Manual: Penalty and Interest
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
            </div>
          </div>
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href={`/#/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/4LHN/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 843
                  </div>
                  Claim for refund and request for abatement
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>Reasonable Cause Assistant (RCA)</strong> - Often,
                  when IRS agents use the RCA to make determinations, it results
                  in penalty abatement denial letters that don't seem to address
                  all the facts and arguments presented in the taxpayer's
                  penalty abatement request. If this happens, request an appeal
                  of the determination to present all of the client's facts and
                  circumstances.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const css = k`

`;
