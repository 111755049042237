import React from "react";
import { Link, useParams } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { useTrustFundOutcomes } from "../trust-fund-outcomes.helper";

export function TrustFundEmploymentTax({ urls, outcomes }) {
  const {
    employmentTaxAvailable,
    cantCalculateOutcomes,
    employmentTaxWarningAvailable,
  } = useTrustFundOutcomes(outcomes);
  const params = useParams();
  return (
    <div>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {(employmentTaxAvailable || employmentTaxWarningAvailable) && (
                  <span className="cps-color-primary">
                    Business-level resolution available
                  </span>
                )}
                {!employmentTaxAvailable &&
                  !employmentTaxWarningAvailable &&
                  !cantCalculateOutcomes && (
                    <span className="cps-warning">
                      Business-level resolution unlikely
                    </span>
                  )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              <div>
                {(employmentTaxAvailable || employmentTaxWarningAvailable) && (
                  <div>
                    Thank you for using the Trust Fund Recovery Penalty
                    Assistant! Based on your answers, your client may qualify
                    for business-level resolution. Check out the resources below
                    for additional help.
                    {employmentTaxWarningAvailable && (
                      <p>
                        <strong>Caution</strong> - you may have a potential
                        conflict of interest between the business and the
                        owner(s)/officer(s) of the business, especially if there
                        are more than one. You may want to consider obtaining
                        conflict waivers if appropriate, and know you would be
                        prohibited from representing more than one owner in a
                        TFRP case.
                      </p>
                    )}
                  </div>
                )}
                {!employmentTaxAvailable &&
                  !employmentTaxWarningAvailable &&
                  !cantCalculateOutcomes && (
                    <div>
                      Thank you for using the Trust Fund Recovery Penalty
                      Assistant! Based on your answers, business-level
                      resolution for your client is unlikely. Sometimes it
                      doesn't hurt to try though - check out the resources below
                      to learn more.
                    </div>
                  )}
              </div>

              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the{" "}
                  <Link to={urls.assistant}>
                    Trust Fund Recovery Penalty Assistant
                  </Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          A business is responsible to withhold employment taxes from wages paid
          to its employees, and remit these amounts to the IRS in a timely
          fashion along with its portion of the matching share of employment
          taxes. When a business fails to do this, it is a serious issue for the
          business and potentially a serious issue for any responsible parties
          associated with the business.
        </p>

        <p>
          In the case of a sole proprietorship, the sole proprietor is already
          considered to be 100% responsible for all employment taxes since he or
          she is not separated from the business through a different entity. It
          is therefore not necessary to seek assessment of a TFRP against the
          sole proprietor.
        </p>

        <p>
          The IRS considers employment tax liabilities to be a serious matter,
          and as a result, will usually assign employment tax cases out of ACS
          to its highest-level collection personnel, Revenue Officers. This is
          especially true in cases where the business owes more than $25,000, is
          a repeat offender, or has defaulted on an Installment Agreement.
        </p>

        <p>
          Chances are, the first move the Revenue Officer will make is to have
          an unannounced visit to the business, during which the Revenue Officer
          will drop off their business card along with (1) Form 9297, Summary of
          Taxpayer Contact and (2) Letter 1058, Final Notice of Intent to Levy
          and Notice of Your Right to a Hearing.
        </p>

        <p>
          This Summary of Taxpayer Contact will contain a list of items the IRS
          wants from the business to determine how it can repay the taxes. These
          demands may include items such as unfiled returns; proof of current
          compliance with Federal tax deposits; IRS Form 433-B; and recent bank
          statements along with a deadline to provide such information, usually
          2-3 weeks out.
        </p>

        <p>
          <strong>
            Payments and installment agreements: <sup>*</sup>
          </strong>
        </p>

        <p>
          If the business is able to pay the entire amount of taxes owed in full
          now, or at least the trust fund portion of the taxes owed, it may
          prevent the launch of a TFRP investigation. You will need to ensure
          the payment is properly designated towards Trust Fund taxes,
          especially if it is a partial payment only.
        </p>

        <p>
          If the business cannot pay the entire amount of taxes owed in full
          now, you can prevent a TFRP investigation by setting up an Express
          Installment Agreement if the business qualifies. To qualify the
          business must meet the following requirements:
        </p>

        <ul>
          <li>Owe less than $25,000</li>
          <li>Pay the debt in full within 24 months</li>
          <li>
            Be fully compliant with all filings and current payments (at least
            for the current quarter)
          </li>
        </ul>

        <p>
          Additionally, you may be able to set up this IA with ACS and not need
          to have it assigned to a Revenue Officer. Using this method, you will
          not need to provide any financial information.
        </p>

        <p>
          If the business cannot pay the entire amount of taxes owed and it owes
          more than $25,000, it may not be able to prevent a TFRP investigation.
          In this case, the business can still set up an Installment Agreement,
          but will need to provide financial information on a 433-B, along with
          bank statements to the RO or to ACS.
        </p>

        <p>
          <strong>
            <sup>*</sup>Note:
          </strong>{" "}
          In the case of setting up any Installment Agreement for the business,
          there is no right to designate payments. The government has the right
          to designate payments in the best interest of the government, so you
          may still consider making occasional voluntary payments that are
          designated directly to Trust Fund taxes.
        </p>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Contact info</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>Tax professional priority line</strong>
                  <br />
                  1-866-860-4259
                </p>
                <p>
                  <strong>Business collections line</strong>
                  <br />
                  1-800-829-3903
                </p>
                <p>
                  <strong>General business information line</strong>
                  <br />
                  1-800-829-0115
                </p>
                <p>
                  To reach your local taxpayer advocate office{" "}
                  <a href="http://www.taxpayeradvocate.irs.gov" target="_blank">
                    www.taxpayeradvocate.irs.gov
                  </a>{" "}
                  or (877) 777-4778
                </p>
              </div>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/C544L/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 433-B
                  </div>
                  Collection information statement for businesses
                </div>
              </Link>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/GXNXQ/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 433-D
                  </div>
                  Installment Agreement
                </div>
              </Link>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/Voluntary_Business_Payment_Designation_Letter.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Voluntary business payment designation
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/In_Business_Trust_Fund_Express_IA_Cover_Letter.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - In-business trust fund express IA cover
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/trust_fund_recovery/In_Business_Trust_Fund Express_IA_Call_Script.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  In-business trust fund express IA call script
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/irm/part5/irm_05-014-005.html"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      IRM - In-business trust tund express installment
                      agreements
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/irm/part5/irm_05-014-007.html"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      IRM - In-business trust fund installment agreements
                      requiring financial analysis and determining ability to
                      pay
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p15.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 15 - (Circular E) Employer's tax guide
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p15a.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 15-A - Employer's supplemental tax guide
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
            </div>
          </div>

          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>Appeal options</strong> - If your client received a
                  Letter 1058, Final Notice of Intent to Levy and Notice of Your
                  Right to a Hearing, you may want to consider whether it makes
                  sense to file an Appeal. An Appeal can help you set up a
                  payment arrangement for your client and hold collections
                  during the process, as well as allow evidence to be heard in
                  the appeals division instead of in general collections or by
                  the assigned Revenue Officer.
                </p>
                <p>
                  <strong>Compliance</strong> - This is an important factor to
                  consider in any employment tax case. Even if an employer has
                  not been compliant with filings or payments in the past, the
                  employer needs to show it is in current compliance, and can
                  maintain the compliance. Have the employer file all required
                  returns and provide proof of payment of all current employment
                  tax liabilities, even if it can't pay old amounts. This is
                  helpful if you are seeking an Installment Agreement, since it
                  will show the employer is meeting its current obligation and
                  no longer adding to the liability. If the employer can show a
                  good track record of this as you are working the case, it will
                  go a long way in working out a solution.
                </p>
                <p>
                  <strong>Penalty abatement</strong> - Consider if there are
                  circumstances that may have caused the business to be unable
                  to meet its obligations. If reasonable cause can be
                  considered, it may also serve to reduce the amount of
                  liability to levels that may allow for Express Installment
                  Agreements (liability less than $25,000) or assist the
                  business in being able to pay down the liability faster.
                </p>
                <p>
                  <strong>Pay down balance</strong> - If your client is in
                  current compliance, and has only some of the funds needed to
                  pay towards its outstanding balance, consider if the amount
                  paid could bring the total balance below $25,000 in order to
                  qualify for an Express Installment Agreement. Consider this in
                  combination with Penalty Abatement options mentioned above.
                </p>
                <p>
                  <strong>Business viability</strong> - You may have to ask the
                  difficult question of whether the business is a viable
                  business if it is not able to meet its employment tax
                  obligations. In this case, it may be in the better interest of
                  the business and the owners to close rather than to continue
                  to accrue employment tax liabilities that it cannot pay.
                </p>
                <p>
                  <strong>Offer in compromise</strong> - In some cases, your
                  business client may have been subject to a payroll company
                  taking the funds it paid in to cover taxes, and failing to
                  remit the payment themselves. This is an opportunity for an
                  OIC to assist the business if it qualifies. In addition, your
                  business client could also potentially qualify if it has paid
                  all the Trust Fund taxes and are left only owing the
                  employer's matching share of the taxes and penalties.
                </p>
                <p>
                  <strong>Prevention</strong> - One of the best ways to assist a
                  client in getting a payroll tax issue cleared up and in good
                  graces with the IRS is simply doing things in a different
                  manner. You may need to teach your client to set aside its
                  payroll taxes in a separate account or simply pay it in more
                  often (for example, a monthly depositor can choose to remit
                  its payments more frequently, such as on a weekly basis if
                  desired). This may help the client remain in compliance and
                  get back on track.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
