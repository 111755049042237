import React from "react";
import { useCss, k } from "kremling";
import { CpButton, CpCard } from "canopy-styleguide!sofe";

export function AssistantOutro({ children, goBack }) {
  const scope = useCss(css);
  return (
    <CpCard {...scope} className="cp-p-16">
      <div className="cp-text-center">{children}</div>

      <div className="cp-assistant__table cp-assistant__navbar">
        <div className="cp-assistant__table-row">
          <div
            className="cp-assistant__table-cell cp-assistant__navbox"
            style={{ textAlign: "left" }}
          >
            <CpButton
              icon="caret-large-left"
              onClick={goBack}
              aria-label="Go back"
            />
          </div>
          <div className="cp-assistant__table-cell cp-assistant__progress-bar__container">
            <div className="cp-assistant__progress-bar cps-bg-gray-6">
              <div
                id="percentageComplete"
                className="cp-assistant__progress-bar cps-bg-color-primary"
                style={{
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              ></div>
            </div>
          </div>
          <div
            className="cp-assistant__table-cell cp-assistant__navbox"
            style={{ textAlign: "right" }}
          >
            {" "}
          </div>
        </div>
      </div>
    </CpCard>
  );
}

const css = k`
  
`;
