import React from "react";
import { throttle } from "lodash";
import { fromEvent } from "rxjs";
import AsyncDecorator from "async-decorator/rx6";
import styles from "./resolution-cases-table.styles.css";
import ResolutionCasesTableRow from "./resolution-cases-table-row";
import {
  getEmptyStateConfig,
  getColumns,
} from "./resolution-cases-table.helper.js";
import { CpEmptyState, CpLoader } from "canopy-styleguide!sofe";

@AsyncDecorator
export default class ResolutionCasesTable extends React.PureComponent {
  state = {
    showing: undefined,
    showingAccessor: undefined,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fromEvent(document, "scroll").subscribe(this.throttledScroll),
      fromEvent(document, "click").subscribe(this.hideDetails)
    );
  }

  render() {
    const emptyStateConfig = getEmptyStateConfig(this.props.showing);
    const columns = getColumns(this);
    return (
      <div>
        {this.props.resolutionCases.length > 0 ? (
          <div className={`${styles.table} cps-card`}>
            <div
              className={`${styles.headerRow} ${styles.rowWrapper} cps-bg-white`}
            >
              <ResolutionCasesTableRow
                selected={this.props.allSelected}
                isHeader={true}
                onColumnClick={this.props.updateSort}
                columns={columns}
                sortOptions={this.props.sortOptions}
                selectResolutionCase={this.props.selectAllResolutionCases}
                deselectResolutionCase={this.props.deselectAllResolutionCases}
              />
              <hr className="cps-margin-0" />
            </div>
            <div style={{ paddingTop: "5rem" }}>
              {this.props.resolutionCases.map((resolutionCase, index) => (
                <div className={`${styles.rowWrapper}`} key={index}>
                  <ResolutionCasesTableRow
                    selected={
                      this.props.allSelected ||
                      this.props.selectedResolutionCases[resolutionCase.id]
                    }
                    deselectResolutionCase={this.props.deselectResolutionCase}
                    selectResolutionCase={this.props.selectResolutionCase}
                    resolutionCase={resolutionCase}
                    columns={columns}
                  />
                  {this.props.resolutionCases.length !== index + 1 && (
                    <hr className="cps-margin-0" />
                  )}
                </div>
              ))}
            </div>
            {this.props.fetchingMore && (
              <div>
                <hr />
                <div className={`${styles.loaderWrapper}`}>
                  <CpLoader />
                </div>
              </div>
            )}
            <div></div>
          </div>
        ) : (
          <div className="cp-pt-16">
            <CpEmptyState
              img={emptyStateConfig.image}
              text={emptyStateConfig.header}
              subText={emptyStateConfig.description}
            />
          </div>
        )}
      </div>
    );
  }

  preventHideDetails = (evt) => {
    evt.shouldHide = false;
  };

  hideDetails = (evt) => {
    if (evt.keepPopupOpen !== true) {
      this.setState({ showing: undefined });
    }
  };

  updateDetailDropdown = (evt) => {
    this.setState((prev) => {
      let showing;
      let showingAccessor;
      if (
        evt.currentlyShowing === this.state.showing &&
        evt.showingAccessor === this.state.showingAccessor
      ) {
        showing = undefined;
      } else {
        showing = evt.currentlyShowing;
        showingAccessor = evt.showingAccessor;
        evt.keepPopupOpen = true;
      }
      return {
        showing,
        showingAccessor,
      };
    });
  };

  throttledScroll = throttle((evt) => {
    this.handleScroll(evt);
  }, 100);

  handleScroll = (evt) => {
    const scrollIsNearBottom =
      evt.target.scrollingElement &&
      evt.target.scrollingElement.scrollTop + window.innerHeight >=
        evt.target.scrollingElement.scrollHeight - 32 * 2;
    if (
      !this.props.fetchingMore &&
      scrollIsNearBottom &&
      this.props.anotherPageExists
    ) {
      this.props.loadNextPage();
    }
  };
}
