import React from "react";
import { getSectionType } from "./sections.utils";
import { useRouteMatch } from "react-router-dom";
import { SectionWorkpapers } from "./pages/workpapers/section-workpapers.component";
import { NavContent } from "primary-navbar!sofe";
import styles from "./sections.styles.css";
import { Assistant } from "../programs/assistant.component";
import { AssistantOutcomes } from "../programs/assistant-outcomes.component";

export function SectionsSwitch({ resolutionCase, clientUser = false }) {
  const { params } = useRouteMatch();
  const sectionType = getSectionType(
    resolutionCase,
    params.programSlug,
    params.sectionSlug,
    params.clientId,
    clientUser
  );

  const program = resolutionCase.program_data.programs.find(
    (program) => program.slug === params.programSlug
  );
  const section = program?.sections.find(
    (section) => section.slug === params.sectionSlug
  );

  let content;
  switch (sectionType) {
    case "workpaper":
      content = (
        <SectionWorkpapers
          clientId={params.clientId}
          resolutionCase={resolutionCase}
          section={section}
          program={program}
        />
      );
      break;
    case "assistant":
      content = (
        <Assistant
          key={params.programSlug}
          params={params}
          resolutionCase={resolutionCase}
          section={section}
          program={program}
        />
      );
      break;
    case "outcomes":
      content = (
        <AssistantOutcomes
          params={params}
          resolutionCase={resolutionCase}
          section={section}
          program={program}
        />
      );
  }

  return (
    <NavContent
      className="wide-menu"
      clientMenuPossible={true}
      hasTopnavSecondary={false}
    >
      <div className={styles.cardContainer}>{content}</div>
    </NavContent>
  );
}
