// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-resolution-case-sections-sections-styles__cardContainer--13ZOJ {\n  min-width: 70rem;\n  max-width: 136rem;\n  margin: 0 auto;\n}\n", ""]);
// Exports
exports.locals = {
	"cardContainer": "src-resolution-case-sections-sections-styles__cardContainer--13ZOJ"
};
module.exports = exports;
