import React from "react";
import styles from "./resolution-case-summary.styles.css";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

export function ProgressListItem({ service, renderIcon }) {
  function getUrl() {
    if (service.type === "step") {
      return `program/${service.programSlug}/section/${service.slug}`;
    } else if (service.type === "request") {
      return `client_requests/${service.id}`;
    }
  }

  return (
    <Link to={getUrl()} className={styles.inProgressListItem}>
      <div className="cp-mr-24">{renderIcon()}</div>
      <div className={styles.inProgressTitle}>
        <div className={`${styles.centerVertical} cp-subheader-sm`}>
          {service.programName && (
            <div className="cp-mr-4">{service.programName} &mdash;</div>
          )}
          <div className={`${styles.serviceName} cps-ellipsis`}>
            {service.name || "Untitled"}
          </div>
          <div className={styles.summaryLabel}>{capitalize(service.type)}</div>
        </div>
        <div className="cp-color-app-inactive-text">
          Updated {DateTime.fromMillis(service.updated_at).toFormat("MMM d")}
          <span> {service.updated_by ? `by ${service.updated_by}` : ""}</span>
        </div>
      </div>
    </Link>
  );
}
