import React from "react";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function LeviesOicOrInstallment() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Is an <a onClick={installmentAgreementModal}>installment agreement</a>{" "}
        or <a onClick={oicModal}>offer in compromise</a> currently pending or in
        effect?
      </strong>
    </div>
  );
}

function oicModal() {
  renderInfoModal(
    "Offer in Compromise",
    <>
      An agreement between a taxpayer and the IRS that settles the taxpayer's
      tax liabilities for less than the full amount owed. Taxpayers who can
      fully pay the liabilities through an installment agreement or other means
      will generally not be eligible for an Offer in Compromise.
    </>
  );
}

function installmentAgreementModal() {
  renderInfoModal(
    "Installment Agreement",
    <>
      If a taxpayer is financially unable to pay his or her tax debt
      immediately, he or she may be able to make monthly payments through an
      installment agreement.
    </>
  );
}
