import { isPlainObject } from "lodash";
import { liensConstants } from "./liens.constants";

// -----------------------------------------------------
// create all of the recognized states of the finite state automaton
// -----------------------------------------------------
const states = {};

states.INITIAL_STATE = createBinaryState(
  liensConstants.INTRO,
  "Y_PAID_IN_FULL",
  "Y_PAID_IN_FULL"
);

states.Y_PAID_IN_FULL = createBinaryState(
  liensConstants.PAID_IN_FULL,
  {
    nextPlaceToGo: "YY_DISPUTE_TAX",
    registerOutcomes: [liensConstants.WITHDRAWAL, liensConstants.RELEASE],
  },
  {
    nextPlaceToGo: "YN_PLANS_AND_MEANS",
    unregisterOutcomes: [liensConstants.WITHDRAWAL, liensConstants.RELEASE],
  }
);

states.YN_PLANS_AND_MEANS = createBinaryState(
  liensConstants.PLANS_AND_MEANS,
  {
    nextPlaceToGo: "YNY_DISPUTE_TAX",
    registerOutcomes: [liensConstants.WITHDRAWAL, liensConstants.RELEASE],
  },
  {
    nextPlaceToGo: "YNN_LIEN_WITHDRAWAL",
    unregisterOutcomes: [liensConstants.WITHDRAWAL, liensConstants.RELEASE],
  }
);

// Path A
states.YY_DISPUTE_TAX = states.YNY_DISPUTE_TAX = createBinaryState(
  liensConstants.DISPUTE_TAX,
  {
    nextPlaceToGo: "YYY_PURCHASE_PROPERTY",
    registerOutcomes: [liensConstants.APPEAL],
  },
  {
    nextPlaceToGo: "YYN_PROPOSE_ALTERNATIVE",
    unregisterOutcomes: [liensConstants.APPEAL],
  }
);

states.YYN_PROPOSE_ALTERNATIVE = createBinaryState(
  liensConstants.PROPOSE_ALTERNATIVE,
  {
    nextPlaceToGo: "YYNY_PURCHASE_PROPERTY",
    registerOutcomes: [liensConstants.APPEAL],
  },
  {
    nextPlaceToGo: "YYNN_REASONABLE_CAUSE",
    unregisterOutcomes: [liensConstants.APPEAL],
  }
);

states.YYNN_REASONABLE_CAUSE = createBinaryState(
  liensConstants.REASONABLE_CAUSE,
  {
    nextPlaceToGo: "YYNNY_PURCHASE_PROPERTY",
    registerOutcomes: [liensConstants.APPEAL],
  },
  {
    nextPlaceToGo: "YYNNN_PURCHASE_PROPERTY",
    unregisterOutcomes: [liensConstants.APPEAL],
  }
);

// Path B
states.YNN_LIEN_WITHDRAWAL = createBinaryState(
  liensConstants.LIEN_WITHDRAWAL,
  {
    nextPlaceToGo: "YNNY_DISPUTE_TAX",
    registerOutcomes: [liensConstants.WITHDRAWAL],
  },
  {
    nextPlaceToGo: "YNNN_INSTALLMENT_LESS_THAN_25000",
    unregisterOutcomes: [liensConstants.WITHDRAWAL],
  }
);

states.YNNN_INSTALLMENT_LESS_THAN_25000 = createBinaryState(
  liensConstants.INSTALLMENT_LESS_THAN_25000,
  {
    nextPlaceToGo: "YNNNY_DISPUTE_TAX",
    registerOutcomes: [liensConstants.WITHDRAWAL],
  },
  {
    nextPlaceToGo: "YNNNN_INVALID_LIEN",
    unregisterOutcomes: [liensConstants.WITHDRAWAL],
  }
);

states.YNNNN_INVALID_LIEN = createBinaryState(
  liensConstants.INVALID_LIEN,
  {
    nextPlaceToGo: "YNNNNY_DISPUTE_TAX",
    registerOutcomes: [liensConstants.WITHDRAWAL],
  },
  "YNNNNN_BANKRUPTCY"
);

states.YNNY_DISPUTE_TAX =
  states.YNNNY_DISPUTE_TAX =
  states.YNNNNY_DISPUTE_TAX =
    createBinaryState(
      liensConstants.DISPUTE_TAX,
      {
        nextPlaceToGo: "YNNYY_BANKRUPTCY",
        registerOutcomes: [liensConstants.APPEAL],
      },
      {
        nextPlaceToGo: "YNNYN_PROPOSE_ALTERNATIVE",
        unregisterOutcomes: [liensConstants.APPEAL],
      }
    );

states.YNNYN_PROPOSE_ALTERNATIVE = createBinaryState(
  liensConstants.PROPOSE_ALTERNATIVE,
  {
    nextPlaceToGo: "YNNYNY_BANKRUPTCY",
    registerOutcomes: [liensConstants.APPEAL],
  },
  {
    nextPlaceToGo: "YNNYNN_REASONABLE_CAUSE",
    unregisterOutcomes: [liensConstants.APPEAL],
  }
);

states.YNNYNN_REASONABLE_CAUSE = createBinaryState(
  liensConstants.REASONABLE_CAUSE,
  {
    nextPlaceToGo: "YNNYNNY_BANKRUPTCY",
    registerOutcomes: [liensConstants.APPEAL],
  },
  {
    nextPlaceToGo: "YNNYNNN_BANKRUPTCY",
    unregisterOutcomes: [liensConstants.APPEAL],
  }
);

states.YNNYY_BANKRUPTCY =
  states.YNNNNN_BANKRUPTCY =
  states.YNNYNY_BANKRUPTCY =
  states.YNNYNNY_BANKRUPTCY =
  states.YNNYNNN_BANKRUPTCY =
    createBinaryState(
      liensConstants.BANKRUPTCY,
      {
        nextPlaceToGo: "YNNYYY_PURCHASE_PROPERTY",
        registerOutcomes: [liensConstants.RELEASE],
      },
      {
        nextPlaceToGo: "YNNYYN_SURETY_BOND",
        unregisterOutcomes: [liensConstants.RELEASE],
      }
    );

states.YNNYYN_SURETY_BOND = createBinaryState(
  liensConstants.SURETY_BOND,
  {
    nextPlaceToGo: "YNNYYNY_PURCHASE_PROPERTY",
    registerOutcomes: [liensConstants.RELEASE],
  },
  {
    nextPlaceToGo: "YNNYYNN_STATUTE_EXPIRED",
    unregisterOutcomes: [liensConstants.RELEASE],
  }
);

states.YNNYYNN_STATUTE_EXPIRED = createBinaryState(
  liensConstants.STATUTE_EXPIRED,
  {
    nextPlaceToGo: "YNNYYNNY_PURCHASE_PROPERTY",
    registerOutcomes: [liensConstants.RELEASE],
  },
  {
    nextPlaceToGo: "YNNYYNNN_PURCHASE_PROPERTY",
    unregisterOutcomes: [liensConstants.RELEASE],
  }
);

// Paths A & B converge
states.YYY_PURCHASE_PROPERTY =
  states.YYNY_PURCHASE_PROPERTY =
  states.YYNNY_PURCHASE_PROPERTY =
  states.YYNNN_PURCHASE_PROPERTY =
  states.YNNYYY_PURCHASE_PROPERTY =
  states.YNNYYNY_PURCHASE_PROPERTY =
  states.YNNYYNNY_PURCHASE_PROPERTY =
  states.YNNYYNNN_PURCHASE_PROPERTY =
    createBinaryState(
      liensConstants.PURCHASE_PROPERTY,
      {
        nextPlaceToGo: "YYYY_RESTRUCTURE",
        registerOutcomes: [liensConstants.DISCHARGE],
      },
      {
        nextPlaceToGo: "YYYN_DOUBLE_VALUE",
        unregisterOutcomes: [liensConstants.DISCHARGE],
      }
    );

states.YYYN_DOUBLE_VALUE = createBinaryState(
  liensConstants.DOUBLE_VALUE,
  {
    nextPlaceToGo: "YYYNY_RESTRUCTURE",
    registerOutcomes: [liensConstants.DISCHARGE],
  },
  {
    nextPlaceToGo: "YYYNN_SELL_PROPERTY",
    unregisterOutcomes: [liensConstants.DISCHARGE],
  }
);

states.YYYNN_SELL_PROPERTY = createBinaryState(
  liensConstants.SELL_PROPERTY,
  "YYYNNY_PROCEEDS_TO_IRS",
  "YYYNNN_RESTRUCTURE"
);

states.YYYNNY_PROCEEDS_TO_IRS = createBinaryState(
  liensConstants.PROCEEDS_TO_IRS,
  {
    nextPlaceToGo: "YYYNNYY_RESTRUCTURE",
    registerOutcomes: [liensConstants.DISCHARGE],
  },
  {
    nextPlaceToGo: "YYYNNYN_LESS_THAN_ZERO",
    unregisterOutcomes: [liensConstants.DISCHARGE],
  }
);

states.YYYNNYN_LESS_THAN_ZERO = createBinaryState(
  liensConstants.LESS_THAN_ZERO,
  {
    nextPlaceToGo: "YYYNNYNY_RESTRUCTURE",
    registerOutcomes: [liensConstants.DISCHARGE],
  },
  {
    nextPlaceToGo: "YYYNNYNN_PROCEEDS_IN_ESCROW",
    unregisterOutcomes: [liensConstants.DISCHARGE],
  }
);

states.YYYNNYNN_PROCEEDS_IN_ESCROW = createBinaryState(
  liensConstants.PROCEEDS_IN_ESCROW,
  {
    nextPlaceToGo: "YYYNNYNNY_RESTRUCTURE",
    registerOutcomes: [liensConstants.DISCHARGE],
  },
  {
    nextPlaceToGo: "YYYNNYNNN_RESTRUCTURE",
    unregisterOutcomes: [liensConstants.DISCHARGE],
  }
);

states.YYYY_RESTRUCTURE =
  states.YYYNY_RESTRUCTURE =
  states.YYYNNN_RESTRUCTURE =
  states.YYYNNYY_RESTRUCTURE =
  states.YYYNNYNY_RESTRUCTURE =
  states.YYYNNYNNY_RESTRUCTURE =
  states.YYYNNYNNN_RESTRUCTURE =
    createBinaryState(
      liensConstants.RESTRUCTURE,
      {
        nextPlaceToGo: [liensConstants.SUBORDINATION],
        registerOutcomes: [liensConstants.SUBORDINATION],
      },
      {
        nextPlaceToGo: "YYYYN_REFINANCING",
        unregisterOutcomes: [liensConstants.SUBORDINATION],
      }
    );

states.YYYYN_REFINANCING = createBinaryState(
  liensConstants.REFINANCING,
  {
    nextPlaceToGo: [liensConstants.SUBORDINATION],
    registerOutcomes: [liensConstants.SUBORDINATION],
  },
  {
    nextPlaceToGo: "YYYYNN_LINE_OF_CREDIT",
    unregisterOutcomes: [liensConstants.SUBORDINATION],
  }
);

states.YYYYNN_LINE_OF_CREDIT = createBinaryState(
  liensConstants.LINE_OF_CREDIT,
  {
    nextPlaceToGo: [liensConstants.SUBORDINATION],
    registerOutcomes: [liensConstants.SUBORDINATION],
  },
  {
    nextPlaceToGo: [liensConstants.RELIEF_UNLIKELY],
    registerOutcomes: [liensConstants.RELIEF_UNLIKELY],
    unregisterOutcomes: [liensConstants.SUBORDINATION],
  }
);

function createBinaryState(questionSlug, stateIfYes, stateIfNo) {
  const result = function (value, previousQuestionSlug) {
    let nextPlaceToGo;

    if (value === true) {
      nextPlaceToGo = stateIfYes;
    } else if (value === false) {
      nextPlaceToGo = stateIfNo;
    } else {
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    }

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (isPlainObject(nextPlaceToGo)) {
      if (Array.isArray(nextPlaceToGo.nextPlaceToGo)) {
        return {
          reachedOutcome: true,
          outcomes: nextPlaceToGo.nextPlaceToGo,
          nextState: null,
          nextQuestionSlug: null,
          currentQuestionSlug: questionSlug,
          outcomesToRegister: nextPlaceToGo.registerOutcomes,
          outcomesToUnregister: nextPlaceToGo.unregisterOutcomes,
          previousQuestionSlug,
        };
      } else if (typeof nextPlaceToGo.nextPlaceToGo === "string") {
        if (!states[nextPlaceToGo.nextPlaceToGo]) {
          throw new Error(
            `Could not find state by name of '${nextPlaceToGo.nextPlaceToGo}'`
          );
        }

        return {
          reachedOutcome: false,
          outcomes: null,
          nextState: states[nextPlaceToGo.nextPlaceToGo],
          nextQuestionSlug: states[nextPlaceToGo.nextPlaceToGo].questionSlug,
          currentQuestionSlug: questionSlug,
          outcomesToRegister: nextPlaceToGo.registerOutcomes,
          outcomesToUnregister: nextPlaceToGo.unregisterOutcomes,
          previousQuestionSlug,
        };
      } else {
        throw new Error(
          `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo.nextPlaceToGo}' of value '${
            nextPlaceToGo.nextPlaceToGo
          }'`
        );
      }
    } else if (typeof nextPlaceToGo === "string") {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }

      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(
        `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`
      );
    }
  };

  result.questionSlug = questionSlug;
  return result;
}

export function liensStateMachine(answers = {}, questionToStopAt) {
  const initialState = {
    reachedOutcome: false,
    outcomes: liensConstants.OUTCOME_NOT_YET_CALCULABLE,
    nextState: states.INITIAL_STATE,
    nextQuestionSlug: liensConstants.INTRO,
    currentQuestionSlug: null,
    previousQuestionSlug: null,
    nextQuestionIsOutcome: false,
  };
  answers = JSON.parse(JSON.stringify(answers));

  return traverse(answers, questionToStopAt, initialState);
}

function traverse(answers, questionToStopAt, currentState) {
  const newState = currentState.nextState(
    answers[currentState.nextQuestionSlug],
    currentState.currentQuestionSlug
  );

  newState.outcomesToRegister = [
    ...(newState?.outcomesToRegister || []),
    ...(currentState?.outcomesToRegister || []),
  ];

  if (currentState.nextQuestionSlug === questionToStopAt) {
    return createResultForNonOutcomeState(currentState, newState);
  } else {
    if (newState.reachedOutcome) {
      newState.previousQuestionSlug = newState.currentQuestionSlug;
      newState.currentQuestionSlug = null;
      return newState;
    } else if (!newState.nextState) {
      return newState;
    } else {
      return traverse(answers, questionToStopAt, newState);
    }
  }
}

function createResultForNonOutcomeState(state, newState) {
  return {
    reachedOutcome: false,
    outcomes: null,
    nextState: null,
    nextQuestionSlug: newState ? newState.nextQuestionSlug : null,
    nextQuestionIsOutcome: !!newState.reachedOutcome,
    currentQuestionSlug: state.nextQuestionSlug,
    previousQuestionSlug: state.currentQuestionSlug,
    outcomesToRegister: newState.outcomesToRegister || null,
    outcomesToUnregister: newState.outcomesToUnregister || null,
  };
}
