import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { useHistory } from "react-router-dom";

import { useInnocentSpouseOutcome } from "./innocent-spouse-outcomes.helper";
import { tryRestart } from "../assistant-utils";

export function InnocentSpouseOutro({ outcomes, urls, restartAssistant }) {
  const history = useHistory();
  const {
    innocentSpouseAvailable,
    separationOfLiabilityAvailable,
    communityPropertyAvailable,
    equitableReliefAvailable,
    reliefUnlikely,
  } = useInnocentSpouseOutcome(outcomes);

  return (
    <div>
      <div className="cp-assistant-outro__recommendation">
        <div className="cps-subheader-sm">Recommendation:</div>

        {innocentSpouseAvailable && (
          <div>
            <div className="cps-subheader-sm cps-color-primary">
              Innocent Spouse Relief available
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Innocent Spouse Assistant! Based on your
              answers, your client may qualify for Innocent Spouse Relief.
            </div>
          </div>
        )}

        {separationOfLiabilityAvailable && (
          <div>
            <div className="cps-subheader-sm cps-color-primary">
              Relief by Separation of Liability available
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Innocent Spouse Assistant! Based on your
              answers, your client may qualify for Relief by Separation of
              Liability.
            </div>
          </div>
        )}

        {communityPropertyAvailable && (
          <div>
            <div className="cps-subheader-sm cps-color-primary">
              Relief from Tax Liability Attributable to an Item of Community
              Property available
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Innocent Spouse Assistant! Based on your
              answers, your client may qualify for Relief from Tax Liability
              Attributable to an Item of Community Property.
            </div>
          </div>
        )}

        {equitableReliefAvailable && (
          <div>
            <div className="cps-subheader-sm cps-color-primary">
              Equitable Relief available
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Innocent Spouse Assistant! Based on your
              answers, your client may qualify for Equitable Relief.
            </div>
          </div>
        )}

        {reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm cps-warning">Relief unlikely</div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Innocent Spouse Assistant! Based on your
              answers, relief for your client is unlikely. Sometimes it doesn't
              hurt to try though - check out our outcomes to learn more.
            </div>
          </div>
        )}
      </div>

      <div className="end-of-assistant-actions">
        <CpButton onClick={() => history.push(urls.resolution)}>
          Go to outcomes
        </CpButton>
        <CpButton btnType="flat" onClick={() => tryRestart(restartAssistant)}>
          Restart assistant
        </CpButton>
      </div>
    </div>
  );
}
