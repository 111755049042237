import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";

const TOOLTIP = `Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington, and Wisconsin`;

export function InnocentSpouseCommunityPropertyState() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Did the client live in a{" "}
        <CpTooltip text={TOOLTIP}>
          <a>community property state</a>
        </CpTooltip>
        ?
      </strong>
    </div>
  );
}
