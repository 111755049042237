import React, { useEffect, useMemo, useState, useRef, lazy } from "react";
import styles from "./comments.styles.css";
import { trim, cloneDeep, isNil, isEmpty, orderBy } from "lodash";
import { DateTime } from "luxon";
import { CpButton, CpNameChip, CpTextarea } from "canopy-styleguide!sofe";
import {
  deleteComment,
  getComments,
  postComment,
  updateComment,
} from "src/common/comments/comments.resource";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { catchAsyncStacktrace } from "auto-trace";
import { useAttachmentDropzone } from "../use-attachment-dropzone.hook";
import { AttachmentCard } from "../attachment-card.component";
import { saveAttachments } from "../attachments.resource";
import { CommentsProvider, useComments } from "./comments.context";

const AddFileButton = lazy(() =>
  import("rich-text-ui!sofe")
    .then((mod) => mod.getAddFileButton())
    .then((button) => button)
);

const newCommentTemplate = (
  loggedInUser,
  parentObjType,
  parentObjId,
  text
) => ({
  body: text,
  relationships: {
    author: {
      name: loggedInUser.name,
      initials: loggedInUser.initials,
      type: "user",
      id: loggedInUser.id,
    },
    for: {
      type: parentObjType,
      id: parentObjId,
    },
  },
});

export function Comments({
  attachments,
  children,
  clientId,
  filesUpload,
  onAttachmentSend,
  pivotId,
  pivotType,
}) {
  const [comments, setComments] = useState([]);
  const [attachmentsBeforeSend, setAttachmentsBeforeSend] = useState([]);
  const [editingCommentId, setEditingCommentId] = useState("");
  const [user] = useWithUserAndTenant();

  const { filesLoading } = useAttachmentDropzone(
    {
      disabled: !filesUpload,
      dropzoneId: "#attachmentDropzone",
      onUpload,
      pivotId,
      pivotType: "request_comment",
      targetId: clientId,
      targetPath: "clients",
    },
    [pivotId, pivotType]
  );

  useEffect(() => {
    if (!pivotId) return;
    const subscription = getComments(clientId, pivotType, pivotId).subscribe(
      setComments
    );
    return () => subscription.unsubscribe();
  }, [clientId, pivotId, pivotType]);

  function handlePostComment(commentText, attachment, cb) {
    const newComment = newCommentTemplate(
      user,
      pivotType,
      pivotId,
      commentText
    );
    postComment(clientId, pivotType, pivotId, newComment).subscribe(
      ({ id }) => {
        setComments((state) => [
          ...state,
          { ...newComment, id, created_at: DateTime.now().toMillis() },
        ]);
        cb();
        if (attachment) {
          patchAttachments(id);
        }
      },
      catchAsyncStacktrace()
    );
  }

  function patchAttachments(commentId) {
    const promises = [];
    for (let i = 0; i < attachmentsBeforeSend.length; i++) {
      const attachment = attachmentsBeforeSend[i];
      const attachmentSummary = {
        id: attachment.id,
        pivot_type: "client_requests",
        pivot_id: Number(pivotId),
        ancestor_id: commentId,
        ancestor_type: "comment",
      };
      attachment.relationships = {
        ...(attachment.relationships || {}),
        ancestor: { type: "comment", id: commentId },
      };
      onAttachmentSend({ ...attachmentSummary, ...attachment });

      promises.push(
        saveAttachments(
          "clients",
          clientId,
          "client_requests",
          pivotId,
          { file: attachment },
          commentId,
          "comment"
        )
      );
    }

    Promise.all(promises)
      .then(() => {
        setAttachmentsBeforeSend(null);
      })
      .catch(() => setAttachmentsBeforeSend(null));
  }

  function onUpload(uploadedAttachment) {
    setAttachmentsBeforeSend((prevState) => [...prevState, uploadedAttachment]);
  }

  function handleDeleteComment(commentId) {
    deleteComment(clientId, commentId).subscribe(({ success }) => {
      if (success) {
        setComments((state) =>
          state.filter((comment) => comment.id !== commentId)
        );
      }
    }, catchAsyncStacktrace());
  }

  function handlePostCommentEdit(editCommentText) {
    const oldCommentIndex = comments.findIndex(
      ({ id }) => id === editingCommentId
    );
    let payload = cloneDeep(comments[oldCommentIndex]);
    delete payload.id;
    delete payload.client_id;
    delete payload.created_at;
    payload.body = editCommentText;

    updateComment(clientId, editingCommentId, payload).subscribe(({ id }) => {
      setEditingCommentId("");
      setComments((state) => {
        const editComment = state.find(({ id }) => id === editingCommentId);
        const _state = cloneDeep(state);
        _state.splice(oldCommentIndex, 1, {
          ...editComment,
          body: editCommentText,
        });
        return _state;
      });
    }, catchAsyncStacktrace());
  }

  return (
    <CommentsProvider
      comments={comments}
      handleDeleteComment={handleDeleteComment}
      handlePostCommentEdit={handlePostCommentEdit}
      onEditComment={setEditingCommentId}
      editingCommentId={editingCommentId}
      attachments={attachments || []}
      user={user}
      handlePostComment={handlePostComment}
      attachmentsBeforeSend={attachmentsBeforeSend}
      setAttachmentsBeforeSend={setAttachmentsBeforeSend}
      filesUpload={filesUpload}
      filesLoading={filesLoading}
    >
      <div className={styles.commentsContainer}>{children}</div>
    </CommentsProvider>
  );
}

export const CommentsInput = ({ className, clientId }) => {
  const {
    user,
    handlePostComment,
    attachmentsBeforeSend,
    setAttachmentsBeforeSend,
    filesUpload,
    filesLoading,
  } = useComments();
  const [commentText, setCommentText] = useState("");

  return (
    <div
      className={`${styles.commentInputContainer} ${
        className ? className : ""
      }`}
    >
      <CpNameChip className={styles.commentNameChip} name={user?.name} />
      <div className={styles.commentInput}>
        <div style={{ position: "relative" }}>
          {filesUpload && (
            <div
              style={{ position: "absolute", right: 0, zIndex: 1 }}
            >
              <AddFileButton
                clientId={clientId}
                handleFiles={(files) =>
                  setAttachmentsBeforeSend((prevFiles) => [
                    ...prevFiles ? prevFiles : [],
                    ...files,
                  ])
                }
              />
            </div>
          )}
          <CpTextarea
            id="attachmentDropzone"
            height={32}
            resize="auto"
            maxHeight={150}
            style={{ overflowY: "auto" }}
            onChange={setCommentText}
            value={commentText}
            placeholder="Add a comment..."
          />
        </div>
        {isEmpty(filesLoading)
          ? !isEmpty(attachmentsBeforeSend) &&
            attachmentsBeforeSend.map((attachment) => (
              <AttachmentCard key={attachment.id} attachment={attachment} />
            ))
          : filesLoading.map((file, i) => (
              <AttachmentCard key={`loading-${i}`} attachment={file} loading />
            ))}
        {(!!trim(commentText) || !isEmpty(attachmentsBeforeSend)) && (
          <div className={styles.postCommentActions}>
            <CpButton
              btnType="primary"
              onClick={() =>
                handlePostComment(commentText, attachmentsBeforeSend, () =>
                  setCommentText("")
                )
              }
              className="cp-mr-8"
            >
              Post
            </CpButton>
            <CpButton
              btnType="tertiary"
              onClick={() => {
                setAttachmentsBeforeSend(null);
                setCommentText("");
              }}
            >
              Cancel
            </CpButton>
          </div>
        )}
      </div>
    </div>
  );
};

export const CommentsList = ({ className, availableActions, sortOrder }) => {
  const {
    comments,
    handleDeleteComment,
    handlePostCommentEdit,
    attachments,
    onEditComment,
    editingCommentId,
    user,
  } = useComments();
  const [editCommentText, setEditCommentText] = useState("");
  const orderedComments = orderBy(
    comments,
    ["created_at"],
    [sortOrder ? sortOrder : "asc"]
  );
  const commentsRef = useRef(null);

  useEffect(() => {
    //Scroll to bottom
    commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
  }, [comments]);

  const commentsWithAttachments = useMemo(() => {
    return orderedComments.map((comment) => ({
      comment,
      attachments: attachments.filter(
        (attachment) =>
          comment.id.trim() === attachment.relationships?.ancestor.id
      ),
    }));
  }, [comments, attachments]);

  return (
    <div
      ref={commentsRef}
      className={`${styles.commentsWrapper} ${className ? className : ""}`}
    >
      {commentsWithAttachments.map(
        ({ comment: { relationships, created_at, body, id }, attachments }) => (
          <div className={styles.commentWrapper} key={id}>
            <div className={styles.commentTop}>
              <div className="cp-mr-8">
                <CpNameChip name={relationships.author.name} />
              </div>
              <div>
                <div className={styles.commentInfo}>
                  <div className={styles.commentTitle}>
                    <strong className="cp-mr-4">
                      {relationships.author.name}
                    </strong>{" "}
                    • {DateTime.fromMillis(created_at).toFormat("LLL d")} at{" "}
                    {DateTime.fromMillis(created_at).toFormat("h:mm a")}
                  </div>
                  {editingCommentId !== id && (
                    <div className={styles.commentActions}>
                      {(isNil(availableActions) ||
                        availableActions?.includes("edit")) &&
                        relationships.author.id === user.id && (
                          <CpButton
                            onClick={() => {
                              onEditComment(id);
                              setEditCommentText(body);
                            }}
                            icon="crud-pencil"
                            small
                            aria-label="Edit comment"
                          />
                        )}
                      {(isNil(availableActions) ||
                        availableActions?.includes("delete")) && (
                        <CpButton
                          onClick={() => handleDeleteComment(id)}
                          icon="crud-trash-small"
                          aria-label="Delete comment"
                          small
                        />
                      )}
                    </div>
                  )}
                </div>
                {editingCommentId === id ? (
                  <div>
                    <CpTextarea
                      onChange={setEditCommentText}
                      value={editCommentText}
                      className={styles.editInput}
                    />
                    <CpButton
                      className="cp-mr-8"
                      onClick={() => handlePostCommentEdit(editCommentText)}
                      btnType="primary"
                    >
                      Post
                    </CpButton>
                    <CpButton
                      onClick={() => {
                        onEditComment("");
                        setEditCommentText("");
                      }}
                      btnType="tertiary"
                    >
                      Cancel
                    </CpButton>
                  </div>
                ) : (
                  <div>{body}</div>
                )}
              </div>
            </div>
            {attachments.map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                attachment={attachment}
                isDownloadable
              />
            ))}
          </div>
        )
      )}
    </div>
  );
};
