// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-resolution-case-letters-letters-styles__lettersCardsWrapper--3P_dD {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 35.4rem);\n  grid-auto-rows: 17.9rem;\n  gap: 1.6rem;\n  overflow-y: auto;\n}\n\n.src-resolution-case-letters-letters-styles__lettersCard--VHc_i {\n  display: grid;\n  grid-template-columns: 11.5rem 1fr;\n  grid-template-rows: 14.5rem;\n  gap: 16px;\n}\n\n.src-resolution-case-letters-letters-styles__letterThumbnail--3hI-j {\n  width: 100%;\n  height: 100%;\n  border: 1px solid lightgrey;\n}\n\n.src-resolution-case-letters-letters-styles__letterOpenLink--2SxA2 {\n  color: var(--cp-color-app-primary);\n  cursor: pointer;\n}\n\n.src-resolution-case-letters-letters-styles__letterOpenLink--2SxA2:hover {\n  text-decoration: underline;\n}\n\n.src-resolution-case-letters-letters-styles__lettersLastEdited--NqJbW {\n  color: var(--cp-color-app-secondary-text);\n}\n\n.src-resolution-case-letters-letters-styles__lettersDelete--2fJ9q {\n  visibility: hidden;\n}\n\n.src-resolution-case-letters-letters-styles__lettersCard--VHc_i:hover .src-resolution-case-letters-letters-styles__lettersDelete--2fJ9q,\n.src-resolution-case-letters-letters-styles__newLettersCard--2H6xU:hover .src-resolution-case-letters-letters-styles__lettersDelete--2fJ9q {\n  visibility: visible;\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"lettersCardsWrapper": "src-resolution-case-letters-letters-styles__lettersCardsWrapper--3P_dD",
	"lettersCard": "src-resolution-case-letters-letters-styles__lettersCard--VHc_i",
	"letterThumbnail": "src-resolution-case-letters-letters-styles__letterThumbnail--3hI-j",
	"letterOpenLink": "src-resolution-case-letters-letters-styles__letterOpenLink--2SxA2",
	"lettersLastEdited": "src-resolution-case-letters-letters-styles__lettersLastEdited--NqJbW",
	"lettersDelete": "src-resolution-case-letters-letters-styles__lettersDelete--2fJ9q",
	"newLettersCard": "src-resolution-case-letters-letters-styles__newLettersCard--2H6xU"
};
module.exports = exports;
