import React from "react";
import { Link, useParams } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { useLeviesOutcomes } from "../levies-outcomes.helper";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function LeviesReturningProperty({ urls, outcomes }) {
  const params = useParams();
  const { returningPropertyAvailable, cantCalculateOutcomes } =
    useLeviesOutcomes(outcomes);
  return (
    <div>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {returningPropertyAvailable && (
                  <span className="cps-color-primary">
                    Returning Property available
                  </span>
                )}
                {!returningPropertyAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">
                    Returning Property unlikely
                  </span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {returningPropertyAvailable && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, your client may qualify for Return of Property. Check
                  out the resources below for additional help.
                </div>
              )}
              {!returningPropertyAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, Return of Property for your client is unlikely.
                  Sometimes it doesn't hurt to try though - check out the
                  resources below to learn more.
                </div>
              )}
              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the <Link to={urls.assistant}>Levies Assistant</Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          Levied property may be returned to a taxpayer in various
          circumstances, including the following:
        </p>
        <ul>
          <li>IRS procedures were not followed</li>
          <li>The levy was issued prematurely</li>
          <li>The levy was in violation of the law</li>
          <li>
            The levy was <a onClick={wrongfulLeviesModal}>wrongfully issued</a>
          </li>
          <li>
            Returning the levy proceeds is in the best interests of the taxpayer
            and government
          </li>
          <li>Returning the levy proceeds facilitates collection</li>
          <li>
            An <a onClick={installmentAgreementModal}>installment agreement</a>{" "}
            was made for a liability included on the levy
          </li>
        </ul>
        <p>
          Levied property (other than money) in possession of the IRS may be
          returned at any time. An amount of money equal to the amount of money
          levied upon or received from a sale of property may be returned at any
          time before the expiration of 9 months from the date of the levy.
          Under the Tax Cuts and Jobs Act of 2017, levies made after December
          22, 2017, the time limit is extended to a two-year period.
        </p>
        <p>
          When levy proceeds are returned, the delinquent tax is generally not
          forgiven. The taxpayer is still required to pay the amount owed. After
          the payment is returned to the taxpayer, interest and penalties once
          again begin to accrue.
        </p>
        <p>
          Interest is generally paid by the IRS along with the returned property
          only in cases where a third party's property is wrongfully levied.
        </p>
        <p>IRS sales procedures</p>
        <p>
          Before selling a seized property, the IRS will calculate a minimum bid
          price. The IRS provides the taxpayer with a copy of the calculation
          and gives the taxpayer an opportunity to challenge the fair market
          value determination. The IRS will then provide the taxpayer with a
          notice of sale and announce the pending sale to the public, usually
          through local newspapers or flyers posted in public places. After
          giving public notice, the IRS will generally wait at least 10 days
          before selling the property.
        </p>
        <p>
          When the IRS seizes and sells taxpayer property, the net proceeds are
          applied to the taxpayer's tax debt. Proceeds from the sale are applied
          in the following order:
        </p>
        <ol>
          <li>Cost of seizing and selling the property</li>
          <li>The taxpayer's tax debt</li>
        </ol>
        <p>
          If there are any proceeds remaining after the sale costs and tax
          liability are fully paid, the taxpayer can request the funds as a
          refund.
        </p>
        <p>
          To recover real estate, the taxpayer (and/or anyone with an interest
          in the property) may "redeem" it within 180 days of the sale by paying
          the purchaser what they paid, plus interest at 20% annually.
        </p>
        <p>Bank charges</p>
        <p>
          If your client incurs bank charges as a result of the wrongful seizure
          of his or her property, you may request that the IRS provide
          reimbursement for the charges paid by filing Form 8546,{" "}
          <i>Claim for Reimbursement of Bank Charges</i>. To be eligible to
          recover bank charges from the IRS, all of the following conditions
          must be satisfied:
        </p>
        <ul>
          <li>The IRS caused the error</li>
          <li>
            Your client did not contribute to continuing or compounding the
            error
          </li>
          <li>
            Before the levy, you or your client must have responded timely to
            IRS inquiries and not refused to submit information relevant to the
            liability
          </li>
        </ul>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/Letter-PropertyReturn.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Property return
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/AttachmentToForm9423.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Attachment to Form 9423
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/AttachmentToForm12153.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Attachment to Form 12153
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p594.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 594 - The IRS collection process
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p1660.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 1660 - Collection Appeal rights
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p4528.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 4528, Making an administrative Wrongful Levy
                      claim under Internal Revenue Code (IRC) Section 6343(b)
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 4235, Collection Advisory Group addresses
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
            </div>
          </div>

          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/V912/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 9423
                  </div>
                  Collection Appeal Request
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/CPK7D/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 12153
                  </div>
                  Request for a Collection Due Process or Equivalent Hearing
                </div>
              </Link>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://cdn.canopytax.com/static/levies/f8546.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 8546
                  </div>
                  Claim for Reimbursement of Bank Charges
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <ul>
                  <li>
                    Except in the case of erroneous levies, proceeds from levies
                    issued in the last 9 months can be returned to the taxpayer{" "}
                    <strong>at the discretion of the Service</strong>. It will
                    be up to the practitioner and/or taxpayer to convince the
                    Service that based on facts and circumstances of the
                    particular case, proceeds should be returned.
                  </li>
                  <li>
                    The request for return of levy proceeds must be in writing.
                  </li>
                  <li>
                    A return of seized property is not all that common since all
                    other administrative options and case circumstances have
                    been considered prior to the actual seizure.
                  </li>
                  <li>
                    If the request is rejected by the Service, an appeal may be
                    made using Collection Appeal Program (CAP) procedures, or,
                    if Collection Due Process (CDP) rights exist under IRC
                    6330(f) and are timely exercised, by raising the issue at a
                    CDP hearing or an equivalent hearing, whichever may be
                    applicable.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function installmentAgreementModal() {
  renderInfoModal(
    "Installment agreement",
    <>
      If a taxpayer is financially unable to pay his or her tax debt
      immediately, he or she may be able to make monthly payments through an
      installment agreement.
    </>
  );
}

function wrongfulLeviesModal() {
  renderInfoModal(
    "Wrongful levies",
    <>
      <p>
        The IRS distinguishes between "wrongful" levies and other types of
        improper or erroneous levies. A "wrongful levy" is one that improperly
        attaches property belonging to a third party in which the taxpayer has
        no rights.
      </p>
      <p>
        An erroneous or improper levy is one that properly seeks to capture a
        taxpayer's property (rather than a third party's property), but for
        example, nevertheless is served prematurely or otherwise in violation of
        an administrative procedure or law.
      </p>
    </>
  );
}
