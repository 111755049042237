import React, { useState } from "react";
import { a } from "kremling";

export function Summary({ children }) {
  const [show, setShow] = useState(false);
  return (
    <div className="cps-card cps-card-nonrounded-top cps-margin-top-16 cps-flexible-focus">
      <div className="cps-card__header cps-subheader-sm">
        <strong>Summary</strong>
      </div>
      <div
        className={a("cps-card__body cp-outcomes__card__content").m(
          "cp-outcomes__card__contracted",
          !show
        )}
      >
        {children}
        {!show && (
          <div className="cp-outcomes__card__content__fade">&nbsp;</div>
        )}
        <div
          className="cps-slat-lg cp-assistant__table cp-outcomes__slat__more-less"
          onClick={() => setShow(!show)}
        >
          <div
            className="cp-assistant__table-cell"
            style={{ verticalAlign: "middle", alignContent: "center" }}
          >
            <a className="cps-color-primary" style={{ textDecoration: "none" }}>
              <strong>{!show ? "See more" : "See less"}</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
