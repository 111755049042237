import React, { useState } from "react";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";

import { useInnocentSpouseOutcome } from "./innocent-spouse-outcomes.helper";
import {
  OutcomeHeader,
  OutcomeHeaderItem,
} from "../common/outcome-header.component";
import { innocentSpouseConstants } from "./innocent-spouse.constants";
import { InnocentSpouseOutcome } from "./outcomes/innocent-spouse-outcome.component";
import { InnocentSpouseSeparationOfLiability } from "./outcomes/innocent-spouse-separation-of-liability.component";
import { InnocentSpouseCommunityProperty } from "./outcomes/innocent-spouse-community-property.component";
import { InnocentSpouseEquitableRelief } from "./outcomes/innocent-spouse-equitable-relief.component";

export function InnocentSpouseResolution({ outcomes, urls, params }) {
  const {
    equitableReliefAvailable,
    communityPropertyAvailable,
    separationOfLiabilityAvailable,
    innocentSpouseAvailable,
  } = useInnocentSpouseOutcome(outcomes);

  const [resolutionType, setResolutionType] = useState(
    (() => {
      if (equitableReliefAvailable) {
        return innocentSpouseConstants.EQUITABLE_RELIEF;
      } else if (communityPropertyAvailable) {
        return innocentSpouseConstants.COMMUNITY_PROPERTY;
      } else if (separationOfLiabilityAvailable) {
        return innocentSpouseConstants.SEPARATION_OF_LIABILITY;
      } else if (innocentSpouseAvailable) {
        return innocentSpouseConstants.INNOCENT_SPOUSE;
      }
      return innocentSpouseConstants.INNOCENT_SPOUSE;
    })()
  );

  return (
    <div>
      <OutcomeHeader value={resolutionType} onChange={setResolutionType}>
        <OutcomeHeaderItem
          id={innocentSpouseConstants.INNOCENT_SPOUSE}
          active={innocentSpouseAvailable}
        >
          Innocent spouse
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={innocentSpouseConstants.SEPARATION_OF_LIABILITY}
          active={separationOfLiabilityAvailable}
        >
          Separation of liability
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={innocentSpouseConstants.COMMUNITY_PROPERTY}
          active={communityPropertyAvailable}
        >
          Community property
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={innocentSpouseConstants.EQUITABLE_RELIEF}
          active={equitableReliefAvailable}
        >
          Equitable relief
        </OutcomeHeaderItem>
      </OutcomeHeader>

      <CpSwitch expression={resolutionType}>
        <CpCase value={innocentSpouseConstants.INNOCENT_SPOUSE}>
          <InnocentSpouseOutcome
            outcomes={outcomes}
            urls={urls}
            params={params}
          />
        </CpCase>
        <CpCase value={innocentSpouseConstants.SEPARATION_OF_LIABILITY}>
          <InnocentSpouseSeparationOfLiability
            outcomes={outcomes}
            urls={urls}
            params={params}
          />
        </CpCase>
        <CpCase value={innocentSpouseConstants.COMMUNITY_PROPERTY}>
          <InnocentSpouseCommunityProperty
            outcomes={outcomes}
            urls={urls}
            params={params}
          />
        </CpCase>
        <CpCase value={innocentSpouseConstants.EQUITABLE_RELIEF}>
          <InnocentSpouseEquitableRelief
            outcomes={outcomes}
            urls={urls}
            params={params}
          />
        </CpCase>
      </CpSwitch>
    </div>
  );
}
