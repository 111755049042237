import { groupBy } from "lodash";
import { DateTime } from "luxon";

export function groupRequests(requests) {
  return groupBy(requests, function ({ first_sent_at, status }) {
    if (!first_sent_at) return "unsent";
    return status;
  });
}

export function getRelativeTime(date, timeZone) {
  if (!date) return "";
  const dateObject = new Date(date);
  if (timeZone) {
    return DateTime.fromJSDate(dateObject).setZone(timeZone).toRelative();
  } else {
    return DateTime.fromJSDate(dateObject).toUTC().toRelative();
  }
}

export function getUrlFromState(state, params) {
  return (
    window.location.origin +
    "/#/redirect?state=" +
    encodeURI(state) +
    "&params=" +
    encodeURI(JSON.stringify(params || {}))
  );
}

export function getStateFromUrl(url) {
  const regex = /.*?state=(.*)&params=(.*)/g;
  const m = regex.exec(url);
  return {
    state: decodeURI(m[1]),
    params: JSON.parse(decodeURI(m[2])),
  };
}
