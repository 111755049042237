import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";

const TOOLTIP =
  "These specific penalty codes are 6651(a)(1), 6651(a)(2), 6651(a)(3), 6656, 6698(a)(1), and 6699(a)(1)";

export function PenaltyAbatementPenaltyReason() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Is the{" "}
        <CpTooltip text={TOOLTIP}>
          <a>penalty</a>
        </CpTooltip>{" "}
        for Failure to File (FTF), Failure to Pay (FTP), or Failure to Deposit
        (FTD)?
      </strong>
    </div>
  );
}
