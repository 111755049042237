import React from "react";
import { useCss, k } from "kremling";
import { CpButton, CpCard } from "canopy-styleguide!sofe";

export function AssistantQuestion({
  children,
  question,
  resumeBanner,
  hideResumeBanner,
  submitAnswer,
  questionSlug,
  answersInMemory,
  updateAnswersInMemory,
  answerData,
  goBack,
  goForward,
}) {
  const scope = useCss(css);

  const handleAnswer = (answer) => {
    submitAnswer(questionSlug, answer);
  };

  if (!question) {
    return null;
  }

  const disableNext = () => {
    const answers = answersInMemory || answerData?.answers?.[questionSlug];
    if (!answers) {
      return true;
    } else if (typeof answers === "object") {
      const keys = Object.keys(answers);
      return !keys.some((key) => {
        return answers[key];
      });
    }
    return true;
  };

  return (
    <div {...scope}>
      {resumeBanner && (
        <div className="cp-assistant__resume-banner cps-flexible-focus cps-animate-fade">
          <span>
            Welcome back! Don't worry, we brought you right back where you were.
          </span>
          <a onClick={hideResumeBanner}>
            <i className="cps-pull-right cps-icon cps-icon-close cps-white cp-assistant__resume-banner__close"></i>
          </a>
        </div>
      )}

      <CpCard className="cp-assistant__card">
        {/*all the specific questions are rendered here*/}
        <div className="question-container">{children}</div>

        {question.type === "boolean" && (
          <div className="assistant-boolean">
            <CpButton
              btnType={
                question.answer === false || question.answer === undefined
                  ? "secondary"
                  : "primary"
              }
              onClick={() => handleAnswer(true)}
            >
              Yes
            </CpButton>
            <CpButton
              btnType={
                question.answer === true || question.answer === undefined
                  ? "secondary"
                  : "primary"
              }
              onClick={() => handleAnswer(false)}
            >
              No
            </CpButton>
          </div>
        )}

        <div className="cp-assistant__table cp-assistant__navbar">
          <div className="cp-assistant__table-row">
            <div
              className="cp-assistant__table-cell cp-assistant__navbox"
              style={{ textAlign: "left" }}
            >
              {goBack && (
                <CpButton
                  icon="caret-large-left"
                  onClick={goBack}
                  aria-label="Go back"
                />
              )}
            </div>
            <div className="cp-assistant__table-cell cp-assistant__progress-bar__container">
              <div className="cp-assistant__progress-bar cps-bg-gray-6">
                <div
                  id="percentageComplete"
                  className="cp-assistant__progress-bar cps-bg-color-primary"
                  style={{
                    top: 0,
                    left: 0,
                    width: `${question.percentage * 100}%`,
                  }}
                ></div>
              </div>
            </div>
            <div
              className="cp-assistant__table-cell cp-assistant__navbox"
              style={{ textAlign: "right" }}
            >
              {goForward && (
                <CpButton
                  icon="caret-large-right"
                  onClick={goForward}
                  aria-label="Go forward"
                />
              )}
              {question.type === "checkboxes" && (
                <CpButton
                  onClick={() => {
                    handleAnswer(answersInMemory);
                    updateAnswersInMemory(null);
                  }}
                  disabled={disableNext()}
                >
                  Next
                </CpButton>
              )}
            </div>
          </div>
        </div>
      </CpCard>
    </div>
  );
}

const css = k`
  .cp-assistant__resume-banner {
    height: 4.8rem;
    line-height: calc(4.8rem - 1.6rem - 1.6rem);
    padding: 1.6rem;
    color: white;
    background-color: var(--cp-color-app-primary);
    font-size: 1.6rem;
  }
  .cp-assistant__resume-banner__close {
    margin-top: -0.4rem;
  }

  .cp-assistant__card {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    min-height: 30rem;
    align-items: center;
  }

  .cp-assistant__answer-button--transitioning {
    transition: .2s color, .2s background-color;
  }

  .cp-assistant__modal__title {
    text-align: left;
  }

  .cp-assistant__modal__body {
    padding-bottom: 2.4rem;
    max-height: 50rem;
    overflow-x: hidden;
    text-align: left;
  }
  
  .assistant-boolean {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    padding: 3.2rem 0;
  }
  
  .question-container {
    width: 100%;
  }
`;
