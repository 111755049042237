import React from "react";
import { useParams, Link } from "react-router-dom";

import { renderInfoModal } from "../../common/render-info-modal.component";
import { Summary } from "../../common/summary.component";
import { useLeviesOutcomes } from "../levies-outcomes.helper";

export function LeviesPreventingLevy({ outcomes, urls }) {
  const params = useParams();
  const { preventingLevyAvailable, cantCalculateOutcomes } =
    useLeviesOutcomes(outcomes);
  return (
    <div>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {preventingLevyAvailable && (
                  <span className="cps-color-primary">
                    Preventing Levy available
                  </span>
                )}
                {!preventingLevyAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">Preventing Levy unlikely</span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {preventingLevyAvailable && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, your client may qualify for Prevention of Levy. Check
                  out the resources below for additional help.
                </div>
              )}
              {!preventingLevyAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, Prevention of Levy for your client is unlikely.
                  Sometimes it doesn't hurt to try though - check out the
                  resources below to learn more.
                </div>
              )}
              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the <Link to={urls.assistant}>Levies Assistant</Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          An IRS levy is a legal seizure of a taxpayer's assets to satisfy a tax
          debt. Levies are different from liens. A lien is a legal claim against
          property to secure payment of the tax debt, while a levy actually
          seizes the property to satisfy the tax debt.
        </p>
        <p>
          The IRS utilizes levies to collect delinquent tax liabilities when
          other administrative efforts have failed and the IRS deems it the next
          appropriate action.
        </p>
        <p>
          The IRS employs both single occurrence and continuous levies. An
          example of a single occurrence levy is the seizure of bank account
          funds. This levy is issued one time. When a single occurrence levy is
          issued, 45 - 60 days must pass before the IRS can issue another
          request for a levy.
        </p>
        <p>
          Seizing a portion of a taxpayer's social security benefits for as long
          as he or she receives the benefits is an example of a continuous levy.
        </p>
        <p>The IRS can usually levy only after three requirements are met:</p>

        <ol>
          <li>
            The IRS assessed the tax and sent a Notice and Demand for Payment (a
            tax bill);
            <br />
            <br />
          </li>
          <li>
            The taxpayer neglected or refused to pay the tax; and
            <br />
            <br />
          </li>
          <li>
            The IRS sent Letter 1058,{" "}
            <i>
              Final Notice of Intent to Levy and Notice of Your Right to A
              Hearing
            </i>
            , at least 30 days before the levy. However, there are exceptions
            when the IRS doesn't have to offer a hearing at least 30 days before
            seizing a taxpayer's property, such as when the collection of tax is
            in jeopardy or the IRS is levying a state tax refund. A hearing may
            be requested after the seizure in these examples.
          </li>
        </ol>

        <p>
          The purpose of the 1058 letter is to warn the taxpayer that failure to
          respond may result in imminent enforcement. It is legally sufficient
          to support subsequent collection action by levy regardless of the
          amount of time that has passed since its issuance. However, it is a
          common IRS administrative practice (but not a requirement) for the
          taxpayer to receive a new warning of enforcement action before a
          notice of levy is issued if there has been no other enforcement action
          or warning of enforcement for at least 180 days. No new warning of
          enforcement action may be given if the IRS believes special
          circumstances could apply or the collection is in jeopardy.
        </p>
        <p>
          When deemed appropriate, the IRS may levy almost any asset a taxpayer
          owns or has an interest in, including the following:
        </p>

        <ul>
          <li>Bank accounts</li>
          <li>Wages</li>
          <li>Accounts receivables</li>
          <li>Retirement accounts</li>
          <li>Cash value of life insurance policies</li>
          <li>Cars</li>
          <li>Boats</li>
          <li>Houses</li>
        </ul>

        <p>
          Some of these assets will require managerial, territory or area
          director approval to be levied. Also note that some property is{" "}
          <a onClick={exemptPropertyModal}>exempt from seizure</a> and may not
          be levied by the IRS.
        </p>
        <p>
          If your client has an unpaid tax bill, you can prevent a future levy
          by contacting the IRS and establishing a mutually satisfactory course
          of action, such as paying the overdue tax in full, establishing an{" "}
          <a onClick={installmentAgreementModal}>installment agreement</a>, or
          settling the debt via an{" "}
          <a onClick={offerInCompromiseModal}>offer in compromise</a>.
          Additionally, your client must remain current with all future paying &
          filing requirements and deadlines.
        </p>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>

              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/AttachmentToForm9423.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Attachment to Form 9423
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/AttachmentToForm12153.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Attachment to Form 12153
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Contact info</strong>
              </div>

              <div className="cps-card__body">
                <ul>
                  <li>
                    <strong>
                      <i>Form 9423</i>
                    </strong>
                    <br />
                    Never forward your request for an Appeals conference
                    directly to Appeals. It must be submitted to the office
                    which took the action on your installment agreement.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>
                      <i>Form 12153</i>
                    </strong>
                    <br />
                    Call the phone number on the notice or 1-800-829-1040 if you
                    are not sure about the correct address or if you want to fax
                    your request. File your request by mail at the address on
                    your lien notice or levy notice. You may also fax your
                    request. Do not send your CDP or equivalent hearing request
                    directly to the IRS Office of Appeals.
                  </li>
                </ul>
              </div>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p5.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 5 - Your appeal rights
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p594.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 594 - The IRS collection process
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p1660.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 1660 - Collection appeal rights
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
            </div>
          </div>

          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/V912/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 9423
                  </div>
                  Collection Appeal Request
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/CPK7D/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 12153
                  </div>
                  Request for a Collection Due Process or Equivalent Hearing
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/CF9RS/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 911
                  </div>
                  Request for a Taxpayer Advocate Service Assistance
                </div>
              </Link>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <ul>
                  <li>
                    Behind the filing of a Notice of Federal Tax Lien, levies
                    are the most commonly used method of enforced collection
                    action taken by the Service.
                  </li>
                  <li>
                    Keeping open lines of communication with the IRS is the best
                    way to prevent a levy. This is especially true if a taxpayer
                    is assigned a Revenue Officer.
                  </li>
                  <li>
                    If you are just beginning work with a taxpayer who has
                    property that is in the process of being seized, you can
                    contact the agent and ask for a managerial review, even if
                    the IRS is on its way to seize the asset. The IRS will
                    provide you with the opportunity to have your case and
                    information heard on behalf of the taxpayer.
                  </li>
                  <li>
                    Missing a stated deadline set by an assigned Revenue Officer
                    will almost always result in the issuance of a levy.
                  </li>
                  <li>
                    If a taxpayer receives a Letter 1058 and you believe that
                    the potential levy should not be processed, you will need to
                    request an appeal hearing within the proper timeframe for a
                    regular hearing, which is within 30 days of the notice
                    mailing, or an equivalent hearing within one year. In this
                    hearing the IRS can determine if an alternative method of
                    collection should be considered, such as an Installment
                    Agreement, or whether it should move forward with the levy.
                  </li>
                  <li>
                    Once Letter 1058 has been issued and the required timeframe
                    has lapsed, levies can be issued at any time to any source
                    deemed appropriate by the assigned Revenue Officer. This can
                    include daily, weekly, monthly or sporadically issued
                    levies.
                  </li>
                  <li>
                    The general phone number to call the Taxpayer Advocate
                    Service is 1-877-777-4778. There is also an{" "}
                    <a
                      href="https://www.irs.gov/Advocate/Local-Taxpayer-Advocate"
                      target="_blank"
                    >
                      interactive map
                    </a>{" "}
                    to help you find the contact information of the closest TAS
                    office. Remember that the state to choose is based on the
                    state the taxpayer is located in, not where the
                    representative is located.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function exemptPropertyModal() {
  renderInfoModal(
    "Examples of property exempt from levy",
    <>
      <p>Examples of property exempt from levy:</p>
      <ul>
        <li>Unemployment benefits</li>
        <li>Certain annuity and pension benefits</li>
        <li>Certain disability payments</li>
        <li>Workers compensation</li>
        <li>Certain public assistance payments</li>
        <li>Minimum weekly exempt income</li>
        <li>Income for court-ordered child support payments</li>
        <li>Certain personal property and personal household effects</li>
      </ul>
    </>
  );
}

function installmentAgreementModal() {
  renderInfoModal(
    "Installment agreement",
    <>
      If a taxpayer is financially unable to pay his or her tax debt
      immediately, he or she may be able to make monthly payments through an
      installment agreement.
    </>
  );
}

function offerInCompromiseModal() {
  renderInfoModal(
    "Offer in Compromise",
    <p>
      An agreement between a taxpayer and the IRS that settles the taxpayer's
      tax liabilities for less than the full amount owed. Taxpayers who can
      fully pay the liabilities through an installment agreement or other means
      will generally not be eligible for an Offer in Compromise.
    </p>
  );
}
