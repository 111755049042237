import React, { useEffect, useState, useMemo } from "react";
import {
  CpButton,
  CpEmptyState,
  CpIcon,
  CpInput,
} from "canopy-styleguide!sofe";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DateTime } from "luxon";
import { trim } from "lodash";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import { successToast } from "toast-service!sofe";
import {
  deleteAttachmentForTenant,
  getAttachmentsForTenant,
} from "src/common/attachments.resource";
import { getFileIconPath } from "../sections/pages/workpapers/attachments.utils";
import { applicationProps } from "src/resolution-cases-ui";
import { useAttachmentDropzone } from "src/common/use-attachment-dropzone.hook";

export function SortableTemplateStep(props) {
  const [user, tenant] = useWithUserAndTenant();
  const {
    step,
    editingStep,
    onEditStep,
    onDeleteStep,
    onCancelEditStep,
    onUpdateStep,
  } = props;
  const [stepName, setStepName] = useState(step.name);
  const [stepDescription, setStepDescription] = useState(step.description);
  const [attachments, setAttachments] = useState();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id, disabled: !!editingStep });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const containers = useMemo(() => ({}), []);
  useAttachmentDropzone(
    {
      dropzoneId: `#workpaperFilesNew${step.id}`,
      clickableId: "#upload-a-workpaper-new",
      onUpload,
      targetPath: "tenants",
      targetId: tenant?.id,
      pivotType: "service_template_steps",
      pivotId: editingStep && step.id,
    },
    [editingStep]
  );

  useEffect(() => {
    if (!tenant) return;
    getAttachmentsForTenant(
      tenant.id,
      "service_template_steps",
      step.id
    ).subscribe((result) => {
      setAttachments(result);
    });
  }, [tenant]);

  function onUpload(attachmentsResponse) {
    setAttachments((attachments) => [...attachments, attachmentsResponse]);
  }

  function onDownloadAttachment(attachment) {
    SystemJS.import("docs-ui!sofe").then((m) => {
      m.isFileSafeToOpen(attachment.file_id).then(
        ({ trust, virusStatuses, file }) => {
          if (trust) {
            window.location.href = file.download_link;
          } else {
            containers[attachment.id] = document.getElementById(
              `file-${attachment.id}`
            );
            const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
              virusFound: virusStatuses.infectedDocs.length > 0,
              domElement: containers[attachment.id],
              handleCloseModal() {
                parcel.unmount();
              },
            });
          }
        }
      );
    });
  }

  function onDeleteAttachment(attachment) {
    setAttachments(attachments.filter((a) => a.id !== attachment.id));
    const timeout = setTimeout(() => {
      deleteAttachmentForTenant(tenant.id, attachment.id).subscribe(() => {});
    }, 6000);

    successToast(
      "You have deleted a workpaper. Click undo if you did not mean to delete this workpaper.",
      "Undo",
      () => {
        clearTimeout(timeout);
        setAttachments(attachments);
        successToast("Workpaper restored successfully");
      },
      6000
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {!!editingStep && editingStep.id === step.id ? (
        <div className="cps-card +min-width-700 cp-mb-24 cp-mt-24">
          {/*Title and Description*/}
          <div className="cp-p-24">
            <CpInput
              id="step-name"
              onChange={setStepName}
              value={stepName}
              label="Step Title"
              required={true}
              className="cp-mb-16"
              maxLength={60}
              style={{ width: "40rem" }}
            />
            <CpInput
              id="step-description"
              onChange={setStepDescription}
              value={stepDescription}
              label="Step Description"
              required={true}
              maxLength={512}
              style={{ width: "40rem" }}
            />
          </div>
          {/*Template Workpapers*/}
          <div
            className="cps-card__header"
            style={{
              borderTop: "solid 1px var(--cps-color-border)",
              borderBottom: "solid 1px var(--cps-color-border)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "var(--cp-color-well-l3-bg)",
            }}
          >
            <strong>Workpapers</strong>
            {hasAccess(user, "filesPermissions") && (
              <CpButton btnType="flat" id="upload-a-workpaper-new">
                + Upload a workpaper
              </CpButton>
            )}
          </div>
          <div>
            <div id={`workpaperFilesNew${step.id}`}>
              {!attachments || attachments.length === 0 ? (
                <div
                  className="cps-slat +noclick cp-p-32"
                  style={{ height: "100%" }}
                >
                  <CpEmptyState
                    img="es_note"
                    text="No workpapers added"
                    subText="Please drop workpapers here"
                  />
                </div>
              ) : (
                attachments.map((attachment) => (
                  <div className="cps-slat" key={attachment.id}>
                    <div className="cps-slat__badge">
                      <img
                        width="20"
                        src={`${getFileIconPath(attachment.filename)}`}
                        className="cp-service-templates__attachment-type-icon"
                      />
                    </div>
                    <div className="cps-slat__content">
                      <div className="cps-slat__content__title">
                        {attachment.filename}
                      </div>
                      <div className="cps-slat__content__description">
                        {`${
                          attachment.created_at
                            ? `${DateTime.fromMillis(
                                attachment.created_at
                              ).toLocaleString(DateTime.DATE_MED)} `
                            : ""
                        }by ${attachment.relationships.uploaded_by.name}`}
                      </div>
                    </div>
                    <div className="cps-slat__actions +hover-show cps-btn-icon">
                      <CpButton
                        icon="af-open-down"
                        aria-label="Download"
                        onClick={() => onDownloadAttachment(attachment)}
                      />
                      <CpButton
                        icon="crud-trash-large"
                        aria-label="Delete"
                        onClick={() => onDeleteAttachment(attachment)}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div
            className="cps-card__footer cps-bg-gray-2"
            style={{ borderTop: "none" }}
          >
            <CpButton
              className="cp-mr-8"
              onClick={() => onUpdateStep(step, stepName, stepDescription)}
              disabled={!(trim(stepName) && trim(stepDescription))}
            >
              Save changes
            </CpButton>
            <CpButton btnType="tertiary" onClick={onCancelEditStep}>
              Cancel
            </CpButton>
            <CpButton
              btnType="tertiary"
              className="cps-red cps-pull-right"
              onClick={() => onDeleteStep(step)}
            >
              Delete step
            </CpButton>
          </div>
        </div>
      ) : (
        <div
          className={`cps-slat-lg cp-mb-8 ${
            step.is_removable ? "" : "+disabled"
          }`}
        >
          <CpIcon
            name="misc-drag-handle"
            className="cps-center-vertical cp-icon-drag"
          />
          <div className="cps-slat-lg__content">
            <div className="cps-slat-lg__content__title">{step.name}</div>
            {step.description}
          </div>
          <div className="cps-slat-lg__actions +hover-show cps-margin-top-4">
            {step.is_removable ? (
              <>
                <CpButton
                  icon="crud-pencil"
                  aria-label="Edit"
                  onClick={() => onEditStep(step)}
                />
                <CpButton
                  icon="crud-trash-large"
                  aria-label="Delete"
                  onClick={() => onDeleteStep(step)}
                />
              </>
            ) : (
              <CpIcon
                name="misc-padlock"
                fill="var(--cp-color-app-disabled-text)"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
