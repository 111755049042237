export function createBlankService(allPrograms, name, description) {
  const uid = generateServiceSlug(allPrograms);
  return {
    uid: uid,
    slug: uid,
    name,
    description,
    is_removable: true,
    sections: [],
    visible: true,
  };
}

export function generateServiceSlug(allServices) {
  let slug = 1;
  while (allServices.find((program) => program.uid === slug.toString())) {
    slug++;
  }
  return slug.toString();
}
