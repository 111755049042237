import React from "react";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function InnocentSpouseUnfair18() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Under all facts and circumstances, would it{" "}
        <a onClick={unfairModal}>not be fair</a> to include the item of
        community income in your client's gross income?
      </strong>
    </div>
  );
}

function unfairModal() {
  renderInfoModal(
    "Unfair",
    <>
      <p>
        The IRS will consider all of the facts and circumstances of the case in
        order to determine whether it is unfair to hold your client responsible
        for the understated tax due to the item of community income.
      </p>
      <p>The following are examples of factors the IRS will consider:</p>
      <ul>
        <li>
          Whether your client received a benefit, either directly or indirectly,
          from the omitted item of community income.
        </li>
        <li>
          Whether your client's spouse (or former spouse) deserted her or him.
        </li>
        <li>
          Whether your client and her or his spouse have been divorced or
          separated.
        </li>
        <li>
          Whether your client will suffer economic hardship if relief is not
          granted.
        </li>
        <li>
          Whether your client's spouse (or former spouse) has a legal obligation
          to pay the outstanding federal income tax liability.
        </li>
        <li>
          Whether your client has made a good faith effort to comply with income
          tax laws in subsequent years.
        </li>
        <li>Whether your client was in poor physical or mental health.</li>
      </ul>
    </>
  );
}
