import React from "react";

import { renderInfoModal } from "../../common/render-info-modal.component";

export function InnocentSpouseErroneousItems() {
  return (
    <div>
      <div className="cps-subheader-sm cp-text-center">
        <strong>
          Is the understatement of tax due to{" "}
          <a onClick={erroneousItemsModal}>erroneous items</a> of the client's
          spouse (or former spouse)?
        </strong>
      </div>
    </div>
  );
}

function erroneousItemsModal() {
  renderInfoModal(
    "Erroneous Items",
    <>
      <p>Erroneous items include the following:</p>
      <ul>
        <li>
          Unreported income – Any gross income item received by your spouse (or
          former spouse) that is not reported.
        </li>
        <li>
          Incorrect deduction, credit, or basis – Any improper deduction,
          credit, or property basis claimed by your spouse (or former spouse).
        </li>
      </ul>
    </>
  );
}
