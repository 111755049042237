// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-resolution-case-archive-archive-styles__archiveWrapper--3Ba9Y {\n  min-width: 70rem;\n  max-width: 136rem;\n  margin: 0 auto;\n}\n\n.src-resolution-case-archive-archive-styles__archiveBody--3BrBL {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 3.2rem;\n}\n", ""]);
// Exports
exports.locals = {
	"archiveWrapper": "src-resolution-case-archive-archive-styles__archiveWrapper--3Ba9Y",
	"archiveBody": "src-resolution-case-archive-archive-styles__archiveBody--3BrBL"
};
module.exports = exports;
