// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-common-date-picker-styles__datePickerButton--kZ-Ua {\n  position: relative;\n  padding: 0.4rem !important;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.src-common-date-picker-styles__datePickerImage--1_WzN {\n  width: 2.4rem;\n  height: 2.4rem;\n  margin-top: 0.8rem;\n}\n\n.src-common-date-picker-styles__datePickerDayNumber--3-96k {\n  position: absolute;\n  top: 1rem;\n  width: 2.4rem;\n  margin-left: auto;\n  margin-right: auto;\n  font-size: 12px;\n  color: #777777;\n  font-weight: 700;\n  line-height: 19px;\n}\n\n.src-common-date-picker-styles__datePickerMonthAbbreviation--35XWy {\n  position: absolute;\n  top: 0.3rem;\n  width: 2.4rem;\n  margin-left: auto;\n  margin-right: auto;\n  font-size: 7px;\n  color: #ffffff;\n  font-weight: 700;\n  line-height: 11px;\n  text-transform: uppercase;\n}\n", ""]);
// Exports
exports.locals = {
	"datePickerButton": "src-common-date-picker-styles__datePickerButton--kZ-Ua",
	"datePickerImage": "src-common-date-picker-styles__datePickerImage--1_WzN",
	"datePickerDayNumber": "src-common-date-picker-styles__datePickerDayNumber--3-96k",
	"datePickerMonthAbbreviation": "src-common-date-picker-styles__datePickerMonthAbbreviation--35XWy"
};
module.exports = exports;
