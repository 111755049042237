import React from "react";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";

import { penaltyAbatementConstants } from "./penalty-abatement.constants";
import { PenaltyAbatementCircumstance } from "./questions/penalty-abatement-circumstance.component";
import { PenaltyAbatementPenaltyReason } from "./questions/penalty-abatement-penalty-reason.component";
import { PenaltyAbatementPenaltyType } from "./questions/penalty-abatement-penalty-type.component";
import { PenaltyAbatementPayments } from "./questions/penalty-abatement-payments.component";
import { PenaltyAbatementCompliance } from "./questions/penalty-abatement-compliance.component";

export const PenaltyAbatementQuestionSwitch = ({
  question,
  answers,
  updateAnswersInMemory,
}) => {
  return (
    <CpSwitch expression={question?.questionSlug}>
      <CpCase value={penaltyAbatementConstants.CIRCUMSTANCE}>
        <PenaltyAbatementCircumstance
          updateAnswersInMemory={updateAnswersInMemory}
          answer={answers[penaltyAbatementConstants.CIRCUMSTANCE]}
        />
      </CpCase>
      <CpCase value={penaltyAbatementConstants.COMPLIANCE}>
        <PenaltyAbatementCompliance />
      </CpCase>
      <CpCase value={penaltyAbatementConstants.PAYMENTS}>
        <PenaltyAbatementPayments />
      </CpCase>
      <CpCase value={penaltyAbatementConstants.PENALTY_REASON}>
        <PenaltyAbatementPenaltyReason />
      </CpCase>
      <CpCase value={penaltyAbatementConstants.PENALTY_TYPE}>
        <PenaltyAbatementPenaltyType />
      </CpCase>
      <CpCase default>
        <div className="cps-subheader-sm cp-text-center">
          <strong>{question?.questionText}</strong>
        </div>
      </CpCase>
    </CpSwitch>
  );
};
