import React, { Children, createContext, useContext } from "react";
import { a } from "kremling";

const OutcomeHeaderContext = createContext({});

export function OutcomeHeader({ children, value, onChange }) {
  const hideIcons = Children.toArray(children).every((c) => !c.props.active);
  return (
    <OutcomeHeaderContext.Provider value={{ value, onChange, hideIcons }}>
      <div className="cps-card cps-card-nonrounded-bottom cps-card-bottom-line cps-bg-gray-4 cps-flexible-focus">
        <div>
          <ul className="cps-body-sm cp-outcomes__tabs cps-padding-left-0">
            {children}
          </ul>
        </div>
      </div>
    </OutcomeHeaderContext.Provider>
  );
}

export function OutcomeHeaderItem({ children, testIdAgainst, active, id }) {
  const { value, onChange, hideIcons } = useContext(OutcomeHeaderContext);
  const isActive = testIdAgainst ? testIdAgainst.includes(value) : value === id;
  return (
    <li
      className={a("cp-outcomes__tabs__item").t(
        "cp-outcomes__tabs__item--active",
        "cp-outcomes__tabs__item--inactive",
        isActive
      )}
      onClick={() => onChange(id)}
    >
      <i
        className={a(
          "cps-icon cps-icon-valid cp-outcomes__tabs__item__recommended"
        )
          .m("cp-outcomes__tabs__item__recommended--hidden", !active)
          .m("cp-outcomes__tabs__item__recommended--no-display", hideIcons)}
      ></i>
      <strong>{children}</strong>
    </li>
  );
}
