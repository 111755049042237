import React from "react";
import { Link } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { renderInfoModal } from "../../common/render-info-modal.component";
import { useInnocentSpouseOutcome } from "../innocent-spouse-outcomes.helper";

export function InnocentSpouseSeparationOfLiability({
  outcomes,
  urls,
  params,
}) {
  const { cantCalculateOutcomes, separationOfLiabilityAvailable } =
    useInnocentSpouseOutcome(outcomes);
  return (
    <div>
      <div>
        <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
          <div className="cps-card__body">
            <div className="cps-row cps-margin-top-8 cps-padding-left-8">
              <div className="cps-body">
                Recommendation:{" "}
                <span>
                  {separationOfLiabilityAvailable && (
                    <span className="cps-color-primary">
                      Relief by Separation of Liability available
                    </span>
                  )}
                  {!separationOfLiabilityAvailable &&
                    !cantCalculateOutcomes && (
                      <span className="cps-warning">
                        Relief by Separation of Liability unlikely
                      </span>
                    )}
                </span>
                {cantCalculateOutcomes && (
                  <span className="cps-light-gray">
                    Recommendation unavailable
                  </span>
                )}
              </div>
              <div>
                <div>
                  {separationOfLiabilityAvailable && (
                    <div>
                      Thank you for using the Innocent Spouse Assistant! Based
                      on your answers, your client may qualify for Relief by
                      Separation of Liability. Check out the resources below for
                      additional help.
                    </div>
                  )}
                  {!separationOfLiabilityAvailable &&
                    !cantCalculateOutcomes && (
                      <div>
                        Thank you for using the Innocent Spouse Assistant! Based
                        on your answers, Relief by Separation of Liability for
                        your client is unlikely. Sometimes it doesn't hurt to
                        try though - check out the resources below to learn
                        more.
                      </div>
                    )}
                </div>

                {cantCalculateOutcomes && (
                  <div>
                    We cannot provide a recommendation at this time. Please
                    complete the{" "}
                    <Link to={urls.assistant}>Innocent Spouse Assistant</Link>{" "}
                    if you would like custom recommendations for your client.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Summary>
          <p>
            Under this type of relief, the understated tax (plus interest and
            penalties) on a taxpayer's joint return is allocated between the
            taxpayer and her/his current or former spouse. The understated tax
            allocated to the taxpayer is generally the amount she/he is
            responsible for.
          </p>

          <p>
            This type of relief is available for liabilities resulting from
            understated tax. However, refunds are not allowed for any
            liabilities that have been paid.
          </p>

          <p>
            To request separation of liability relief, the taxpayer must have
            filed a joint return and meet either of the following requirements
            at the time Form 8857 is filed.
          </p>

          <ul>
            <li>
              The taxpayer is no longer married to (or is legally separated
              from) the spouse with whom the taxpayer filed the joint return for
              which she/he is requesting relief. (Under this rule, the taxpayer
              is no longer married if widowed.)
              <br />
              <br />
            </li>
            <li>
              The taxpayer was not a member of the{" "}
              <a onClick={sameHouseholdModal}>same household</a> as the spouse
              with whom the taxpayer filed the joint return at any time during
              the 12-month period ending on the date Form 8857 is filed.
            </li>
          </ul>

          <p>
            Even if the taxpayer meets these two requirements, separation of
            liability relief does not apply to any part of the understated tax
            due to the current or former spouse's erroneous items of which the
            taxpayer had <a onClick={actualKnowledgeModal}>actual knowledge</a>.
            The taxpayer and spouse (or former spouse) remain jointly and
            severally liable for this part of the understated tax. However, as
            compared to innocent spouse relief, the burden of proof is on the
            IRS (instead of the taxpayer), and the knowledge requirement is
            lower since "reason to know" is not one of the considerations.
          </p>

          <p>
            In addition, separation of liability relief is not available if the
            IRS proves that the taxpayer and spouse transferred assets to third
            parties as part of a fraudulent scheme.
          </p>

          <p>
            Finally, the taxpayer must also prove that she/he did not{" "}
            <a onClick={transferOfPropertyModal}>
              transfer property to avoid tax
            </a>{" "}
            and establish the basis for allocating the erroneous items.
          </p>
        </Summary>

        <div className="cps-fixed-masonry">
          <div className="cps-row">
            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Templates</strong>
                </div>
                <a
                  className="cps-slat"
                  href="https://cdn.canopytax.com/static/innocent_spouse/separation_of_liability/Letter_Separation_Of_Liability.docx"
                >
                  <div className="cps-slat__badge cps-padding-left-24">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                      className="cp-outcomes__file-icon"
                    />
                  </div>
                  <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                    Letter - Separation of liability
                  </div>
                </a>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Contact info</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>
                      <i>If using the U.S. Postal Service</i>
                    </strong>
                    <br />
                    Internal Revenue Service
                    <br />
                    P.O. Box 120053
                    <br />
                    Covington, KY 41012
                  </p>
                  <p>
                    <strong>
                      <i>If using a private delivery service</i>
                    </strong>
                    <br />
                    Internal Revenue Service
                    <br />
                    201 W. Rivercenter Blvd., Stop 840F
                    <br />
                    Covington, KY 41011
                  </p>
                  <p>
                    <strong>
                      <i>If submitting via fax</i>
                    </strong>
                    <br />
                    Fax number: 855-233-8558
                  </p>
                </div>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Links and articles</strong>
                </div>

                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/publications/p971/ar02.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 971 - Innocent spouse relief
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/taxtopics/tc205.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Topic 205 - Innocent spouse relief (including separation
                        of liability and equitable relief)
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
              </div>
            </div>

            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>IRS forms</strong>
                </div>

                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/DQ4V/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 12509
                    </div>
                    Statement of Disagreement
                  </div>
                </Link>
                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/YQVL/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 8379
                    </div>
                    Injured Spouse Allocation
                  </div>
                </Link>
                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/9F4Q/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 8857
                    </div>
                    Request for Innocent Spouse Relief
                  </div>
                </Link>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Best practices</strong>
                </div>

                <div className="cps-card__body">
                  <ul>
                    <li>
                      <strong>Conflicts of interest</strong> - Beware of
                      potential conflicts of interest which arise when you
                      represent both parties. You may not be able to represent
                      either if you originally prepared the return in question.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Letter to former or current spouse</strong> - Be
                      sure your client is aware the IRS will send a letter to
                      the former or current spouse asking them to verify
                      statements of the taxpayer seeking relief.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Offer in Compromise considerations</strong> - To
                      qualify for separation of liability relief, you must not
                      be involved in an Offer in Compromise payment program with
                      the IRS. The Offer in Compromise program legally closes
                      the tax year that any of your unpaid taxes are applied to.
                      Taxes cannot be re-determined once that happens.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function sameHouseholdModal() {
  renderInfoModal(
    "Same Household",
    <>
      <p>
        The taxpayer and spouse are not members of the same household if they
        are living apart and are estranged. However, the taxpayer and spouse are
        considered members of the same household if any of the following
        conditions are met.
      </p>

      <ol>
        <li>
          The taxpayer and spouse reside in the same dwelling.
          <br />
          <br />
        </li>
        <li>
          The taxpayer and spouse reside in separate dwellings but are not
          estranged, and one is temporarily absent from the other's household as
          explained in (3) below.
          <br />
          <br />
        </li>
        <li>
          Either spouse is temporarily absent from the household and it is
          reasonable to assume that the absent spouse will return to the
          household, and the household or a substantially equivalent household
          is maintained in anticipation of the absent spouse's return. Examples
          of temporary absences include absence due to imprisonment, illness,
          business, vacation, military service, or education.
          <br />
          <br />
        </li>
      </ol>
    </>
  );
}

function actualKnowledgeModal() {
  renderInfoModal(
    "Actual Knowledge",
    <>
      <p>The taxpayer had actual knowledge of an erroneous item if:</p>

      <ul>
        <li>
          She/he knew an item of unreported income was received, no matter if
          received in cash or other forms.
          <br />
        </li>
        <li>
          She/he knew of the facts that made an incorrect deduction or credit
          unallowable.
          <br />
        </li>
        <li>
          For a false or inflated deduction, she/he knew that the expense was
          not incurred, or not incurred to the extent shown on the tax return.
        </li>
      </ul>

      <p>
        Knowledge of the source of an erroneous item is not sufficient to
        establish actual knowledge. Also, the taxpayer's actual knowledge may
        not be inferred when she/he merely had a reason to know of the erroneous
        item. Similarly, the IRS does not have to establish that the taxpayer
        knew of the source of an erroneous item in order to establish that
        she/he had actual knowledge of the item itself.
      </p>

      <p>
        The taxpayer's actual knowledge of the proper tax treatment of an
        erroneous item is not relevant for purposes of demonstrating actual
        knowledge of that item. Neither is the taxpayer's actual knowledge of
        how the erroneous item was treated on the tax return. For example, if
        the taxpayer knew that her/his spouse received dividend income, relief
        is not available for that income even if the taxpayer did not know it
        was taxable.
      </p>

      <p>
        <i>Example</i>
      </p>

      <p>
        Bill and Karen Green filed a joint return showing Karen's wages of
        $50,000 and Bill's self-employment income of $10,000. The IRS audited
        their return and found that Bill did not report $20,000 of
        self-employment income. The additional income resulted in a $6,000
        understated tax, plus interest and penalties. After obtaining a legal
        separation from Bill, Karen filed Form 8857 to request separation of
        liability relief. The IRS proved that Karen actually knew about the
        $20,000 of additional income at the time she signed the joint return.
        Bill is liable for all of the understated tax, interest, and penalties
        because all of it was due to his unreported income. Karen is also liable
        for the understated tax, interest, and penalties due to the $20,000 of
        unreported income because she actually knew of the item. The IRS can
        collect the entire $6,000 plus interest and penalties from either Karen
        or Bill because they are jointly and individually liable for it.
      </p>

      <p>
        <i>Factors supporting actual knowledge</i>
      </p>

      <p>
        The IRS may rely on all facts and circumstances in determining whether a
        taxpayer actually knew of an erroneous item at the time the return was
        signed. The following are examples of factors the IRS may use:
      </p>

      <ul>
        <li>
          Whether the taxpayer made a deliberate effort to avoid learning about
          the item in order to be shielded from liability.
          <br />
        </li>
        <li>
          Whether the taxpayer and the taxpayer's current or former spouse
          jointly owned the property that resulted in the erroneous item.
        </li>
      </ul>

      <p>
        The taxpayer will not be considered to have had an ownership interest in
        an item based solely on the operation of community property law. Rather,
        if the taxpayer resided in a community property state at the time the
        return was signed, she/he will be considered to have had an ownership
        interest in an item only if the taxpayer's name appeared on the
        ownership documents, or there otherwise is an indication that the
        taxpayer asserted dominion and control over the item.
      </p>

      <p>
        <i>Example</i>
      </p>

      <p>
        Harry and Wanda live in Arizona, a community property state. After their
        marriage, Harry opens a bank account in his name. Under the operation of
        the community property state laws of Arizona, Wanda owns ½ of the bank
        account. However, Wanda does not have an ownership interest in the
        account for purposes of demonstrating that Wanda had actual knowledge of
        an erroneous item because the account is not held in her name and there
        is no other indication that she asserted dominion and control over the
        item.
      </p>

      <p>
        <i>Exception for spousal abuse or domestic violence</i>
      </p>

      <p>
        Even if the taxpayer had actual knowledge, she/he may still qualify for
        relief if the taxpayer establishes that:
      </p>

      <ol>
        <li>
          she/he was the victim of spousal abuse or domestic violence before
          signing the return, and
          <br />
        </li>
        <li>
          because of that abuse, the taxpayer did not challenge the treatment of
          any items on the return because she/he was afraid the current or
          former spouse would retaliate. If the taxpayer establishes that she/he
          signed the joint return under duress (threat of harm or other form of
          coercion), then it is not a joint return, and the taxpayer is not
          liable for any tax shown on that return or any tax deficiency for that
          return. However, the taxpayer may be required to file a separate
          return for that tax year.
          <br />
        </li>
      </ol>
    </>
  );
}

function transferOfPropertyModal() {
  renderInfoModal(
    "Transfer of Property to Avoid Tax",
    <>
      <p>
        If the taxpayer's current or former spouse transfers property (or the
        right to property) to the taxpayer for the main purpose of avoiding tax
        or payment of tax, the tax liability allocated to the taxpayer will be
        increased by the fair market value of the property on the date of the
        transfer; however, this increase may not be more than the entire amount
        of the liability. A transfer will be presumed to have as its main
        purpose the avoidance of tax or tax payment if the transfer is made one
        year before the IRS sent its first letter of proposed deficiency, or
        anytime after. This presumption will not apply if:
      </p>

      <ul>
        <li>
          The transfer was made under a divorce decree, separate maintenance
          agreement, or a written instrument incident to such an agreement, or
          <br />
          <br />
        </li>
        <li>
          The taxpayer establishes that the transfer did not have as its main
          purpose the avoidance of tax or payment of tax.
        </li>
      </ul>

      <p>
        If the presumption does not apply, but the IRS can establish that the
        purpose of the transfer was the avoidance of tax or payment of tax, the
        tax liability allocated to the taxpayer will be increased as explained
        above.
      </p>
    </>
  );
}
