import React from "react";
import { Switch, Route } from "react-router-dom";
import { NavContent } from "primary-navbar!sofe";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { ResolutionCaseTemplate } from "./resolution-case-template.component";
import { ServiceTemplate } from "./service-template.component";

export default function TemplateRoutes(props) {
  const [user] = useWithUserAndTenant();
  return (
    <NavContent hasTopnavSecondary={true} clientMenuPossible={false}>
      {user && (
        <Switch>
          <Route exact path={`${props.match.url}`}>
            <ResolutionCaseTemplate />
          </Route>
          <Route path={`${props.match.url}/:id`}>
            <ServiceTemplate {...props} />
          </Route>
        </Switch>
      )}
    </NavContent>
  );
}
