import React, { useEffect, useMemo, useState } from "react";
import { NavContent } from "primary-navbar!sofe";
import { isEmpty, sortBy } from "lodash";
import {
  CpCard,
  CpButton,
  CpLoader,
  CpEmptyState,
} from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { useParams, Link } from "react-router-dom";
import {
  archiveResolutionCase,
  deleteResolutionCase,
  getResolutionCasesList,
  restoreResolutionCase,
} from "../common/resolution-cases.resource";
import styles from "./resolution-cases-list.styles.css";
import { ResolutionCasesListItem } from "./resolution-cases-list-item.component";
import { catchSyncStacktrace } from "auto-trace";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

export function ResolutionCasesList({ listType }) {
  const [resolutionCases, setResolutionCases] = useState([]);
  const { clientId } = useParams();
  const sortedResolutionCases = useMemo(
    () => sortBy(resolutionCases, (e) => e.updated_at),
    [resolutionCases]
  );
  const [loading, setLoading] = useState(true);

  const [user, tenant] = useWithUserAndTenant();
  const ft_crm = tenant?.crm_status === "crm_hierarchy_complete" && featureEnabled("ft_crm");

  useEffect(() => {
    const sub = getResolutionCasesList(clientId, listType).subscribe(
      (resolutionCases) => {
        setResolutionCases(resolutionCases);
        setLoading(false);
      },
      (err) => {
        catchSyncStacktrace(err);
        setLoading(false);
      }
    );

    return () => sub.unsubscribe();
  }, [clientId]);

  function onArchive(resolutionCaseId) {
    archiveResolutionCase(clientId, resolutionCaseId).subscribe(
      (resolutionCase) => {
        let _resolutionCases = [...resolutionCases];
        _resolutionCases = _resolutionCases.filter(
          ({ id }) => resolutionCase.id !== id
        );
        setResolutionCases(_resolutionCases);
      }
    );
  }

  function onDelete(resolutionCaseId) {
    deleteResolutionCase(clientId, resolutionCaseId).subscribe(
      (resolutionCase) => {
        let _resolutionCases = [...resolutionCases];
        _resolutionCases = _resolutionCases.filter(
          ({ id }) => resolutionCase.id !== id
        );
        setResolutionCases(_resolutionCases);
      }
    );
  }

  function onMakeActive(resolutionCaseId) {
    restoreResolutionCase(clientId, resolutionCaseId).subscribe(
      (resolutionCase) => {
        let _resolutionCases = [...resolutionCases];
        _resolutionCases = _resolutionCases.filter(
          ({ id }) => resolutionCase.id !== id
        );
        setResolutionCases(_resolutionCases);
      }
    );
  }

  return (
    <NavContent
      className={ft_crm ? "" : "with-sidebar"}
      clientMenuPossible={true}
      hasTopnavSecondary={false}
    >
      <div className={styles.resolutionCasesListContainer}>
        <CpCard>
          {loading ? (
            <div className="cp-pt-32 cp-pb-32">
              <CpLoader size="lg" />
            </div>
          ) : (
            <>
              <CpCard.Header>
                <div className="cp-subheader">
                  {listType === "active" && "Resolution Cases"}
                  {listType === "archived" && "Archived Resolution Cases"}
                  {listType === "trash" && "Deleted Resolution Cases"}
                </div>
                <CpButton
                  anchor
                  btnType="flat"
                  href={`#/client/${clientId}/resolution-cases/create`}
                >
                  Create new resolution case
                </CpButton>
              </CpCard.Header>
              <div>
                {isEmpty(sortedResolutionCases) && !loading ? (
                  <div className="cp-pt-32 cp-pb-32">
                    {listType === "trash" && (
                      <CpEmptyState
                        img="es_delete"
                        text="The trash is empty"
                        subText="There is nothing in the trash, but if there were it would be here."
                      />
                    )}
                    {listType === "archived" && (
                      <CpEmptyState
                        img="es_project_archive"
                        text="The archive is empty"
                        subText="There is nothing in the archive, but if there were it would be here."
                      />
                    )}
                    {listType === "active" && (
                      <CpEmptyState
                        img="es_resolution_cases_list"
                        text="Add a resolution case"
                        subText="A resolution case allows you to bundle and invoice your work."
                        cta={
                          <CpButton
                            anchor
                            btnType="primary"
                            href={`#/client/${clientId}/resolution-cases/create`}
                          >
                            Add resolution case
                          </CpButton>
                        }
                      />
                    )}
                  </div>
                ) : (
                  sortedResolutionCases.map((resolutionCase) => (
                    <ResolutionCasesListItem
                      key={resolutionCase.id}
                      clientId={clientId}
                      resolutionCase={resolutionCase}
                      onArchive={() => onArchive(resolutionCase.id)}
                      onDelete={() => onDelete(resolutionCase.id)}
                      onMakeActive={() => onMakeActive(resolutionCase.id)}
                      listType={listType}
                    />
                  ))
                )}
              </div>
            </>
          )}
        </CpCard>
        <div className={styles.resolutionCasesListNav}>
          <Link
            className={styles.resolutionCasesListNavLink}
            to={`/client/${clientId}/resolution-cases/list/active`}
          >
            Active
          </Link>
          <Link
            className={styles.resolutionCasesListNavLink}
            to={`/client/${clientId}/resolution-cases/list/archived`}
          >
            Archived
          </Link>
          <Link
            className={styles.resolutionCasesListNavLink}
            to={`/client/${clientId}/resolution-cases/list/trash`}
          >
            Deleted
          </Link>
        </div>
      </div>
    </NavContent>
  );
}
