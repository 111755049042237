import React from "react";

export default class MenuItem extends React.PureComponent {
  handleClick = (evt) => {
    this.props.menuItem.click();
  };

  render() {
    return (
      <li onClick={this.handleClick}>
        <a className="cps-color-primary-text">{this.props.menuItem.title}</a>
      </li>
    );
  }
}
