import { keys, reduce } from "lodash";
import FilterDropdown from "./filter-dropdown";

export function getNavButtons(component) {
  return [
    {
      text: "Move to active",
      hideExpression: () =>
        keys(component.state.selectedResolutionCases).length < 1 ||
        component.state.showing === "active",
      click: component.activate,
    },
    {
      icon: "trash",
      hideExpression: () =>
        keys(component.state.selectedResolutionCases).length < 1 ||
        component.state.showing === "deleted",
      hover: "Delete selected resolution case(s)",
      click: component.delete,
    },
    {
      icon: "archive",
      hideExpression: () =>
        keys(component.state.selectedResolutionCases).length < 1 ||
        component.state.showing === "archived" ||
        component.state.showing === "deleted",
      hover: "Archive selected resolution case(s)",
      click: component.archive,
    },
    {
      component: FilterDropdown,
      props: {
        config: {
          icon: "filter",
        },
        selectItem: component.filterSelect,
      },
    },
    {
      icon: "more",
      menu: [
        {
          title: "Active Resolution Cases",
          click: () => component.setShowingThenFetch("active"),
          hideExpression: () => component.state.showing === "active",
        },
        {
          title: "Archived Resolution Cases",
          click: () => component.setShowingThenFetch("archived"),
          hideExpression: () => component.state.showing === "archived",
        },
        {
          title: "Deleted Resolution Cases",
          click: () => component.setShowingThenFetch("deleted"),
          hideExpression: () => component.state.showing === "deleted",
        },
      ],
    },
  ];
}

export function getSelectAllResolutionCasesObject(resolutionCases) {
  return reduce(
    resolutionCases,
    (result, value) => {
      result[value.id] = true;
      return result;
    },
    {}
  );
}

export function getColumnSortString(columnSortObj) {
  return `${columnSortObj.ascending ? "" : "-"}${
    columnSortMap[columnSortObj.columnTitle] || columnSortObj.columnTitle
  }`;
}

export const taxResServiceSlugs = {
  collections: true,
  innocent_spouse: true,
  liens: true,
  levies: true,
  trust_fund_recovery: true,
  penalty_abatement: true,
};

export const columnSortMap = {
  "Resolution Case Name": "name",
  "Engagement Name": "name",
  Client: "relationships.for.name",
  "In Progress": "steps.in_progress.count",
  "In Review": "steps.needs_review.count",
  Completed: "steps.complete.count",
  "Last Editor": "updated_by",
  "Days Open": "days_open",
  "Due Date": "due_date",
};
