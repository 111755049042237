export const innocentSpouseConstants = {
  // Outcomes
  INNOCENT_SPOUSE: "INNOCENT_SPOUSE",
  SEPARATION_OF_LIABILITY: "SEPARATION_OF_LIABILITY",
  COMMUNITY_PROPERTY: "COMMUNITY_PROPERTY",
  EQUITABLE_RELIEF: "EQUITABLE_RELIEF",

  RELIEF_UNLIKELY: "RELIEF_UNLIKELY",
  OUTCOME_NOT_YET_CALCULABLE: null,

  // Question slugs
  INTRO: "intro", //0
  JOINT_RETURN: "joint_return", //1
  MULTI_SELECT: "multi_select", //2
  UNDERSTATEMENT: "understatement", //2a
  UNDERPAYMENT: "underpayment", //2b
  NEITHER_UNDERSTATEMENT_OR_UNDERPAYMENT: "neither", //2c
  ERRONEOUS_ITEMS: "erroneous_items", //3
  KNOW_UNDERSTATEMENT: "know_understatement", //4
  SEPARATED: "separated", //5
  PAID_TAX: "paid_tax", //6
  UNFAIR_7: "unfair_7", //7
  TRANSFER_TO_SPOUSE: "transfer_to_spouse", //8
  TRANSFER_TO_CLIENT: "transfer_to_client", //9
  FRAUD_INTENT: "fraud_intent", //10
  SPOUSE_ITEM: "spouse_item", //11
  SAME_HOUSEHOLD: "same_household", //12
  UNFAIR_13: "unfair_13", //13
  COMMUNITY_PROPERTY_STATE: "community_property_state", //14
  INCLUDE_IN_GROSS_INCOME: "include_in_gross_income", //15
  COMMUNITY_INCOME_RELATED: "community_income_related", //16
  KNOW_COMMUNITY_INCOME: "know_community_income", //17
  UNFAIR_18: "unfair_18", //18
};
