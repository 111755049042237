import React, { useEffect, useState } from "react";
import { trim } from "lodash";
import { CpButton, CpModal, CpInput } from "canopy-styleguide!sofe";

export function CreateStepModal({ show, onClose, onSubmit, saving }) {
  const [stepName, setStepName] = useState("");
  const [stepDescription, setStepDescription] = useState("");

  useEffect(() => {
    if (!show) return;
    setStepName("");
    setStepDescription("");
  }, [show]);

  return (
    <CpModal show={show} onClose={onClose}>
      <CpModal.Header title="Add a Step to this Service" />
      <CpModal.Body>
        <CpInput
          id="step-name"
          onChange={setStepName}
          value={stepName}
          label="Step Title"
          required={true}
          className="cp-mb-16"
          maxLength={38}
        />
        <CpInput
          id="step-description"
          onChange={setStepDescription}
          value={stepDescription}
          label="Step Description"
          required={true}
          maxLength={512}
        />
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          onClick={() => onSubmit(stepName, stepDescription)}
          btnType="primary"
          disabled={!(trim(stepName) && trim(stepDescription))}
          showLoader={saving}
        >
          Add step
        </CpButton>
        <CpButton className="cp-ml-8" btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
