import React from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { CpIcon } from "canopy-styleguide!sofe";
import styles from "./resolution-case-summary.styles.css";

export function ActivityListItem({
  activity,
  clientId,
  clientInactive,
  resolutionCaseId,
  isDeleted,
}) {
  const {
    date,
    icon,
    name,
    performed_by_name,
    link_type,
    no_link,
    value = {},
    program,
    section,
  } = activity;
  const luxonDate = DateTime.fromISO(date);
  return (
    <div className={styles.activityListItem}>
      <div
        className={`${styles.centerVertical} ${styles.flexEllipsis} cp-mr-16`}
      >
        <div className={styles.activityIcon}>
          <CpIcon name={icon} />
        </div>
        <div>
          <span>{performed_by_name} </span>
          {link_type !== "client_requests" && <span>{name} </span>}

          {/* Link to client requests */}
          {link_type === "client_requests" && (
            <span>
              {no_link ? (
                <span> {name}</span>
              ) : (
                <Link to={`client_requests`}> {name}</Link>
              )}
            </span>
          )}

          {!!value && (
            <>
              {/* Links to Step (Step created, survey sent, workpaper uploaded) */}
              {link_type === "step" && !isDeleted && value.name && (
                <span>
                  <i>
                    {no_link ? (
                      <span> {value.name}</span>
                    ) : (
                      <Link
                        to={`program/${program.slug}/section/${section.slug}`}
                      >
                        {" "}
                        {value.name}
                      </Link>
                    )}
                  </i>
                </span>
              )}

              {/* Link directly to an edited survey */}
              {link_type === "survey" && (
                <span>
                  <i>
                    {no_link ? (
                      <span> {value.name}</span>
                    ) : (
                      <Link
                        to={`/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/survey`}
                      >
                        {" "}
                        {value.name}
                      </Link>
                    )}
                  </i>
                </span>
              )}

              {/* Link to Manage Services */}
              {link_type === "services" && !!value.name && (
                <span>
                  <i>
                    {no_link ? (
                      <span> {value.name}</span>
                    ) : (
                      <Link to={`services`}> {value.name}</Link>
                    )}
                  </i>
                </span>
              )}

              {/* Link to Client Request */}
              {link_type === "client_request" && !isDeleted && value.title && (
                <span>
                  <i>
                    {no_link ? (
                      <span> {value.title}</span>
                    ) : (
                      <Link to={`client_requests/${value.requestId}`}>
                        {" "}
                        {value.title}
                      </Link>
                    )}
                  </i>
                </span>
              )}

              {/* Link to Resolution Case Letter */}
              {link_type === "resolution_case_letter" &&
                !isDeleted &&
                value.title && (
                  <span>
                    <i>
                      {no_link ? (
                        <span> {value.title}</span>
                      ) : (
                        <Link
                          to={`letter-generator/${value.id}`}
                          style={{
                            pointerEvents: clientInactive ? "none" : "",
                            color:
                              clientInactive &&
                              "var(--cp-color-app-inactive-text)",
                          }}
                        >
                          {" "}
                          {value.title}
                        </Link>
                      )}
                    </i>
                  </span>
                )}

              {/* Text without a link */}
              {(!link_type || isDeleted) && (value.name || value.title) && (
                <span>
                  <i> {value.name || value.title}</i>
                </span>
              )}

              {/* Step, Service, or Letter being renamed */}
              {name.includes("renamed") && (
                <span>
                  <i> {value.old_name}</i> to
                  {(!link_type || isDeleted) && value.new_name && (
                    <span>
                      <i> {value.new_name}</i>
                    </span>
                  )}
                  {/* Step or service being renamed */}
                  {link_type === "step" && !isDeleted && value.new_name && (
                    <span>
                      <i>
                        {no_link ? (
                          <span> {value.new_name}</span>
                        ) : (
                          <Link
                            to={`program/${program.slug}/section/${section.slug}`}
                          >
                            {" "}
                            {value.new_name}
                          </Link>
                        )}
                      </i>
                    </span>
                  )}
                  {/* Letter being renamed */}
                  {link_type === "resolution_case_letter" && !isDeleted && (
                    <span>
                      <i>
                        {no_link ? (
                          <span> {value.new_name}</span>
                        ) : (
                          <Link to={`letter-generator/${value.id}`}>
                            {" "}
                            {value.new_name}
                          </Link>
                        )}
                      </i>
                    </span>
                  )}
                </span>
              )}

              {/* Due date changed */}
              {name.includes("changed the resolution case due date") && (
                <span> {DateTime.fromISO(value).toFormat("D")} </span>
              )}
              {/* section to which a step was added (Currently not displaying this) */}
              {/*{name.includes('added the step') &&*/}
              {/*  <span> to {value.parent_service_name} </span>*/}
              {/*}*/}
              {/* new status for updated steps */}
              {name.includes("changed the status") && (
                <span> to {value.status} </span>
              )}
              {/* recipient email for sent surveys */}
              {!!value &&
                (!!value.surveyId || !!value.requestId) &&
                !!value.recipient && <span> to {value.recipient} </span>}
              {/* letter sent to client files */}
              {name.includes("sent the letter") && (
                <span> to client files</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.centerVertical}>
        <span className="cp-mr-12">{luxonDate.toFormat("D")}</span>
        <span>{luxonDate.toFormat("t")}</span>
      </div>
    </div>
  );
}
