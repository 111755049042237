import React, { useState } from "react";
import { CpTooltip, CpRadio } from "canopy-styleguide!sofe";
import { innocentSpouseConstants } from "../innocent-spouse.constants";

const underStatementOfTax =
  "An understated tax is when the IRS determines that the total tax should be more than the amount that was actually shown on your return.";

export function InnocentSpouseMultiSelect({ submitAnswer, answer: _answer }) {
  const [answer, setAnswer] = useState(_answer);
  const [disabled, setDisabled] = useState(false);

  function updateAnswer(a) {
    setAnswer(a);
    setDisabled(true);
    submitAnswer(innocentSpouseConstants.MULTI_SELECT, a);
  }

  return (
    <div>
      <div className="cps-subheader-sm cp-text-center">
        <strong>
          Does the joint return have an{" "}
          <CpTooltip text={underStatementOfTax}>
            <a>understatement of tax</a>
          </CpTooltip>{" "}
          or an underpayment of tax?
        </strong>
      </div>

      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <div className="cp-pt-24">
            <CpRadio
              onChange={updateAnswer}
              value={answer}
              name="multiSelect"
              disabled={disabled}
            >
              <CpRadio.Item id="understatement">Understatement</CpRadio.Item>
              <CpRadio.Item id="underpayment">Underpayment</CpRadio.Item>
              <CpRadio.Item id="neither">Neither</CpRadio.Item>
            </CpRadio>
          </div>
        </div>
      </div>
    </div>
  );
}
