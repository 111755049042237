import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";

export function InnocentSpouseCommunityIncomeRelated() {
  return (
    <div>
      <div className="cps-subheader-sm cp-text-center cp-mb-16">
        <strong>
          Was the item of community income related to any of the following?
        </strong>
      </div>

      <div style={{ textAlign: "left" }}>
        <ul>
          <li>
            Wages, salaries, and other compensation the client's spouse (or
            former spouse) received for services he or she performed as an
            employee.
          </li>
          <li>
            Income the client's spouse (or former spouse) derived from a trade
            or business he or she operated as a sole proprietor.
          </li>
          <li>
            Income the client's spouse (or former spouse) received as a
            distributive share of partnership income.
          </li>
          <li>
            Income the client's spouse (or former spouse) received from{" "}
            <CpTooltip text="Use the appropriate community property law to determine what is separate property.">
              <a>separate property</a>
            </CpTooltip>{" "}
            (other than income described in income described above).
          </li>
          <li>
            Any other income that belongs to the client's spouse (or former
            spouse) under community property law.
          </li>
        </ul>
      </div>
    </div>
  );
}
