import React, { useState } from "react";
import { CpCard, CpButton } from "canopy-styleguide!sofe";
import { NavContent } from "primary-navbar!sofe";
import styles from "./archive.styles.css";
import {
  archiveResolutionCase,
  restoreResolutionCase,
} from "src/common/resolution-cases.resource";
import { catchAsyncStacktrace } from "auto-trace";
import { successToast } from "toast-service!sofe";

export function Archive({ resolutionCase, clientId }) {
  const [isArchived, setIsArchived] = useState(resolutionCase.is_archived);

  function handleArchiveResolutionCase() {
    archiveResolutionCase(clientId, resolutionCase.id).subscribe(() => {
      setIsArchived(true);
      successToast("Resolution case successfully locked");
    }, catchAsyncStacktrace());
  }

  function handleUnarchiveResolutionCase() {
    restoreResolutionCase(clientId, resolutionCase.id).subscribe(() => {
      setIsArchived(false);
      successToast("Resolution case successfully unlocked");
    }, catchAsyncStacktrace());
  }

  return (
    <NavContent
      hasTopnavSecondary={false}
      clientMenuPossible={true}
      className="wide-menu"
    >
      <div className={styles.archiveWrapper}>
        <CpCard>
          <CpCard.Header>
            <div className="cp-subheader">Lock & Archive</div>
          </CpCard.Header>
          <div className={styles.archiveBody}>
            <p>
              {isArchived ? (
                <CpButton
                  btnType="flat"
                  onClick={handleUnarchiveResolutionCase}
                >
                  Click to unarchive and make changes to this resolution case
                </CpButton>
              ) : (
                <CpButton btnType="flat" onClick={handleArchiveResolutionCase}>
                  Click to archive and prevent changes to this resolution case
                </CpButton>
              )}
            </p>
            {!isArchived && (
              <p className="cps-text-center">
                By locking the resolution case, you archive this resolution case
                <br />
                and prevent others from being able to change resolution case
                values.
              </p>
            )}
          </div>
        </CpCard>
      </div>
    </NavContent>
  );
}
