import { leviesConstants } from "./levies.constants";

// -----------------------------------------------------
// create all of the recognized states of the finite state automaton
// -----------------------------------------------------
const states = {};
const NA = "not_applicable";
const mostCommonOutcomes = [
  leviesConstants.RELEASING_LEVY,
  leviesConstants.APPEALS,
];

states.INITIAL_STATE = createBinaryState(
  leviesConstants.INTRO,
  "Y_ISSUED_NOTICE",
  "Y_ISSUED_NOTICE"
);

states.Y_ISSUED_NOTICE = createBinaryState(
  leviesConstants.ISSUED_NOTICE,
  "YY_ALREADY_LEVIED",
  [leviesConstants.PREVENTING_LEVY]
);

states.YY_ALREADY_LEVIED = createBinaryState(
  leviesConstants.ALREADY_LEVIED,
  "YYY_LEVY_RELEASED",
  "YYN_PROVE_FINANCIAL_HARDSHIP"
);

states.YYY_LEVY_RELEASED = createBinaryState(
  leviesConstants.LEVY_RELEASED,
  [leviesConstants.RETURNING_PROPERTY, leviesConstants.APPEALS],
  "YYYN_PROVE_FINANCIAL_HARDSHIP"
);

states.YYN_PROVE_FINANCIAL_HARDSHIP = states.YYYN_PROVE_FINANCIAL_HARDSHIP =
  createBinaryState(
    leviesConstants.PROVE_FINANCIAL_HARDSHIP,
    mostCommonOutcomes,
    "YYNN_INCREASE_ABILITY_TO_PAY"
  );

states.YYNN_INCREASE_ABILITY_TO_PAY = createBinaryState(
  leviesConstants.INCREASE_ABILITY_TO_PAY,
  mostCommonOutcomes,
  "YYNNN_ISSUED_PREMATURELY"
);

states.YYNNN_ISSUED_PREMATURELY = createBinaryState(
  leviesConstants.ISSUED_PREMATURELY,
  mostCommonOutcomes,
  "YYNNNN_AGAINST_EXEMPT_PROPERTY"
);

states.YYNNNN_AGAINST_EXEMPT_PROPERTY = createBinaryState(
  leviesConstants.AGAINST_EXEMPT_PROPERTY,
  mostCommonOutcomes,
  "YYNNNNN_VALUE_GREATER_THAN_OWED"
);

states.YYNNNNN_VALUE_GREATER_THAN_OWED = createBinaryState(
  leviesConstants.VALUE_GREATER_THAN_OWED,
  mostCommonOutcomes,
  "YYNNNNNN_OIC_OR_INSTALLMENT"
);

states.YYNNNNNN_OIC_OR_INSTALLMENT = createBinaryState(
  leviesConstants.OIC_OR_INSTALLMENT,
  mostCommonOutcomes,
  "YYNNNNNNN_RELEASE_FACILITATE"
);

states.YYNNNNNNN_RELEASE_FACILITATE = createBinaryState(
  leviesConstants.RELEASE_FACILITATE,
  mostCommonOutcomes,
  "YYNNNNNNNN_SATISFIED_LIABILITY"
);

states.YYNNNNNNNN_SATISFIED_LIABILITY = createBinaryState(
  leviesConstants.SATISFIED_LIABILITY,
  mostCommonOutcomes,
  "YYNNNNNNNNN_FILED_FOR_BANKRUPTCY"
);

states.YYNNNNNNNNN_FILED_FOR_BANKRUPTCY = createBinaryState(
  leviesConstants.FILED_FOR_BANKRUPTCY,
  mostCommonOutcomes,
  "YYNNNNNNNNNN_COLLECTION_PERIOD_EXPIRED"
);

states.YYNNNNNNNNNN_COLLECTION_PERIOD_EXPIRED = createBinaryState(
  leviesConstants.COLLECTION_PERIOD_EXPIRED,
  mostCommonOutcomes,
  "YYNNNNNNNNNNN_IDENTITY_THEFT"
);

states.YYNNNNNNNNNNN_IDENTITY_THEFT = createBinaryState(
  leviesConstants.IDENTITY_THEFT,
  mostCommonOutcomes,
  "YYNNNNNNNNNNNN_ISSUED_ERRONEOUSLY"
);

states.YYNNNNNNNNNNNN_ISSUED_ERRONEOUSLY = createBinaryState(
  leviesConstants.ISSUED_ERRONEOUSLY,
  mostCommonOutcomes,
  "YYNNNNNNNNNNNNN_ISSUED_WRONGFULLY"
);

states.YYNNNNNNNNNNNNN_ISSUED_WRONGFULLY = createBinaryState(
  leviesConstants.ISSUED_WRONGFULLY,
  mostCommonOutcomes,
  "YYNNNNNNNNNNNNNN_ABILITY_TO_PAY"
);

states.YYNNNNNNNNNNNNNN_ABILITY_TO_PAY = createBinaryState(
  leviesConstants.ABILITY_TO_PAY,
  mostCommonOutcomes,
  "YYNNNNNNNNNNNNNNN_ABLE_TO_SATISFY"
);

states.YYNNNNNNNNNNNNNNN_ABLE_TO_SATISFY = createBinaryState(
  leviesConstants.ABLE_TO_SATISFY,
  mostCommonOutcomes,
  [leviesConstants.APPEALS]
);

function createBinaryState(questionSlug, stateIfYes, stateIfNo) {
  const result = function (value, previousQuestionSlug) {
    let nextPlaceToGo;
    if (value === true) nextPlaceToGo = stateIfYes;
    else if (value === false) nextPlaceToGo = stateIfNo;
    else
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (typeof nextPlaceToGo === "string") {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(
        `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`
      );
    }
  };

  result.questionSlug = questionSlug;
  return result;
}

export function leviesStateMachine(answers = {}, questionToStopAt) {
  const initialState = {
    reachedOutcome: false,
    outcomes: leviesConstants.OUTCOME_NOT_YET_CALCULABLE,
    nextState: states.INITIAL_STATE,
    nextQuestionSlug: leviesConstants.INTRO,
    currentQuestionSlug: null,
    previousQuestionSlug: null,
    nextQuestionIsOutcome: false,
  };
  answers = JSON.parse(JSON.stringify(answers));
  if (answers[leviesConstants.CIRCUMSTANCE]) {
    answers[leviesConstants.CIRCUMSTANCE] = !!Object.keys(
      answers[leviesConstants.CIRCUMSTANCE]
    ).find((key) =>
      key !== NA ? answers[leviesConstants.CIRCUMSTANCE][key] : false
    );
  }
  return traverse(answers, questionToStopAt, initialState);
}

function traverse(answers, questionToStopAt, currentState) {
  const newState = currentState.nextState(
    answers[currentState.nextQuestionSlug],
    currentState.currentQuestionSlug
  );
  if (currentState.nextQuestionSlug === questionToStopAt) {
    return createResultForNonOutcomeState(currentState, newState);
  } else {
    if (newState.reachedOutcome) {
      newState.previousQuestionSlug = newState.currentQuestionSlug;
      newState.currentQuestionSlug = null;
      return newState;
    } else if (!newState.nextState) {
      return newState;
    } else {
      return traverse(answers, questionToStopAt, newState);
    }
  }
}

function createResultForNonOutcomeState(state, newState) {
  return {
    reachedOutcome: false,
    outcomes: null,
    nextState: null,
    nextQuestionSlug: newState ? newState.nextQuestionSlug : null,
    nextQuestionIsOutcome: !!newState.reachedOutcome,
    currentQuestionSlug: state.nextQuestionSlug,
    previousQuestionSlug: state.currentQuestionSlug,
  };
}
