import { useMemo } from "react";

import { liensConstants } from "./liens.constants";

export const useLiensOutcomes = (outcomes) => {
  return useMemo(
    () => ({
      cantCalculateOutcomes:
        outcomes === liensConstants.OUTCOME_NOT_YET_CALCULABLE ||
        !outcomes?.length,
      reliefUnlikely: !!(
        outcomes &&
        outcomes.length === 1 &&
        outcomes.indexOf(liensConstants.RELIEF_UNLIKELY) >= 0
      ),
      withdrawalAvailable: !!(
        outcomes && outcomes.indexOf(liensConstants.WITHDRAWAL) >= 0
      ),
      releaseAvailable: !!(
        outcomes && outcomes.indexOf(liensConstants.RELEASE) >= 0
      ),
      dischargeAvailable: !!(
        outcomes && outcomes.indexOf(liensConstants.DISCHARGE) >= 0
      ),
      appealAvailable: !!(
        outcomes && outcomes.indexOf(liensConstants.APPEAL) >= 0
      ),
      subordinationAvailable: !!(
        outcomes && outcomes.indexOf(liensConstants.SUBORDINATION) >= 0
      ),
    }),
    [JSON.stringify(outcomes)]
  );
};
