import { useEffect, useState } from "react";
import { forEach, get, isString } from "lodash";
import Dropzone from "dropzone";
import { catchAsyncStacktrace } from "auto-trace";
import { truncateFilename } from "docs-ui!sofe";

import { getFileIconPath } from "../resolution-case/sections/pages/workpapers/attachments.utils";
import {
  getS3AttachmentTargetEndpoint,
  reportFileUpload,
  saveAttachments,
} from "src/common/attachments.resource";

export function useAttachmentDropzone(
  {
    clickableId,
    disabled = false,
    dropzoneId,
    onUpload,
    pivotId,
    pivotType,
    targetId,
    targetPath,
  },
  deps = []
) {
  const [filesLoading, setFilesLoading] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dropzoneInstance, setDropzoneInstance] = useState(null);

  useEffect(() => {
    if (!pivotId || disabled) return;
    init();
  }, deps);

  async function init() {
    const dropzoneEl = document.querySelector(dropzoneId);
    if (!dropzoneEl) {
      //the route has changed already but we're still trying to init drop zone
      return;
    }
    if (dropzoneEl.dropzone) {
      //when the section changes but not the UI Router "state", the dropzone element from the old section already has dropzone attached
      delete dropzoneEl.dropzone;
    }

    await getS3AttachmentTargetEndpoint(
      targetPath === "client" ? `CON${targetId}}` : "TENANT"
    ).then((response) => {
      const fields = get(response, "fields", {});
      const s3URL = get(response, "url", "");
      const s3Prefix = fields.key.replace("${filename}", "");

      let dropzoneOptions = {
        url: s3URL,
        withCredentials: false,
        uploadMultiple: false,
        parallelUploads: 1,
        maxFilesize: 250,
        addRemoveLinks: false,
        dictDefaultMessage: "",
        dictFallbackText: null,
        dictFileTooBig: "This file exceeds the size limit of {{maxFilesize}}MB",
        clickable: clickableId ? [clickableId] : false,
        accept: function (file, done) {
          setFilesLoading((prevState) => [...prevState, file]);
          done();
        },
        previewTemplate: `
          <div id="container">
            <div class="cps-slat +noclick cp-workpapers__dropzone__preview">
              <div class="cp-workpapers__dropzone__preview__progress"></div>
              <div class="cps-slat__badge">
                <img src="https://cdn.canopytax.com/static/workflow-ui/file_icons/na_icon.png" style="width: 3rem; padding-left: 1rem;" id="cp-file-icon" />
              </div>
              <div class="cps-slat__content">
                <div class="cps-slat__content__title cp-file-upload__filename" data-dz-name></div>
                <span class="cp-file-upload__filesize" data-dz-size></span>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
              </div>
              <div class="cps-slat__actions">
                <div class="dz-success-mark"><i class="cps-icon cps-icon-check cps-primary-green"></i></div>
                <div class="dz-error-mark cps-btn-icon"><a href="" class="cps-link" data-dz-remove><span class="cps-icon cps-icon-close cps-red" style="top: 0;"></span></a></div>
              </div>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
            </div>
          </div>
        `,
        headers: { "Cache-Control": "" },
      };

      if (dropzoneInstance) {
        dropzoneInstance.destroy();
      }

      let dropzone = new Dropzone(dropzoneId, dropzoneOptions);
      setDropzoneInstance(dropzone);

      dropzone.on("sending", (file, xhr, formData) => {
        file.previewElement.querySelector(
          ".cp-workpapers__dropzone__preview__progress"
        ).style.width = "2%";
        file.previewElement.querySelector("#cp-file-icon").src =
          getFileIconPath(file.name);

        forEach(fields, (value, key) => {
          formData.append(key, value);
        });

        formData.set(
          "key",
          s3Prefix + `${get(file, "upload.uuid")}` + truncateFilename(file.name)
        );
      });

      dropzone.on("uploadprogress", (file, progress, bytesSent) => {
        file.previewElement.querySelector(
          ".cp-workpapers__dropzone__preview__progress"
        ).style.width = `${Math.floor(progress)}%`;
      });

      dropzone.on("complete", (file) => {
        if (file.status !== "error") {
          dropzone.removeFile(file);
        }

        const path_to_s3 =
          s3Prefix +
          `${get(file, "upload.uuid")}` +
          truncateFilename(file.name);

        reportFileUpload(
          targetPath === "client" ? `CON${targetId}}` : "TENANT",
          {
            description: "",
            filesize: file.size,
            hidden: false,
            inbox: false,
            is_visible: false,
            mimetype: file.type,
            name: truncateFilename(file.name),
            path_to_s3: path_to_s3,
          }
        )
          .then((file) => {
            const attachment = {
              file: {
                ...file,
                path_to_s3,
              },
            };
            saveAttachments(
              targetPath,
              targetId,
              pivotType,
              pivotId,
              attachment
            ).then((uploaded) => {
              setFilesLoading([]);
              const _uploadedFiles = [...uploadedFiles, uploaded[0]];
              setUploadedFiles(_uploadedFiles);
              onUpload(uploaded[0]);
            });
          })
          .catch(catchAsyncStacktrace());
      });

      dropzone.on("error", (file, message) => {
        let node, _i, _len, _ref, _results;

        file.previewElement.querySelector(
          ".cp-file-upload__filesize"
        ).style.display = "none";
        file.previewElement.querySelector(
          ".cp-file-upload__filename"
        ).style.display = "none";

        file.previewElement.classList.remove("dz-progress");
        file.previewElement.classList.add("dz-error");

        if (file.xhr) {
          message = `Your file ${file.name} failed to upload...`;
        }

        if (!isString(message) && message.error) {
          message = message.error;
        }

        _ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
        _results = [];

        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          node = _ref[_i];
          _results.push((node.textContent = message));
        }

        file.previewElement.querySelector(
          ".cp-workpapers__dropzone__preview__progress"
        ).style.opacity = 0;

        let previewEl = file.previewElement.querySelector(
          ".cp-workpapers__dropzone__preview"
        );
        previewEl.style.color = "#ff0000";
        previewEl.style.backgroundColor = "#feecea";
        previewEl.style.borderTop = "solid 1px #ECE1DF";
        previewEl.style.borderBottom = "solid 1px #ECE1DF";

        return _results;
      });
    });
  }

  return {
    filesLoading,
    uploadedFiles,
  };
}
