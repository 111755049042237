import React, { useEffect, useState } from "react";
import { NavContent } from "primary-navbar!sofe";
import {
  CpCard,
  CpButton,
  CpInput,
  CpRadio,
  CpTextarea,
  CprDatepicker,
  CpIcon,
  CpToggle,
  CpTooltip,
} from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import styles from "./resolution-cases-create.styles.css";
import { useParams, useHistory } from "react-router-dom";
import {
  getServiceTemplatesList,
  createResolutionCase,
  getClientData,
} from "../common/resolution-cases.resource";
import { catchAsyncStacktrace, catchSyncStacktrace } from "auto-trace";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import { taxResServiceSlugs } from "../common/resolution-cases.helpers";
import { generateServiceSlug } from "../resolution-case/services/services.utils";

export function ResolutionCasesCreate() {
  const { clientId } = useParams();
  const history = useHistory();
  const [serviceTemplates, setServiceTemplates] = useState([]);
  const [name, setName] = useState("");
  const [taxpayerType, setTaxpayerType] = useState("individual");
  const [description, setDescription] = useState("");
  const [creatingResolutionCase, setCreatingResolutionCase] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [user, tenant] = useWithUserAndTenant();
  const ft_crm = tenant?.crm_status === 'crm_hierarchy_complete' && featureEnabled("ft_crm");

  useEffect(() => {
    if (!clientId) return;
    const subscription = getClientData(clientId).subscribe(
      ({ is_business }) => {
        if (is_business) setTaxpayerType("business");
      },
      catchAsyncStacktrace()
    );
    return () => subscription.unsubscribe();
  }, [clientId]);

  useEffect(() => {
    if (!tenant) return;
    const subscription = getServiceTemplatesList(tenant.id).subscribe(
      (services) => {
        const _selectedServices = [];
        const _serviceTemplates = [...serviceTemplates];
        services.forEach((service) => {
          service.uid = generateServiceSlug(services);
          service.visible = service.slug === "collections";
          if (service.visible) {
            _selectedServices.push(service.uid);
          }
          delete service.id;
          _serviceTemplates.push(service);
        });
        setSelectedServices(_selectedServices);
        setServiceTemplates(_serviceTemplates);
      },
      catchAsyncStacktrace()
    );
    return () => subscription.unsubscribe();
  }, [tenant]);

  function shouldShowService(slug) {
    if (slug === "planning") return false;
    if (taxResServiceSlugs.includes(slug))
      return hasAccess(user)("tax_resolution_services");
    else return hasAccess(user)("custom_services");
  }

  function handleDateChange(e) {
    const date = new Date(e.detail);
    setDueDate(date.getTime());
  }

  function handleServiceToggle(toggledStatus, serviceId) {
    let _selectedServices = [...selectedServices];
    if (toggledStatus && !_selectedServices.includes(serviceId)) {
      _selectedServices.push(serviceId);
    } else {
      _selectedServices = _selectedServices.filter((id) => id !== serviceId);
    }
    setSelectedServices(_selectedServices);
  }

  function handleCreateResolutionCase() {
    setCreatingResolutionCase(true);
    const _serviceTemplates = serviceTemplates.map((service) => {
      return {
        ...service,
        ...(selectedServices.includes(service.uid) ? { visible: true } : {}),
        sections: [],
      };
    });

    let newResolutionCase = {
      name,
      description,
      taxpayer_type: taxpayerType,
      due_date: dueDate,
      program_data: {
        programs: _serviceTemplates,
      },
    };

    createResolutionCase(clientId, newResolutionCase).subscribe(
      ({ id }) => {
        history.push(
          `/taxes/client/${clientId}/resolution-cases/${id}/summary`
        );
      },
      (err) => {
        setCreatingResolutionCase(false);
        catchSyncStacktrace(err);
      }
    );
  }

  return (
    <NavContent
      className={ft_crm ? "" : "with-sidebar"}
      clientMenuPossible={true}
      hasTopnavSecondary={false}
    >
      <div className={styles.resolutionCaseDetailsContainer}>
        <CpCard>
          <CpCard.Header>
            <div className="cp-subheader">Resolution case details</div>
          </CpCard.Header>
          <CpCard.Body>
            <div className="cp-mb-24">
              <CpInput
                required
                value={name}
                onChange={setName}
                label="Resolution case name"
                placeholder="What should your resolution case be called?"
                maxLength={255}
              />
            </div>
            <div className="cp-mb-24">
              <label htmlFor="resolution-case-description" className="cp-label">
                Description
              </label>
              <CpTextarea
                className={styles.textArea}
                resize="vertical"
                height={56}
                value={description}
                onChange={setDescription}
                placeholder="Describe the purpose of your resolution case here..."
                id="resolution-case-description"
              />
            </div>
            <div className="cp-mb-24">
              <label className="cp-label">
                Is this resolution case for an individual taxpayer?
              </label>
              <CpRadio
                name="resolution-case-type"
                value={taxpayerType}
                onChange={setTaxpayerType}
              >
                <CpRadio.Item className="cp-mb-12" id="individual">
                  Yes, this is an individual taxpayer
                </CpRadio.Item>
                <CpRadio.Item id="business">
                  No, this is a business taxpayer
                </CpRadio.Item>
              </CpRadio>
            </div>
            <div className="cp-mb-24">
              <label htmlFor="resolution-case-due-date" className="cp-label">
                Due date
              </label>
              <div
                className={`${styles.datePickerWrapper} cps-has-feedback cps-has-feedback-left`}
              >
                <CpIcon className={styles.calendarIcon} name="misc-calendar" />
                <div className={styles.dateInput}>
                  <CprDatepicker
                    id="resolution-case-due-date"
                    date={dueDate}
                    events={{ datechange: handleDateChange }}
                    placeholder="No due date selected"
                    orientation="bottom"
                  />
                </div>
              </div>
            </div>
            <div className="cp-mb-24">
              <label className="cp-mb-16 cp-label">
                Which service(s) will you be offering in this resolution case?*
              </label>
              <div className="cp-mb-12">
                {serviceTemplates.map((serviceTemplate) => {
                  if (shouldShowService(serviceTemplate.slug)) {
                    return (
                      <div
                        key={serviceTemplate.uid}
                        className={`${styles.serviceListItem}`}
                      >
                        <div
                          className={`${styles.serviceItemTitle} ${styles.flexEllipsis}`}
                        >
                          <div>{serviceTemplate.name}</div>
                          <div className={styles.infoIcon}>
                            <CpTooltip text={serviceTemplate.description}>
                              <CpIcon
                                className="cp-color-app-icon"
                                name="information-circle-open-large"
                              />
                            </CpTooltip>
                          </div>
                        </div>
                        <div className={styles.serviceItemAction}>
                          <CpToggle
                            checked={selectedServices.includes(
                              serviceTemplate.uid
                            )}
                            onChange={(value) =>
                              handleServiceToggle(value, serviceTemplate.uid)
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div>
                * Services can be added and removed after creating a resolution
                case
              </div>
            </div>
            <div>
              <CpButton
                onClick={handleCreateResolutionCase}
                className="cp-mr-8"
                showLoader={creatingResolutionCase}
                disabled={!name}
              >
                Create resolution case
              </CpButton>
              <CpButton
                anchor
                btnType="flat"
                href={`#/client/${clientId}/resolution-cases/list/active`}
              >
                Cancel
              </CpButton>
            </div>
          </CpCard.Body>
        </CpCard>
      </div>
    </NavContent>
  );
}
