import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import { getUrlFromState } from "src/resolution-case/client-requests/client-requests.utils";

export function getClientRequests(clientId, resolutionCaseId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests`
  ).pipe(pluck("client_requests"));
}

export function getClientRequest(clientId, resolutionCaseId, clientRequestId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/${clientRequestId}`
  ).pipe(pluck("client_requests"));
}

export function createClientRequest(clientId, resolutionCaseId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests`,
    {
      method: "POST",
      body: { client_requests: {} },
    }
  ).pipe(pluck("client_requests"));
}

export function updateRequest(
  clientId,
  resolutionCaseId,
  clientRequestId,
  body
) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/${clientRequestId}`,
    {
      method: "PUT",
      body: { client_requests: body },
    }
  ).pipe(pluck("client_requests"));
}

export function deleteClientRequest(
  clientId,
  resolutionCaseId,
  clientRequestId
) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/${clientRequestId}`,
    {
      method: "DELETE",
    }
  );
}

export function sendClientRequests(clientId, requests) {
  const body = {
    client_requests: requests.map((request) => ({
      id: request.id,
      ...request.contactIds ? { contact_ids: request.contactIds } : {},
      sent: true,
      client_url: getUrlFromState("client.general.requests.instance", {
        clientRequestId: request.id,
        clientId: clientId,
      }),
    })),
    client_url_to_requests: getUrlFromState("client.general.requests", {
      clientId: clientId,
    }),
  };

  return fetchAsObservable(`/api/clients/${clientId}/requests`, {
    method: "PUT",
    body,
  }).pipe(pluck("client_requests"));
}
