import React, { useState } from "react";
import { CpToggle, CpIcon, CpButton, CpTooltip } from "canopy-styleguide!sofe";
import styles from "./services.styles.css";

export function ServiceLineItem({ service, onEditService, onToggleService }) {
  const [isVisible, setIsVisible] = useState(service.visible);
  const [isHovering, setIsHovering] = useState(false);

  function handleMouseEnter() {
    if (isHovering) return;
    setIsHovering(true);
  }

  function handleMouseLeave() {
    if (!isHovering) return;
    setIsHovering(false);
  }

  function handleServiceToggle(value) {
    setIsVisible(value);
    onToggleService(service.uid, value);
  }

  return (
    <div
      className={styles.serviceItem}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.serviceItemTitle}>
        <div>{service.name}</div>
        {service.description && (
          <CpTooltip text={service.description}>
            <CpIcon
              className="cp-ml-12 cp-color-app-icon"
              name="information-circle-open-large"
            />
          </CpTooltip>
        )}
        {isHovering && service.is_removable && (
          <CpButton
            small
            btnType="flat"
            onClick={() => onEditService(service)}
            className="cp-ml-12"
          >
            Edit
          </CpButton>
        )}
      </div>
      <div>
        <CpToggle onChange={handleServiceToggle} checked={isVisible} />
      </div>
    </div>
  );
}
