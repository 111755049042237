import React from "react";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function LeviesProveFinancialHardship() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Can you prove{" "}
        <a onClick={proveFinancialHardshipModal}>financial hardship</a>?
      </strong>
    </div>
  );
}

function proveFinancialHardshipModal() {
  renderInfoModal(
    "Financial Hardship",
    <>
      <div className="cp-mb-16">
        If the IRS determines that a levy is creating an immediate economic
        hardship, the levy may be released by the IRS. Financial hardship is
        also one of the qualifying reasons to involve the Taxpayer Advocate
        Service, which can be a valuable resource in obtaining a levy release.
      </div>

      <div className="cp-mb-16">
        An economic hardship occurs when the IRS has determined that the levy
        prevents the taxpayer from meeting basic, reasonable living expenses.
        The IRS will normally need the taxpayer to provide certain financial
        information in order for it to determine if a levy is causing hardship.
      </div>

      <div className="cp-mb-16">
        Documents that are commonly used to prove economic hardship include the
        following:
      </div>

      <ul>
        <li>Medical bills</li>
        <li>Eviction notices</li>
        <li>Bank statements</li>
        <li>Foreclosure notices</li>
        <li>Utility disconnect notices</li>
      </ul>
    </>
  );
}
