import React from "react";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";
import { leviesConstants } from "./levies.constants";
import { LeviesProveFinancialHardship } from "./questions/levies-prove-financial-hardship.component";
import { LeviesOicOrInstallment } from "./questions/levies-oic-or-installment.component";
import { LeviesAbleToSatisfy } from "./questions/levies-able-to-satisfy.component";
import { LeviesAgainstExemptProperty } from "./questions/levies-against-exempt-property.component";
import { LeviesIssuedErroneously } from "./questions/levies-issued-erroneously.component";
import { LeviesIssuedWrongfully } from "./questions/levies-issued-wrongfully.component";

export const LeviesQuestionSwitch = ({ question }) => {
  return (
    <CpSwitch expression={question?.questionSlug}>
      <CpCase value={leviesConstants.PROVE_FINANCIAL_HARDSHIP}>
        <LeviesProveFinancialHardship />
      </CpCase>
      <CpCase value={leviesConstants.OIC_OR_INSTALLMENT}>
        <LeviesOicOrInstallment />
      </CpCase>
      <CpCase value={leviesConstants.ABLE_TO_SATISFY}>
        <LeviesAbleToSatisfy />
      </CpCase>
      <CpCase value={leviesConstants.AGAINST_EXEMPT_PROPERTY}>
        <LeviesAgainstExemptProperty />
      </CpCase>
      <CpCase value={leviesConstants.ISSUED_ERRONEOUSLY}>
        <LeviesIssuedErroneously />
      </CpCase>
      <CpCase value={leviesConstants.ISSUED_WRONGFULLY}>
        <LeviesIssuedWrongfully />
      </CpCase>
      <CpCase default>
        <div className="cps-subheader-sm cp-text-center">
          <strong>{question?.questionText}</strong>
        </div>
      </CpCase>
    </CpSwitch>
  );
};
