import React from "react";
import { Link } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { renderInfoModal } from "../../common/render-info-modal.component";
import { useInnocentSpouseOutcome } from "../innocent-spouse-outcomes.helper";

export function InnocentSpouseEquitableRelief({ outcomes, urls, params }) {
  const { cantCalculateOutcomes, equitableReliefAvailable } =
    useInnocentSpouseOutcome(outcomes);

  return (
    <div>
      <div>
        <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
          <div className="cps-card__body">
            <div className="cps-row cps-margin-top-8 cps-padding-left-8">
              <div className="cps-body">
                Recommendation:{" "}
                <span>
                  {equitableReliefAvailable && (
                    <span className="cps-color-primary">
                      Equitable Relief available
                    </span>
                  )}
                  {!equitableReliefAvailable && !cantCalculateOutcomes && (
                    <span className="cps-warning">
                      Equitable Relief unlikely
                    </span>
                  )}
                </span>
                {cantCalculateOutcomes && (
                  <span className="cps-light-gray">
                    Recommendation unavailable
                  </span>
                )}
              </div>
              <div>
                {equitableReliefAvailable && (
                  <div>
                    Thank you for using the Innocent Spouse Assistant! Based on
                    your answers, your client may qualify for Equitable Relief.
                    Check out the resources below for additional help.
                  </div>
                )}
                {!equitableReliefAvailable && !cantCalculateOutcomes && (
                  <div>
                    Thank you for using the Innocent Spouse Assistant! Based on
                    your answers, Equitable Relief for your client is unlikely.
                    Sometimes it doesn't hurt to try though - check out the
                    resources below to learn more.
                  </div>
                )}

                {cantCalculateOutcomes && (
                  <div>
                    We cannot provide a recommendation at this time. Please
                    complete the{" "}
                    <Link to={urls.assistant}>Innocent Spouse Assistant</Link>{" "}
                    if you would like custom recommendations for your client.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Summary>
          <p>
            If a taxpayer does not qualify for innocent spouse relief or
            separation of liability relief, she/he may still be relieved of
            responsibility for tax, interest, and penalties through equitable
            relief.
          </p>

          <p>
            Unlike innocent spouse relief or separation of liability relief,
            which only provides relief for tax that is assessed due to an audit,
            a taxpayer can get equitable relief from either an{" "}
            <a onClick={understatedTaxModal}>understated tax</a> or an{" "}
            <a onClick={unpaidTaxModal}>unpaid tax</a>.
          </p>

          <p>
            In order to be considered for equitable relief from joint and
            several liability, the taxpayer must meet all of the following
            threshold conditions. In order to be considered for equitable relief
            from liability for tax attributable to an item of community income
            under IRC Section 66, the taxpayer must meet all of the following
            threshold conditions except for items 1 and 2. For additional
            information regarding relief for community property states, please
            see the community property outcome.
          </p>

          <ol>
            <li>
              The taxpayer is not eligible for innocent spouse relief or
              separation of liability relief.
              <br />
              <br />
            </li>
            <li>
              The taxpayer filed a joint return for the tax year(s) at issue.
              <br />
              <br />
            </li>
            <li>
              The taxpayer <a onClick={timelyFiledModal}>timely filed</a> a
              claim for relief.
              <br />
              <br />
            </li>
            <li>
              The taxpayer and current or former spouse did not transfer assets
              to one another as a part of a{" "}
              <a onClick={fraudulentSchemeModal}>fraudulent scheme</a>
              .<br />
              <br />
            </li>
            <li>
              The taxpayer's current or former spouse did not{" "}
              <a onClick={transfersOfPropertyToAvoidTaxModal}>
                transfer property for the main purpose of avoiding tax
              </a>{" "}
              or the payment of tax.
              <br />
              <br />
            </li>
            <li>
              The taxpayer did not knowingly participate in the filing of a
              fraudulent joint return.
              <br />
              <br />
            </li>
            <li>
              The income tax liability from which the taxpayer seeks relief is
              attributable (either in full or in part) to an item or an unpaid
              tax on income resulting from the current or former spouse. If the
              liability is partially attributable to the taxpayer, then relief
              can only be considered for the part of the liability attributable
              to the taxpayer's current or former spouse. The IRS will consider
              granting relief regardless of whether the understated tax,
              deficiency, or unpaid tax is attributable (in full or in part) to
              the taxpayer if any of the following exceptions apply:
              <br />
              <br />
              <ol type="a">
                <li>
                  The item is attributable or partially attributable to the
                  taxpayer solely due to the operation of community property
                  law. If the taxpayer meets this exception, that item will be
                  considered attributable to the current or former spouse for
                  purposes of equitable relief.
                  <br />
                  <br />
                </li>
                <li>
                  If the item is titled in the taxpayer's name, the item is
                  presumed to be attributable to the taxpayer. However, she/he
                  can rebut this presumption based on the facts and
                  circumstances.
                  <br />
                  <br />
                </li>
                <li>
                  The taxpayer did not know, and had no reason to know, that
                  funds intended for the payment of tax were misappropriated by
                  the current or former spouse for his or her benefit. If the
                  taxpayer meets this exception, the IRS will consider granting
                  equitable relief although the unpaid tax may be attributable
                  in part or in full to the taxpayer's item, and only to the
                  extent the funds intended for payment were taken by the
                  taxpayer's current or former spouse.
                  <br />
                  <br />
                </li>
                <li>
                  The taxpayer establishes that she/he was the victim of spousal
                  abuse or domestic violence before the return was filed, and
                  that, as a result of the prior abuse, the taxpayer did not
                  challenge the treatment of any items on the return for fear of
                  the current or former spouse's retaliation. If the taxpayer
                  meets this exception, relief will be considered even though
                  the understated tax or unpaid tax may be attributable in part
                  or in full to the taxpayer's item.
                  <br />
                  <br />
                </li>
                <li>
                  The item giving rise to the understated tax or deficiency is
                  attributable to the taxpayer, but she/he establishes that
                  their current or former the spouse's fraud is the reason for
                  the erroneous item.
                </li>
              </ol>
            </li>
          </ol>

          <p>
            <strong>
              Factors for determining whether to grant equitable relief
            </strong>
          </p>

          <p>
            If the taxpayer meets all the threshold conditions, the IRS will
            grant equitable relief if it can be established that it would be
            unfair to hold the taxpayer liable for the understated or unpaid
            tax. The IRS will consider all facts and circumstances of the
            taxpayer's case in determining whether it is unfair to hold her/him
            liable for all or part of the unpaid income tax liability or
            deficiency, and whether full or partial equitable relief should be
            granted.
          </p>

          <p>
            The factors listed below are considered in the IRS's decision; these
            factors are listed as guides and not intended to be an exclusive
            list. Other factors relevant to the taxpayer's case also may be
            considered. In evaluating the claim for relief, no one factor or a
            majority of factors necessarily determines the outcome. The degree
            of importance of each factor varies depending on the taxpayer's
            facts and circumstances. Abuse or the exercise of financial control
            by the taxpayer's current or former spouse is a factor that may
            impact the other factors, as described below. Factors the IRS will
            consider include the following:
          </p>

          <ul>
            <li>
              <a onClick={maritalStatusModal}>Marital status</a>
            </li>
            <li>
              <a onClick={economicHardshipModal}>Economic hardship</a>
            </li>
            <li>
              <a onClick={reasonToKnowModal}>Knowledge or reason to know</a>
            </li>
            <li>
              <a onClick={legalObligationModal}>Legal obligation</a>
            </li>
            <li>
              <a onClick={significantBenefitModal}>Significant benefit</a>
            </li>
            <li>
              <a onClick={complianceModal}>Compliance with tax laws</a>
            </li>
            <li>
              <a onClick={healthModal}>Mental or physical health</a>
            </li>
          </ul>
        </Summary>

        <div className="cps-fixed-masonry">
          <div className="cps-row">
            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Templates</strong>
                </div>
                <a
                  className="cps-slat"
                  href="https://cdn.canopytax.com/static/innocent_spouse/equitable_relief/Letter_Equitable_Relief.docx"
                >
                  <div className="cps-slat__badge cps-padding-left-24">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                      className="cp-outcomes__file-icon"
                    />
                  </div>
                  <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                    Letter - Equitable relief
                  </div>
                </a>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Contact info</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>
                      <i>If using the U.S. Postal Service</i>
                    </strong>
                    <br />
                    Internal Revenue Service
                    <br />
                    P.O. Box 120053
                    <br />
                    Covington, KY 41012
                  </p>
                  <p>
                    <strong>
                      <i>If using a private delivery service</i>
                    </strong>
                    <br />
                    Internal Revenue Service
                    <br />
                    201 W. Rivercenter Blvd., Stop 840F
                    <br />
                    Covington, KY 41011
                  </p>
                  <p>
                    <strong>
                      <i>If submitting via fax</i>
                    </strong>
                    <br />
                    Fax number: 855-233-8558
                  </p>
                </div>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Links and articles</strong>
                </div>

                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/publications/p971/ar02.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 971 - Innocent spouse relief
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/taxtopics/tc205.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Topic 205 - Innocent spouse relief (including separation
                        of liability and equitable relief)
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="http://www.journalofaccountancy.com/issues/2014/may/innocent-spouse-relief-20139090.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        New rules for innocent spouse equitable relief
                      </span>
                    </div>
                    Journal of Accountancy
                  </div>
                </a>
              </div>
            </div>

            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>IRS forms</strong>
                </div>

                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/DQ4V/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 12509
                    </div>
                    Statement of Disagreement
                  </div>
                </Link>
                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/YQVL/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 8379
                    </div>
                    Injured Spouse Allocation
                  </div>
                </Link>
                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/9F4Q/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 8857
                    </div>
                    Request for Innocent Spouse Relief
                  </div>
                </Link>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Best practices</strong>
                </div>

                <div className="cps-card__body">
                  <ul>
                    <li>
                      <strong>Conflicts of interest</strong> - Beware of
                      potential conflicts of interest which arise when you
                      represent both parties. You may not be able to represent
                      either if you originally prepared the return in question.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Two-year limit</strong> - As of July 25, 2011 the
                      IRS will no longer apply the two-year limit to new
                      equitable relief requests or requests currently being
                      considered by the agency. Also, you can re-apply with 8857
                      form if you were denied solely for the two-year rule as
                      long as it is still within the collection statutes.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Unpaid amounts</strong> - Relief is available for
                      amounts shown on the return, but which remain unpaid.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Refunds</strong> - Refunds are available.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Facts and circumstances</strong> - When
                      considering facts and circumstances, no factor is
                      controlling. Factors are classified as favorable,
                      unfavorable, or neutral.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Letter to former or current spouse</strong> - Be
                      sure your client is aware the IRS will send a letter to
                      the former or current spouse asking them to verify
                      statements of the taxpayer seeking relief.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Offer in Compromise considerations</strong> - To
                      qualify for equitable relief, you must not be involved in
                      an Offer in Compromise payment program with the IRS. The
                      Offer in Compromise program legally closes the tax year
                      that any of your unpaid taxes are applied to. Taxes cannot
                      be re-determined once that happens.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function understatedTaxModal() {
  renderInfoModal(
    "Understated tax",
    <p>
      Taxpayer has an understated tax if the IRS determined that the total tax
      should be more than the amount that was actually shown on the taxpayer's
      return. This is usually the result of an audit or examination.
    </p>
  );
}

function unpaidTaxModal() {
  renderInfoModal(
    "Unpaid tax",
    <p>
      An unpaid tax is an amount of tax properly reported on a return but that
      has not been paid. For example, a taxpayer's joint 2012 return shows an
      amount owed of $5,000. $2,000 was paid with the return. The unpaid tax is
      $3,000.
    </p>
  );
}

function fraudulentSchemeModal() {
  renderInfoModal(
    "Fraudulent scheme",
    <p>
      A fraudulent scheme includes a scheme to defraud the IRS or another third
      party, such as a creditor, former spouse, or business partner.
    </p>
  );
}

function timelyFiledModal() {
  renderInfoModal(
    "Timely filed",
    <>
      <p>
        The amount of time to request equitable relief depends on whether the
        taxpayer is seeking relief from a balance due, seeking a credit or
        refund, or both.
      </p>
      <p>
        <i>Balance due.</i> Generally, the taxpayer must file a request within
        the time period the IRS has to collect the tax. Generally, the IRS has
        10 years from the date the tax liability was assessed to collect the
        tax. In certain cases, the 10-year period is suspended. The amount of
        time the suspension is in effect will extend the time the IRS has to
        collect the tax. See Pub. 594, The IRS Collection Process, for details.
      </p>
      <p>
        <i>Credit or refund.</i> Generally, the taxpayer must file his/her
        request within 3 years after the date the original return was filed or
        within 2 years after the date the tax was paid, whichever is later. But
        the taxpayer may have more time to file if living in a federally
        declared disaster area or she/he is physically or mentally unable to
        manage financial affairs. See Pub. 556, Examination of Returns, Appeal
        Rights, and Claims for Refund, for details.
      </p>
      <p>
        <i>Both a balance due and a credit or refund.</i> If the taxpayer is
        seeking a refund of amounts paid and relief from a balance due over and
        above what was paid, the time period for credit or refund will apply to
        any payments made, and the time period for collection of a balance due
        amount will apply to any unpaid liability.
      </p>
    </>
  );
}

function transfersOfPropertyToAvoidTaxModal() {
  renderInfoModal(
    "Transfers of property to avoid tax",
    <>
      <p>
        If the taxpayer's current or former spouse transfers property (or the
        right to property) to the taxpayer for the main purpose of avoiding or
        paying tax, the tax liability allocated to the taxpayer will be
        increased by the fair market value of the property on the date of the
        transfer, but this increase may not be more than the entire amount of
        the liability. A transfer will be presumed to have as its main purpose
        the avoidance of tax or tax payment if the transfer is made one year
        before the IRS sent its first letter of proposed deficiency, or anytime
        after. This presumption will not apply if:
      </p>

      <ul>
        <li>
          The transfer was made under a divorce decree, separate maintenance
          agreement, or a written instrument incident to such an agreement, or
          <br />
          <br />
        </li>
        <li>
          The taxpayer establishes that the transfer did not have as its main
          purpose the avoidance of tax or payment of tax.
        </li>
      </ul>

      <p>
        If the presumption does not apply, but the IRS can establish that the
        purpose of the transfer was the avoidance of tax or payment of tax, the
        tax liability allocated to the taxpayer will be increased as explained
        above.
      </p>
    </>
  );
}

function maritalStatusModal() {
  renderInfoModal(
    "Marital status",
    <>
      <p>
        The IRS will consider whether you are no longer married to your spouse
        as of the date the IRS makes its determination. If you are still married
        to your spouse, this factor is neutral. If you are no longer married to
        your spouse, this factor will weigh in favor of relief. This means if
        you are still married, it is not as favorable. You will be treated as
        being no longer married to your spouse only in the following situations.
      </p>

      <ul>
        <li>
          You are divorced from your spouse.
          <br />
          <br />
        </li>
        <li>
          You are legally separated from your spouse under applicable state law.
          <br />
          <br />
        </li>
        <li>
          You are a widow or widower and are not an heir to your spouse's estate
          that would have sufficient assets to pay the tax liability.
          <br />
          <br />
        </li>
        <li>
          You have not been a member of the same household as your spouse at any
          time during the 12-month period ending on the date the IRS makes its
          determination. For these purposes, a temporary absence (for example,
          due to imprisonment, illness, business, military service, or
          education) is not considered separation if the absent spouse is
          expected to return to the household. You are a member of the same
          household as your spouse for any period in which both of you maintain
          the same residence.
          <br />
          <br />
        </li>
      </ul>
    </>
  );
}

function economicHardshipModal() {
  renderInfoModal(
    "Economic hardship",
    <p>
      The IRS will consider whether you will suffer economic hardship if relief
      is not granted. For purposes of this factor, an economic hardship exists
      if satisfaction of the tax liability in whole or in part will cause you to
      be unable to pay reasonable basic living expenses. The IRS will determine
      whether you meet this factor based on the information you provide in Part
      IV of Form 8857. If denying relief will cause you to suffer economic
      hardship, this factor will weigh in favor of relief. If denying relief
      will not cause you to suffer economic hardship, this factor will be
      neutral.
    </p>
  );
}

function reasonToKnowModal() {
  renderInfoModal(
    "Knowledge or reason to know",
    <>
      <p>
        <i>Understated tax on a joint return.</i> The IRS will consider whether
        you knew or had reason to know of the item giving rise to the
        understated tax or deficiency as of the date the joint return (including
        a joint amended return) was filed, or the date you reasonably believed
        the joint return was filed. If you did not know and had no reason to
        know of the item giving rise to the understated tax, this factor will
        weigh in favor of relief. If you knew or had reason to know of the item
        giving rise to the understated tax, this factor will weigh against
        relief. Actual knowledge of the item giving rise to the understated tax
        or deficiency will not be weighed more heavily than any other factor.
        Depending on the facts and circumstances, if you were abused by your
        spouse or former spouse (as discussed later), or your current or former
        spouse maintained control of the household finances by restricting your
        access to financial information, and because of the abuse or financial
        control, you were not able to challenge the treatment of any items on
        the joint return for fear of your current or former spouse's
        retaliation, this factor will weigh in favor of relief even if you knew
        or had reason to know of the items giving rise to the understated tax or
        deficiency.
      </p>

      <p>
        <i>Understated tax on a return other than a joint return.</i> The IRS
        will consider whether you knew or had reason to know of an item of
        community income properly includible in gross income.
      </p>
      <p>
        <i>Unpaid tax.</i> In the case of an income tax liability that was
        properly reported but not paid, the IRS will consider whether (as of the
        date the return was filed or the date you reasonably believed the return
        was filed) you knew or had reason to know that your current or former
        spouse would or could not pay the tax liability at that time or within a
        reasonable period of time after the filing of the return. This factor
        will weigh in favor of relief if you reasonably expected your current or
        former spouse to pay the tax liability reported on the return. A
        reasonable expectation of payment will be presumed if the spouses
        submitted a request for an installment agreement to pay the tax reported
        as due on the return. To benefit from the presumption, the request for
        an installment agreement must be filed by the later of 90 days after the
        due date for payment of the tax, or 90 days after the return was filed.
        The request must detail the plan for paying the tax, interest, and
        penalties, satisfy the liability within a reasonable time, and it must
        not be unreasonable for you to believe that your current or former
        spouse will be able to make the payments requested in the installment
        agreement.
      </p>
      <p>
        This factor will weigh against relief if, based on the facts and
        circumstances of the case, it was not reasonable for you to believe that
        your current or former spouse would or could pay the tax liability shown
        on the return. For example, if prior to the return being filed, or the
        date you reasonably believed the return was filed, you knew of your
        current or former spouse's prior bankruptcies, financial difficulties,
        or other issues with the IRS or other creditors, or was otherwise aware
        of difficulties in timely paying bills, then this factor will generally
        weigh against relief.
      </p>
      <p>
        Depending on the facts and circumstances, if you were abused by your
        spouse or former spouse (as discussed later), or your spouse (or former
        spouse) maintained control of the household finances by restricting your
        access to financial information, and because of the abuse or financial
        control, you were not able to question the payment of the taxes reported
        as due on the return or challenge your current or former spouse's
        assurance regarding payment of the taxes for fear of his or her
        retaliation, this factor will weigh in favor of relief even if you knew
        or had reason to know about your spouse's intent or ability to pay the
        taxes due.
      </p>
      <p>
        In the case of an unpaid tax on an amended return that reports a
        liability based on items not properly reported on the original return,
        the initial inquiry is whether (as of the date the amended return was
        filed, or the date you reasonably believed the amended return was filed)
        you reasonably expected that your current or former spouse would pay the
        tax within a reasonable period of time. If so, this factor will weigh in
        favor of relief. However, if it was not reasonable for you to expect
        that your current or former spouse would pay the tax, your knowledge or
        reason to know of the understated tax on the original return will also
        be considered. If you knew or had reason to know of the item giving rise
        to the understated tax on the original return, then this factor will
        weigh against relief. If you did not know or have reason to know of the
        item, then this factor will weigh in favor of relief.
      </p>
      <p>
        <i>Reason to know.</i> The facts and circumstances that are considered
        in determining whether you had reason to know of an understated tax, or
        reason to know whether your spouse (or former spouse) could or would pay
        the reported tax liability, include, but are not limited to the
        following.
      </p>

      <ul>
        <li>Your level of education.</li>
        <li>Any deceit or evasiveness of your current or former spouse.</li>
        <li>
          Your degree of involvement in the activity generating the income tax
          liability.
        </li>
        <li>Your involvement in business or household financial matters.</li>
        <li>Your business or financial expertise.</li>
        <li>
          Any lavish or unusual expenditures compared with past spending levels.
        </li>
      </ul>

      <p>
        <i>Example</i>
      </p>

      <p>
        You and your spouse filed a joint 2011 return. That return showed you
        owed $10,000. You had $5,000 of your own money and you took out a loan
        to pay the other $5,000. You gave two checks for $5,000 each to your
        spouse to pay the $10,000 liability. Without telling you, your spouse
        took the $5,000 loan and spent it on himself. You and your spouse were
        divorced in 2012. In addition, you had no knowledge or reason to know at
        the time you signed the return that the tax would not be paid. These
        facts indicate to the IRS that it may be unfair to hold you liable for
        the $5,000 unpaid tax. The IRS will consider these facts, together with
        all of the other facts and circumstances, to determine whether to grant
        you equitable relief from the $5,000 unpaid tax.
      </p>
      <p>
        Abuse by your spouse (or former spouse). For purposes of the equitable
        relief rules, if you establish that you were the victim of abuse (not
        amounting to duress, which is discussed in the Instructions for Form
        8857), then depending on the facts and circumstances of your situation,
        the abuse may result in certain factors weighing in favor of relief when
        otherwise the factor may have weighed against relief. Abuse comes in
        many forms and can include physical, psychological, sexual, or emotional
        abuse, including efforts to control, isolate, humiliate, and intimidate
        you, or to undermine your ability to reason independently and be able to
        do what is required under the tax laws. The IRS will consider all the
        facts and circumstances in determining whether you were abused. The IRS
        also will consider the impact of your current or former spouse's alcohol
        or drug abuse in determining whether you were abused. Depending on the
        facts and circumstances, abuse of your child or other family member
        living in the household may constitute abuse of you.
      </p>
    </>
  );
}

function legalObligationModal() {
  renderInfoModal(
    "Legal obligation",
    <p>
      The IRS will consider whether you or your current or former spouse has a
      legal obligation to pay the outstanding federal income tax liability. For
      purposes of this factor, a legal obligation is an obligation arising from
      a divorce decree or other legally binding agreement. This factor will
      weigh in favor of relief if your former spouse has the sole legal
      obligation to pay the outstanding income tax liability pursuant to a
      divorce decree or agreement. This factor will be neutral if you knew or
      had reason to know, when entering into the divorce decree or agreement,
      that your former spouse would not pay the income tax liability. This
      factor will weigh against relief if you have the sole legal obligation.
      The fact that your current or former spouse has been relieved of liability
      for the taxes at issue as a result of a discharge in bankruptcy is
      disregarded in determining whether you have the sole legal obligation.
      This factor will be neutral if, based on an agreement or consent order,
      both spouses have a legal obligation to pay the outstanding income tax
      liability, the spouses are not separated or divorced, or the divorce
      decree or agreement is silent as to any obligation to pay the outstanding
      income tax liability.
    </p>
  );
}

function significantBenefitModal() {
  renderInfoModal(
    "Significant benefit",
    <p>
      The IRS will consider whether you significantly benefited from the unpaid
      income tax liability or understated tax. A significant benefit is any
      benefit in excess of normal support. For example, if you enjoyed the
      benefits of a lavish lifestyle, such as owning luxury assets and taking
      expensive vacations, this factor will weigh against relief. If, however,
      your spouse (or former spouse) controlled the household and business
      finances or there was abuse (discussed earlier) such that he or she made
      the decision on spending funds for a lavish lifestyle, then this mitigates
      this factor so that it is neutral. If only your current or former spouse
      significantly benefitted from the unpaid tax or understatement, and you
      had little or no benefit, or your spouse (or former spouse) enjoyed the
      benefit to your detriment, this factor will weigh in favor of relief. If
      the amount of unpaid tax or understated tax was small such that neither
      spouse received a significant benefit, then this factor is neutral.
      Whether the amount of unpaid tax or understated tax is small such that
      neither spouse received a significant benefit will vary depending on the
      facts and circumstances of each case.
    </p>
  );
}

function complianceModal() {
  renderInfoModal(
    "Compliance with income tax laws",
    <>
      <p>
        The IRS will consider whether you have made a good faith effort to
        comply with the income tax laws in the tax years following the tax year
        or years to which the request for relief relates.
      </p>
      <p>
        If you are compliant for tax years after being divorced from your
        spouse, then this factor will weigh in favor of relief. If you are not
        compliant, then this factor will weigh against relief. If you made a
        good faith effort to comply with the tax laws but were unable to fully
        comply, then this factor will be neutral. For example, if you timely
        filed an income tax return but were unable to fully pay the tax
        liability due to your poor financial or economic situation after the
        divorce, then this factor will be neutral.
      </p>
      <p>
        If you remain married to your spouse, whether or not legally separated
        or living apart, and continue to file joint returns with your spouse
        after requesting relief, then this factor will be neutral if the joint
        returns are compliant with the tax laws. If the joint returns are not
        compliant with the tax laws, then this factor will weight against
        relief.
      </p>
      <p>
        If you remain married to your spouse but file separate returns, this
        factor will weigh in favor of relief if you are compliant with the tax
        laws. If you are not compliant with the tax laws, then this factor will
        weigh against relief. If you made a good faith effort to comply with the
        tax laws but were unable to fully comply, then this factor will be
        neutral. For example, if you timely filed an income tax return but were
        unable to fully pay the tax liability due to your poor financial or
        economic situation as a result of being separated or living apart from
        your spouse, then this factor will be neutral.
      </p>
    </>
  );
}

function healthModal() {
  renderInfoModal(
    "Mental or physical health",
    <>
      <p>
        The IRS will consider whether you were in poor physical or mental
        health. This factor will weigh in favor of relief if you were in poor
        mental or physical health at one of the following times.
      </p>

      <ul>
        <li>
          At the time the return or returns for which the request for relief
          relates were filed.
        </li>
        <li>
          At the time you reasonably believed the return or returns were filed.
        </li>
        <li>At the time you request relief.</li>
      </ul>

      <p>
        The IRS will consider the nature, extent, and duration of your
        condition, including the ongoing economic impact of your illness. If you
        were in neither poor physical nor poor mental health, this factor is
        neutral.
      </p>
    </>
  );
}
