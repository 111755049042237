import React, { Suspense } from "react";
import { HashRouter } from "react-router-dom";
import { CpLoader } from "canopy-styleguide!sofe";
import { NavContent } from "primary-navbar!sofe";
import PrivateRoute from "./common/private-route.component";
import Dashboard from "./dashboard/dashboard.component";
import { ResolutionCaseRoutes } from "./resolution-case/resolution-case-routes.component";
import { ResolutionCasesList } from "./client-dashboard/resolution-cases-list.component";
import { ResolutionCasesCreate } from "./client-dashboard/resolution-cases-create.component";
import TemplateRoutes from "./resolution-case/templates/template-routes.component";

export default function Root() {
  if (window.location.href.includes("engagements")) {
    // Remove this when we add engagements routes
    window.location.replace(
      window.location.href.replace("engagements", "resolution-cases")
    );
  }

  return (
    <HashRouter>
      <Suspense
        fallback={
          <NavContent hasTopnavSecondary={true} clientMenuPossible={true}>
            <CpLoader />
          </NavContent>
        }
      >
        <PrivateRoute
          path="/resolution-cases/dashboard"
          permissions="tasks_resolution_cases"
        >
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute
          path="/client/:clientId/resolution-cases/list/active"
          permissions="tasks_resolution_cases"
        >
          <ResolutionCasesList listType="active" />
        </PrivateRoute>
        <PrivateRoute
          path="/client/:clientId/resolution-cases/list/archived"
          permissions="tasks_resolution_cases"
        >
          <ResolutionCasesList listType="archived" />
        </PrivateRoute>
        <PrivateRoute
          path="/client/:clientId/resolution-cases/list/trash"
          permissions="tasks_resolution_cases"
        >
          <ResolutionCasesList listType="trash" />
        </PrivateRoute>
        <PrivateRoute
          path="/client/:clientId/resolution-cases/create"
          permissions="tasks_resolution_cases"
        >
          <ResolutionCasesCreate />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/global-settings/service-templates"
          permissions="templates_tax_resolution_cases"
        >
          <TemplateRoutes />
        </PrivateRoute>
        <PrivateRoute
          path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId"
          permissions="tasks_resolution_cases"
        >
          <ResolutionCaseRoutes />
        </PrivateRoute>
      </Suspense>
    </HashRouter>
  );
}
