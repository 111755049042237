// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-common-collaborator-picker-styles__assigneeLabel--1G8cI {\n  width: 24px !important;\n  height: 24px !important;\n  min-width: 24px !important;\n}\n\n.src-common-collaborator-picker-styles__dialogHeader--2Ic3S {\n  padding: 0.8rem;\n}\n\n.src-common-collaborator-picker-styles__dialogListItem--h7iSF {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 0.8rem;\n}\n\n.src-common-collaborator-picker-styles__dialogListItem--h7iSF:hover {\n  background: var(--cp-color-menu-hover-bg);\n}\n\n.src-common-collaborator-picker-styles__dialogListItemButton--gAj_6 {\n  background: transparent;\n  outline: none;\n  border: none;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n  padding: 0.8rem 0;\n}\n\n.src-common-collaborator-picker-styles__dialogFooter--THd3e {\n  padding: 0.8rem;\n}\n", ""]);
// Exports
exports.locals = {
	"assigneeLabel": "src-common-collaborator-picker-styles__assigneeLabel--1G8cI",
	"dialogHeader": "src-common-collaborator-picker-styles__dialogHeader--2Ic3S",
	"dialogListItem": "src-common-collaborator-picker-styles__dialogListItem--h7iSF",
	"dialogListItemButton": "src-common-collaborator-picker-styles__dialogListItemButton--gAj_6",
	"dialogFooter": "src-common-collaborator-picker-styles__dialogFooter--THd3e"
};
module.exports = exports;
