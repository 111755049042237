import React, { useState } from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";

export function InnocentSpouseKnowCommunityIncome() {
  const [modalKnow, setModalKnow] = useState(false);
  const [modalReasonKnow, setModalReasonKnow] = useState(false);

  return (
    <div>
      <div className="cps-subheader-sm cp-text-center">
        <strong>
          Did your client <a onClick={() => setModalKnow(true)}>know</a> of, or{" "}
          <a onClick={() => setModalReasonKnow(true)}>had reason to know</a> of,
          that community income?
        </strong>
      </div>

      <CpModal show={modalKnow} onClose={() => setModalKnow(false)}>
        <CpModal.Header title="Know" />
        <CpModal.Body>
          You knew or had reason to know of an item of community income if you
          actually knew of the item of community income, or a reasonable person
          in similar circumstances would have known of the item of community
          income. If you are aware of the source of the item of community income
          or the income-producing activity, but are unaware of the specific
          amount, you are considered to know or have reason to know of the item
          of community income. Not knowing the specific amount is not a basis
          for relief.
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton btnType="secondary" onClick={() => setModalKnow(false)}>
            Done
          </CpButton>
        </CpModal.Footer>
      </CpModal>

      <CpModal show={modalReasonKnow} onClose={() => setModalReasonKnow(false)}>
        <CpModal.Header title="Reason to know" />
        <CpModal.Body>
          <p>
            The IRS will consider all facts and circumstances in determining
            whether you had reason to know of an item of community income. The
            facts and circumstances include:
          </p>
          <ul>
            <li>
              The nature of the item of community income and the amount of the
              item relative to other income items.
            </li>
            <li>
              The financial situation of you and your spouse (or former spouse).
            </li>
            <li>Your educational background and business experience.</li>
            <li>
              Whether the item of community income represented a departure from
              a recurring pattern reflected in prior years' returns (for
              example, omitted income from an investment regularly reported on
              prior years' returns).
            </li>
          </ul>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="secondary"
            onClick={() => setModalReasonKnow(false)}
          >
            Done
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </div>
  );
}
