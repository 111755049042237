import React from "react";
import { useParams, Link } from "react-router-dom";

import { useLeviesOutcomes } from "../levies-outcomes.helper";
import { Summary } from "../../common/summary.component";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function LeviesReleasingLevy({ outcomes, urls }) {
  const params = useParams();
  const { releasingLevyAvailable, cantCalculateOutcomes } =
    useLeviesOutcomes(outcomes);
  return (
    <div>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {releasingLevyAvailable && (
                  <span className="cps-color-primary">
                    Releasing a Levy or Proposed Levy available
                  </span>
                )}
                {!releasingLevyAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">
                    Releasing a Levy or Proposed Levy unlikely
                  </span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {releasingLevyAvailable && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, your client may qualify for Releasing a Levy or
                  Proposed Levy. Check out the resources below for additional
                  help.
                </div>
              )}
              {!releasingLevyAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Levies Assistant! Based on your
                  answers, Releasing a Levy or Proposed Levy for your client is
                  unlikely. Sometimes it doesn't hurt to try though - check out
                  the resources below to learn more.
                </div>
              )}
              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the <Link to={urls.assistant}>Levies Assistant</Link>{" "}
                  if you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p>
          If the IRS intends to issue or has already issued a levy, contact the
          IRS immediately to resolve your client's tax liability and request a
          levy release. A taxpayer may seek a levy release through the following
          means:
        </p>
        <ul>
          <li>Paying the overdue taxes</li>
          <li>
            Making other arrangements to satisfy or settle the tax debt with the
            IRS, such as an{" "}
            <a onClick={installmentAgreementModal}>installment agreement</a> or{" "}
            <a onClick={offerInCompromiseModal}>offer in compromise</a>
          </li>
          <li>
            Proving <a onClick={financialHardshipModal}>financial hardship</a>
          </li>
          <li>
            Establishing that the period for collection ended prior to the levy
            being issued
          </li>
          <li>Establishing that the taxpayer was a victim of identity theft</li>
          <li>
            Proving to the IRS that releasing the levy will increase your
            client's ability to pay his or her taxes
          </li>
          <li>
            Establishing that the value of the levied property is more than the
            amount owed and releasing the levy won't impede the IRS's ability to
            collect the amount owed
          </li>
          <li>
            Establishing that the levy was{" "}
            <a onClick={erroneousLevyModal}>issued erroneously</a> by the IRS,
            such as against{" "}
            <a onClick={exemptPropertyModal}>property exempt from seizure</a> or
            while the taxpayer was in bankruptcy and an automatic stay was in
            effect
          </li>
          <li>
            Establishing that the levy was{" "}
            <a onClick={wrongfullyLevyModal}>issued wrongfully</a>
          </li>
        </ul>

        <p>
          The release of a levy does not mean that your client does not have to
          pay the balance due. You must still make arrangements with the IRS to
          resolve your client's tax debt or a levy may be reissued.
        </p>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Templates</strong>
              </div>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/Letter-LevyRelease.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Letter - Levy release
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/Letter-LevyReleaseCallScript.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Levy release call script
                </div>
              </a>
              <a
                className="cps-slat"
                href="https://cdn.canopytax.com/static/levies/AttachmentToForm9423.docx"
              >
                <div className="cps-slat__badge cps-padding-left-24">
                  <img
                    src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                    className="cp-outcomes__file-icon"
                  />
                </div>
                <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                  Attachment to Form 9423
                </div>
              </a>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p5.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 5 - Your appeal rights
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p594.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 594 - The IRS collection process
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p1660.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 1660 - Collection Appeal rights
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p1546.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 1546 - Taxpayer Advocate Service is here to
                      help
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
            </div>
          </div>

          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/V912/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 9423
                  </div>
                  Collection Appeals Request
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/CF9RS/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 911
                  </div>
                  Request for Taxpayer Advocate Service Assistance
                </div>
              </Link>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <ul>
                  <li>
                    <a onClick={financialHardshipModal}>Financial hardship</a>{" "}
                    is the most common reason to request a levy release. If
                    requesting levy release due to economic hardship, be
                    prepared to provide financial information to the IRS
                    supporting your claim.
                  </li>
                  <li>
                    If levied funds from a bank account causes immediate
                    economic hardship to a taxpayer, you have a qualifying
                    reason to involve the Taxpayer Advocate Service. Once the
                    hardship has been corroborated, the TAS has the ability to
                    assist in having the IRS immediately release the levy. This
                    can be a very valuable resource, especially in light of the
                    fact that the normal window of time to have a bank levy
                    released is only 21 days before funds are submitted to the
                    IRS. Obtaining a refund from the IRS after the 21 day window
                    has passed is normally a longer process than obtaining the
                    levy release before the IRS has received the funds.
                  </li>
                  <li>
                    Levy releases can be made verbally or in writing. Be
                    prepared to make your case to the Service as to why the Levy
                    should be released and have supporting documentation to the
                    effect. A call to the ACS representative or assigned Revenue
                    Officer (RO) may be the most effective means of requesting a
                    levy release.
                  </li>
                  <li>
                    For more expedient levy releases, provide the ACS/RO making
                    the release with the levy source contact information,
                    including the contact name and fax #. Without providing this
                    information and requesting that the release be faxed, copies
                    of each Form 668-D will be sent via regular US mail. Faxing
                    of releases is most commonly requested of banks and
                    employers.
                  </li>
                  <li>
                    Levies can be fully or partially released. This is done by
                    an RO or ACS via Form 668-D,{" "}
                    <i>Release of Levy/Release of Property from Levy</i>. If a
                    full levy release is not granted, a partial levy release can
                    be requested. Again, be prepared to explain why it's in the
                    best interest of the Service and have supporting
                    documentation available.
                  </li>
                  <li>
                    Partial releases are much more common than one might
                    imagine. Many times, RO's will determine net payroll
                    required for a business and release just that amount. Other
                    times, an RO may determine the "national standards" for
                    living expenses per month and release only that amount from
                    a bank levy.
                  </li>
                  <li>
                    If the IRS denies your request to release a levy, you may
                    appeal this decision.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function exemptPropertyModal() {
  renderInfoModal(
    "Exempt property",
    <>
      <p>Examples of property exempt from levy:</p>
      <ul>
        <li>Unemployment benefits</li>
        <li>Certain annuity and pension benefits</li>
        <li>Certain disability payments</li>
        <li>Workers compensation</li>
        <li>Certain public assistance payments</li>
        <li>Minimum weekly exempt income</li>
        <li>Income for court-ordered child support payments</li>
        <li>Certain personal property and personal household effects</li>
      </ul>
    </>
  );
}

function installmentAgreementModal() {
  renderInfoModal(
    "Installment agreement",
    <>
      If a taxpayer is financially unable to pay his or her tax debt
      immediately, he or she may be able to make monthly payments through an
      installment agreement.
    </>
  );
}

function offerInCompromiseModal() {
  renderInfoModal(
    "Offer in compromise",
    <>
      An agreement between a taxpayer and the IRS that settles the taxpayer's
      tax liabilities for less than the full amount owed. Taxpayers who can
      fully pay the liabilities through an installment agreement or other means
      will generally not be eligible for an Offer in Compromise.
    </>
  );
}

function financialHardshipModal() {
  renderInfoModal(
    "Financial hardship",
    <>
      <p>
        If the IRS determines that a levy is creating an immediate economic
        hardship, the levy may be released by the IRS.
      </p>
      <p>
        An economic hardship occurs when the IRS has determined that the levy
        prevents the taxpayer from meeting basic, reasonable living expenses.
        The IRS will normally need the taxpayer to provide certain financial
        information in order for it to determine if a levy is causing hardship.
      </p>
      <p>
        Documents that are commonly used to prove economic hardship include the
        following:
      </p>
      <ul>
        <li>Medical bills</li>
        <li>Eviction Notices</li>
        <li>Bank statements</li>
        <li>Foreclosure notices</li>
        <li>Utility disconnect notices</li>
      </ul>
    </>
  );
}

function erroneousLevyModal() {
  renderInfoModal(
    "Erroneous levy",
    <>
      <p>
        A levy is issued erroneously if it is in violation of an administrative
        procedure or law.
      </p>
      <p>Examples include the following:</p>
      <ul>
        <li>The levy was issued prematurely</li>
        <li>Your client already paid the entire tax liability</li>
        <li>The levy was issued before the IRS sent the required notice</li>
        <li>
          The levy was issued while the taxpayer was in bankruptcy and an
          automatic stay was in effect
        </li>
        <li>
          The expenses of seizing and selling the levied property would be
          greater than the fair market value of the property
        </li>
        <li>
          An Installment Agreement request, Innocent Spouse Relief request, or
          Offer in Compromise was being considered or had been accepted and was
          in effect (unless the Agreement does not allow for the return of
          previously levied upon property)
        </li>
        <li>The period for collection ended prior to the levy being issued</li>
      </ul>
    </>
  );
}

function wrongfullyLevyModal() {
  renderInfoModal(
    "Wrongful levy",
    <>
      A levy that improperly attaches property belonging to a third party in
      which the taxpayer has no rights.
    </>
  );
}
