import React, { useEffect, useState } from "react";
import { useCss, k } from "kremling";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";
import { serviceConst } from "./assistant.constants";
import { PenaltyAbatementResolution } from "./penalty-abatement/penalty-abatement-resolution.component";
import { AssistantHeader } from "./common/assistant-header.component";
import {
  getEnvelope,
  getProgramSectionService,
} from "../../common/resolution-cases.resource";
import { useUrlBuilder } from "./assistant-utils";
import { InnocentSpouseResolution } from "./innocent-spouse/innocent-spouse-resolution.component";
import { getAnswers } from "./assistant.resources";
import { LiensResolution } from "./liens/liens-resolution.component";
import { LeviesResolution } from "./levies/levies-resolution.component";
import { TrustFundResolution } from "./trust-fund/trust-fund-resolution.component";

export function AssistantOutcomes({
  params,
  resolutionCase,
  section,
  program,
}) {
  const scope = useCss(css);
  const [{ sectionEnvelope, fullSection, loading, outcomes }, setState] =
    useState({
      sectionEnvelope: null,
      fullSection: null,
      outcomes: [],
      loading: true,
    });

  useEffect( () => {
    const getInfo = async () => {
      const { sectionEnvelope, fullSection } = await getEnvelopeAndFullSection();
      const { outcomes = [] } = await getAnswers(
        params.clientId,
        resolutionCase.id,
        params.programSlug
      );
      setState((prevState) => ({
        ...prevState,
        sectionEnvelope,
        fullSection,
        outcomes,
        loading: false,
      }));
    }
    getInfo();
  }, []);

  const urls = useUrlBuilder(
    params.clientId,
    params.resolutionCaseId,
    params.programSlug
  );

  const updateState = (nextState) => {
    setState((prevState) => ({
      ...prevState,
      ...(typeof nextState === "function" ? nextState(prevState) : nextState),
    }));
  };

  const getEnvelopeAndFullSection = async () => {
    const fullSection = await getProgramSectionService(
      params.clientId,
      resolutionCase.id,
      section.id
    );
    const sectionEnvelope = await getEnvelope(
      params.clientId,
      resolutionCase.id,
      section.id,
      fullSection?.relationships?.children?.[0]?.references
    );
    return {
      sectionEnvelope,
      fullSection,
    };
  };
  if (loading || !outcomes) return null;

  return (
    <div {...scope}>
      <AssistantHeader
        params={params}
        resolutionCase={resolutionCase}
        section={section}
        program={program}
        sectionEnvelope={sectionEnvelope}
        fullSection={fullSection}
        updateState={updateState}
      >
        Outcome Resolution
      </AssistantHeader>
      <CpSwitch expression={params.programSlug}>
        <CpCase value={serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG}>
          <PenaltyAbatementResolution urls={urls} outcomes={outcomes} />
        </CpCase>
        <CpCase value={serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG}>
          <InnocentSpouseResolution
            outcomes={outcomes}
            params={params}
            resolutionCase={resolutionCase}
            urls={urls}
          />
        </CpCase>
        <CpCase value={serviceConst.LIENS_SERVICE_SLUG}>
          <LiensResolution urls={urls} outcomes={outcomes} />
        </CpCase>
        <CpCase value={serviceConst.LEVIES_SERVICE_SLUG}>
          <LeviesResolution urls={urls} outcomes={outcomes} />
        </CpCase>
        <CpCase value={serviceConst.TRUST_FUND_SERVICE_SLUG}>
          <TrustFundResolution urls={urls} outcomes={outcomes} />
        </CpCase>
      </CpSwitch>
    </div>
  );
}

const css = k`
  .cp-outcomes__tabs {
    text-align: center;
    list-style-type: none;
    height: 6.6rem;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .cp-outcomes__tabs__item {
    cursor: pointer;
    float: left;
    padding-left:16px;
    padding-right:16px;
    margin-left: 8px;
    margin-right:8px;
    font-size: 1.4rem;
    min-height: 6.6rem;
    text-align: center;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cp-outcomes__tabs__item__recommended {
    color: var(--primary-green);
    padding-bottom: .3rem;
  }

  .cp-outcomes__tabs__item__recommended--hidden {
    visibility: hidden;
  }

  .cp-outcomes__tabs__item__recommended--no-display {
    display: none;
  }

  .cp-outcomes__tabs__item {
    color: #333333;
    opacity: .5;
    text-decoration: none;
  }

  .cp-outcomes__tabs__item:hover {
    opacity: 1;
  }

  .cp-outcomes__tabs__item--inactive {
    padding-top: 0; /* when there is no bottom border, no padding top for to compensate for centering on the whole 6.6rem; */
  }

  .cp-outcomes__tabs__item--active {
    padding-top: .2rem; /* when there is a bottom border, we want to center the entire tabs section */
    font-weight: 600;
    opacity: 1;

    border-bottom: .2rem solid var(--cps-color-primary);
    box-sizing: border-box;
  }

  .cp-outcomes-caption{
    opacity: .75;
  }

  .cps-card-nonrounded-bottom{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .cps-card-nonrounded-top{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .cps-card-bottom-line{
    border-bottom: 0.1rem solid #E9E9E9;
  }

  .cps-bg-color-danger{
    background-color: #FF8B64 !important;
  }

  .cps-bg-color-warning{
    background-color: #FFC300 !important;
  }

  .cps-bg-color-info{
    background-color: #45A8F8 !important;
  }

  .cp-outcomes__box {
    min-height: 105px;
  }
  .cp-outcomes__input {
    background: 0 0;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
    color: inherit;
    outline: 0;
    font-weight: 400;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  .cp-outcomes__form-icon {
    padding-top: 1.4rem;
    width: 1.8rem;
  }

  .cp-outcomes__file-icon {
    padding-top: .3rem;
    width: 1.8rem;
  }

  .cp-outcomes__skinny-card {
    min-width: 1rem !important;
  }

  .cp-outcomes__full-width {
    max-width: calc(100% - 4rem);
  }

  .cp-outcomes__full-width-card {
    width: 100% !important;
    max-width: 40rem !important;
  }

  .cp-outcomes__card__contracted {
    height: 19rem;
    overflow: hidden;
  }

  .cp-outcomes__card__content__fade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(19rem - 5rem - 7.2rem);
    margin-bottom: 7.2rem;
    background: -webkit-linear-gradient(bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    background: -o-linear-gradient(bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  }

  .cp-outcomes__slat__more-less {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .cp-outcomes__card__content {
    position: relative;
    padding-bottom: 8rem;
  }

  .cp-outcomes__hide-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cp-outcomes__templates__hide-overflow {
    max-width: calc(100% - 9rem) !important;
  }

`;
