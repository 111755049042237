import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  CpButton,
  CpCard,
  CpEmptyState,
  CpLoader,
} from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import {
  getResolutionCaseTemplateService,
  updateResolutionCaseTemplateService,
  deleteResolutionCaseTemplateService,
  createResolutionCaseTemplateStep,
  updateResolutionCaseTemplateStep,
  deleteResolutionCaseTemplateStep,
} from "../../common/resolution-cases.resource";
import { catchAsyncStacktrace } from "auto-trace";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { CreateEditServiceModal } from "../services/create-edit-service-modal.component";
import { DeleteServiceModal } from "./delete-service-modal.component";
import { CreateStepModal } from "../services/create-step-modal.component";
import { SortableTemplateStep } from "./sortable-template-step";

export function ServiceTemplate(props) {
  const { id } = useParams();
  const [user, tenant] = useWithUserAndTenant();
  const [template, setTemplate] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddStepModal, setShowAddStepModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editingStep, setEditingStep] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (!tenant || !id) return;
    getResolutionCaseTemplateService(tenant.id, id).subscribe((result) => {
      setTemplate(result);
    });
  }, [id, tenant]);

  function openEditServiceModal() {
    setShowCreateModal(true);
  }
  function closeServiceModal() {
    setShowCreateModal(false);
  }

  function onSaveService(name, description) {
    setSaving(true);
    const service = { ...template, name, description };
    updateResolutionCaseTemplateService(tenant.id, service).subscribe(
      (result) => {
        setTemplate(service);
        closeServiceModal();
        setSaving(false);
      },
      catchAsyncStacktrace()
    );
  }

  function openDeleteServiceModal() {
    setShowDeleteModal(true);
  }

  function closeDeleteModal() {
    setShowDeleteModal(false);
  }

  function onDeleteService() {
    setSaving(true);
    deleteResolutionCaseTemplateService(tenant.id, template.id).subscribe(
      () => {
        closeDeleteModal();
        setSaving(false);
        window.location = "#/admin/global-settings/service-templates";
      },
      catchAsyncStacktrace()
    );
  }

  function openAddStepModal() {
    setShowAddStepModal(true);
  }

  function closeAddStepModal() {
    setShowAddStepModal(false);
  }

  function onSaveStep(name, description) {
    setSaving(true);
    const data = { name, description, is_removable: true };
    createResolutionCaseTemplateStep(tenant.id, template.id, data).subscribe(
      (newStep) => {
        setTemplate({
          ...template,
          template_steps: [...(template?.template_steps || []), newStep],
        });
        setSaving(false);
        setShowAddStepModal(false);
      }
    );
  }

  function onEditStep(step) {
    setEditingStep(step);
  }

  function onDeleteStep(step) {
    setTemplate({
      ...template,
      template_steps: template.template_steps.filter((s) => step.id !== s.id),
    });
    //I hate this method, but it's how it was done in angularJS and it doesn't look like the backend supports
    //restoring a step with a PUT. Probably because of how workpapers are stored?
    let timeout = setTimeout(() => {
      deleteResolutionCaseTemplateStep(
        tenant.id,
        template.id,
        step.id
      ).subscribe(() => {});
    }, 6000);

    successToast(
      "Step deleted successfully",
      "Undo",
      () => {
        clearTimeout(timeout);
        setTemplate(template); //the closure has the original template with the step that's being deleted
        successToast("Step restored successfully");
      },
      6000
    );
  }

  function onCancelEditStep() {
    setEditingStep(null);
  }

  function onUpdateStep(step, stepName, stepDescription) {
    setEditingStep(null);
    const updatedStep = {
      ...step,
      name: stepName,
      description: stepDescription,
    };
    updateResolutionCaseTemplateStep(
      tenant.id,
      template.id,
      updatedStep
    ).subscribe(() => {
      const { template_steps } = template;
      const index = template_steps.findIndex((s) => s.id === updatedStep.id);
      setTemplate({
        ...template,
        template_steps: Object.assign([], template_steps, {
          [index]: updatedStep,
        }),
      });
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const { template_steps } = template;
      const oldIndex = template_steps.findIndex(
        (step) => step.id === active.id
      );
      const newIndex = template_steps.findIndex((step) => step.id === over.id);
      const newTemplate = {
        ...template,
        template_steps: arrayMove(template_steps, oldIndex, newIndex),
      };
      setTemplate(newTemplate);
      updateResolutionCaseTemplateService(tenant.id, newTemplate).subscribe(
        () => {}
      );
    }
  }

  return !template ? (
    <CpLoader />
  ) : (
    <div className="cps-flexible-focus">
      <CpButton
        btnType="tertiary"
        icon="arrow-line-left"
        className="cp-mb-16"
        onClick={() =>
          (window.location = "#/admin/global-settings/service-templates")
        }
      >
        Back to services
      </CpButton>

      <CpCard className="cp-mb-24 \">
        <div
          className="cp-mt-24 cp-ml-24 cp-mb-8"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="cp-subheader ">{template.name}</div>
          {template.is_removable ? (
            <div className="cp-mr-24">
              <CpButton
                icon="crud-pencil"
                aria-label="Edit"
                onClick={openEditServiceModal}
              />
              <CpButton
                icon="crud-trash-large"
                aria-label="Delete"
                onClick={openDeleteServiceModal}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="cp-wt-semibold cp-body cp-ml-24 cp-mb-24">
          {template.description}
        </div>
      </CpCard>
      <div
        className="cp-body cps-pr-12 cp-pt-32 cp-pl-16"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>{template.name} Steps</div>
        <CpButton btnType="flat" onClick={openAddStepModal}>
          Add a step
        </CpButton>
      </div>
      {template.template_steps && !isEmpty(template.template_steps) ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={template.template_steps}
            strategy={verticalListSortingStrategy}
          >
            {template.template_steps.map((step, index) => (
              <SortableTemplateStep
                key={step.id}
                id={step.id}
                step={step}
                editingStep={editingStep}
                onEditStep={() => onEditStep(step)}
                onDeleteStep={onDeleteStep}
                onCancelEditStep={onCancelEditStep}
                onUpdateStep={onUpdateStep}
              />
            ))}
          </SortableContext>
        </DndContext>
      ) : (
        <CpCard>
          <CpEmptyState
            className="cp-p-32"
            img="es_templates"
            text="No template steps"
            subText="Build your service by adding new steps."
            cta={
              <CpButton btnType="primary" onClick={openAddStepModal}>
                Add step
              </CpButton>
            }
          />
        </CpCard>
      )}
      <CreateEditServiceModal
        show={showCreateModal}
        onClose={closeServiceModal}
        service={template}
        onSubmit={onSaveService}
        saving={saving}
      />
      <DeleteServiceModal
        show={showDeleteModal}
        onClose={closeDeleteModal}
        onSubmit={onDeleteService}
        saving={saving}
      />
      <CreateStepModal
        show={showAddStepModal}
        onClose={closeAddStepModal}
        onSubmit={onSaveStep}
        saving={saving}
      />
    </div>
  );
}
