import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { useHistory } from "react-router-dom";

import { tryRestart } from "../assistant-utils";
import { usePenaltyAbatementOutcomes } from "./penalty-abatement-outcomes.helper";

export function PenaltyAbatementOutro({ outcomes, urls, restartAssistant }) {
  const history = useHistory();

  const {
    onlyFTA,
    onlyReasonableCause,
    bothFTAAndReasonableCause,
    reliefUnlikely,
    nonAssessment,
  } = usePenaltyAbatementOutcomes(outcomes);

  return (
    <div>
      <div className="cp-assistant-outro__recommendation">
        {bothFTAAndReasonableCause && (
          <div>
            <div className="cps-subheader-sm">Recommendation:</div>
            <div className="cps-subheader-sm cps-color-primary">
              First Time Abatement available
            </div>
            <div className="cps-subheader-sm cps-color-primary">
              Reasonable Cause available
              {nonAssessment && <span>(Non-assessment)</span>}
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Penalty Abatement Assistant! Based on your
              answers, your client may qualify for either First Time Abatement
              or Reasonable Cause penalty relief.
            </div>
          </div>
        )}
        {reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm">
              Recommendation:{" "}
              <span className="cps-warning">Penalty Relief unlikely</span>
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Penalty Abatement Assistant! Based on your
              answers, penalty relief for your client is unlikely. Sometimes it
              doesn't hurt to try though - check out our outcomes to learn more.
            </div>
          </div>
        )}
        {onlyFTA && (
          <div>
            <div className="cps-subheader-sm">
              Recommendation:{" "}
              <span className="cps-color-primary">
                First Time Abatement available
              </span>
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Penalty Abatement Assistant! Based on your
              answers, your client may qualify for First Time Abatement relief.
            </div>
          </div>
        )}
        {onlyReasonableCause && (
          <div>
            <div className="cps-subheader-sm">
              Recommendation:{" "}
              <span className="cps-color-primary">
                Reasonable Cause available
                {nonAssessment && <span> (non-assessment)</span>}
              </span>
            </div>
            {nonAssessment ? (
              <div className="cp-assistant-outro__recommendation__explanation">
                Thank you for using the Penalty Abatement Assistant! Based on
                your answers, your client may qualify for non-assessment of a
                penalty due to Reasonable Cause.
              </div>
            ) : (
              <div className="cp-assistant-outro__recommendation__explanation">
                Thank you for using the Penalty Abatement Assistant! Based on
                your answers, your client may qualify for Reasonable Cause
                penalty relief.
              </div>
            )}
          </div>
        )}
      </div>

      <div className="end-of-assistant-actions">
        <CpButton onClick={() => history.push(urls.resolution)}>
          Go to outcomes
        </CpButton>
        <CpButton btnType="flat" onClick={() => tryRestart(restartAssistant)}>
          Restart assistant
        </CpButton>
      </div>
    </div>
  );
}
