// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dashboard-resolution-cases-table-steps-resolution-case-detail-view-resolution-case-detail-view-styles__normalLink--17KGT {\n  text-decoration: none;\n}\n\n.src-dashboard-resolution-cases-table-steps-resolution-case-detail-view-resolution-case-detail-view-styles__bold--1iI_7 {\n  font-weight: 700 !important;\n}\n\n.src-dashboard-resolution-cases-table-steps-resolution-case-detail-view-resolution-case-detail-view-styles__titleListItem--2otl6 {\n  background-color: #f2f2f2 !important;\n}\n.src-dashboard-resolution-cases-table-steps-resolution-case-detail-view-resolution-case-detail-view-styles__titleListItem--2otl6:hover {\n  background-color: #f2f2f2 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"normalLink": "src-dashboard-resolution-cases-table-steps-resolution-case-detail-view-resolution-case-detail-view-styles__normalLink--17KGT",
	"bold": "src-dashboard-resolution-cases-table-steps-resolution-case-detail-view-resolution-case-detail-view-styles__bold--1iI_7",
	"titleListItem": "src-dashboard-resolution-cases-table-steps-resolution-case-detail-view-resolution-case-detail-view-styles__titleListItem--2otl6"
};
module.exports = exports;
