import React, { useState, useMemo } from "react";
import {
  CpInput,
  CpNameChip,
  CpDropdown,
  CpButton,
  CpTooltip,
} from "canopy-styleguide!sofe";
import { hasAccess } from "cp-client-auth!sofe";

import styles from "./collaborator-picker.styles.css";
export function CollaboratorPicker({
  activeTeamMembers,
  assignee = {},
  onSelect,
  onRemove,
  openInviteNewTeamMember,
  requiredPermission,
}) {
  const [searchVal, setSearchVal] = useState("");

  const filteredByPermission = useMemo(() => {
    return activeTeamMembers.filter((user) =>
      hasAccess(user, true)(requiredPermission)
    );
  }, [JSON.stringify(activeTeamMembers)]);

  return (
    <CpDropdown
      allowContentClicks
      contentWidth={300}
      position="bottom-start"
      renderTrigger={({ toggle }) =>
        assignee.id ? (
          <CpButton
            style={{ padding: "0 .4rem" }}
            btnType="flat"
            onClick={toggle}
          >
            <CpNameChip className={styles.assigneeLabel} name={assignee.name} />
          </CpButton>
        ) : (
          <CpTooltip text="Add team member">
            <CpButton
              onClick={toggle}
              icon="person-add"
              aria-label="Add team member"
            />
          </CpTooltip>
        )
      }
      renderContent={({ close }) => (
        <div>
          <div className={styles.dialogHeader}>
            <CpInput
              onChange={setSearchVal}
              value={searchVal}
              placeholder="Type a collaborator's name"
            />
          </div>
          <div>
            {filteredByPermission
              .filter(({ name }) =>
                name.toLowerCase().includes(searchVal.toLowerCase())
              )
              .map((teamMember) => {
                const { id, name } = teamMember;
                const isSelected = assignee.id === id;
                return (
                  <div key={id} className={styles.dialogListItem}>
                    <button
                      className={styles.dialogListItemButton}
                      key={id}
                      onClick={() => {
                        onSelect(teamMember);
                        close();
                      }}
                    >
                      <CpNameChip
                        className="cp-mr-8"
                        name={name}
                        active={isSelected}
                      />{" "}
                      {name}
                    </button>
                    {isSelected && (
                      <CpButton
                        icon="close-large"
                        onClick={onRemove}
                        aria-label="Remove assignee"
                      />
                    )}
                  </div>
                );
              })}
          </div>
          {openInviteNewTeamMember && (
            <div className={styles.dialogFooter}>
              <CpButton btnType="flat" onClick={openInviteNewTeamMember}>
                + Create a new team member
              </CpButton>
            </div>
          )}
        </div>
      )}
    />
  );
}
