import React from "react";
import styles from "./comments/comments.styles.css";
import { getFileIconPath } from "../resolution-case/sections/pages/workpapers/attachments.utils";
import { CpButton, CpCard, CpLoader } from "canopy-styleguide!sofe";
import { applicationProps } from "../resolution-cases-ui";

function formatBytes(bytes, precision) {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
    return "-";
  }

  if (typeof precision === "undefined") {
    precision = 1;
  }

  let units = ["B", "KB", "MB", "GB", "TB"];
  let number = Math.floor(Math.log(bytes) / Math.log(1024));
  let val = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);

  if (number === 0) return "Less than 1KB";

  return (
    (val.match(/\.0*$/) ? val.substr(0, val.indexOf(".")) : val) + units[number]
  );
}

export function AttachmentCard({
  attachment,
  isDownloadable,
  onDelete,
  loading,
}) {
  function handleDownload() {
    SystemJS.import("docs-ui!sofe").then((m) => {
      m.isFileSafeToOpen(attachment.file_id || attachment.id).then(
        ({ trust, virusStatuses, file }) => {
          if (trust) {
            window.location = file.download_link;
          } else {
            const el = document.getElementById(`file-${attachment.id}`);
            const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
              virusFound: virusStatuses.infectedDocs.length > 0,
              domElement: el,
              handleCloseModal() {
                parcel.unmount();
              },
            });
          }
        }
      );
    });
  }

  if (loading) {
    return (
      <CpCard className={styles.attachmentsContainerLoading}>
        <CpLoader />
      </CpCard>
    );
  }

  return (
    <CpCard className={styles.attachmentContainer}>
      <div>
        <div className="cp-mr-16">
          <img
            width="25"
            src={getFileIconPath(attachment.filename || attachment.name)}
            aria-hidden
          />
        </div>
      </div>
      <div className={styles.flexEllipsis}>
        <div className="cp-body-sm cp-wt-semibold">
          {attachment.filename || attachment.name}
        </div>
        <div className="cp-caption cp-color-app-disabled-text">
          Size: {formatBytes(attachment.filesize)}
        </div>
      </div>
      <div className={styles.attachmentActions}>
        <span id={`file-${attachment.id}`}></span>
        {isDownloadable && (
          <CpButton icon="af-open-down" onClick={handleDownload} />
        )}
        {onDelete && (
          <CpButton
            icon="crud-trash-small"
            onClick={() => onDelete(attachment.id)}
          />
        )}
      </div>
    </CpCard>
  );
}
