import React from "react";
import { renderInfoModal } from "../../common/render-info-modal.component";

export function LeviesIssuedErroneously() {
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Was the levy issued <a onClick={issuedErroneouslyModal}>erroneously</a>{" "}
        in some other way?
      </strong>
    </div>
  );
}

function issuedErroneouslyModal() {
  renderInfoModal(
    "Erroneous Levy",
    <>
      A levy that is served in violation of an administrative procedure or law.
    </>
  );
}
