import React from "react";
import { Link, useParams } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { renderInfoModal } from "../../common/render-info-modal.component";
import { useLiensOutcomes } from "../liens-outcomes.helper";

export function LiensDischarge({ outcomes, urls }) {
  const params = useParams();
  const { dischargeAvailable, cantCalculateOutcomes } =
    useLiensOutcomes(outcomes);
  return (
    <div>
      <div>
        <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
          <div className="cps-card__body">
            <div className="cps-row cps-margin-top-8 cps-padding-left-8">
              <div className="cps-body">
                Recommendation:{" "}
                <span>
                  {dischargeAvailable && (
                    <span className="cps-color-primary">
                      Lien Discharge available
                    </span>
                  )}
                  {!dischargeAvailable && !cantCalculateOutcomes && (
                    <span className="cps-warning">Lien Discharge unlikely</span>
                  )}
                </span>
                {cantCalculateOutcomes && (
                  <span className="cps-light-gray">
                    Recommendation unavailable
                  </span>
                )}
              </div>
              <div>
                {dischargeAvailable && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, your client may qualify for Lien Discharge. Check
                    out the resources below for additional help.
                  </div>
                )}
                {!dischargeAvailable && !cantCalculateOutcomes && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, Lien Discharge for your client is unlikely.
                    Sometimes it doesn't hurt to try though - check out the
                    resources below to learn more.
                  </div>
                )}

                {cantCalculateOutcomes && (
                  <div>
                    We cannot provide a recommendation at this time. Please
                    complete the{" "}
                    <Link to={urls.assistant}>Liens Assistant</Link> if you
                    would like custom recommendations for your client.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Summary>
          <p>
            A lien discharge removes a lien from a specific piece of property to
            allow the taxpayer to sell that property, usually because the sale
            will benefit the IRS. When a lien discharge is granted, the taxpayer
            has to pay the IRS only the remaining proceeds from the sale (if
            any). Additionally, an injured third party can request a lien
            discharge when transferring property.
          </p>
          <p>
            When the IRS receives the taxpayer's application for a lien
            discharge, it will carefully research your client's account and
            examine all information. This can entail conversations with an
            appraiser, title company, and others associated with the real estate
            transaction. The IRS may also request other documents and
            information, such as photos of the property, an explanation of
            closing cost amounts, and an explanation of the relationship of the
            parties involved in the transaction.
          </p>
          <p>
            A lien discharge can be requested if any one of the following
            conditions is met:
          </p>

          <ul>
            <li>
              <strong>
                IRS receives remaining proceeds of property sale (IRC §
                6325(b)(2)(A))
              </strong>{" "}
              - The IRS will discharge the lien if the net proceeds of the
              property sale will be paid to the IRS.
              <p>
                <i>Example:</i> Your client wants to sell property worth
                $50,000, and the buyer wants clear title to the property being
                purchased. The property has debt and expenses of sale equal to
                $45,000. Your client owes the IRS $80,000. Your client can
                request a lien discharge and pay the IRS $5,000 at closing.
              </p>
            </li>
            <li>
              <strong>
                No equity in property sold (short sale or break-even sale) (IRC
                § 6325(b)(2)(B))
              </strong>{" "}
              - The IRS may discharge the lien if the owner has no equity in the
              property (the debt and expenses on the property exceed its value).
              The IRS would not receive proceeds from this sale.
              <p>
                <i>Example:</i> Your client wants to sell property worth
                $50,000, and the buyer wants clear title to the property being
                purchased. The property has debt and expenses of sale equal to
                $65,000. Your client owes the IRS $80,000. Your client can
                request a lien discharge because he or she has no equity in the
                property.
              </p>
            </li>
            <li>
              <strong>
                Money remains after sale of property, and proceeds require
                escrow (auction or foreclosure) (IRC § 6325(b)(3))
              </strong>{" "}
              - In this situation, secured creditors, including the IRS, are
              disputing their respective rights to the sale proceeds, which are
              held in an escrow account while the disputes are settled.
              <p>
                <i>Example:</i> Your client's property is foreclosed and sold
                for $100,000. Lien amounts between multiple parties total
                $150,000. There is a dispute about who is in priority. One of
                the parties can apply for a lien discharge to allow him or her
                to have clear title while the dispute over the proceeds is
                resolved.
              </p>
            </li>
            <li>
              <strong>
                Purchased property with a federal tax lien attached (IRC §
                6325(b)(4))
              </strong>{" "}
              - In this situation, your client must deposit the amount of money
              needed to discharge the lien or furnish a surety bond for the same
              amount.
              <p>
                <i>Example:</i> Your client purchased property in 2001 that was
                subject to a tax lien. He or she did not obtain a certificate of
                discharge upon purchase, so the lien was still in effect on the
                transferred property. Your client wants to sell the property,
                free of the tax lien. She or he should request a lien discharge
                and deposit the amount to satisfy the lien.
              </p>
            </li>
            <li>
              <strong>
                Other property is worth twice the debt to the IRS (IRC §
                6325(b)(1))
              </strong>{" "}
              - Though rarely done, the IRS may discharge a lien on a piece of
              property to allow your client to sell that property if he or she
              owns other property worth twice the tax debt. In short, the IRS
              would do this because it is in the government's best interest to
              go after the more valuable property.
              <p>
                The fair market value of the remaining, unsold properties must
                be at least double the sum of:
              </p>
              <ul>
                <li>
                  The unsatisfied tax liability secured by the lien
                  <br />
                  <br />
                </li>
                <li>The encumbrances on remaining properties</li>
              </ul>
              <p>
                <i>Example:</i> Your client wants to sell property worth $50,000
                and has debt and expenses of sale of $45,000. The buyer wants
                clear title to the property being purchased. Your client owns
                other property with a fair market value of $300,000 and
                associated loans of $40,000. Your client owes the IRS $80,000.
                Your client can request a lien discharge on the first property
                because the second property is worth more than twice the IRS
                liability ($300,000/$80,000 + $40,000).
              </p>
            </li>
          </ul>

          <p>The following steps outline the lien discharge process:</p>

          <ul>
            <li>The IRS files a lien at your client's local courthouse.</li>
            <li>
              Your client is selling property and meets the lien discharge
              qualifications.
            </li>
            <li>
              Prepare the lien discharge package including Form 14135,
              Application for Certificate of Discharge of Property from Federal
              Tax Lien, with all of the required documentation. Refer to
              Publication 783, Instructions on How to Apply for Certificate of
              Discharge from Federal Tax Lien.
            </li>
            <li>
              Submit the lien discharge request to the IRS Advisory Group.
            </li>
            <li>
              Wait <a onClick={waitForDeterminationModal}>30 to 90 days</a> for
              an IRS determination.
            </li>
            <li>
              If the IRS approves the request, it issues a conditional
              commitment letter for a certificate of discharge.
            </li>
            <li>
              If the IRS does not approve the discharge request, follow up
              immediately with the Advisory Group manager and, if necessary,
              consider filing an appeal.
            </li>
          </ul>
        </Summary>

        <div className="cps-fixed-masonry">
          <div className="cps-row">
            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Templates</strong>
                </div>
                <a
                  className="cps-slat"
                  href="https://cdn.canopytax.com/static/liens/discharge/AttachmentToForm14135.docx"
                >
                  <div className="cps-slat__badge cps-padding-left-24">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                      className="cp-outcomes__file-icon"
                    />
                  </div>
                  <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                    Attachment to Form 14135
                  </div>
                </a>
              </div>
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Contact info</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>Mailing Form 14135</strong>
                    <br />
                    IRS, Attn: Advisory Group Manager
                    <br />
                    Refer to{" "}
                    <a
                      href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                      target="_blank"
                    >
                      Publication 4235
                    </a>{" "}
                    Collection Advisory Group Addresses for the correct address
                    to send Form 14135. The correct address is the office
                    assigned to the location where the property is located.
                  </p>

                  <p>
                    <strong>Other</strong>
                    <br />
                    If you want to know how much you owe in order to pay the
                    lien(s) in full, call 1-800-913-6050, visit the nearest
                    Taxpayer Assistance Center, or contact the revenue officer
                    assigned to your case. You can also obtain a copy of lost
                    liens or check the status of liens or requests.
                  </p>
                </div>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Links and articles</strong>
                </div>

                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/Businesses/Small-Businesses-&-Self-Employed/Understanding-a-Federal-Tax-Lien"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Understanding a Federal Tax Lien
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p783.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 783 - Certificate of Discharge instructions
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 4235 - Collection Advisory Group contact
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p1468.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Guidelines for processing Notice of Federal Tax Lien
                        documents
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
              </div>
            </div>

            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>IRS forms</strong>
                </div>

                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/CJQLB/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 14135
                    </div>
                    Application for Certificate of Discharge of Property from
                    Federal Tax Lien
                  </div>
                </Link>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Best practices</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>Notice of Federal Tax Lien life</strong> - A
                    certificate of discharge does not affect the life of the
                    Notice of Federal Tax Lien. It does not extinguish the tax
                    liability nor does it preclude the IRS from determining
                    additional tax or penalties.
                  </p>
                  <p>
                    <strong>Specifically described property</strong> - A
                    certificate of discharges does not remove the federal tax
                    lien from any property or asset other than that particular
                    one requested in the discharge application. A certificate of
                    discharge removes only the specifically described property
                    from the effects of the lien
                  </p>
                  <p>
                    <strong>Form 14135</strong> - If you are submitting Form
                    14135 on behalf of the taxpayer as a Power of Attorney, Box
                    4 must be checked, and a copy of the in-force Form 2848 must
                    be attached. Ensure that all required documents as outlined
                    in Publication 783 are included with the submission of Form
                    14135.
                  </p>
                  <p>
                    <strong>Stand-alone actions</strong> - Lien subordination
                    and lien discharge are stand-alone actions, not to be
                    combined with one another, or with a release or withdrawal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function waitForDeterminationModal() {
  renderInfoModal(
    "Wait 30 to 90 days for an IRS determination",
    <p>
      This can be a large disadvantage. If a client is considering selling a
      piece of property and applying the proceeds toward her/his tax debt, it is
      often better to remove the lien before putting it on the market and
      finding a willing buyer.
    </p>
  );
}
