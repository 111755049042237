import React from "react";
import { Summary } from "../../common/summary.component";
import { renderInfoModal } from "../../common/render-info-modal.component";
import { Link } from "react-router-dom";
import { useLiensOutcomes } from "../liens-outcomes.helper";

export function LiensRelease({ outcomes, urls }) {
  const { releaseAvailable, cantCalculateOutcomes } =
    useLiensOutcomes(outcomes);
  return (
    <div>
      <div>
        <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
          <div className="cps-card__body">
            <div className="cps-row cps-margin-top-8 cps-padding-left-8">
              <div className="cps-body">
                Recommendation:{" "}
                <span>
                  {releaseAvailable && (
                    <span className="cps-color-primary">
                      Lien Release available
                    </span>
                  )}
                  {!releaseAvailable && !cantCalculateOutcomes && (
                    <span className="cps-warning">Lien Release unlikely</span>
                  )}
                </span>
                {cantCalculateOutcomes && (
                  <span className="cps-light-gray">
                    Recommendation unavailable
                  </span>
                )}
              </div>
              <div>
                {releaseAvailable && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, your client may qualify for Lien Release. Check out
                    the resources below for additional help.
                  </div>
                )}
                {!releaseAvailable && !cantCalculateOutcomes && (
                  <div>
                    Thank you for using the Liens Assistant! Based on your
                    answers, Lien Release for your client is unlikely. Sometimes
                    it doesn't hurt to try though - check out the resources
                    below to learn more.
                  </div>
                )}

                {cantCalculateOutcomes && (
                  <div>
                    We cannot provide a recommendation at this time. Please
                    complete the{" "}
                    <Link to={urls.assistant}>Liens Assistant</Link> if you
                    would like custom recommendations for your client.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Summary>
          <p>
            A lien does not attach to only a particular piece of property. A
            Notice of Federal Tax Lien (NFTL) attaches to all assets (tangible,
            intangible) that the liable Taxpayer has, has interest in, or may
            have in the future until the tax liability is fully satisfied.
          </p>

          <p>
            Section 6325(a) of the Internal Revenue Code directs the IRS to
            release a Federal Tax Lien no later than 30 days after a liability
            becomes fully paid or legally unenforceable (e.g., the statute of
            limitations for collecting the tax has expired). The IRS also must
            release a lien when it accepts a bond for payment of the tax.
          </p>

          <p>
            Paying by certified or cashier's check can expedite the release. A
            lien release can be issued immediately with proof of payment if
            specifically requested by the Taxpayer. One caveat is that an
            immediate release is not available if full payment is made via
            credit or debit card.
          </p>

          <p>
            You may request a lien release when any of the following conditions
            are met:
          </p>

          <ul>
            <li>
              Your client paid the entire balance but did not receive a
              certificate of release (IRC § 6325(a)(1))
            </li>
            <li>
              The tax balance is discharged in bankruptcy (IRC § 6325(a)(1))
            </li>
            <li>
              Your client obtained a surety bond from a U.S. Treasury-approved
              surety company (IRC § 6325(a)(2))
            </li>
            <li>The collection statute has expired (IRC § 6325(a)(1))</li>
          </ul>

          <p>The following steps outline the lien release process:</p>

          <ul>
            <li>
              Your client meets the requirements to obtain a lien release as
              listed above
            </li>
            <li>
              A Federal Tax Lien will automatically{" "}
              <a onClick={automaticReleaseModal}>release</a> after full
              satisfaction of the tax liability or expiration of the Collection
              Statute (within 30 days).
            </li>
            <li>
              If the IRS has not released the lien within 30 days, you can
              request a Certificate of Release of Federal Tax Lien. Publication
              1450, Instructions for Requesting a Certificate of Release of
              Federal Tax Lien should be used for this request.
            </li>
          </ul>
        </Summary>

        <div className="cps-fixed-masonry">
          <div className="cps-row">
            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Templates</strong>
                </div>
                <a
                  className="cps-slat"
                  href="https://cdn.canopytax.com/static/liens/release/Letter-RequestForCertificateOfRelease.docx"
                >
                  <div className="cps-slat__badge cps-padding-left-24">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                      className="cp-outcomes__file-icon"
                    />
                  </div>
                  <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                    Letter - Request for Certificate of Release
                  </div>
                </a>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Contact info</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>Mailing letter and appropriate attachments</strong>
                    <br />
                    IRS, Attn: Advisory Group Manager
                    <br />
                    Refer to{" "}
                    <a
                      href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                      target="_blank"
                    >
                      Publication 4235
                    </a>{" "}
                    Collection Advisory Group Addresses for the correct address
                    to send the letter. The correct address is the office
                    assigned to the location where the property is located.
                  </p>

                  <p>
                    <strong>Other</strong>
                    <br />
                    If you want to know how much you owe in order to pay the
                    lien(s) in full, call 1-800-913-6050, visit the nearest
                    Taxpayer Assistance Center, or contact the revenue officer
                    assigned to your case. You can also obtain a copy of lost
                    liens or check the status of liens or requests.
                  </p>
                </div>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Links and articles</strong>
                </div>

                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/Businesses/Small-Businesses-&-Self-Employed/Understanding-a-Federal-Tax-Lien"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Understanding a Federal Tax Lien
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p1450.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 1450 - Certificate of Release instructions
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p4235.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 4235 - Collection Advisory Group contact
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/pub/irs-pdf/p1468.pdf"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Guidelines for processing Notice of Federal Tax Lien
                        documents
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
              </div>
            </div>

            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Best practices</strong>
                </div>

                <div className="cps-card__body">
                  <ul>
                    <li>
                      <strong>Lien release vs withdrawal</strong> - A lien
                      release is not the same as a lien withdrawal. A lien
                      release is the IRS' relinquishment of rights to a
                      taxpayer's assets or rights/interests in an asset due to a
                      tax liability. A lien withdrawal requests the IRS to
                      withdraw the Notice of Federal Tax Lien from public
                      record.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Other release circumstances</strong> - A lien can
                      self-release by the expiration of the Collection Statute
                      Expiration Date (CSED). A lien can be released due to
                      other circumstances or events including full payment of
                      the tax liability, bankruptcy discharge of the debt, or by
                      taxpayer obtaining a surety bond.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Be proactive</strong> - Just because a lien is
                      supposed to self-release does not mean that it will. You
                      are in a good position to advocate this for your client
                      and by reviewing the CSED dates, satisfaction of the debt
                      in full, bankruptcy, etc.., and taking proactive measures
                      to ask for its release.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Partial release</strong> - Taxpayers can request a
                      partial release of the Federal Tax Lien (FTL) under
                      certain circumstances. A partial release may be applicable
                      when more than one taxpayer is listed on the original FTL
                      and one of the two satisfies her/his tax obligation. A
                      partial release may also be possible when a FTL lists
                      multiple periods of liabilities and one or more are
                      satisfied. A request can be made by the taxpayer to have
                      the satisfied periods annotated "partial release."
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Credit reporting agencies</strong> - The IRS does
                      not report Notice of Federal Tax Liens (NFTL's) directly
                      to the credit reporting agencies. The NFTL is a public
                      document filed at the recorder/clerk office in the county
                      of the taxpayer's residence. It alerts the public
                      (including other creditors) that the IRS is asserting a
                      secured claim against the taxpayer's assets. The NFTL
                      attaches to all assets (property, tangible, intangible) a
                      taxpayer has, has interest in, or may have in the future,
                      until the tax liability is settled in full. With that
                      said, credit reporting agencies do take note of NFTL's.
                      Although the IRS does not specifically give each credit
                      reporting agency notice of a lien's filing, it is not
                      hidden either. A NFTL can negatively impact a taxpayer's
                      credit score and hence his/her borrowing ability. Be aware
                      that a lien release neither removes the NFTL from public
                      record, nor affects a credit report. To resolve credit
                      report issues, see the lien withdrawal outcome page.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Simultaneous requests</strong> - The
                      Commissioner's 2011 Fresh Start Initiative allows
                      withdrawal of your Notice of Federal Tax Lien after the
                      lien's release, but has the following requirements: 1)
                      Your tax liability has been satisfied and your lien has
                      been released, 2) You are in compliance for the past three
                      years in filing (all individual returns, business returns,
                      and information returns), and 3) You are current on your
                      estimated tax payments and federal tax deposits, as
                      applicable.
                      <p>
                        Whenever possible, do not be satisfied with obtaining
                        only a release if your client meets the requirements of
                        having a full withdrawal. Remember - a withdrawal is far
                        better because it is as though the lien never existed.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function automaticReleaseModal() {
  renderInfoModal(
    "Automatic release",
    <>
      <p>
        You can check the column titled "Last Day for Refiling" which is usually
        directly under the name and address on the lien document to determine if
        the lien is self-released. On the day following the "Last Day for
        Refiling" the NFTL itself will operate as a certificate of release as
        defined by IRC 6325(a).
      </p>
      <p>
        Since a lien may include multiple liabilities, each will have its own
        self-release point. Full satisfaction of the tax liability means all the
        liabilities shown on the lien must be satisfied or self-released before
        the NFTL will be considered fully released.
      </p>
    </>
  );
}
