/* Jdee and our resolution experts have drawn up a state machine for the questions and outcomes of innocent spouse.
 * This is our implementation of the state machine. Each state is an object with a function that, when called with a value, returns
 * the next state. For sanity's sake, the names of the states are the answers that led to that state (Y/N), followed by
 * a somewhat human readable string that represents the question. If you don't like this, then feel free to hate us.
 * However, I felt it made sense at the time :D
 *
 * Additionally, when you reach an outcome state, the outcomes are returned as part of the state, as an array of string
 * constants. If no outcome state is reached because not enough answers have been provided, then nextState will be null
 * and outcomes will be null.
 */

import { innocentSpouseConstants } from "./innocent-spouse.constants.js";

// -----------------------------------------------------
// create all of the recognized states of the finite state automaton
// -----------------------------------------------------
const states = {};

states.INITIAL_STATE = createBinaryState(
  innocentSpouseConstants.INTRO,
  "Y_JOINT_RETURN",
  "Y_JOINT_RETURN"
);

states.Y_JOINT_RETURN = createBinaryState(
  innocentSpouseConstants.JOINT_RETURN,
  "YY_MULTI_SELECT",
  "YN_COMMUNITY_PROPERTY_STATE"
);

states.YY_MULTI_SELECT = createMultiSelectState(
  innocentSpouseConstants.MULTI_SELECT
);

states.YYA_ERRONEOUS_ITEMS = createBinaryState(
  innocentSpouseConstants.ERRONEOUS_ITEMS,
  "YYAY_KNOW_UNDERSTATEMENT",
  "YYAN_SEPARATED"
);

states.YYAY_KNOW_UNDERSTATEMENT = createBinaryState(
  innocentSpouseConstants.KNOW_UNDERSTATEMENT,
  "YYAYY_SEPARATED",
  "YYAYN_UNFAIR_13"
);

states.YYAYN_UNFAIR_13 = createBinaryState(
  innocentSpouseConstants.UNFAIR_13,
  [innocentSpouseConstants.INNOCENT_SPOUSE],
  "YYAYNN_SEPARATED"
);

states.YYAN_SEPARATED =
  states.YYAYY_SEPARATED =
  states.YYAYNN_SEPARATED =
    createBinaryState(
      innocentSpouseConstants.SEPARATED,
      [innocentSpouseConstants.SEPARATION_OF_LIABILITY],
      "YYBNY_SAME_HOUSEHOLD"
    );

states.YYBNY_SAME_HOUSEHOLD = createBinaryState(
  innocentSpouseConstants.SAME_HOUSEHOLD,
  "YYBNYY_UNFAIR_7",
  [innocentSpouseConstants.SEPARATION_OF_LIABILITY]
);

states.YYB_PAID_TAX = createBinaryState(
  innocentSpouseConstants.PAID_TAX,
  [innocentSpouseConstants.RELIEF_UNLIKELY],
  "YYBN_UNFAIR_7"
);

states.YYBN_UNFAIR_7 =
  states.YNYY_UNFAIR_7 =
  states.YNYNN_UNFAIR_7 =
  states.YNYNYY_UNFAIR_7 =
  states.YYBNYY_UNFAIR_7 =
    createBinaryState(
      innocentSpouseConstants.UNFAIR_7,
      "YYBNY_TRANSFER_TO_SPOUSE",
      [innocentSpouseConstants.RELIEF_UNLIKELY]
    );

states.YYBNY_TRANSFER_TO_SPOUSE = createBinaryState(
  innocentSpouseConstants.TRANSFER_TO_SPOUSE,
  [innocentSpouseConstants.RELIEF_UNLIKELY],
  "YYBNYN_TRANSFER_TO_CLIENT"
);

states.YYBNYN_TRANSFER_TO_CLIENT = createBinaryState(
  innocentSpouseConstants.TRANSFER_TO_CLIENT,
  [innocentSpouseConstants.RELIEF_UNLIKELY],
  "YYBNYNN_FRAUD_INTENT"
);

states.YYBNYNN_FRAUD_INTENT = createBinaryState(
  innocentSpouseConstants.FRAUD_INTENT,
  [innocentSpouseConstants.RELIEF_UNLIKELY],
  "YYBNYNNN_SPOUSE_ITEM"
);

states.YYBNYNNN_SPOUSE_ITEM = createBinaryState(
  innocentSpouseConstants.SPOUSE_ITEM,
  [innocentSpouseConstants.EQUITABLE_RELIEF],
  [innocentSpouseConstants.RELIEF_UNLIKELY]
);

states.YN_COMMUNITY_PROPERTY_STATE = createBinaryState(
  innocentSpouseConstants.COMMUNITY_PROPERTY_STATE,
  "YNY_INCLUDE_IN_GROSS_INCOME",
  [innocentSpouseConstants.RELIEF_UNLIKELY]
);

states.YNY_INCLUDE_IN_GROSS_INCOME = createBinaryState(
  innocentSpouseConstants.INCLUDE_IN_GROSS_INCOME,
  "YNYY_UNFAIR_7",
  "YNYN_COMMUNITY_INCOME_RELATED"
);

states.YNYN_COMMUNITY_INCOME_RELATED = createBinaryState(
  innocentSpouseConstants.COMMUNITY_INCOME_RELATED,
  "YNYNY_KNOW_COMMUNITY_INCOME",
  "YNYNN_UNFAIR_7"
);

states.YNYNY_KNOW_COMMUNITY_INCOME = createBinaryState(
  innocentSpouseConstants.KNOW_COMMUNITY_INCOME,
  "YNYNYY_UNFAIR_7",
  "YNYNYN_UNFAIR_18"
);

states.YNYNYN_UNFAIR_18 = createBinaryState(
  innocentSpouseConstants.UNFAIR_18,
  [innocentSpouseConstants.COMMUNITY_PROPERTY],
  [innocentSpouseConstants.RELIEF_UNLIKELY]
);

//----------------------------------------------------
function createMultiSelectState(questionSlug) {
  let result = function (answer, previousQuestionSlug) {
    let nextPlaceToGo;

    if (answer === "understatement") {
      nextPlaceToGo = "YYA_ERRONEOUS_ITEMS";
    } else if (answer === "underpayment") {
      nextPlaceToGo = "YYB_PAID_TAX";
    } else if (answer === "neither") {
      nextPlaceToGo = [innocentSpouseConstants.RELIEF_UNLIKELY];
    }

    if (!nextPlaceToGo) {
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    }

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (typeof nextPlaceToGo === "string") {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }

      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(
        `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`
      );
    }
  };

  result.questionSlug = questionSlug;
  return result;
}

// -----------------------------------------------------
// TODO: Move the below methods into a shared helper
// -----------------------------------------------------

function createBinaryState(questionSlug, stateIfYes, stateIfNo) {
  const result = function (value, previousQuestionSlug) {
    let nextPlaceToGo;

    if (value === true) {
      nextPlaceToGo = stateIfYes;
    } else if (value === false) {
      nextPlaceToGo = stateIfNo;
    } else {
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    }

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (typeof nextPlaceToGo === "string") {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }

      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(
        `Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`
      );
    }
  };

  result.questionSlug = questionSlug;
  return result;
}

export function innocentSpouseStateMachine(answers = {}, questionToStopAt) {
  const initialState = {
    reachedOutcome: false,
    outcomes: innocentSpouseConstants.OUTCOME_NOT_YET_CALCULABLE,
    nextState: states.INITIAL_STATE,
    nextQuestionSlug: innocentSpouseConstants.INTRO,
    currentQuestionSlug: null,
    previousQuestionSlug: null,
    nextQuestionIsOutcome: false,
  };
  answers = JSON.parse(JSON.stringify(answers));

  return traverse(answers, questionToStopAt, initialState);
}

function traverse(answers, questionToStopAt, currentState) {
  const newState = currentState.nextState(
    answers[currentState.nextQuestionSlug],
    currentState.currentQuestionSlug
  );
  if (currentState.nextQuestionSlug === questionToStopAt) {
    return createResultForNonOutcomeState(currentState, newState);
  } else {
    if (newState.reachedOutcome) {
      newState.previousQuestionSlug = newState.currentQuestionSlug;
      newState.currentQuestionSlug = null;
      return newState;
    } else if (!newState.nextState) {
      return newState;
    } else {
      return traverse(answers, questionToStopAt, newState);
    }
  }
}

function createResultForNonOutcomeState(state, newState) {
  return {
    reachedOutcome: false,
    outcomes: null,
    nextState: null,
    nextQuestionSlug: newState ? newState.nextQuestionSlug : null,
    nextQuestionIsOutcome: !!newState.reachedOutcome,
    currentQuestionSlug: state.nextQuestionSlug,
    previousQuestionSlug: state.currentQuestionSlug,
  };
}
