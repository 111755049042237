import React, { useState } from "react";

import { penaltyAbatementConstants } from "./penalty-abatement.constants";
import { PenaltyAbatementFirstTime } from "./outcomes/penalty-abatement-first-time.component";
import { PenaltyAbatementReasonableCause } from "./outcomes/penalty-abatement-reasonable-cause.component";
import { usePenaltyAbatementOutcomes } from "./penalty-abatement-outcomes.helper";
import {
  OutcomeHeader,
  OutcomeHeaderItem,
} from "../common/outcome-header.component";

export function PenaltyAbatementResolution({ urls, outcomes }) {
  const { firstTimeAbatementAvailable, reasonableCauseAvailable } =
    usePenaltyAbatementOutcomes(outcomes);
  const [resolutionType, setResolutionType] = useState(
    (() => {
      if (!firstTimeAbatementAvailable && reasonableCauseAvailable) {
        return penaltyAbatementConstants.REASONABLE_CAUSE;
      }
      return penaltyAbatementConstants.FIRST_TIME_ABATEMENT;
    })()
  );

  if (!resolutionType) return null;

  return (
    <div>
      <OutcomeHeader value={resolutionType} onChange={setResolutionType}>
        <OutcomeHeaderItem
          id={penaltyAbatementConstants.FIRST_TIME_ABATEMENT}
          active={firstTimeAbatementAvailable}
        >
          First time abatement
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={penaltyAbatementConstants.REASONABLE_CAUSE}
          active={reasonableCauseAvailable}
        >
          Reasonable cause
        </OutcomeHeaderItem>
      </OutcomeHeader>

      {resolutionType === penaltyAbatementConstants.FIRST_TIME_ABATEMENT && (
        <PenaltyAbatementFirstTime outcomes={outcomes} urls={urls} />
      )}
      {resolutionType === penaltyAbatementConstants.REASONABLE_CAUSE && (
        <PenaltyAbatementReasonableCause outcomes={outcomes} urls={urls} />
      )}
    </div>
  );
}
