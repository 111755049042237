import React from "react";
import { get } from "lodash";
import {
  filterOutProgramsWithoutRelevantSections,
  addClientRequestsToResolutionCase,
} from "./resolution-case-detail-view.helper.js";
import { Link } from "react-router-dom";
import styles from "./resolution-case-detail-view.styles.css";

export default class ResolutionCasesDetailView extends React.PureComponent {
  render() {
    const filteredResolutionCase = filterOutProgramsWithoutRelevantSections(
      this.props.fullResolutionCase,
      this.props.status
    );
    const patchedResolutionCase = addClientRequestsToResolutionCase(
      filteredResolutionCase,
      this.props.clientRequests,
      this.props.status
    );
    const clientId = patchedResolutionCase.relationships.for.id;
    return (
      <div>
        {get(patchedResolutionCase, "program_data.programs", []).map(
          (program) => {
            return (
              <div key={program.uid}>
                <li className={`${styles.titleListItem}`}>
                  <a className={`${styles.bold}`}>{program.name}</a>
                </li>
                {program.sections.map((section) => {
                  return (
                    <li key={section.id}>
                      <Link
                        to={this.getLinkLocation(
                          program,
                          section,
                          clientId,
                          patchedResolutionCase.id
                        )}
                        className={`${styles.normalLink} cps-general cps-wt-regular`}
                      >
                        {section.name || section.title}
                      </Link>
                    </li>
                  );
                })}
              </div>
            );
          }
        )}
      </div>
    );
  }

  getLinkLocation = (program, section, clientId, resolutionCaseId) => {
    if (program.slug === "client_requests") {
      return `/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/client_requests/${section.id}`;
    } else {
      return `/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/program/${program.slug}/section/${section.slug}`;
    }
  };
}
