// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dashboard-filter-dropdown-filter-dropdown-styles__wideDropdown--9aL1x {\n  width: 350px;\n  max-height: 500px;\n  overflow: auto;\n}\n\n.src-dashboard-filter-dropdown-filter-dropdown-styles__pill--TUOpT {\n  border-radius: 5px;\n  min-width: 80px;\n  display: flex;\n}\n\n.src-dashboard-filter-dropdown-filter-dropdown-styles__blue__pill--3RTsu {\n  background-color: var(--cps-color-pill-bg);\n  border: solid 1px var(--cps-color-pill-text);\n}\n\n.src-dashboard-filter-dropdown-filter-dropdown-styles__pill__text--xp4Il {\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  height: 100%;\n  font-weight: normal;\n  flex: 4;\n  white-space: nowrap;\n}\n\n.src-dashboard-filter-dropdown-filter-dropdown-styles__blue__pill__text--3mmZ7 {\n  color: var(--cps-color-pill-text);\n}\n\n.src-dashboard-filter-dropdown-filter-dropdown-styles__pill__action--1R6l7 {\n  flex: 1;\n}\n\n.src-dashboard-filter-dropdown-filter-dropdown-styles__name--3uKJM {\n  text-overflow: ellipsis;\n  max-width: 27rem;\n  overflow: hidden;\n  white-space: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"wideDropdown": "src-dashboard-filter-dropdown-filter-dropdown-styles__wideDropdown--9aL1x",
	"pill": "src-dashboard-filter-dropdown-filter-dropdown-styles__pill--TUOpT",
	"blue__pill": "src-dashboard-filter-dropdown-filter-dropdown-styles__blue__pill--3RTsu",
	"pill__text": "src-dashboard-filter-dropdown-filter-dropdown-styles__pill__text--xp4Il",
	"blue__pill__text": "src-dashboard-filter-dropdown-filter-dropdown-styles__blue__pill__text--3mmZ7",
	"pill__action": "src-dashboard-filter-dropdown-filter-dropdown-styles__pill__action--1R6l7",
	"name": "src-dashboard-filter-dropdown-filter-dropdown-styles__name--3uKJM"
};
module.exports = exports;
