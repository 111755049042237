import React from "react";
import { useCss, k } from "kremling";

const penalties = [
  "IRC 6038",
  "IRC 6038A",
  "IRC 6039E",
  "IRC 6651(a)(1)",
  "IRC 6651(a)(2)",
  "IRC 6651(a)(3)",
  "IRC 6652(a)(1)",
  "IRC 6652(c)(1)",
  "IRC 6652(c)(2)",
  "IRC 6652(d)(2)",
  "IRC 6652(e)",
  "IRC 6652(h)",
  "IRC 6652(i)",
  "IRC 6652(j)",
  "IRC 6662",
  "IRC 6662A",
  "IRC 6656(a)",
  "IRC 6657",
  "IRC 6692",
  "IRC 6698",
  "IRC 6699",
  "IRC 6721",
  "IRC 6722",
  "IRC 6723",
];

export function PenaltyAbatementPenaltyType() {
  const scope = useCss(css);
  return (
    <div {...scope} className="cps-subheader-sm cp-text-center">
      <div className="cps-padding-bottom-32">
        <strong>Is your client's penalty listed in the table below?</strong>
      </div>

      <div className="penalty-type-columns">
        {penalties.map((penalty) => (
          <div key={penalty}>{penalty}</div>
        ))}
      </div>
    </div>
  );
}

const css = k`
  .penalty-type-columns {
    margin: 0 auto;
    font-size: 1.4rem;
    width: 80rem;
    column-count: 5;
    column-rule: solid .1rem var(--cp-color-app-border);
  }

  .penalty-type-columns > div {
    text-align: left;
    padding-left: 2.4rem;
    padding-bottom: 1.6rem;
  }
`;
