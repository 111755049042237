import styles from "./resolution-cases-list.styles.css";
import { Link } from "react-router-dom";
import React, { useMemo } from "react";
import { CpButton, CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import { DateTime } from "luxon";

export function ResolutionCasesListItem({
  resolutionCase,
  clientId,
  listType,
  onDelete,
  onArchive,
  onMakeActive,
}) {
  const formattedDate = useMemo(
    () =>
      DateTime.fromISO(resolutionCase.updated_at).toFormat("MMM d, y h:mm a"),
    [resolutionCase.updated_at]
  );

  function renderResolutionCaseItemActions() {
    const deleteBtn = (
      <CpTooltip text="Delete">
        <CpButton
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
          btnType="icon"
          className="cp-mr-8"
          icon="crud-trash-large"
          aria-label={`Delete ${resolutionCase.name}`}
        />
      </CpTooltip>
    );

    const moveToActiveBtn = (
      <CpButton
        btnType="flat"
        onClick={(e) => {
          e.preventDefault();
          onMakeActive();
        }}
      >
        Move to active
      </CpButton>
    );

    switch (listType) {
      case "archived":
        return (
          <>
            {deleteBtn}
            {moveToActiveBtn}
          </>
        );
      case "trash":
        return moveToActiveBtn;
      default:
        return (
          <>
            {deleteBtn}
            <CpTooltip text="Archive">
              <CpButton
                onClick={(e) => {
                  e.preventDefault();
                  onArchive(resolutionCase.id);
                }}
                btnType="icon"
                icon="crud-archive"
                aria-label={`Archive ${resolutionCase.name}`}
              />
            </CpTooltip>
          </>
        );
    }
  }

  return (
    <Link
      key={resolutionCase.id}
      className={styles.resolutionCasesListItem}
      to={`/taxes/client/${clientId}/resolution-cases/${resolutionCase.id}/summary`}
    >
      <div
        className={`${styles.resolutionCasesListItemHeader} ${styles.flexEllipsis}`}
      >
        <div className={styles.resolutionCasesListItemIcon}>
          <CpIcon
            className="cp-color-app-primary-dark-text"
            name="misc-document-calculator"
          />
        </div>
        <div>
          <div className="cp-subheader-sm cp-wt-regular cp-color-app-primary-text cp-ellipsis">
            {resolutionCase.name}{" "}
            <span className="cp-body-sm cp-color-app-secondary-text">
              - Updated {formattedDate}
            </span>
          </div>
          <div className="cp-body cp-color-app-primary-text cp-ellipsis">
            {resolutionCase.description}
          </div>
        </div>
      </div>
      <div className={styles.resolutionCasesListItemActions}>
        {renderResolutionCaseItemActions()}
      </div>
    </Link>
  );
}
