import fetcher, { fetchAsObservable, fetchWithSharedCache } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import canopyUrls from "canopy-urls!sofe";

export function getResolutionCasesList(clientId, listType) {
  let query = "";
  if (listType === "trash") {
    query = "?onlyTrashed=true";
  } else if (listType === "archived") {
    query = "?onlyArchived=true";
  }
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases_list${query}`
  ).pipe(pluck("resolution_cases"));
}

export function getResolutionCase(clientId, resolutionCaseId) {
  if (!clientId)
    throw new Error(
      `Cannot get resolution case without being provided a clientId`
    );
  if (!resolutionCaseId)
    throw new Error(
      `Cannot get resolution case without being provided an resolutionCaseId`
    );

  return fetchWithSharedCache(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`,
    `/resolution-cases/${resolutionCaseId}`
  ).pipe(pluck("resolution_cases"));
}

export function getResolutionCaseRequests(clientId, resolutionCaseId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests`
  ).pipe(pluck("client_requests"));
}

export function getServiceTemplatesList(tenantId) {
  return fetchAsObservable(`/api/tenants/${tenantId}/servicetemplates`).pipe(
    pluck("service_templates")
  );
}

export function createResolutionCaseTemplateService(tenantId, service) {
  return fetchAsObservable(`/api/tenants/${tenantId}/servicetemplates`, {
    method: "POST",
    body: { service_templates: service },
  }).pipe(pluck("service_templates"));
}

export function getResolutionCaseTemplateService(tenantId, serviceId) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/servicetemplates/${serviceId}`
  ).pipe(pluck("service_templates"));
}

export function updateResolutionCaseTemplateService(tenantId, service) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/servicetemplates/${service.id}`,
    {
      method: "PUT",
      body: { service_templates: service },
    }
  ).pipe(pluck("service_templates"));
}

export function deleteResolutionCaseTemplateService(tenantId, serviceId) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/servicetemplates/${serviceId}`,
    {
      method: "DELETE",
    }
  );
}

export function createResolutionCaseTemplateStep(tenantId, serviceId, step) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/servicetemplates/${serviceId}/steps`,
    {
      method: "POST",
      body: { service_template_steps: step },
    }
  ).pipe(pluck("service_template_steps"));
}

export function getResolutionCaseTemplateStep(tenantId, serviceId, stepId) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/servicetemplates/${serviceId}/steps/${stepId}`
  );
}

export function updateResolutionCaseTemplateStep(tenantId, serviceId, step) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/servicetemplates/${serviceId}/steps/${step.id}`,
    {
      method: "PUT",
      body: { service_template_steps: step },
    }
  );
}

export function deleteResolutionCaseTemplateStep(tenantId, serviceId, stepId) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/servicetemplates/${serviceId}/steps/${stepId}`,
    {
      method: "DELETE",
    }
  );
}

export function createResolutionCase(clientId, resolutionCase) {
  return fetchAsObservable(`/api/clients/${clientId}/resolution_cases`, {
    method: "POST",
    body: { resolution_cases: resolutionCase },
  }).pipe(pluck("resolution_cases"));
}

export function updateResolutionCaseObs(clientId, resolutionCaseId, payload) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`,
    {
      method: "PATCH",
      body: { resolution_cases: payload },
    }
  ).pipe(pluck("resolution_cases"));
}

export function updateResolutionCasePromise(clientId, resolutionCase) {
  if (!clientId)
    throw new Error(
      `Cannot update resolution case without being provided a clientId`
    );
  if (!resolutionCase)
    throw new Error(
      `Cannot update resolution case without being provided an resolution case`
    );

  //This is a stale value that is no longer allowed at this endpoint
  delete resolutionCase.archived_at;

  //Use fetcher here because it updates the cache for resolution-cases-menu
  return fetcher(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${
      resolutionCase.id
    }`,
    {
      method: "PUT",
      body: { resolution_cases: resolutionCase },
    }
  ).then((data) => {
    return data.json();
  });
}

export function deleteResolutionCase(clientId, resolutionCaseId) {
  return updateResolutionCaseObs(clientId, resolutionCaseId, {
    is_archived: false,
    is_deleted: true,
  });
}

export function archiveResolutionCase(clientId, resolutionCaseId) {
  return updateResolutionCaseObs(clientId, resolutionCaseId, {
    is_archived: true,
  });
}

export function restoreResolutionCase(clientId, resolutionCaseId) {
  return updateResolutionCaseObs(clientId, resolutionCaseId, {
    is_archived: false,
    is_deleted: false,
  });
}

export function getResolutionCaseHistory(clientId, resolutionCaseId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/history`
  ).pipe(pluck("object_history"));
}

export function updateProgramSection(
  clientId,
  resolutionCaseId,
  sectionId,
  resolutionCaseSections,
  notifications
) {
  const payload = {
    resolution_case_sections: resolutionCaseSections,
  };

  if (notifications) {
    payload.notifications = notifications;
  }

  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}`,
    {
      method: "PUT",
      body: payload,
    }
  ).toPromise();
}

export function getClientData(clientId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}?include=users`
  ).pipe(pluck("clients"));
}

export function getProgramSectionService(
  clientId,
  resolutionCaseId,
  sectionId
) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}`
  )
    .pipe(pluck("resolution_case_sections"))
    .toPromise();
}

export function addWorkpaper(clientId, resolutionCaseId, sectionId, workpaper) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}/workpapers/`,
    {
      method: "POST",
      body: { workpapers: workpaper },
    }
  )
    .pipe(pluck("resolution_cases"))
    .toPromise();
}

export function getEnvelope(clientId, resolutionCaseId, sectionId, envelopeId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}/envelopes/${envelopeId}`
  )
    .pipe(pluck("envelopes"))
    .toPromise();
}

export function updateEnvelope(
  clientId,
  resolutionCaseId,
  sectionId,
  envelopeId,
  envelope
) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}/envelopes/${envelopeId}`,
    {
      method: "PUT",
      body: { envelopes: envelope },
    }
  );
}
