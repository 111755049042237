import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Letters } from "./letters.component";

export default function LetterRoutes() {
  if (window.location.href.includes("engagements")) {
    // Remove this when we add engagements routes
    window.location.replace(
      window.location.href.replace("engagements", "resolution-cases")
    );
  }
  return (
    <>
      <Route
        exact
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/letter-generator"
        component={Letters}
      />
      <Route
        exact
        path="/taxes/client/:clientId/resolution-cases/:resolutionCaseId/letter-generator/:letterId"
        render={({ match }) => (
          <Redirect
            to={{
              pathname: `/taxes/client/${match.params.clientId}/resolution-cases/${match.params.resolutionCaseId}/letter-generator`,
              state: { letterId: match.params.letterId },
            }}
          />
        )}
      />
    </>
  );
}
