import React from "react";
import { useHistory } from "react-router-dom";
import { CpButton } from "canopy-styleguide!sofe";

import { tryRestart } from "../assistant-utils";
import { useLeviesOutcomes } from "./levies-outcomes.helper";

export function LeviesOutro({ urls, restartAssistant, outcomes }) {
  const history = useHistory();
  const {
    reliefUnlikely,
    appealsAvailable,
    preventingLevyAvailable,
    releasingLevyAvailable,
    returningPropertyAvailable,
  } = useLeviesOutcomes(outcomes);
  return (
    <div>
      <div className="cp-assistant-outro__recommendation">
        <div className="cps-subheader-sm">Recommendation:</div>

        {!reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm cps-color-primary">
              {appealsAvailable && (
                <span>
                  Appeals available
                  <br />
                </span>
              )}
              {preventingLevyAvailable && (
                <span>
                  Preventing Levy available
                  <br />
                </span>
              )}
              {releasingLevyAvailable && (
                <span>
                  Releasing Levy available
                  <br />
                </span>
              )}
              {returningPropertyAvailable && (
                <span>
                  Returning Property available
                  <br />
                </span>
              )}
            </div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Levies Assistant! Based on your answers,
              your client may qualify for the outcome(s) listed above.
            </div>
          </div>
        )}

        {reliefUnlikely && (
          <div>
            <div className="cps-subheader-sm cps-warning">Relief unlikely</div>
            <div className="cp-assistant-outro__recommendation__explanation">
              Thank you for using the Levies Assistant! Based on your answers,
              relief for your client is unlikely. Sometimes it doesn't hurt to
              try though - check out our outcomes to learn more.
            </div>
          </div>
        )}
      </div>

      <div className="end-of-assistant-actions">
        <CpButton onClick={() => history.push(urls.resolution)}>
          Go to outcomes
        </CpButton>
        <CpButton btnType="flat" onClick={() => tryRestart(restartAssistant)}>
          Restart assistant
        </CpButton>
      </div>
    </div>
  );
}
