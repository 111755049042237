import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getAnswers(clientId, resolutionCaseId, programSlug) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/services/${programSlug}/answers`
  )
    .pipe(pluck("answers"))
    .toPromise();
}

export function saveAnswers(clientId, resolutionCaseId, programSlug, answers) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/services/${programSlug}/answers`,
    {
      method: "PUT",
      body: answers,
    }
  ).toPromise();
}
