import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  CpButton,
  CpCard,
  CpEmptyStateCard,
  CpLoader,
} from "canopy-styleguide!sofe";
import { catchAsyncStacktrace } from "auto-trace";
import {
  getServiceTemplatesList,
  createResolutionCaseTemplateService,
  updateResolutionCaseTemplateService,
  deleteResolutionCaseTemplateService,
} from "../../common/resolution-cases.resource";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import { CreateEditServiceModal } from "../services/create-edit-service-modal.component";
import { DeleteServiceModal } from "./delete-service-modal.component";

export function ResolutionCaseTemplate() {
  const [user, tenant] = useWithUserAndTenant();
  const userHasAccess = hasAccess(user);
  const [canopyServices, setCanopyServices] = useState(null);
  const [customServices, setCustomServices] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editService, setEditService] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!tenant) return;
    getServiceTemplatesList(tenant.id).subscribe((services) => {
      setCanopyServices(services.filter((service) => !service.is_removable));
      setCustomServices(services.filter((service) => service.is_removable));
    }, catchAsyncStacktrace());
  }, [tenant]);

  function openCreateServiceModal() {
    setShowCreateModal(true);
  }

  function openEditServiceModal(evt, service) {
    evt.preventDefault();
    setEditService(service);
    setShowCreateModal(true);
  }

  function closeServiceModal() {
    setShowCreateModal(false);
    setEditService(null);
  }

  function onSaveService(name, description) {
    setSaving(true);
    if (editService) {
      const service = { ...editService, name, description };
      updateResolutionCaseTemplateService(tenant.id, service).subscribe(() => {
        setCustomServices(
          customServices.map((s) => (s.id === service.id ? service : s))
        );
        closeServiceModal();
        setSaving(false);
      }, catchAsyncStacktrace());
    } else {
      const service = {
        name,
        description,
      };
      createResolutionCaseTemplateService(tenant.id, service).subscribe(
        (service) => {
          window.location = `#/admin/global-settings/service-templates/${service.id}`;
        },
        catchAsyncStacktrace()
      );
    }
  }

  function openDeleteServiceModal(evt, service) {
    evt.preventDefault();
    setServiceToDelete(service);
    setShowDeleteModal(true);
  }

  function closeDeleteModal() {
    setShowDeleteModal(false);
    setServiceToDelete(null);
  }

  function onDeleteService() {
    setSaving(true);
    deleteResolutionCaseTemplateService(
      tenant.id,
      serviceToDelete.id
    ).subscribe(() => {
      setCustomServices(
        customServices.filter((s) => serviceToDelete.id !== s.id)
      );
      closeDeleteModal();
      setSaving(false);
    }, catchAsyncStacktrace());
  }

  return !canopyServices || !customServices || !user ? (
    <CpLoader />
  ) : (
    <div className="cps-flexible-focus">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <div className="cp-subheader cp-pt-8 cp-pl-16 cp-mb-24">
          Tax Resolution Case Templates
        </div>
        {userHasAccess("custom_services") && (
          <CpButton btnType="flat" onClick={openCreateServiceModal}>
            Add a custom service
          </CpButton>
        )}
      </div>

      {userHasAccess("tax_resolution_services") && (
        <CpCard>
          {canopyServices.map((service) => (
            <Link
              key={service.id}
              to={{
                pathname: `/admin/global-settings/service-templates/${service.id}`,
              }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="cps-slat-lg">
                <div className="cps-slat-lg__content cp-service-templates__cps-slat-lg__content__stretch">
                  <div className="cps-slat-lg__content__title cps-wt-semibold">
                    {service.name}
                  </div>
                  {service.description}
                </div>
              </div>
            </Link>
          ))}
        </CpCard>
      )}

      {userHasAccess("custom_services") &&
        !userHasAccess("tax_resolution_services") &&
        isEmpty(customServices) && (
          <CpEmptyStateCard
            img="es_templates"
            text="No service templates"
            subText="You don't have any service templates yet. Create a new service template to get started."
            cta={
              <CpButton btnType="primary" onClick={openCreateServiceModal}>
                Create a new service template
              </CpButton>
            }
          />
        )}

      {userHasAccess("custom_services") && !isEmpty(customServices) && (
        <>
          <div className="cp-body cp-wt-light cp-pt-32 cps-pb-12 cp-pl-16 cp-mb-24">
            Custom Services
          </div>
          <CpCard>
            {customServices.map((service) => (
              <Link
                key={service.id}
                to={{
                  pathname: `/admin/global-settings/service-templates/${service.id}`,
                }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="cps-slat-lg">
                  <div className="cps-slat-lg__content cp-service-templates__cps-slat-lg__content__stretch">
                    <div className="cps-slat-lg__content__title cps-wt-semibold">
                      {service.name}
                    </div>
                    {service.description}
                  </div>
                  <div
                    className="cps-slat-lg__actions +hover-show cp-pt-8"
                    style={{ zIndex: "3" }}
                  >
                    <CpButton
                      icon="crud-pencil"
                      aria-label="Edit"
                      onClick={(e) => openEditServiceModal(e, service)}
                    />
                    <CpButton
                      icon="crud-trash-large"
                      aria-label="Delete"
                      onClick={(e) => openDeleteServiceModal(e, service)}
                    />
                  </div>
                </div>
              </Link>
            ))}
          </CpCard>
        </>
      )}
      <CreateEditServiceModal
        show={showCreateModal}
        onClose={closeServiceModal}
        service={editService}
        onSubmit={onSaveService}
        saving={saving}
      />
      <DeleteServiceModal
        show={showDeleteModal}
        onClose={closeDeleteModal}
        onSubmit={onDeleteService}
        saving={saving}
      />
    </div>
  );
}
