import React, { useState } from "react";
import { useCss, k } from "kremling";
import { useController } from "react-hook-form";
import { CpIcon, CpCheckbox } from "canopy-styleguide!sofe";

export function CheckboxClickableField({
  control,
  fieldName,
  label,
  children,
}) {
  const scope = useCss(css);
  const [open, setOpen] = useState(false);

  const {
    field: { value, onChange },
  } = useController({
    control,
    defaultValue: false,
    name: fieldName,
  });

  return (
    <div {...scope} className="checkbox-clickable">
      <div className="checkbox-clickable__row">
        <div
          className="checkbox-clickable__checkbox"
          onClick={() => onChange(!value)}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <CpCheckbox
              type="checkbox"
              className="checkbox-override"
              onChange={onChange}
              checked={value}
            />
          </div>
        </div>
        {children ? (
          <button
            className="checkbox-clickable__row-right"
            onClick={() => setOpen(!open)}
          >
            {label}
            <CpIcon
              name={open ? "caret-large-up" : "caret-large-down"}
              fill="var(--cp-app-icon)"
              aria-label={open ? "close" : "open"}
            />
          </button>
        ) : (
          <div className="checkbox-clickable__row-right-no-click">{label}</div>
        )}
      </div>
      {open && <div className="checkbox-clickable__content">{children}</div>}
    </div>
  );
}

const css = k`
  .checkbox-clickable {
    font-size: 1.4rem;
    overflow: hidden;
    border-radius: .8rem;
    margin-bottom: .8rem;
  }
  
  .checkbox-clickable__checkbox {
    display: flex;
    height: 4rem;
    width: 4rem;
    align-items: center;
    justify-content: center;
    border-radius: .8rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000, .07);
    }
  }

  .checkbox-clickable__row {
    border-radius: .8rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    cursor: default;
  }
  
  .checkbox-clickable__row-right {
    flex-grow: 1;
    padding: 0 .8rem 0 1.6rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: .8rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    
    &:hover {
      background-color: rgba(#000, .07);
    }
  }
  
  .checkbox-clickable__row-right-no-click {
    flex-grow: 1;
    padding: 0 .8rem 0 1.6rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkbox-override {
    > div {
      margin: 0;
    }
  }
  
  .checkbox-clickable__content {
    overflow: hidden;
    padding: .8rem 4.8rem 1.6rem 5.6rem;
  }
`;
