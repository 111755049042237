// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-common-change-status-styles__circleIcon--15LZS {\n  height: 2.2rem;\n  width: 2.2rem;\n  border-radius: 100vh;\n}\n\n.src-common-change-status-styles__circleGreen--14BFP {\n  background: var(--cp-color-radio-selected-bg);\n}\n\n.src-common-change-status-styles__circleRed--1WF_5 {\n  background: var(--cp-color-input-error-border);\n}\n\n.src-common-change-status-styles__circleButton--1hAAk {\n  padding: 0.4rem 0.6rem !important;\n}\n\n.src-common-change-status-styles__notificationsModalListItem--1-8xm {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 0.8rem;\n}\n\n.src-common-change-status-styles__notificationsModalListItemName--_7OaP {\n  display: flex;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"circleIcon": "src-common-change-status-styles__circleIcon--15LZS",
	"circleGreen": "src-common-change-status-styles__circleGreen--14BFP",
	"circleRed": "src-common-change-status-styles__circleRed--1WF_5",
	"circleButton": "src-common-change-status-styles__circleButton--1hAAk",
	"notificationsModalListItem": "src-common-change-status-styles__notificationsModalListItem--1-8xm",
	"notificationsModalListItemName": "src-common-change-status-styles__notificationsModalListItemName--_7OaP"
};
module.exports = exports;
