export const liensConstants = {
  // Outcomes
  APPEAL: "APPEAL",
  DISCHARGE: "DISCHARGE",
  RELEASE: "RELEASE",
  SUBORDINATION: "SUBORDINATION",
  WITHDRAWAL: "WITHDRAWAL",

  RELIEF_UNLIKELY: "RELIEF_UNLIKELY",
  OUTCOME_NOT_YET_CALCULABLE: null,

  // Question slugs
  INTRO: "intro", //0
  PAID_IN_FULL: "paid_in_full", //1
  PLANS_AND_MEANS: "plans_and_means", //2
  LIEN_WITHDRAWAL: "lien_withdrawal", //3
  INSTALLMENT_LESS_THAN_25000: "installment_less_than_25000", //4
  INVALID_LIEN: "invalid_lien", //5
  DISPUTE_TAX: "dispute_tax", //6
  // this question was eliminated from the flow //7
  PROPOSE_ALTERNATIVE: "propose_alternative", //8
  REASONABLE_CAUSE: "reasonable_cause", //9
  BANKRUPTCY: "bankruptcy", //10
  SURETY_BOND: "surety_bond", //11
  STATUTE_EXPIRED: "statute_expired", //12
  PURCHASE_PROPERTY: "purchase_property", //13
  DOUBLE_VALUE: "double_value", //14
  SELL_PROPERTY: "sell_property", //15
  PROCEEDS_TO_IRS: "proceeds_to_irs", //16
  LESS_THAN_ZERO: "less_than-zero", //17
  PROCEEDS_IN_ESCROW: "proceeds_in_escrow", //18
  RESTRUCTURE: "restructure", //19
  REFINANCING: "refinancing", //20
  LINE_OF_CREDIT: "line_of_credit", //21
};
