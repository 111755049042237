import React, { useState } from "react";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";

import { trustFundConstants } from "./trust-fund.constants";
import {
  OutcomeHeader,
  OutcomeHeaderItem,
} from "../common/outcome-header.component";
import { useTrustFundOutcomes } from "./trust-fund-outcomes.helper";
import { TrustFundAppeals } from "./outcomes/trust-fund-appeals.component";
import { TrustFundEmploymentTax } from "./outcomes/trust-fund-employment-tax.component";
import { TrustFundTfrpDefense } from "./outcomes/trust-fund-tfrp-defense.component";

export function TrustFundResolution({ urls, outcomes }) {
  const {
    appealAvailable,
    employmentTaxWarningAvailable,
    employmentTaxAvailable,
    TFRPDefenseAvailable,
  } = useTrustFundOutcomes(outcomes);
  const [resolutionType, setResolutionType] = useState(
    (() => {
      if (appealAvailable) {
        return trustFundConstants.APPEAL;
      } else if (employmentTaxAvailable || employmentTaxWarningAvailable) {
        return trustFundConstants.EMPLOYMENT_TAX;
      } else if (TFRPDefenseAvailable) {
        return trustFundConstants.TFRP_DEFENSE;
      }
      return trustFundConstants.APPEAL;
    })()
  );
  return (
    <>
      <OutcomeHeader value={resolutionType} onChange={setResolutionType}>
        <OutcomeHeaderItem
          id={trustFundConstants.APPEAL}
          active={appealAvailable}
        >
          Appeals
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={trustFundConstants.EMPLOYMENT_TAX}
          testIdAgainst={[
            trustFundConstants.EMPLOYMENT_TAX,
            trustFundConstants.EMPLOYMENT_TAX_WARNING,
          ]}
          active={employmentTaxAvailable || employmentTaxWarningAvailable}
        >
          Business-level resolution
        </OutcomeHeaderItem>
        <OutcomeHeaderItem
          id={trustFundConstants.TFRP_DEFENSE}
          active={TFRPDefenseAvailable}
        >
          Individual-level resolution
        </OutcomeHeaderItem>
      </OutcomeHeader>

      <CpSwitch expression={resolutionType}>
        <CpCase value={trustFundConstants.APPEAL}>
          <TrustFundAppeals urls={urls} outcomes={outcomes} />
        </CpCase>
        <CpCase value={trustFundConstants.EMPLOYMENT_TAX}>
          <TrustFundEmploymentTax urls={urls} outcomes={outcomes} />
        </CpCase>
        <CpCase value={trustFundConstants.TFRP_DEFENSE}>
          <TrustFundTfrpDefense urls={urls} outcomes={outcomes} />
        </CpCase>
      </CpSwitch>
    </>
  );
}
