// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-resolution-case-services-services-styles__servicesContainer--oQ6PM {\n  max-width: 136rem;\n  margin: 0 auto;\n}\n\n.src-resolution-case-services-services-styles__serviceItem--2QeoP {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0.8rem 1.6rem;\n}\n\n.src-resolution-case-services-services-styles__serviceItemTitle--3LJC8 {\n  display: flex;\n  align-items: center;\n}\n\n.src-resolution-case-services-services-styles__serviceItem--2QeoP:not(:last-child) {\n  border-bottom: solid 1px var(--cps-color-border);\n}\n", ""]);
// Exports
exports.locals = {
	"servicesContainer": "src-resolution-case-services-services-styles__servicesContainer--oQ6PM",
	"serviceItem": "src-resolution-case-services-services-styles__serviceItem--2QeoP",
	"serviceItemTitle": "src-resolution-case-services-services-styles__serviceItemTitle--3LJC8"
};
module.exports = exports;
