import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";

export function PenaltyAbatementCompliance() {
  const TOOLTIP =
    "If the taxpayer is not currently in compliance, they are ineligible at this time. However, there is an opportunity to pursue this again as soon as they are in compliance.";
  return (
    <div className="cps-subheader-sm cp-text-center">
      <strong>
        Has your client filed all currently required returns, or has she/he
        filed the appropriate extensions (i.e., is your client in{" "}
        <CpTooltip text={TOOLTIP}>
          <a>compliance</a>
        </CpTooltip>{" "}
        for their filing requirements)?
      </strong>
    </div>
  );
}
