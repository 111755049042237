import React from "react";
import { Link } from "react-router-dom";

import { renderInfoModal } from "../../common/render-info-modal.component";
import { Summary } from "../../common/summary.component";
import { useInnocentSpouseOutcome } from "../innocent-spouse-outcomes.helper";

export function InnocentSpouseOutcome({ outcomes, urls, params }) {
  const { innocentSpouseAvailable, cantCalculateOutcomes } =
    useInnocentSpouseOutcome(outcomes);
  return (
    <div>
      <div>
        <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
          <div className="cps-card__body">
            <div className="cps-row cps-margin-top-8 cps-padding-left-8">
              <div className="cps-body">
                Recommendation:{" "}
                <span>
                  {innocentSpouseAvailable && (
                    <span className="cps-color-primary">
                      Innocent Spouse Relief available
                    </span>
                  )}
                  {!innocentSpouseAvailable && !cantCalculateOutcomes && (
                    <span className="cps-warning">
                      Innocent Spouse Relief unlikely
                    </span>
                  )}
                </span>
                {cantCalculateOutcomes && (
                  <span className="cps-light-gray">
                    Recommendation unavailable
                  </span>
                )}
              </div>
              <div>
                <div>
                  {innocentSpouseAvailable && (
                    <div>
                      Thank you for using the Innocent Spouse Assistant! Based
                      on your answers, your client may qualify for Innocent
                      Spouse Relief. Check out the resources below for
                      additional help.
                    </div>
                  )}
                  {!innocentSpouseAvailable && !cantCalculateOutcomes && (
                    <div>
                      Thank you for using the Innocent Spouse Assistant! Based
                      on your answers, Innocent Spouse Relief for your client is
                      unlikely. Sometimes it doesn't hurt to try though - check
                      out the resources below to learn more.
                    </div>
                  )}
                </div>

                {cantCalculateOutcomes && (
                  <div>
                    We cannot provide a recommendation at this time. Please
                    complete the{" "}
                    <Link to={urls.assistant}>Innocent Spouse Assistant</Link>{" "}
                    if you would like custom recommendations for your client.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Summary>
          <p>
            Although many taxpayers enjoy tax benefits from filing joint
            returns, those benefits may be outweighed by an unpaid balance or
            tax deficiency on a return. Generally speaking, each spouse has
            joint and several liability for tax amounts due. This means that she
            or he may be held fully responsible if the other spouse does not
            pay. Innocent spouse relief can relieve the taxpayer of her/his
            responsibility for paying tax, interest, and penalties if the
            taxpayer's current or former spouse improperly reported or omitted
            items on a tax return.
          </p>
          <p>
            Amounts owed that qualify for relief for the taxpayer become
            collectable only from the taxpayer's current or former spouse.
            However, the taxpayer will still be responsible for any tax,
            interest, or penalties that do not qualify for innocent spouse
            relief. In other words, the IRS can still collect these amounts from
            either the taxpayer or the taxpayer's current or former spouse. In
            addition, innocent spouse relief does not prevent the IRS from
            pursuing remedies against interest in community property or against
            the duty to pay tax on half the spouse's income in a community
            property state. In these situations, you may wish to explore relief
            options through IRC 66. For additional information regarding relief
            for community property states, please see the community property
            outcome.
          </p>
          <div>
            The taxpayer must meet all of the following conditions to qualify
            for innocent spouse relief:
            <ol>
              <li>
                Taxpayer filed a joint return unless{" "}
                <a onClick={openSignedUnderDuressModal}>signed under duress</a>.
                (Remember, a Married Filing Separate return does not qualify!)
                <br />
                <br />
              </li>
              <li>
                There is an{" "}
                <a onClick={openUnderstatedTaxModal}>understated tax</a> on the
                return that is due to{" "}
                <a onClick={openErroneousItemsModal}>erroneous items</a> of the
                taxpayer's spouse (or former spouse). Keep in mind that innocent
                spouse relief is not available for unpaid tax.
                <br />
                <br />
              </li>
              <li>
                Taxpayer can show that when the the joint return was signed, the
                taxpayer <a onClick={openDidNotKnowModal}>did not know</a>, and
                had <a onClick={openNoReasonToKnowModal}>no reason to know</a>
                , that the understated tax existed (or the extent to which the
                understated tax existed). This means the burden of proof is on
                the taxpayer.
                <br />
                <br />
              </li>
              <li>
                Taking into account all the facts and circumstances, it would be{" "}
                <a onClick={openUnfairModal}>unfair</a> to hold the taxpayer
                liable for the understated tax.
                <br />
                <br />
              </li>
              <li>
                Taxpayer must elect relief no later than two years after
                collection activity begins with respect to the requesting
                spouse. This could also mean two years from the issuance of a
                Collection Due Process (CDP), two years from taking a refund, or
                completion of a suit with the IRS (which could occur many years
                later)
              </li>
            </ol>
          </div>
          <p>
            Innocent spouse relief will not be granted if the IRS proves that
            the taxpayer and the taxpayer's spouse (or former spouse)
            transferred property to one another as part of a fraudulent scheme.
            A fraudulent scheme includes a scheme to defraud the IRS or another
            third party, such as a creditor, former spouse, or business partner.
          </p>
        </Summary>

        <div className="cps-fixed-masonry">
          <div className="cps-row">
            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Templates</strong>
                </div>
                <a
                  className="cps-slat"
                  href="https://cdn.canopytax.com/static/innocent_spouse/innocent_spouse/Letter_Innocent_Spouse.docx"
                >
                  <div className="cps-slat__badge cps-padding-left-24">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/docx_icon.png"
                      className="cp-outcomes__file-icon"
                    />
                  </div>
                  <div className="cps-slat__content cp-outcomes__hide-overflow cp-outcomes__templates__hide-overflow">
                    Letter - Innocent spouse
                  </div>
                </a>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Contact info</strong>
                </div>

                <div className="cps-card__body">
                  <p>
                    <strong>
                      <i>If using the U.S. Postal Service</i>
                    </strong>
                    <br />
                    Internal Revenue Service
                    <br />
                    P.O. Box 120053
                    <br />
                    Covington, KY 41012
                  </p>
                  <p>
                    <strong>
                      <i>If using a private delivery service</i>
                    </strong>
                    <br />
                    Internal Revenue Service
                    <br />
                    201 W. Rivercenter Blvd., Stop 840F
                    <br />
                    Covington, KY 41011
                  </p>
                  <p>
                    <strong>
                      <i>If submitting via fax</i>
                    </strong>
                    <br />
                    Fax number: 855-233-8558
                  </p>
                </div>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Links and articles</strong>
                </div>

                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/publications/p971/ar02.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Publication 971 - Innocent spouse relief
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="https://www.irs.gov/taxtopics/tc205.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        Topic 205 - Innocent spouse relief (including separation
                        of liability and equitable relief)
                      </span>
                    </div>
                    Internal Revenue Service
                  </div>
                </a>
                <a
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  href="http://www.journalofaccountancy.com/issues/2014/may/innocent-spouse-relief-20139090.html"
                  target="_blank"
                >
                  <div className="cps-slat-lg__content cp-outcomes__full-width">
                    <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                      <span className="cps-blue-link">
                        New rules for innocent spouse equitable relief
                      </span>
                    </div>
                    Journal of Accountancy
                  </div>
                </a>
              </div>
            </div>

            <div className="cps-col-xs-6">
              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>IRS forms</strong>
                </div>

                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/DQ4V/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 12509
                    </div>
                    Statement of Disagreement
                  </div>
                </Link>
                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/YQVL/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 8379
                    </div>
                    Injured Spouse Allocation
                  </div>
                </Link>
                <Link
                  className="cps-slat-lg cp-outcomes__skinny-card"
                  to={`/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/9F4Q/sections/0`}
                >
                  <div className="cps-slat-lg__badge">
                    <div className="cps-slat-lg__badge__main">
                      <img
                        src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                        className="cp-outcomes__form-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                    style={{ width: "calc(100% - 10rem)" }}
                  >
                    <div className="cps-slat-lg__content__title">
                      IRS Form 8857
                    </div>
                    Request for Innocent Spouse Relief
                  </div>
                </Link>
              </div>

              <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
                <div className="cps-card__header cps-subheader-sm">
                  <strong>Best practices</strong>
                </div>

                <div className="cps-card__body">
                  <ul>
                    <li>
                      <strong>Conflicts of interest</strong> - Beware of
                      potential conflicts of interest which arise when you
                      represent both parties. You may not be able to represent
                      either if you originally prepared the return in question.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Reason to know</strong> - The reason to know
                      determination is important to the IRS in innocent spouse
                      relief requests because the IRS will try to defeat your
                      claim based on this. The burden of proof is on the
                      taxpayer and not the IRS to establish this for innocent
                      spouse relief requests, so this is an important
                      consideration.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Letter to former or current spouse</strong> - Be
                      sure your client is aware the IRS will send a letter to
                      the former or current spouse asking them to verify
                      statements of the taxpayer seeking relief.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>Offer in Compromise considerations</strong> - To
                      qualify for innocent spouse relief, you must not be
                      involved in an Offer in Compromise payment program with
                      the IRS. The Offer in Compromise program legally closes
                      the tax year that any of your unpaid taxes are applied to.
                      Taxes cannot be re-determined once that happens.
                      <br />
                      <br />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function openUnderstatedTaxModal() {
  renderInfoModal(
    "Understated tax",
    <p>
      Taxpayer has an understated tax if the IRS determined that the total tax
      should be more than the amount that was actually shown on the taxpayer's
      return. This is usually the result of an audit or examination.
    </p>
  );
}

function openDidNotKnowModal() {
  renderInfoModal(
    "Did not know",
    <>
      <p>
        If the taxpayer actually knew about the erroneous item belonging to the
        taxpayer's current or former spouse, the innocent spouse relief does not
        apply to any part of the understated tax due to that item. The taxpayer
        and the taxpayer's current or former spouse remain jointly liable for
        that part of the understated tax. However, if the taxpayer can establish
        she or he did not know or have reason to know of the extent of the
        understatement, the taxpayer may be relieved of liability to the extent
        it is attributed to the portion she or he was not knowledgeable about.
      </p>

      <p>The taxpayer had actual knowledge of an erroneous item if:</p>

      <ul>
        <li>
          She/he knew an item of unreported income was received, no matter if
          received in cash or other forms.
          <br />
          <br />
        </li>
        <li>
          She/he knew of the facts that made an incorrect deduction or credit
          unallowable.
          <br />
          <br />
        </li>
        <li>
          For a false or inflated deduction, she/he knew the expense was not
          incurred, or not incurred to the extent shown on the tax return.
        </li>
      </ul>

      <p>
        Knowledge of the source of an erroneous item is not sufficient to
        establish actual knowledge. Also, the taxpayer's actual knowledge may
        not be inferred when she/he merely had a reason to know of the erroneous
        item. Similarly, the IRS does not have to establish that the taxpayer
        knew of the source of an erroneous item in order to establish that
        she/he had actual knowledge of the item itself.
      </p>

      <p>
        The taxpayer's actual knowledge of the proper tax treatment of an
        erroneous item is not relevant for purposes of demonstrating actual
        knowledge of that item. Neither is the taxpayer's actual knowledge of
        how the erroneous item was treated on the tax return. For example, if
        the taxpayer knew that her/his spouse received dividend income, relief
        is not available for that income even if the taxpayer did not know it
        was taxable.
      </p>

      <p>
        <i>Example</i>
      </p>

      <p>
        Bill and Karen Green filed a joint return showing Karen's wages of
        $50,000 and Bill's self-employment income of $10,000. The IRS audited
        their return and found that Bill did not report $20,000 of
        self-employment income. The additional income resulted in a $6,000
        understated tax, plus interest and penalties. After obtaining a legal
        separation from Bill, Karen filed Form 8857 to request separation of
        liability relief. The IRS proved that Karen actually knew about the
        $20,000 of additional income at the time she signed the joint return.
        Bill is liable for all of the understated tax, interest, and penalties
        because all of it was due to his unreported income. Karen is also liable
        for the understated tax, interest, and penalties due to the $20,000 of
        unreported income because she actually knew of the item. The IRS can
        collect the entire $6,000 plus interest and penalties from either Karen
        or Bill because they are jointly and individually liable for it.
      </p>

      <p>
        <i>Factors supporting actual knowledge</i>
      </p>

      <p>
        The IRS may rely on all facts and circumstances in determining whether
        the taxpayer actually knew of an erroneous item at the time the return
        was signed. The following are examples of factors the IRS may use:
      </p>

      <ul>
        <li>
          Whether the taxpayer made a deliberate effort to avoid learning about
          the item in order to be shielded from liability.
          <br />
          <br />
        </li>
        <li>
          Whether the taxpayer and the taxpayer's current or former spouse
          jointly owned the property that resulted in the erroneous item.
        </li>
      </ul>

      <p>
        The taxpayer will not be considered to have had an ownership interest in
        an item based solely on the operation of community property law. Rather,
        if the taxpayer resided in a community property state at the time the
        return was signed, she/he will be considered to have had an ownership
        interest in an item only if the taxpayer's name appeared on the
        ownership documents, or there otherwise is an indication that the
        taxpayer asserted dominion and control over the item.
      </p>

      <p>
        <i>Example</i>
      </p>

      <p>
        Harry and Wanda live in Arizona, a community property state. After their
        marriage, Harry opens a bank account in his name. Under the operation of
        the community property state laws of Arizona, Wanda owns ½ of the bank
        account. However, Wanda does not have an ownership interest in the
        account for purposes of demonstrating that Wanda had actual knowledge of
        an erroneous item because the account is not held in her name and there
        is no other indication that she asserted dominion and control over the
        item.
      </p>

      <p>
        <i>Knowledge of the extent of understatement</i>
      </p>

      <p>
        If a taxpayer who otherwise qualifies for innocent spouse relief fails
        to establish that she/he didn't know or have reason to know of the
        understatement, but does establish she/he did not know or have reason to
        know the extent of the understatement, that individual may be relieved
        of the liability to the extent the liability is attributed to the
        portion of understatement she/he did not know or have reason to know.
      </p>

      <p>
        <i>Example</i>
      </p>

      <p>
        John and Mary are married and file a joint tax return, but a year later,
        John is convicted of embezzling $2 million. John kept all the
        embezzlement income in a separate bank account and spent most of it to
        support his gambling habit. However, each month during the year, he
        transferred $10,000 from his account to the joint bank account and Mary
        used it to pay household expenses. She also regularly received bank
        statements for the joint account. Mary had no knowledge or reason to
        know of the embezzlement activities, but she did know or have reason to
        know of the $120,000 of the $2 million at the time she signed the return
        because that amount passed through their joint bank account. Therefore,
        Mary may be relieved of the liability arising from $1,880,000 of the
        unreported income, but she cannot be relieved of the liability arising
        from $120,000 of the unreported income of which she knew and had reason
        to know.
      </p>

      <p>
        <i>Exception for spousal abuse or domestic violence</i>
      </p>

      <p>
        Even if the taxpayer had actual knowledge, she/he may still qualify for
        relief if the taxpayer establishes that:
      </p>

      <ol>
        <li>
          she/he was the victim of spousal abuse or domestic violence before
          signing the return, and
          <br />
          <br />
        </li>
        <li>
          because of that abuse, the taxpayer did not challenge the treatment of
          any items on the return because she/he was afraid the current or
          former spouse would retaliate. If the taxpayer establishes that she/he
          signed the joint return under duress (threat of harm or other form of
          coercion), then it is not a joint return, and the taxpayer is not
          liable for any tax shown on that return or any tax deficiency for that
          return. However, the taxpayer may be required to file a separate
          return for that tax year.
          <br />
          <br />
        </li>
      </ol>
    </>
  );
}

function openNoReasonToKnowModal() {
  renderInfoModal(
    "No reason to know",
    <>
      <p>
        If the taxpayer had reason to know about an erroneous item that belongs
        to her/his current or former spouse, innocent spouse relief does not
        apply to any part of the understated tax due to that item. The taxpayer
        and spouse (or former spouse) remain jointly liable for that part of the
        understated tax. The IRS will consider all facts and circumstances in
        determining whether the taxpayer had reason to know of an understated
        tax due to an erroneous item. The facts and circumstances include:
      </p>

      <ul>
        <li>
          The nature of the erroneous item and the amount of the erroneous item
          relative to other items.
          <br />
          <br />
        </li>
        <li>
          The financial situation of the taxpayer and spouse (or former spouse).
          <br />
          <br />
        </li>
        <li>
          Taxpayer's educational background and business experience.
          <br />
          <br />
        </li>
        <li>
          The extent of the taxpayer's participation in the activity that
          resulted in the erroneous item.
          <br />
          <br />
        </li>
        <li>
          Whether the taxpayer failed to ask, at or before the time the return
          was signed, about items included or omitted from the return that a
          reasonable person would question.
          <br />
          <br />
        </li>
        <li>
          Whether the erroneous item represented a departure from a recurring
          pattern reflected in prior years' returns (for example, omitted income
          from an investment regularly reported on prior years' returns).
        </li>
      </ul>

      <p>
        The taxpayer may qualify for partial relief if, at the time the return
        was filed, the taxpayer had no knowledge or reason to know of a portion
        of an erroneous item. The taxpayer will be relieved of the understated
        tax due to that portion of the item if all other requirements are met
        for that portion.
      </p>
    </>
  );
}

function openUnfairModal() {
  renderInfoModal(
    "Unfair",
    <>
      <p>
        The IRS will consider all of the facts and circumstances of the case in
        order to determine whether it is unfair to hold the taxpayer responsible
        for the understated tax. The following are examples of factors the IRS
        will consider:
      </p>

      <ul>
        <li>
          Whether the taxpayer received a benefit in excess of normal support,
          either directly or indirectly, from the understated tax. Normal
          support depends on the taxpayer's particular circumstances. Evidence
          of a direct or indirect benefit may consist of transfers of property
          or rights to property, including transfers that may be received
          several years after the year of the understated tax.
          <br />
          <br />
        </li>
        <li>
          Whether the taxpayer's current or former spouse deserted her/him.
          <br />
          <br />
        </li>
        <li>
          Whether the taxpayer and spouse have been divorced or separated.
          <br />
          <br />
        </li>
        <li>
          Whether the taxpayer received a benefit on the return from the
          understated tax.
        </li>
      </ul>

      <p>
        <i>Example</i>
      </p>

      <p>
        Taxpayer receives money from her/his spouse that is beyond normal
        support. The money can be traced to the spouse's lottery winnings that
        were not reported on the taxpayer's joint return. The taxpayer will be
        considered to have received a significant benefit from that income. This
        is true even if the taxpayer's spouse provides the money several years
        after he or she received it.
      </p>
    </>
  );
}

function openSignedUnderDuressModal() {
  renderInfoModal(
    "Signed under duress",
    <>
      If an individual establishes the return was signed under legal duress, the
      return is <u>not</u> a joint return. This means the individual is not
      jointly and severally liable for the tax shown on the return or deficiency
      later assessed on the return. The return is adjusted to reflect only the
      liability of the other individual.
    </>
  );
}

function openErroneousItemsModal() {
  renderInfoModal(
    "Erroneous items",
    <>
      <p>Erroneous items are either of the following:</p>

      <ol>
        <li>
          Unreported income. This is any gross income item received by the
          taxpayer's spouse (or former spouse) that is not reported.
          <br />
          <br />
        </li>
        <li>
          Incorrect deduction, credit, or basis. This is any improper deduction,
          credit, or property basis claimed by the taxpayer's spouse (or former
          spouse).
          <br />
          <br />
        </li>
      </ol>

      <p>
        <i>Examples</i>
      </p>

      <ul>
        <li>
          The expense for which the deduction is taken was never paid or
          incurred. For example, the taxpayer's spouse, a cash-basis taxpayer,
          deducted $10,000 of advertising expenses on Schedule C of a joint Form
          1040, but never paid for any advertising.
          <br />
          <br />
        </li>
        <li>
          The expense does not qualify as a deductible expense. For example, the
          taxpayer's spouse claimed a business fee deduction of $10,000 that was
          for the payment of state fines. Fines are not deductible.
          <br />
          <br />
        </li>
        <li>
          No factual argument can be made to support the deductibility of the
          expense. For example, the taxpayer's spouse claimed $4,000 for
          security costs related to a home office, which were actually
          veterinary and food costs for the two dogs of the taxpayer's family.
          <br />
          <br />
        </li>
      </ul>
    </>
  );
}
