import React, { useState } from "react";
import { NavContent } from "primary-navbar!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import styles from "./details.styles.css";
import { useHistory, useParams } from "react-router-dom";
import {
  CpCard,
  CpTextarea,
  CpInput,
  CpIcon,
  CprDatepicker,
  CpButton,
} from "canopy-styleguide!sofe";
import { updateResolutionCasePromise } from "src/common/resolution-cases.resource";
import { trim } from "lodash";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

export function Details({ resolutionCase }) {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(resolutionCase.name || "");
  const [description, setDescription] = useState(
    resolutionCase.description || ""
  );
  const [dueDate, setDueDate] = useState(resolutionCase.due_date || null);
  const history = useHistory();
  const { clientId } = useParams();

  const [user, tenant] = useWithUserAndTenant();
  const ft_crm = tenant?.crm_status === "crm_hierarchy_complete" && featureEnabled("ft_crm");

  function disableSave() {
    const valueHasChanged =
      trim(name) === resolutionCase.name &&
      trim(description) === resolutionCase.description &&
      dueDate === resolutionCase.due_date;
    const missingRequiredValues = !trim(name);
    return valueHasChanged || missingRequiredValues;
  }

  function handleDateChange(e) {
    const date = new Date(e.detail);
    setDueDate(date.getTime());
  }

  function saveChanges() {
    setSaving(true);
    updateResolutionCasePromise(clientId, {
      id: resolutionCase.id,
      due_date: dueDate,
      description,
      name,
    }).then(() => {
      history.goBack();
    });
  }

  return (
    <NavContent
      className={ft_crm ? "" : "with-sidebar"}
      clientMenuPossible={true}
      hasTopnavSecondary={false}
    >
      <div className={styles.detailWrapper}>
        <CpCard>
          <CpCard.Header>
            <div className="cp-subheader">Resolution Case Settings</div>
          </CpCard.Header>
          <CpCard.Body>
            <div className="cp-mb-16">
              <CpInput
                required
                value={name}
                onChange={setName}
                label="Resolution case name"
                placeholder="What should your resolution case be called?"
                maxLength={255}
              />
            </div>
            <div className="cp-mb-16">
              <label htmlFor="resolution-case-description" className="cp-label">
                Description
              </label>
              <CpTextarea
                className={styles.textArea}
                resize="vertical"
                height={56}
                value={description}
                onChange={setDescription}
                placeholder="Describe the purpose of your resolution case here..."
                id="resolution-case-description"
              />
            </div>
            <div className="cp-mb-32">
              <label htmlFor="resolution-case-due-date" className="cp-label">
                Due date
              </label>
              <div
                className={`${styles.datePickerWrapper} cps-has-feedback cps-has-feedback-left`}
              >
                <CpIcon className={styles.calendarIcon} name="misc-calendar" />
                <div className={styles.dateInput}>
                  <CprDatepicker
                    id="resolution-case-due-date"
                    date={dueDate}
                    events={{ datechange: handleDateChange }}
                    placeholder="No due date selected"
                    orientation="bottom"
                  />
                </div>
              </div>
            </div>
            <div>
              <CpButton
                disabled={disableSave()}
                onClick={saveChanges}
                className="cp-mr-8"
                loading={saving}
              >
                Save changes
              </CpButton>
              <CpButton
                btnType="tertiary"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </CpButton>
            </div>
          </CpCard.Body>
        </CpCard>
      </div>
    </NavContent>
  );
}
