import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export function DeleteServiceModal({ show, onClose, onSubmit, saving }) {
  return (
    <CpModal show={show} onClose={onClose}>
      <CpModal.Header title="Delete Custom Service" />
      <CpModal.Body>
        By deleting this custom service template you will lose any custom steps
        and associated workpapers for this service template. Resolution Cases
        previously created containing this service template will not be
        affected.
        <p>If you're okay with this, click 'Delete service' below.</p>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          onClick={() => onSubmit()}
          btnType="primary"
          showLoader={saving}
        >
          Delete service
        </CpButton>
        <CpButton className="cp-ml-8" btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
