// programs
export const serviceConst = {
  PENALTY_ABATEMENTS_SERVICE_SLUG: "penalty_abatement",
  INNOCENT_SPOUSE_SERVICE_SLUG: "innocent_spouse",
  LIENS_SERVICE_SLUG: "liens",
  LEVIES_SERVICE_SLUG: "levies",
  TRUST_FUND_SERVICE_SLUG: "trust_fund_recovery",
};

// sections
export const sectionConst = {
  PENALTY_ABATEMENT_SECTION_SLUG: "abatement_assistant",
  INNOCENT_SPOUSE_SECTION_SLUG: "innocent_spouse_assistant",
  LIENS_SECTION_SLUG: "liens_assistant",
  LEVIES_SECTION_SLUG: "levies_assistant",
  TRUST_FUND_SECTION_SLUG: "trust_fund_recovery_assistant",
};

// outcomes
export const outcomeConst = {
  PENALTY_ABATEMENT_OUTCOMES_SLUG: "abatement_resolution",
  INNOCENT_SPOUSE_OUTCOMES_SLUG: "innocent_spouse_resolution",
  LIENS_OUTCOMES_SLUG: "liens_resolution",
  LEVIES_OUTCOMES_SLUG: "levies_resolution",
  TRUST_FUND_OUTCOMES_SLUG: "trust_fund_recovery_resolution",
};
