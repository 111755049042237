import React from "react";
import { CpSwitch, CpCase, CpButton } from "canopy-styleguide!sofe";
import { serviceConst } from "./assistant.constants";
import { Link } from "react-router-dom";

export function AssistantIntro({ programSlug, urls, submitAnswer }) {
  const startAssistant = () => {
    submitAnswer("intro", true);
  };

  return (
    <div>
      <div className="cps-flexible-focus cps-card cps-text-center cp-assistant-intro">
        <div className="cps-subheader cps-color-primary cp-assistant-intro__welcome">
          Welcome to Canopy's
          <br />
          <CpSwitch expression={programSlug}>
            <CpCase value={serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG}>
              Penalty Abatements Assistant
            </CpCase>
            <CpCase value={serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG}>
              Innocent Spouse Assistant
            </CpCase>
            <CpCase value={serviceConst.LIENS_SERVICE_SLUG}>
              Liens Assistant
            </CpCase>
            <CpCase value={serviceConst.LEVIES_SERVICE_SLUG}>
              Levies Assistant
            </CpCase>
            <CpCase value={serviceConst.TRUST_FUND_SERVICE_SLUG}>
              Trust Fund Recovery Penalty Assistant
            </CpCase>
          </CpSwitch>
        </div>

        <div className="cps-subheader-sm cps-medium-gray cp-assistant-intro__smart-recommend">
          A smart recommendation is only a few questions away
        </div>

        <div className="cp-assistant-intro__section--content cps-margin-bottom-8">
          <span className="cps-color-primary">
            <strong>Simply powerful</strong>
          </span>{" "}
          - Answer simple questions and reduce your research burden.
        </div>
        <div className="cp-assistant-intro__section--content cps-margin-bottom-8">
          <span className="cps-color-primary">
            <strong>Smart and productive</strong>
          </span>{" "}
          - Cover various scenarios so you don't miss anything.
        </div>
        <div className="cp-assistant-intro__section--content cps-margin-bottom-32">
          <span className="cps-color-primary">
            <strong>Custom recommendations</strong>
          </span>{" "}
          - Access helpful resources tailored to meet your client's needs.
        </div>

        <div className="cp-pt-16">
          <CpButton onClick={startAssistant} className="cp-mb-16">
            Get started
          </CpButton>
        </div>

        <Link
          to={urls.resolution}
          className="cps-light-gray cp-mt-24 cp-assistant-intro__actions__outcome"
        >
          No thanks, take me to outcomes
        </Link>
      </div>
    </div>
  );
}
