// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-resolution-case-details-details-styles__detailWrapper--a7u9e {\n  width: 76rem;\n  margin: 0 auto 0 auto;\n}\n\n.src-resolution-case-details-details-styles__textArea--RHPFw {\n  min-height: 5.6rem;\n}\n\n.src-resolution-case-details-details-styles__datePickerWrapper--3eroW {\n  display: flex;\n  align-items: center;\n}\n\n.src-resolution-case-details-details-styles__calendarIcon--3DNhF {\n  position: absolute;\n  left: 0.8rem;\n  z-index: 100;\n  color: var(--cp-color-app-icon);\n}\n\n.src-resolution-case-details-details-styles__dateInput--3d2-Z {\n  flex: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"detailWrapper": "src-resolution-case-details-details-styles__detailWrapper--a7u9e",
	"textArea": "src-resolution-case-details-details-styles__textArea--RHPFw",
	"datePickerWrapper": "src-resolution-case-details-details-styles__datePickerWrapper--3eroW",
	"calendarIcon": "src-resolution-case-details-details-styles__calendarIcon--3DNhF",
	"dateInput": "src-resolution-case-details-details-styles__dateInput--3d2-Z"
};
module.exports = exports;
