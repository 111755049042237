import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";
import { Link } from "react-router-dom";

import { Summary } from "../../common/summary.component";
import { useInnocentSpouseOutcome } from "../innocent-spouse-outcomes.helper";

export function InnocentSpouseCommunityProperty({ outcomes, params, urls }) {
  const { cantCalculateOutcomes, communityPropertyAvailable } =
    useInnocentSpouseOutcome(outcomes);
  return (
    <div>
      <div className="cps-card cps-card-nonrounded-top cps-flexible-focus">
        <div className="cps-card__body">
          <div className="cps-row cps-margin-top-8 cps-padding-left-8">
            <div className="cps-body">
              Recommendation:{" "}
              <span>
                {communityPropertyAvailable && (
                  <span className="cps-color-primary">
                    Relief from Tax Liability Attributable to an Item of
                    Community Property available
                  </span>
                )}
                {!communityPropertyAvailable && !cantCalculateOutcomes && (
                  <span className="cps-warning">
                    Relief from Tax Liability Attributable to an Item of
                    Community Property unlikely
                  </span>
                )}
              </span>
              {cantCalculateOutcomes && (
                <span className="cps-light-gray">
                  Recommendation unavailable
                </span>
              )}
            </div>
            <div>
              {communityPropertyAvailable && (
                <div>
                  Thank you for using the Innocent Spouse Assistant! Based on
                  your answers, your client may qualify for Relief from Tax
                  Liability Attributable to an Item of Community Property. Check
                  out the resources below for additional help.
                </div>
              )}
              {!communityPropertyAvailable && !cantCalculateOutcomes && (
                <div>
                  Thank you for using the Innocent Spouse Assistant! Based on
                  your answers, Relief from Tax Liability Attributable to an
                  Item of Community Property for your client is unlikely.
                  Sometimes it doesn't hurt to try though - check out the
                  resources below to learn more.
                </div>
              )}

              {cantCalculateOutcomes && (
                <div>
                  We cannot provide a recommendation at this time. Please
                  complete the{" "}
                  <Link to={urls.assistant}>Innocent Spouse Assistant</Link> if
                  you would like custom recommendations for your client.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Summary>
        <p className="cps-wt-bold">Community property and community income</p>

        <p>
          Federal income tax law recognizes the principle of community income in{" "}
          <CpTooltip text="Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington, and Wisconsin">
            <a>community property states</a>
          </CpTooltip>
          , under which community income is treated as going one half to each
          spouse, even if one spouse earns all the income and they file separate
          returns. However, under certain conditions, a separated or innocent
          spouse is relieved from the 50/50 allocation rule by allocating all
          the community income to the spouse that earned it. The IRS may also
          disallow the benefits of community property laws to certain spouses.
        </p>
        <p>
          The laws where the taxpayer resides determines if the two individuals
          are married and subject to community property laws, but federal tax
          law determines the tax on those rights. In this case, federal taxes
          are affected by community property laws only if married taxpayers file
          separate returns while living in a community property state.
        </p>
        <p>
          The IRC Section 66 relief contains four exceptions to the general rule
          that community income is taxed in equal shares to both the taxpayer
          and current or former spouse.
        </p>
        <ol>
          <li>
            Section 66(a) provides rules for the treatment of community income
            when the spouses live apart and do not share income for the entire
            taxable year.
            <br />
            <br />
          </li>
          <li>
            Section 66(b) allows community property laws to be disregarded when
            one spouse is not notified of the nature and amount of items of
            community income.
            <br />
            <br />
          </li>
          <li>
            Section 66(c) allows relief from community property law in other
            cases, which is comparable to the innocent spouse relief in Section
            6015(b) for joint filers.
            <br />
            <br />
          </li>
          <li>
            Section 66(c) also authorizes equitable relief from community
            property laws when the other requirements of this section are not
            met. This is similar to the equitable relief provision in Section
            6015(f) relating to joint filers.
          </li>
        </ol>

        <p className="cps-wt-semibold">
          <i>Timing of request</i>
        </p>

        <p>
          A spouse seeking relief under Section 66(c) generally may seek relief
          only after a deficiency for the year has been asserted, which is when
          a notification of audit or letter indicating outstanding liability for
          that year is sent. The request must be sent in a timely manner so tax
          on the community income can be asserted against the other spouse
          before the statute of limitations under Section 6501 expires.
          Therefore, a request must be made no later than 6 months before the
          statute of limitations expires. In addition, if an examination of the
          taxpayer's return happens during that 6 month period, the latest time
          for requesting relief is 30 days after the commencement of the
          examination.
        </p>

        <p className="cps-wt-semibold">
          <i>Section 66 standards</i>
        </p>

        <p>
          A married individual that does not file a joint return and omits
          her/his share of community property income is relieved of tax
          liability from that income if she/he establishes lack of knowledge or
          reason to know the omitted item, and given the facts and circumstances
          it would be inequitable to include the item of community income in
          her/his gross income.
        </p>

        <p className="cps-wt-semibold">
          <i>Benefit from income</i>
        </p>

        <p>
          In determining if it would be inequitable to include in the gross
          income of the taxpayer lacking knowledge, there may be consideration
          given if she or he benefited from the untaxed income, and whether the
          defense was promptly raised to prevent the statute of limitations on
          running out on the other spouse. This is based on the idea of inequity
          of taxing an individual on community earned income of the other spouse
          where the individual received no benefit from the earnings.
        </p>
        <p>
          IRC Section 66(b) can be used by the IRS to disallow the benefits of
          community property laws to a taxpayer only under certain conditions.
          By its plain language, it isn't a relief provision that can be used by
          a taxpayer to avoid her/his liability on community income. This
          section is not a remedy in itself to claim innocent spouse relief, but
          is used in conjunction with other relief.
        </p>
      </Summary>

      <div className="cps-fixed-masonry">
        <div className="cps-row">
          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Contact info</strong>
              </div>

              <div className="cps-card__body">
                <p>
                  <strong>
                    <i>If using the U.S. Postal Service</i>
                  </strong>
                  <br />
                  Internal Revenue Service
                  <br />
                  P.O. Box 120053
                  <br />
                  Covington, KY 41012
                </p>
                <p>
                  <strong>
                    <i>If using a private delivery service</i>
                  </strong>
                  <br />
                  Internal Revenue Service
                  <br />
                  201 W. Rivercenter Blvd., Stop 840F
                  <br />
                  Covington, KY 41011
                </p>
                <p>
                  <strong>
                    <i>If submitting via fax</i>
                  </strong>
                  <br />
                  Fax number: 855-233-8558
                </p>
              </div>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Links and articles</strong>
              </div>

              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/pub/irs-pdf/p555.pdf"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 555 - Community property
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
              <a
                className="cps-slat-lg cp-outcomes__skinny-card"
                href="https://www.irs.gov/publications/p971/ar02.html"
                target="_blank"
              >
                <div className="cps-slat-lg__content cp-outcomes__full-width">
                  <div className="cps-slat-lg__content__title cp-outcomes__hide-overflow">
                    <span className="cps-blue-link">
                      Publication 971 - Innocent spouse relief
                    </span>
                  </div>
                  Internal Revenue Service
                </div>
              </a>
            </div>
          </div>

          <div className="cps-col-xs-6">
            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>IRS forms</strong>
              </div>

              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/#/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/DQ4V/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 12509
                  </div>
                  Statement of Disagreement
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/#/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/YQVL/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 8379
                  </div>
                  Injured Spouse Allocation
                </div>
              </Link>
              <Link
                className="cps-slat-lg cp-outcomes__skinny-card"
                to={`/#/forms/clients/${params.clientId}/resolution-cases/${params.resolutionCaseId}/sourceforms/9F4Q/sections/0`}
              >
                <div className="cps-slat-lg__badge">
                  <div className="cps-slat-lg__badge__main">
                    <img
                      src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
                      className="cp-outcomes__form-icon"
                    />
                  </div>
                </div>
                <div
                  className="cps-slat-lg__content cp-outcomes__full-width cp-outcomes__hide-overflow"
                  style={{ width: "calc(100% - 10rem)" }}
                >
                  <div className="cps-slat-lg__content__title">
                    IRS Form 8857
                  </div>
                  Request for Innocent Spouse Relief
                </div>
              </Link>
            </div>

            <div className="cps-fixed-masonry__card cps-card cps-margin-top-16">
              <div className="cps-card__header cps-subheader-sm">
                <strong>Best practices</strong>
              </div>

              <div className="cps-card__body">
                <ul>
                  <li>
                    <strong>Marital agreements</strong> - In some states, a
                    husband and wife may enter into an agreement that affects
                    the status of property or income as community or separate
                    property.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Statutory exception applicability</strong> - Several
                    statutory exceptions under federal law allow community
                    property laws to be disregarded for federal tax purposes.
                    The statutory exceptions under federal law do not apply to
                    Registered Domestic Partners (RDPs); however, they now apply
                    to same sex married taxpayers.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
