import React, { useCallback, useEffect, useMemo, useState } from "react";
import { catchError } from "auto-trace";
import { partial, cloneDeep } from "lodash";
import { useCss, k } from "kremling";
import { CpSwitch, CpCase } from "canopy-styleguide!sofe";

import { AssistantHeader } from "./common/assistant-header.component";
import {
  getEnvelope,
  getProgramSectionService,
} from "../../common/resolution-cases.resource";
import {
  questionEnhancer,
  useUrlBuilder,
  totalNumQuestions,
} from "./assistant-utils";
import {
  getAnswers as _getAnswers,
  saveAnswers as _saveAnswers,
} from "./assistant.resources";
import { penaltyAbatementStateMachine } from "./penalty-abatement/penalty-abatement.state-machine";
import { serviceConst } from "./assistant.constants";
import { AssistantQuestion } from "./assistant-question.component";
import { PenaltyAbatementQuestionSwitch } from "./penalty-abatement/penalty-abatement-question-switch.component";
import { PenaltyAbatementOutro } from "./penalty-abatement/penalty-abatement-outro.component";
import { AssistantOutro } from "./assistant-outro.component";
import { innocentSpouseStateMachine } from "./innocent-spouse/innocent-spouse.state-machine";
import { InnocentSpouseQuestionSwitch } from "./innocent-spouse/innocent-spouse-question-switch.component";
import { InnocentSpouseOutro } from "./innocent-spouse/innocent-spouse-outro.component";
import { liensStateMachine } from "./liens/liens.state-machine";
import { AssistantIntro } from "./assistant-intro.component";
import { LiensOutro } from "./liens/liens-outro.component";
import { leviesStateMachine } from "./levies/levies.state-machine";
import { LeviesQuestionSwitch } from "./levies/levies-question-switch.component";
import { LeviesOutro } from "./levies/levies-outro.component";
import { TrustFundOutro } from "./trust-fund/trust-fund-outro.component";
import { TrustFundQuestionSwitch } from "./trust-fund/trust-fund-question-switch.component";
import { trustFundStateMachine } from "./trust-fund/trust-fund.state-machine";

export function Assistant({ params, resolutionCase, section, program }) {
  const [
    {
      fullSection,
      sectionEnvelope,
      answerData,
      answersInMemory,
      loading,
      resumeBanner,
      questionSlug,
    },
    setState,
  ] = useState({
    fullSection: false,
    sectionEnvelope: null,
    answerData: null,
    answersInMemory: null,
    loading: true,
    resumeBanner: false,
    questionSlug: null,
  });

  const scope = useCss(css);

  useEffect(() => {
    resumeAssistant();
  }, []);

  const runStateMachine = useMemo(() => {
    switch (params.programSlug) {
      case serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG:
        return penaltyAbatementStateMachine;
      case serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG:
        return innocentSpouseStateMachine;
      case serviceConst.LIENS_SERVICE_SLUG:
        return liensStateMachine;
      case serviceConst.LEVIES_SERVICE_SLUG:
        return leviesStateMachine;
      case serviceConst.TRUST_FUND_SERVICE_SLUG:
        return trustFundStateMachine;
    }
  }, [params.programSlug]);

  const questionCount = useMemo(
    () => totalNumQuestions(params.programSlug),
    [params.programSlug]
  );

  // simplify resource calls with partials
  const getAnswers = useCallback(
    partial(
      _getAnswers,
      params.clientId,
      resolutionCase.id,
      params.programSlug
    ),
    [params.clientId, resolutionCase.id, params.programSlug]
  );
  const saveAnswers = useCallback(
    partial(
      _saveAnswers,
      params.clientId,
      resolutionCase.id,
      params.programSlug
    ),
    [params.clientId, resolutionCase.id, params.programSlug]
  );

  const urls = useUrlBuilder(
    params.clientId,
    resolutionCase.id,
    params.programSlug
  );

  const question = useMemo(() => {
    if (!answerData?.answers || !questionSlug) return null;
    const state = runStateMachine(answerData.answers, questionSlug);
    return {
      ...questionEnhancer(params.programSlug, questionSlug),
      questionSlug: state.currentQuestionSlug,
      answer: answerData.answers[state.currentQuestionSlug],
      previousQuestionSlug: state.previousQuestionSlug,
      nextQuestionSlug: state.nextQuestionSlug,
      nextQuestionIsOutcome: !!state.nextQuestionIsOutcome,
      percentage: Object.keys(answerData.answers).length / (questionCount - 1), //the -1 is because the intro question doesn't really count
      outcomes: outcomeRegistration(state),
    };
  }, [
    JSON.stringify(answerData?.answer || ""),
    questionSlug,
    params.sectionSlug,
  ]);

  useEffect(() => {
    updateState({ answersInMemory: answerData?.answer });
  }, [JSON.stringify(answerData?.answer || "")]);

  const updateState = (state) => {
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  function outcomeRegistration(state) {
    let outcomes = state?.outcomes || [];

    if (state.outcomesToRegister) {
      state.outcomesToRegister.forEach((outcome) => {
        if (!outcomes.includes(outcome)) {
          outcomes.push(outcome);
        }
      });
    }

    if (state.outcomesToUnregister) {
      state.outcomesToUnregister.forEach((outcome) => {
        let arrIndex = outcomes.findIndex(
          (element, index, array) => outcome === element
        );
        if (arrIndex >= 0) {
          outcomes.splice(arrIndex, 1);
        }
      });
    }
    return outcomes;
  }

  const resumeAssistant = async () => {
    updateState({ loading: true });
    const [_answerData] = await Promise.all([
      getAnswers(),
      getEnvelopeAndFullSection(),
    ]);

    const state = runStateMachine(_answerData.answers);
    updateState({
      answerData: {
        ..._answerData,
        outcomes: outcomeRegistration(state),
      },
      loading: false,
      questionSlug: state.reachedOutcome ? "outro" : state.currentQuestionSlug,
    });
  };

  const getEnvelopeAndFullSection = async () => {
    const fullSection = await getProgramSectionService(
      params.clientId,
      resolutionCase.id,
      section.id
    );
    const sectionEnvelope = await getEnvelope(
      params.clientId,
      resolutionCase.id,
      section.id,
      fullSection?.relationships?.children?.[0]?.references
    );
    updateState({
      sectionEnvelope,
      fullSection,
    });
  };

  const submitAnswer = async (questionSlug, value) => {
    const nextAnswers = {
      answers: {
        ...(answerData?.answers || {}),
        [questionSlug]: value,
      },
      outcomes: answerData.outcomes || [],
    };
    const stateMachineNextState = runStateMachine(nextAnswers.answers);
    nextAnswers.outcomes = outcomeRegistration(stateMachineNextState);

    try {
      updateState({ loading: true });
      await saveAnswers(nextAnswers);
      const nextState = { loading: false, answerData: nextAnswers };

      if (stateMachineNextState.reachedOutcome) {
        nextState.questionSlug = "outro";
      } else if (stateMachineNextState.currentQuestionSlug) {
        nextState.questionSlug = stateMachineNextState.currentQuestionSlug;
      } else {
        throw new Error(
          `Error - cannot determine which question to go to next`
        );
      }
      updateState(nextState);
    } catch (e) {
      catchError()(e);
    }
  };

  const restartAssistant = async () => {
    try {
      await saveAnswers({});
      updateState({
        answerData: {},
        questionSlug: "intro",
      });
    } catch (e) {
      catchError()(e);
    }
  };

  const hideResumeBanner = () => {
    updateState({ resumeBanner: false });
  };

  const updateAnswersInMemory = (nextAnswers) => {
    updateState({
      answersInMemory: nextAnswers,
    });
  };

  const navigateToQuestionSlug = (questionSlug) => {
    updateState({ questionSlug });
  };

  if (loading) return null;

  return (
    <div {...scope}>
      <AssistantHeader
        canRestart={questionSlug !== "intro"}
        restartAssistant={restartAssistant}
        params={params}
        section={section}
        fullSection={fullSection}
        program={program}
        resolutionCase={resolutionCase}
        sectionEnvelope={sectionEnvelope}
        updateState={updateState}
        loading={loading}
      >
        Assistant
      </AssistantHeader>

      <CpSwitch expression={questionSlug}>
        <CpCase value="intro">
          <AssistantIntro
            programSlug={params.programSlug}
            urls={urls}
            submitAnswer={submitAnswer}
          />
        </CpCase>
        <CpCase value="outro">
          <AssistantOutro
            question={question}
            goBack={() => {
              navigateToQuestionSlug(question.previousQuestionSlug);
            }}
          >
            <CpSwitch expression={params.programSlug}>
              <CpCase value={serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG}>
                <PenaltyAbatementOutro
                  outcomes={answerData.outcomes}
                  urls={urls}
                  restartAssistant={restartAssistant}
                />
              </CpCase>
              <CpCase value={serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG}>
                <InnocentSpouseOutro
                  outcomes={answerData.outcomes}
                  urls={urls}
                  restartAssistant={restartAssistant}
                />
              </CpCase>
              <CpCase value={serviceConst.LIENS_SERVICE_SLUG}>
                <LiensOutro
                  outcomes={answerData.outcomes}
                  urls={urls}
                  restartAssistant={restartAssistant}
                />
              </CpCase>
              <CpCase value={serviceConst.LEVIES_SERVICE_SLUG}>
                <LeviesOutro
                  outcomes={answerData.outcomes}
                  urls={urls}
                  restartAssistant={restartAssistant}
                />
              </CpCase>
              <CpCase value={serviceConst.TRUST_FUND_SERVICE_SLUG}>
                <TrustFundOutro
                  outcomes={answerData.outcomes}
                  urls={urls}
                  restartAssistant={restartAssistant}
                />
              </CpCase>
            </CpSwitch>
          </AssistantOutro>
        </CpCase>
        <CpCase default>
          <AssistantQuestion
            question={question}
            resumeBanner={resumeBanner}
            submitAnswer={submitAnswer}
            hideResumeBanner={hideResumeBanner}
            questionSlug={questionSlug}
            answersInMemory={answersInMemory}
            updateAnswersInMemory={updateAnswersInMemory}
            answerData={answerData}
            goBack={(() => {
              if (
                question &&
                question.previousQuestionSlug &&
                questionSlug !== "audit" &&
                question.previousQuestionSlug !== "intro"
              ) {
                return () =>
                  navigateToQuestionSlug(question.previousQuestionSlug);
              }
            })()}
            goForward={(() => {
              if (
                question &&
                question.answer !== undefined &&
                question.answer !== null &&
                questionSlug !== "outro" &&
                questionSlug !== "intro"
              ) {
                return () =>
                  navigateToQuestionSlug(
                    question.nextQuestionIsOutcome
                      ? "outro"
                      : question.nextQuestionSlug
                  );
              }
            })()}
          >
            <CpSwitch expression={params.programSlug}>
              <CpCase value={serviceConst.PENALTY_ABATEMENTS_SERVICE_SLUG}>
                <PenaltyAbatementQuestionSwitch
                  question={question}
                  updateAnswersInMemory={updateAnswersInMemory}
                  answers={answerData?.answers || {}}
                />
              </CpCase>
              <CpCase value={serviceConst.INNOCENT_SPOUSE_SERVICE_SLUG}>
                <InnocentSpouseQuestionSwitch
                  question={question}
                  submitAnswer={submitAnswer}
                  answers={answerData?.answers || {}}
                />
              </CpCase>
              <CpCase value={serviceConst.LIENS_SERVICE_SLUG}>
                <div className="cps-subheader-sm cp-text-center">
                  <strong>{question?.questionText}</strong>
                </div>
              </CpCase>
              <CpCase value={serviceConst.LEVIES_SERVICE_SLUG}>
                <LeviesQuestionSwitch
                  question={question}
                  submitAnswer={submitAnswer}
                  answers={answerData?.answers || {}}
                />
              </CpCase>
              <CpCase value={serviceConst.TRUST_FUND_SERVICE_SLUG}>
                <TrustFundQuestionSwitch
                  question={question}
                  submitAnswer={submitAnswer}
                  answers={answerData?.answers || {}}
                />
              </CpCase>
            </CpSwitch>
          </AssistantQuestion>
        </CpCase>
      </CpSwitch>
    </div>
  );
}

const css = k`
  .cp-assistant__navbar {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2.4rem 2.4rem;
  }

  .cp-assistant__navbox {
    width: 12.8rem;
    vertical-align: middle;
  }

  .cp-assistant__progress-bar__container {
    vertical-align: middle;
  }

  .cp-assistant__progress-bar {
    position: relative;
    height: 1.6rem;
    border-radius: 1rem;
  }

  .cp-assistant__table {
    display: table;
  }

  .cp-assistant__table-row {
    display: table-row;
  }

  .cp-assistant__table-cell {
    display: table-cell;
  }
  
  /* INTRO */
  .cp-assistant-intro {
    padding: 40px 20px 32px;
  }

  .cp-assistant-intro__welcome {
    font-weight: 300 !important;
    line-height: 32px;
    margin-bottom: 14px;
  }

  .cp-assistant-intro__smart-recommend {
    font-style: italic;
    font-weight: 300;
    margin-bottom: 36px;
  }

  .cp-assistant-intro__section--header {
    font-weight: 600;
    margin-bottom: 9px;
  }

  .cp-assistant-intro__section--content {
    line-height: 20px !important;
  }

  .cp-assistant-intro__actions__outcome {
    cursor: pointer;
    font-weight: 600;
  }
  
  /* OUTRO*/
  .end-of-assistant-actions {
    display: flex;
    justify-content: center;
    gap: 8px;
  }


  /*****************
  OVERRIDES
  *****************/
  .cp-assistant__slat--no-background {
    background: none;
    border: none;
    box-shadow: none;
    margin-bottom: 0.3rem;
  }

  .cp-assistant__slat--no-background.\\+noclick:hover {
    background: none;
  }
`;
