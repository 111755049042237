import React, { useEffect, useMemo, useState } from "react";
import { NavContent } from "primary-navbar!sofe";
import styles from "./resolution-case-summary.styles.css";
import {
  CpDropdown,
  CpButton,
  CpCard,
  CpIcon,
  CpModal,
  CpProgressBar,
} from "canopy-styleguide!sofe";
import { DateTime } from "luxon";
import {
  deleteResolutionCase,
  getResolutionCaseHistory,
  getResolutionCaseRequests,
  getClientData,
} from "../../common/resolution-cases.resource";
import { useParams, useHistory, Link } from "react-router-dom";
import { catchAsyncStacktrace, catchError } from "auto-trace";
import {
  getServiceProgress,
  getRequestsProgress,
  formatResolutionCaseHistory,
  isDeleted,
} from "./summary.utils";
import { isEmpty } from "lodash";
import { ProgressListItem } from "./progress-list-item.component";
import { ActivityListItem } from "./activity-list-item.component";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

export function ResolutionCaseSummary({ resolutionCase }) {
  const { clientId } = useParams();
  const history = useHistory();
  const [user] = useWithUserAndTenant();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [resolutionCaseRequests, setResolutionCaseRequests] = useState([]);
  const [resolutionCaseHistory, setResolutionCaseHistory] = useState([]);
  const [clientInactive, setClientInactive] = useState(false);
  const [startDateLabel, endDateLabel] = useMemo(() => {
    const start = DateTime.fromMillis(resolutionCase.created_at).toFormat(
      "MMM d"
    );
    const end = resolutionCase.due_date
      ? DateTime.fromMillis(resolutionCase.due_date).toFormat("MMM d")
      : null;
    return [start, end];
  }, [resolutionCase.created_at, resolutionCase.due_date]);
  const { inProgress, needReview, totalSections, completedSections } =
    useMemo(() => {
      const serviceProgress = getServiceProgress(
        resolutionCase.program_data.programs
      );
      const requestsProgress = getRequestsProgress(resolutionCaseRequests);
      return {
        inProgress: [
          ...serviceProgress.inProgress,
          ...requestsProgress.inProgress,
        ],
        needReview: [
          ...serviceProgress.needReview,
          ...requestsProgress.needReview,
        ],
        totalSections:
          serviceProgress.totalSections + requestsProgress.totalSections,
        completedSections:
          serviceProgress.completedSections +
          requestsProgress.completedSections,
      };
    }, [resolutionCaseRequests, resolutionCase]);

  useEffect(() => {
    const sub = getResolutionCaseRequests(
      clientId,
      resolutionCase.id
    ).subscribe(setResolutionCaseRequests, catchAsyncStacktrace);
    return () => sub.unsubscribe();
  }, [clientId, resolutionCase.id]);

  useEffect(() => {
    const sub = getResolutionCaseHistory(clientId, resolutionCase.id).subscribe(
      (history) => {
        const formattedHistory = formatResolutionCaseHistory(
          resolutionCase,
          history,
          user
        );
        setResolutionCaseHistory(formattedHistory);
      }
    );
    return () => sub.unsubscribe();
  }, [resolutionCase.program_data, resolutionCase.id, clientId, user]);

  function onDelete() {
    deleteResolutionCase(clientId, resolutionCase.id).subscribe(() => {
      history.push(`/client/${clientId}/resolution-cases/list/active`);
    });
  }

  useEffect(() => {
    const subscription = getClientData(clientId).subscribe((res) => {
      setClientInactive(!res.is_active);
    }, catchError());
    return () => subscription.unsubscribe();
  }, [clientId]);

  function calculateDueDatePercentComplete() {
    const { due_date, created_at } = resolutionCase;
    if (!due_date) return 0;
    if (created_at > due_date) {
      return 100;
    } else {
      const percent = Math.floor(
        ((Date.now() - created_at) / (due_date - created_at)) * 100
      );
      if (percent > 100) return 100;
    }
  }

  function calculateSectionProgressComplete() {
    return totalSections
      ? Math.floor((completedSections / totalSections) * 100)
      : 0;
  }

  return (
    <NavContent
      hasTopnavSecondary={false}
      clientMenuPossible={true}
      className={`wide-menu ${styles.summaryNavContent}`}
    >
      <div className={styles.summaryContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.resolutionCaseTitle}>
            <div className="cp-subheader">{resolutionCase.name}</div>
            <div>
              <CpDropdown
                renderTrigger={({ toggle }) => (
                  <CpButton onClick={toggle} icon="misc-gear" aria-label="resolution case options" />
                )}
                renderContent={({ close }) => (
                  <div className="cp-select-list">
                    <Link
                      onClick={close}
                      to={`/taxes/client/${clientId}/resolution-cases/${resolutionCase.id}/details`}
                    >
                      Resolution case details
                    </Link>
                    <button
                      onClick={() => {
                        setShowDeleteConfirm(true);
                        close();
                      }}
                    >
                      Delete resolution case
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.progressContainer}>
            <CpCard className={styles.resolutionCaseDueDateCard}>
              <div className="cp-mb-20">Due date</div>
              <div className={styles.progressCardCallout}>
                {resolutionCase.due_date
                  ? DateTime.fromMillis(resolutionCase.due_date).toFormat(
                      "MMM d, y"
                    )
                  : "No due date"}
              </div>
              {resolutionCase.due_date && (
                <CpProgressBar
                  current={calculateDueDatePercentComplete()}
                  progressMarker="Today"
                  minText={startDateLabel}
                  maxText={endDateLabel}
                />
              )}
            </CpCard>
            <CpCard className={styles.resolutionCaseProgressCard}>
              <div className="cp-mb-20">Resolution case progress</div>
              <div className={styles.progressCardCallout}>
                {calculateSectionProgressComplete()}% Complete
              </div>
              <div>
                <CpProgressBar
                  current={completedSections}
                  currentText={`${completedSections} items complete`}
                  min={0}
                  max={totalSections}
                  minText={`0`}
                  maxText={totalSections?.toString() || "0"}
                />
              </div>
            </CpCard>
          </div>
        </div>
        {!isEmpty(inProgress) && (
          <div className={styles.contentContainer}>
            <div className="cp-mb-12">In progress</div>
            <CpCard>
              {inProgress.map((service) => (
                <ProgressListItem
                  key={service.id}
                  service={service}
                  renderIcon={() => <CpIcon name="shape-circle-dashed" />}
                />
              ))}
            </CpCard>
          </div>
        )}
        {!isEmpty(needReview) && (
          <div className={styles.contentContainer}>
            <div className="cp-mb-12">Needs review</div>
            <CpCard>
              {needReview.map((service) => (
                <ProgressListItem
                  key={service.id}
                  service={service}
                  href={`program/${service.programSlug}/section/${service.slug}`}
                  renderIcon={() => (
                    <CpIcon
                      className="cp-color-app-warning-text"
                      name="shape-circle-open"
                    />
                  )}
                />
              ))}
            </CpCard>
          </div>
        )}
        {!isEmpty(resolutionCaseHistory) && (
          <div className={styles.contentContainer}>
            <div className="cp-mb-12">Resolution case activity</div>
            <CpCard>
              {resolutionCaseHistory.map((activity, index) => {
                const deleted = isDeleted(
                  resolutionCaseHistory,
                  activity.pivot_id
                );
                return (
                  <ActivityListItem
                    activity={activity}
                    clientId={clientId}
                    clientInactive={clientInactive}
                    resolutionCaseId={resolutionCase.id}
                    isDeleted={deleted}
                    key={index}
                  />
                );
              })}
            </CpCard>
          </div>
        )}
      </div>
      <ConfirmDeleteModal
        show={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onSubmit={onDelete}
      />
    </NavContent>
  );
}

function ConfirmDeleteModal({ show, onClose, onSubmit }) {
  return (
    <CpModal show={show} onClose={onClose}>
      <CpModal.Header title="Delete resolution case" />
      <CpModal.Body>
        <p className="cp-wt-semibold">
          By deleting this resolution case you will lose any workpapers,
          comments, statuses, survey answers, and auto-populated forms that were
          produced in this resolution case.
        </p>
        <p className="cp-wt-semibold">
          If you're okay with this then click 'Delete resolution case' below.
        </p>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton onClick={onSubmit} btnType="primary">
          Delete resolution case
        </CpButton>
        <CpButton className="cp-ml-8" btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
