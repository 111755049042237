import { liensConstants } from "./liens.constants";

// Questions
let questions = {
  [liensConstants.PAID_IN_FULL]: {
    questionText: "Has the tax liability been paid in full?",
    type: "boolean",
  },

  [liensConstants.PLANS_AND_MEANS]: {
    questionText:
      "Does your client have plans to pay the tax liability in full and does she/he have the means to do so?",
    type: "boolean",
  },

  [liensConstants.LIEN_WITHDRAWAL]: {
    questionText: `Would a lien withdrawal allow your client to pay the tax liability balance in full?`,
    type: "boolean",
  },

  [liensConstants.INSTALLMENT_LESS_THAN_25000]: {
    questionText:
      "Has your client entered into an installment agreement in which the balance due for the installment agreement $25,000 or less?",
    type: "boolean",
  },

  [liensConstants.INVALID_LIEN]: {
    questionText:
      "Did the IRS file a lien against your client prematurely or not according to IRS procedure?",
    type: "boolean",
  },

  [liensConstants.DISPUTE_TAX]: {
    questionText:
      "Does your client want to dispute the tax owed that is secured by a federal tax lien?",
    type: "boolean",
  },

  [liensConstants.PROPOSE_ALTERNATIVE]: {
    questionText: "Does your client wish to propose a collection alternative?",
    type: "boolean",
  },

  [liensConstants.REASONABLE_CAUSE]: {
    questionText:
      "Does your client have reasonable cause to request abatement of failure to file or failure to pay penalties?",
    type: "boolean",
  },

  [liensConstants.BANKRUPTCY]: {
    questionText:
      "Was the balance of the tax liability discharged in bankruptcy?",
    type: "boolean",
  },

  [liensConstants.SURETY_BOND]: {
    questionText: "Is the client able to obtain a surety bond?",
    type: "boolean",
  },

  [liensConstants.STATUTE_EXPIRED]: {
    questionText:
      "Has the collection statute expired and is the balance due no longer collectable?",
    type: "boolean",
  },

  [liensConstants.PURCHASE_PROPERTY]: {
    questionText:
      "Did your client purchase property that is subject to a lien, and is your client able to furnish a bond or deposit to satisfy the lien?",
    type: "boolean",
  },

  [liensConstants.DOUBLE_VALUE]: {
    questionText:
      "Is the fair market value of other property owned by your client at least double the total of the tax liability owed to the IRS (plus other remaining encumbrances)?",
    type: "boolean",
  },

  [liensConstants.SELL_PROPERTY]: {
    questionText: "Would your client like to sell property held by a lien?",
    type: "boolean",
  },

  [liensConstants.PROCEEDS_TO_IRS]: {
    questionText:
      "Will the proceeds from the sale of property be given to the IRS?",
    type: "boolean",
  },

  [liensConstants.LESS_THAN_ZERO]: {
    questionText:
      "Will the proceeds from the sale of property be equal to or less than zero, and will selling decrease monthly payments and facilitate the payment of tax?",
    type: "boolean",
  },

  [liensConstants.PROCEEDS_IN_ESCROW]: {
    questionText:
      "Will the proceeds from the sale of property be held in escrow?",
    type: "boolean",
  },

  [liensConstants.RESTRUCTURE]: {
    questionText: `Does your client wish to restructure or consolidate existing loans to decrease monthly payments?`,
    type: "boolean",
  },

  [liensConstants.REFINANCING]: {
    questionText: `Will refinancing a loan provide your client with cash to be used for the payment of tax?`,
    type: "boolean",
  },

  [liensConstants.LINE_OF_CREDIT]: {
    questionText: `Does your client wish to obtain a line of credit?`,
    type: "boolean",
  },
};

export const liensQuestionEnhancer = (questionSlug) => {
  return questions[questionSlug];
};
