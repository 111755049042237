import React from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";

export function RestartModal({ onSubmit }) {
  return (
    <>
      <CpModal.Header title="Restart assistant" />
      <CpModal.Body>
        <div>Are you sure you want to restart this assistant?</div>
        <div>This action cannot be undone.</div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton className="cp-mr-8" onClick={() => onSubmit(true)}>
          Restart
        </CpButton>
        <CpButton btnType="flat" onClick={() => onSubmit()}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
